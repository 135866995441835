import React, { useState, useMemo, useEffect } from 'react'
import { userAttributes } from "../../share/localData";
import { DeleteApiwithHeader, GetApiwithHeader, UpdateUserwithHeader, postApiWithHeader } from 'src/Api/Api';
import { CButton, CCard, CCol, CFormTextarea, CNav, CNavItem, CNavLink, CRow, CTabContent, CTabPane } from '@coreui/react';


const Notes = (props) => {
    const codeInfo = props?.codeInfo
    const accessToken = localStorage.getItem("token");
    const { cmpID } = userAttributes();
    const companyId = cmpID;
    const [abn, setAbn] = useState([])
    const [showTextarea, setShowTextarea] = useState(false);
    const [newAddress, setNewAddress] = useState("");
    const [editingAddressId, setEditingAddressId] = useState(null);

    const handleAddAddress = () => {
        setShowTextarea(true);
        setEditingAddressId(null);
    };

    const handleChange = (event) => {
        setNewAddress(event.target.value);
    };

    const headers = useMemo(
        () => ({
            Authorization: `Bearer ${accessToken}`,
        }),
        [accessToken]
    );

    const fetchData = async () => {
        try {
            const res = await GetApiwithHeader(`/api/abn/GetNotes?abn=${codeInfo?.abn}`, headers);
            setAbn(res?.data?.data || []);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const requestData = {
        notes: newAddress,
        abn: codeInfo?.abn // Assuming abn is required by the API
    };


    const handleSaveButtonClick = async () => {
        try {
            const res = editingAddressId ?
                await UpdateUserwithHeader(`/api/abn/Update-Notes/${editingAddressId}`, requestData, headers) :
                await postApiWithHeader(`/api/abn/Create-Notes`, requestData, headers);
            console.log(res);
            if (editingAddressId ? res.statusCode === 100 : res.data.statusCode === 100) {
                setShowTextarea(false);
                setNewAddress("");
                setEditingAddressId(null);
                fetchData();
            } else if (res.data.statusCode === 102) {


            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleEdit = (id, address) => {
        setEditingAddressId(id);
        setNewAddress(address);
        setShowTextarea(true);
    };

    const handleRemove = async (itemId) => {
        try {
            const response = await DeleteApiwithHeader(`/api/abn/Delete-Notes/${itemId}`, headers);
            setAbn(abn.filter(item => item.id !== itemId));

            if (response.statusCode === 100) {
                // toast("Deleted Successfully", { autoClose: 1000 })
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleCancel = () => {
        setShowTextarea(false);
        setNewAddress(""); // Clear textarea value when cancel is clicked
    };

    return <> <CButton onClick={handleAddAddress} className='mt-3 btn-link'>+Add Notes</CButton>
        {showTextarea && (<>
            <CFormTextarea
                id="floatingTextarea"
                floatingLabel="Add Note"
                placeholder="Leave a comment here"
                className="mt-2 mb-2"
                value={newAddress}
                onChange={handleChange}
            />

            <div className="d-flex  justify-content-end text-end">
                <CButton className="mt-2 m-2  btn-outline-gray" onClick={handleCancel}>Cancel</CButton>
                <CButton className="mt-2 m-2" onClick={handleSaveButtonClick}>Save</CButton>
            </div>
        </>
        )}

        {abn && abn.length > 0 && abn?.map(item => (
            <div key={item.id} className=' mt-3 mb-3'>
                <CRow>
                    <CCol>
                        <div className="border-top p-3">
                            <h6>{item.notes}</h6>
                            <div className="text-end ">
                                <CButton className="mt-2 m-2 btn-outline-gray" onClick={() => handleEdit(item.id, item.notes)}>Edit</CButton>
                                <CButton className="mt-2 m-2 btn-outline-gray" onClick={() => handleRemove(item.id)}>Remove</CButton>
                            </div>
                        </div>
                    </CCol>
                </CRow>
            </div>
        ))}
    </>
}
export default Notes