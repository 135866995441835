import React from 'react'
import { createBrowserRouter, Navigate, useLocation } from "react-router-dom";
import Login from './views/pages/login/qrCode';
import DefaultLayout from './layout/DefaultLayout';
import Bank from './views/bank/list';
import Transaction from './views/transactions/List';
import BusniessCode from './views/businessCode/list';
import Invites from './views/invites/list';
import Coding from './views/coding/blp1';
// import Coding from './views/newCoding/coding'; 
import CompanyDetail from './views/companyDetails/CompanyDetail';
import SupportTicket from './views/supportTicket/SupportTicket';
import VerifyCoding from './views/verifyCoding/VerifyCoding';
import AssetEntry from './views/assetEntry/AssetEntry';
import Portfolio from './views/portfolio/Portfolio';
import StandardJournal from './views/standardJournal/StandardJournal';
import Reports from './views/report/Reports';
import Terms from './views/term/Terms';
import PrivacyPolicy from './views/privacy/PrivacyPolicy';
import AboutUs from './views/aboutUs/AboutUs';
import InviteFriend from './views/inviteFriend/InviteFriend';
import HighFre from './views/high/index'
import Receipt from './views/coding/receipt';
import Entry from './views/coding/entry';
import Itim from './views/Itim/itim';
import NavBase from './views/NavBase/NavBase'
import Profile from './views/profile/profile';
import FinancialInstrument  from './views/financialIntruments/List'
import FinancialDetails from './views/financialIntruments/Details'
import Ordering from './views/order/ordering';
import Product from './views/product/Product';
import Customer from './views/customer/Customer';
import Register from './views/Register/Signup';
import VerifyCode from './views/Register/VerifyOtp';
import Thankyou from './views/Register/Thankyou';
import DownloadApk from './views/Register/DownloadApk';
import ForgetPassword from './components/Register/ForgetPassword';
import CreditApplicationForm from './views/creditForm/CreditApplicationForm';
import OrderForm from './components/Register/order';
import Ledger from './views/report/ledger';
import GstReport from './views/report/gstReport'

const ProtectedRoute = ({children}) => {
  const token = localStorage.getItem('token');
  let location = useLocation();

  if(token) {
    return children
  }
  return <Navigate to="/" state={{ from: location}} replace />
};

const UnProtectedRoute = ({children}) => {
  const token = localStorage.getItem('token');
  let location = useLocation();

  if(token) {
    return <Navigate to="/companyDetails" state={{ from: location}} replace />
  }
  return children
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <UnProtectedRoute><Login /></UnProtectedRoute>
  },
  {
    path: "/register",
    element: <Register />
  },
  {
    path: "/verifyOtp",
    element: <VerifyCode />
  },
  {
    path: "/thankYou",
    element: <Thankyou />
  },
  {
    path: "/forget_password",
    element: <ForgetPassword />
  },
  {
    path: "/orders",
    element: <OrderForm />
  },
  {
    path: "/download_apk",
    element:  <DownloadApk />
  },
  {
    path: "/order_details",
    element:  <CreditApplicationForm />
  },
  // {
  //   path: "/download_apk",
  //   element: <UnProtectedRoute><Login /></UnProtectedRoute>
  // },
  {
    path: "/",
    element: <DefaultLayout />,
    children: [
      // {
      //   path: "dashboard",
      //   element: <Dashboard />,
      // },
      {
        path: "companyDetails",
        element: <ProtectedRoute><CompanyDetail/></ProtectedRoute>,
      },
      {
        path: "bank",
        element: <ProtectedRoute><Bank /></ProtectedRoute>,
      },
      // {
      //   path: "bank1",
      //   element: <ProtectedRoute><Bank1 /></ProtectedRoute>,
      // },
      {
        path: "transaction",
        element: <ProtectedRoute><Transaction /></ProtectedRoute>,
      },
      {
        path: "business_code",
        element: <ProtectedRoute><BusniessCode /></ProtectedRoute>,
      },
      {
        path: "high_frequency",
        element: <ProtectedRoute><HighFre /></ProtectedRoute>,
      },
      {
        path: "invites",
        element: <ProtectedRoute><Invites /></ProtectedRoute>,
      },
      {
        path: "receipt",
        element: <ProtectedRoute><Receipt /></ProtectedRoute>,
      },
      {
        path: "coding",
        element: <ProtectedRoute><Coding /></ProtectedRoute>,
      },
      {
        path: "supports",
        element: <ProtectedRoute><SupportTicket/></ProtectedRoute>,
      },
      {
        path: "verifyCode",
        element: <ProtectedRoute><VerifyCoding/></ProtectedRoute>,
      },
      {
        path: "itimCode",
        element: <ProtectedRoute><Itim/></ProtectedRoute>,
      },
      {
        path: "assest",
        element: <ProtectedRoute><AssetEntry/></ProtectedRoute>,
      },
      {
        path: "product",
        element: <ProtectedRoute><Product/></ProtectedRoute>,
      },
      {
        path: "customer",
        element: <ProtectedRoute><Customer/></ProtectedRoute>,
      },
      {
        path: "portfolio",
        element: <ProtectedRoute><Portfolio/></ProtectedRoute>,
      },
      {
        path: "standingJournal",
        element: <ProtectedRoute><StandardJournal/></ProtectedRoute>,
      },
      {
        path: "reports",
        element: <ProtectedRoute><Reports/></ProtectedRoute>,
      },
      {
        path: "ledger",
        element: <ProtectedRoute><Ledger/></ProtectedRoute>,
      },
      {
        path: "gstReport",
        element: <ProtectedRoute><GstReport/></ProtectedRoute>,
      },
      {
        path: "termsAndcondition",
        element: <ProtectedRoute><Terms/></ProtectedRoute>,
      },
      {
        path: "privacypolicy",
        element: <ProtectedRoute><PrivacyPolicy/></ProtectedRoute>,
      },
      {
        path: "aboutus",
        element: <ProtectedRoute><AboutUs/></ProtectedRoute>,
      },
      {
        path: "inviteFriend",
        element: <ProtectedRoute><InviteFriend/></ProtectedRoute>,
      },
      {
        path: "entry",
        element: <ProtectedRoute><Entry/></ProtectedRoute>,
      },
      {
        path: "nowBas",
        element: <NavBase/>,
      },
      {
        path: "profile",
        element: <Profile/>,
      },
      {
        path: "financialInstrument",
        element: <FinancialInstrument/>,
      },
      {
        path: "financialDetails",
        element: <FinancialDetails/>,
      },
      {
        path: "ordering",
        element: <Ordering/>,
      }
      
    ]
  },
  {
    path: "*",
    element: <Navigate to="/" replace />
  }
]);

export default router;
