import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

const filter = createFilterOptions();

export default function AutocompleteComponent({ options, onChange, value, placeholder }) {
    const [val, setValue] = React.useState(null);
    React.useEffect(()=>{setValue(value)},[value])
    return (
        <div className='AutoComplete'>
            <Autocomplete
                value={val}
                onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                        setValue({
                            value: newValue,
                        });
                        onChange({
                            value: newValue,
                        });
                    } else if (newValue && newValue.inputValue) {
                        setValue({
                            value: newValue.inputValue,
                        });
                        onChange({
                            value: newValue.inputValue,
                        });
                    } else {
                        setValue(newValue);
                        onChange(newValue);
                    }
                }}
                onKeyDown={(event) => {
                    // console.log('event.target.value--', event.key);
                    if (event.key === 'Enter') {
                        event.preventDefault();
                        const val = { value: event.target.value };
                        setValue(val);
                        onChange(val);
                    }
                }}
                onBlur={(event) => {
                    if (event.type === 'blur') {
                        event.preventDefault();
                        const val = { value: event.target.value };
                        setValue(val);
                        onChange(val);
                    }
                }}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some(
                    (option) => inputValue === option.value
                    );
                    // if (inputValue !== '' && !isExisting) {
                    //   filtered.push({
                    //     inputValue,
                    //     title: `Edited "${inputValue}"`,
                    //   });
                    // }

                    return filtered;
                }}
                autoComplete
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="free-solo-with-text-demo"
                options={options}
                getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                    return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                    return option.inputValue;
                    }
                    // Regular option
                    return option.value;
                }}
                renderOption={(props, option) => <li {...props}>{option.value}</li>}
                freeSolo
                renderInput={(params) => (
                    <TextField {...params}/>
                )}
                />
        </div>
    );
}