
import React, { useState, useEffect, useMemo } from 'react';
import { Typography, List, ListItem, ListItemText,Divider,Modal, Box,Button } from '@mui/material';
import { CModal, CModalHeader, CModalBody, CModalFooter, CButton,CRow,CCol } from '@coreui/react';
import UploadDoc from './supportAttachment';
import { GetApiwithHeader } from "src/Api/Api";
import { userAttributes } from "../../share/localData";


const Support = (props) => {
  const accessToken = localStorage.getItem("token");
  const {rowdata, handleCloseClick, fetchDataCoding} = props
  const [openModal, setOpenModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  // const { data } = useData();
  const [requestData, setRequestData] = useState()
  const { cmpID, bankID } = userAttributes();

  const headers = useMemo(
    () => ({
      Authorization: `Bearer ${accessToken}`,
    }),
    [accessToken]
  );

  const handleListItemClick = (item) => {
    setSelectedItem(item);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const GetRequestType = async () => {
    try {
      const response = await GetApiwithHeader(
        `/api/support-tickets/Request-Type?companyId=${cmpID}`,
        headers
      );
      const filteredBankData = response?.data?.data;
        setRequestData(filteredBankData)
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    GetRequestType()
  }, [cmpID])
  
  const classes = {
    root: {
      // Add your styles here
    },
    divider: {
     backgroundColor: 'rgba(1, 1, 1, 1.12)', // Adjust divider color
    },
  };
console.log('requestData', requestData);
  return (
    <div className={classes.root}>
      <Typography variant="subtitle1" gutterBottom>
        Please select one of the following topic:
      </Typography>
      <Typography variant="h5" gutterBottom>
       <List component="nav">
        {requestData && requestData?.map((item) => (<>
          <ListItem button onClick={() => handleListItemClick(item)}>
            <ListItemText primary={item.requestType} />
          </ListItem>
          <Divider style={classes.divider} />
            </>
        ))}
      </List>
      </Typography>
      <CModal
        visible={openModal}
        onClose={handleCloseModal}
        className='overflowBox'
      >
        <CModalHeader closeButton>
        <h3>Support Request</h3>
        </CModalHeader>
        <CModalBody className='supportRequestBody'>
          <UploadDoc rowdata={rowdata} selectedItem={selectedItem} handleCloseModal={handleCloseModal} handleCloseClick={handleCloseClick} fetchDataCoding={fetchDataCoding}/>
        </CModalBody>
      </CModal>
    </div>
  );
};

export default Support;
