import React, { useState, useMemo, useCallback, useRef } from "react";
import {
  CContainer,
  CRow,
  CCol,
  CFormLabel,
  CButton,
  CFormTextarea,
  CModal,
  CModalHeader,
  CModalBody,
  CTooltip,
  CFormInput,
} from "@coreui/react";
import {useDropzone} from 'react-dropzone';
import { postApiWithHeader } from "src/Api/Api";
import { userAttributes } from "../../share/localData";
import { toast } from "react-toastify";
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const UploadDoc = (props) => {
  const { selectedItem, handleCloseModal, rowdata, handleCloseClick, fetchDataCoding } = props; // Corrected variable name
  
  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const uploadedDocumentsRef = useRef([]);
  const [description, setDescription] = useState("");

  const { cmpID } = userAttributes();
  const companyId = cmpID;
  const accessToken = localStorage.getItem("token");
  const headersFile = useMemo(
    () => ({
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "multipart/form-data",
    }),
    [accessToken]
  );

  const [openImageViewer, setOpenImageViewer] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);
  const [imageForView, setImageForView] = useState("false");

  const onDrop = useCallback(
    (acceptedFiles) => {
      const filesArray = Array.from(acceptedFiles);
      const uploadedFiles = filesArray.map((file) => ({
        url: URL.createObjectURL(file),
        fileUpload: file,
      }));
      if(uploadedDocumentsRef.current.length > 0){
        uploadedDocumentsRef.current = uploadedDocumentsRef.current.concat(uploadedFiles);
        setUploadedDocuments(uploadedDocumentsRef.current);
      }else{
        uploadedDocumentsRef.current = uploadedFiles;
        setUploadedDocuments(uploadedFiles);
      }
    },
    []
  );

  const {getRootProps, getInputProps, isDragActive} = useDropzone({ accept: { 'image/*': [] }, onDrop});

  const handleCloseImageViewer = () => {
    setOpenImageViewer(false);
  };

  const handleImageViewer = (value, currentIndex) => {
    setImageIndex(currentIndex);
    setImageForView(value);
    setOpenImageViewer(true);
  };

  const handleImagePreview = (index) => {
    const currentIndex = imageIndex + index;
    setImageIndex(currentIndex);
    setImageForView(URL.createObjectURL(uploadedDocuments[currentIndex].fileUpload));
  }

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleDeleteImage = (index) => {
    const updatedArr = uploadedDocumentsRef.current.filter((_, i) => i !== index).map((item)=>({...item, url: URL.createObjectURL(item.fileUpload)}));
    uploadedDocumentsRef.current = updatedArr;
    setUploadedDocuments(updatedArr);
  };

  const handleSave = async () => {
    const formData = new FormData();
    uploadedDocuments.forEach((documentData, index) => {
      const { fileUpload } = documentData;
      formData.append(`fileUploaded`, fileUpload);
    });
    try {
      const response = await postApiWithHeader(
        `api/support-tickets/create/${companyId}?AssigneeId=0&transactionCode=${rowdata?.transactionCode}&Message=${description}&RequestTypeId=${selectedItem?.id}&Status=Raised`,
        formData,
        headersFile
      );
      console.log('response', response?.data);
      if (response?.data?.statusCode === 100) {
        fetchDataCoding();
        toast.success("Support ticket raised.", { autoClose: 1000, onClose: ()=> { 
          handleCloseModal(); 
          handleCloseClick();
          setDescription('');
          setUploadedDocuments([]);
          uploadedDocumentsRef.current = [];
        } });
      }
    } catch (error) {
      console.log("Error:", error);
      toast.error("Error occurred while uploading", { autoClose: 1000 });
    }
  };
  return (
    <div className="addBox2">
      <CContainer>
        <CRow>
          <CCol md="12">
            <div className="mb-3">
              <CFormInput
                id="selectedItem"
                label="Topic"
                rows={3}
                value={selectedItem.requestType}
                disabled
              />
            </div>
          </CCol>
          <CCol md="12">
            <div className="mb-3">
              <CFormTextarea
                id="Description"
                label="Description"
                rows={3}
                onChange={handleDescriptionChange}
              >{description}</CFormTextarea>
            </div>
          </CCol>
          <CCol md="12">
            <CFormLabel>Attachment</CFormLabel>
            <div className="dropzone-multiple-img">
              <div {...getRootProps()} className={`thumb thumb2 dropzone-container}`}>
                <input {...getInputProps()} />
                <p><AddIcon /></p>
              </div>
              {uploadedDocuments.length > 0 && uploadedDocuments.map((documentData, index) => !documentData.isDeleted && (
                <div className="thumb" key={index}>
                  <div className="thumbInner">
                    <CTooltip content="Click for preview">
                      <img
                        src={documentData?.url ? documentData?.url : documentData.filePath}
                        className="img"
                        // Revoke data uri after image is loaded
                        onLoad={() => { URL.revokeObjectURL(documentData?.url ? documentData?.url : documentData.filePath) }}
                        onClick={(e) => handleImageViewer(documentData?.url ? URL.createObjectURL(documentData.fileUpload) : documentData.filePath, index)}
                      />
                    </CTooltip>
                    <button className="closeButton" onClick={() => handleDeleteImage(index)}>
                      <CloseIcon />
                    </button>
                  </div>
                </div>
              )
              )}
            </div>
          </CCol>
          {/* <CCol md="12">
            {uploadedDocuments.length > 0 ? (
              <div className="attachmentdBox">
                {uploadedDocuments.map((documentData, index) => (
                  <div key={index}>
                    <embed
                      src={documentData?.url ? documentData?.url : documentData}
                      style={{ width: "100%", height: "auto" }}
                    />
                    <div className="crossIcon">
                    <button type="button"  onClick={() => handleCancel(index)} class="btn btn-close text-reset" aria-label="Close"></button>
                </div>
                    </div>
                ))}
              </div>
            ) : (
              <div className="text-muted">No documents uploaded</div>
            )}
          </CCol> */}
        </CRow>
        <CRow className="mt-4 justify-content-end">
          <CCol xs="auto">
            <CButton color="light" onClick={handleCloseModal}>
              Cancel
            </CButton>
          </CCol>
          <CCol xs="auto">
            <CButton color="primary" onClick={handleSave}>
              Save
            </CButton>
          </CCol>
        </CRow>

        {/* Model for preview the image */}
        <CModal
          className={"image-viewer modal overflowBox large"}
          visible={openImageViewer}
          onClose={handleCloseImageViewer}
        >
          <CModalHeader>
            <h3>Image Preview  </h3>
          </CModalHeader>
          <CModalBody>
            <div className="imagePreviewButton">
              <button onClick={()=>handleImagePreview(-1)} disabled={imageIndex === 0}>
                <ChevronLeftIcon />
              </button>
              <button onClick={()=>handleImagePreview(1)} disabled={imageIndex === uploadedDocuments.length - 1}>
                <ChevronRightIcon />
              </button>
            </div>
            <div className="text-center">
              <img
                className={"image-viewer img"}
                src={imageForView}
              />
            </div>
          </CModalBody>
        </CModal>
      </CContainer>
    </div>
  );
};

export default UploadDoc;
