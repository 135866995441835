import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
  useRef,
} from "react";
import pako from 'pako';
import { GetApiwithHeader, postApiWithHeader } from "src/Api/Api";
import {
  DataGridPro,
  GridEditInputCell,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridRowEditStopReasons,
  useGridApiRef,
  getGridDateOperators,
  GRID_DATE_COL_DEF,
  GRID_DATETIME_COL_DEF,
} from "@mui/x-data-grid-pro";
import {
  CButton,
  CModalBody,
  CModalHeader,
  CModalFooter,
  CModal,
  CTooltip,
  CSpinner,
} from "@coreui/react";
import AutocompleteEditCells from "./AutoComplete";
import { userAttributes } from "../../share/localData";
import { useData } from "../../components/dataContext";
import OffCanvas from "../../share/offCanvas";
import SplitCanvas from "./splitCanvas";
import Box from "@mui/material/Box";
import UploadDoc from "./uploadDocumnet";
import Support from "./support";
import Popover from "@mui/material/Popover";
import { toast, ToastContainer } from "react-toastify";
import More from "./more";
import { TextField, Typography, Grid, Stack } from "@mui/material";
import IsItim from "./itim";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import HighFrequency from "./highFrequency";
import Checkbox from "@mui/material/Checkbox";
import SupportDetails from "./supportDetails";
import { LicenseInfo } from '@mui/x-license';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { enUS as locale } from 'date-fns/locale';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Add from "../businessCode/Add";
import Select from "react-select";
import RefreshIcon from '@mui/icons-material/Refresh';
import { styled } from '@mui/system';
import { Button } from '@mui/material';
import { format } from "date-fns";

const BasicEditingGrid = (props) => {
  const { data, bankDataLocal } = useData();
  LicenseInfo.setLicenseKey(
    "248a71a5e79c492f4b13769843fcf6dcTz04OTIzMCxFPTE3NDU3NTY1ODYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
  );
  const accessToken = localStorage.getItem("token");
  const { cmpID, bankID, companyName } = userAttributes();
  const companyId = cmpID;
  const [codingData, setCodingData] = useState([]);
  const apiRef = useGridApiRef();
  const [codeData, setCodeData] = useState([]);
  const [canvasOpen, setCanvasOpen] = useState(false);
  const [bankTransactionCode, setBankTransactionCode] = useState();
  const [saveSplit, setSaveSplit] = useState(false);
  const [split, setSplit] = useState(false);
  const [Value, setValue] = useState(null);
  const [canValue, setCanValue] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [gstValue, setGstValue] = useState(true);
  const [checkedRows, setCheckedRows] = useState({});
  const [checkedRowsItim, setCheckedRowsItiM] = useState({});
  const [codeInfo, setCodeInfo] = useState();
  const [rowModesModel, setRowModesModel] = useState({});
  const [codeSelectData, setCodeSelectData] = useState();
  const dataRef = useRef([]);
  const [contextMenu, setContextMenu] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [titleNarration, setTitleNarration] = useState();
  const [rowdata, setRowData] = useState();
  const [searchQuery1, setSearchQuery1] = useState("");
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [selectData, setSelectData] = useState([]);
  const { support, verify, itim, nowBas } = props;
  const CodedValue = bankTransactionCode?.bankEntryCodingResponseDtos;
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [isSpine, setIsSpine] = useState(false);
  const [loadingState, setLoadingState] = useState(true);
  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const uploadedDocumentsRef = useRef([]);
  const [alignment, setAlignment] = useState(1);
  const [label, setLabel] = useState("All");
  const [activeTab, setActiveTab] = useState(0);
  const [statusInfoEl, setStatusInfoEl] = React.useState(null);
  const statusInfoElOpen = Boolean(statusInfoEl);
  const [visible, setVisible] = useState(false);
  const [editValue, setEditValue] = useState(null);
  const [getCategory, setGetCategory] = useState();

  const handleClickOnStatusInfoEl = (event) => {
    setStatusInfoEl(event.currentTarget);
  };
  const handleCloseStatusInfoEl = () => {
    setStatusInfoEl(null);
  };

  const headers = useMemo(
    () => ({
      Authorization: `Bearer ${accessToken}`,
    }),
    [accessToken]
  );

// Function to decompress Gzip data to a json array
const decompressGzipToJson = (data) => {
  try {
    // Decode base64 string to Uint8Array
    const binaryString = atob(data);
    const uint8Array = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      uint8Array[i] = binaryString.charCodeAt(i);
    }

    // Unzip the Uint8Array using pako
    const decompressedData = pako.inflate(uint8Array, { to: 'string' });

    // Parse the decompressed data as JSON
    const jsonData = JSON.parse(decompressedData);
    return jsonData;
  } catch (e) {
    console.log('Error decompressing Gzip data:', e);
    return [];
  }
};

  const fetchData = async (isLoader = undefined) => {
    if(isLoader === undefined){
      setLoadingState(true);
    }
    try {
      const res = await GetApiwithHeader(
        `/api/BankTransactions/GetAll_new/${companyId},12-01-2024?bankAccountId=${bankID ? bankID : 0}`,
        headers
      );
      // if (res?.data && res?.data?.data) {
      setLoadingState(false);
      // }
      // Convert base64 json zip string into json array
      const statementList = await decompressGzipToJson(res?.data?.data);
      // const statementList = res?.data?.data;
      // console.log("statementList-----",statementList);

      const dataNew =
      statementList && statementList.length > 0 ?
      statementList?.map((item) => {
        const amt = item?.depositAmount !== "0"
        ? item?.depositAmount
        : item?.withdrawalAmount !== "0"
          ? item?.withdrawalAmount
          : null;
          // const formattedDate = new Date(item.valueDate).toLocaleDateString('en-GB');
          // const dateStr = item.valueDate;
          // const formattedDate = dateStr.split('T')[0].split('-').reverse().join('/');
          const dateStr = item?.valueDate;
          const [year, month, day] = dateStr.split('T')[0].split('-');
          const formattedDate = `${day}/${month}/${year}`;

        return {
          ...item,
          date : item.valueDate,
          amount: JSON.parse(amt.replace(',','')),
          amountSave: JSON.parse(amt.replace(',','')),
          grossAmountSave: JSON.parse(amt.replace(',','')), 
          balanceSave: JSON.parse(item?.balance),
          isSplit: item?.bankEntryCodingResponseDtos[0]?.isSplit ? true : false,
          codeName: item?.bankEntryCodingResponseDtos[0]?.businessCodeName ? item?.bankEntryCodingResponseDtos[0]?.businessCodeName : "",
          netAmount: item?.bankEntryCodingResponseDtos[0]?.netAmount ? item?.bankEntryCodingResponseDtos[0]?.netAmount : "",
          gstAmount: item?.bankEntryCodingResponseDtos[0]?.gstAmount ? item?.bankEntryCodingResponseDtos[0]?.gstAmount : "",
        }
      }) : [];

      if (dataNew && dataNew?.length > 0) {
        if (support && support) {
          const filteredRoles =
            dataNew && dataNew?.filter((item) => item?.isSupportTicket);
          setCodingData(filteredRoles);
        } else if (verify && verify) {
          const filteredRoles =
            dataNew && dataNew?.filter((item) => item?.isVerificationPending);
          setCodingData(filteredRoles);
        } else if (itim && itim) {
          const filteredRoles =
            dataNew && dataNew?.filter((item) => item?.isITIM);
          setCodingData(filteredRoles);
        } else if (nowBas && nowBas) {
          const filteredRoles =
            dataNew &&
            dataNew?.filter(
              (item) => item?.mappingStatus?.toLowerCase() === "uncoded" || (item?.bankEntryCodingResponseDtos?.length > 0 ? item?.bankEntryCodingResponseDtos.filter(item => item.isBasCode).length > 0 : false)
            );
          setCodingData(filteredRoles);
        } else {
          setCodingData(dataNew && dataNew);
        }
      } else {
        setCodingData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updatedTransactionData = (data) => {
    const replacedArray = codingData.map(obj2 => {
      const match = data.find(obj1 => obj1.id === obj2.id);
      if(match){
        return {...obj2, bankEntryCodingResponseDtos: match.bankEntryCodingResponseDtos}
      }
      return obj2;
    });
    setRowSelectionModel([])
    setCodingData(replacedArray);
  };

  const codeFetchApi = async () => {
    try {
      const response = await GetApiwithHeader(
        `/api/BusinessCodes?companyId=${companyId}`,
        headers
      );

      const filteredBankData = response?.data?.data;
      if (filteredBankData) {
        if (nowBas === "nowBas") {
          const filteredRoles =
            filteredBankData &&
            filteredBankData?.filter((item) => item?.isBasCode);
          setCodeData(filteredRoles);
        } else {
          setCodeData(filteredBankData);
        }
      } else {
        console.error("Data not found in the response:", response);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
    codeFetchApi();
    setValue(1);
  }, [headers, cmpID, bankID, activeTab]);

  useEffect(() => {
    if (CodedValue?.length < 2) {
      setSaveSplit(false);
    } else {
      setSaveSplit(true);
    }
  }, [CodedValue]);

  useEffect(() => {
    setValue(
      bankTransactionCode?.depositAmount !== "0"
        ? bankTransactionCode?.depositAmount
        : bankTransactionCode?.withdrawalAmount !== "0"
          ? Math.abs(bankTransactionCode?.withdrawalAmount)
          : null
    );
  }, [bankTransactionCode]);

  const options = codeData?.map((item) => ({
    ...item,
    value: item.id,
    label: item.codeName,
    isInsurance: item.isInsurance,
    gst: item.gst,
  }));

  const optionsIn = codeData
    ?.filter((itm) => itm.entryType === "In")
    .map((item) => ({
      ...item,
      value: item.id,
      label: item.codeName,
      isInsurance: item.isInsurance,
      gst: item.gst,
    }));

  const optionsOut = codeData
    ?.filter((itm) => itm.entryType === "Out")
    .map((item) => ({
      ...item,
      value: item.id,
      label: item.codeName,
      isInsurance: item.isInsurance,
      gst: item.gst,
    }));

  const handleHelpCanvas = (params) => {
    setRowData(params);
    setCanvasOpen(true);
    setCanValue("Support Request");
  };

  const handleAttachment = (params) => {
    setRowData(params);
    setCanvasOpen(true);
    setCanValue("Attachment");
  };

  const handleClick = (event, params) => {
    setCodeInfo(params);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleSplit = (row) => {
    const updatedRow = {
      ...row,
      bankEntryCodingResponseDtos:
        row.bankEntryCodingResponseDtos &&
          row.bankEntryCodingResponseDtos.length > 0
          ? row.bankEntryCodingResponseDtos.sort((a, b) => a.id - b.id)
          : row.bankEntryCodingResponseDtos,
    };
    setBankTransactionCode(updatedRow);
    const newCheckedRows = { ...checkedRows };
    newCheckedRows[row.id] = !checkedRows[row.id]; // Assuming each row has a unique identifier like 'id'
    setCheckedRows(newCheckedRows);
    setSplit(true);
    setCanvasOpen(true);
    setCanValue("Split");
    setSaveSplit(true);
  };

  const handleItim = (row) => {
    setBankTransactionCode(row);
    const newCheckedRows = { ...checkedRowsItim };
    newCheckedRows[row.id] = !checkedRowsItim[row.id]; // Assuming each row has a unique identifier like 'id'
    setCheckedRowsItiM(newCheckedRows);
    setCanvasOpen(true);
    setCanValue("ITIM");
  };

  const handleSupportDetails = (params) => {
    setCanvasOpen(true);
    setRowData(params);
    setCanValue("Support Details");
  };

  const handleCloseClick = () => {
    setUploadedDocuments([]);
    uploadedDocumentsRef.current = [];
    setCanvasOpen(false);
    setCheckedRows({});
    setSaveSplit(false);
    setBankTransactionCode();
  };
  const funCodeData = (newValue) => {
    setCodeSelectData(newValue);
  };

  const processRowUpdate = async (newRow) => {
    if(newRow.bankEntryCodingResponseDtos && newRow.bankEntryCodingResponseDtos.length > 0 && newRow.bankEntryCodingResponseDtos[0].isSplit === true){
      return newRow;
    }
    const value =
      newRow?.depositAmount !== "0"
        ? newRow?.depositAmount
        : newRow?.withdrawalAmount !== "0"
          ? newRow?.withdrawalAmount
          : null;
    const requestBody = [
      {
        transactionCode: newRow?.transactionCode,
        businessCodeId: newRow?.bankEntryCodingResponseDtos[0]?.businessCodeId,
        abn: newRow?.abn ? newRow?.abn : "",
        splitDefinition: JSON.stringify({
          amount: value,
          splitType: "$",
          splitCount: 1,
          split1_percent: 100,
          split2_percent: 0,
          split3_percent: 0,
          split4_percent: 0,

          split1_amount: value,
          split2_amount: 0,
          split3_amount: 0,
          split4_amount: 0,
        }),
        splitAmount: value,
        gstAmount: newRow?.bankEntryCodingResponseDtos[0]?.gstAmount,
        netAmount: newRow?.bankEntryCodingResponseDtos[0]?.netAmount,
        receiptId: newRow?.receiptId,
        narration: "", //newRow?.narration
        isVerificationPending: newRow?.isVerificationPending,
        isInsurance: newRow?.isInsurance,
        IsGSTAltered: newRow?.bankEntryCodingResponseDtos[0]?.IsGSTAltered,
      },
    ];

    const response = await postApiWithHeader(
      `/api/bankentrycodings/create/${companyId}`,
      requestBody,
      headers
    );
    try {
      if (response?.data?.statusCode === 100) {
        // fetchData();
        return newRow;
      }
    } catch (error) {
      console.error("Error:", error);
      toast.warning(response?.data?.message, { autoClose: 1000 });
    }
  };

  const handleRequestToBookKeeper = async (e, params) => {
    if (!params.isVerificationPending) {
      if (params.bankEntryCodingResponseDtos && params.bankEntryCodingResponseDtos.length > 0) {
        let payload = [];
        params?.bankEntryCodingResponseDtos?.map((item, index) => {
          const splitDef = item.splitDefinition;
          const abc = {
            transactionCode: params?.transactionCode,
            businessCodeId: item?.businessCodeId,
            splitDefinition: splitDef,
            splitAmount: item?.netAmount + item.gstAmount,
            gstAmount: item.gstAmount,
            IsGSTAltered: item.isGSTAltered,
            netAmount: item.netAmount,
            receiptId: item.receiptId,
            narration: params?.narration,
            isVerificationPending: true,
            isInsurance: item?.isInsurance,
          };
          payload.push(abc);
        });
        const response = await postApiWithHeader(
          `/api/bankentrycodings/create/${companyId}`,
          payload,
          headers
        );
        try {
          if (response?.data?.statusCode === 100) {
            toast.success("Submitted for verification.", { autoClose: 1000 });
            fetchData();
          } else {
            toast.warning(response?.data?.message, { autoClose: 1000 });
          }
        } catch (error) {
          console.error("Error:", error);
          toast.warning(response?.data?.message, { autoClose: 1000 });
        }
      } else {
        toast.warning("Please coded the entry.", { autoClose: 1000 });
      }
    } else {
      toast.warning("Already send for verification.", { autoClose: 1000 });
    }
  };

  const validateGST = (data) => {
    const gstValue = data?.props?.value;
    const grossValue =
      data?.row?.depositAmount !== "0"
        ? data?.row?.depositAmount
        : Math.abs(data?.row?.withdrawalAmount);
    if (gstValue <= grossValue) {
      return gstValue;
    }
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const dateAdapter = new AdapterDateFns({ locale });

  const dateColumnType = {
    ...GRID_DATE_COL_DEF,
    resizable: false,
    renderEditCell: (params) => {
      // console.log('params------------*-------', params)
      // return <GridEditDateCell {...params} />;
    },
    filterOperators: getGridDateOperators(false).map((item) => ({
      ...item,
      InputComponent: GridFilterDateInput,
      InputComponentProps: { showTime: false },
    })),
    valueFormatter: (value) => {
      if (value) {
        return format(value, "dd/MM/yyyy");
      }
      return "";
    },
  };

  function GridFilterDateInput(props) {
    const { item, showTime, applyValue, apiRef } = props;

    const Component = showTime ? DateTimePicker : DatePicker;

    const handleFilterChange = (newValue) => {
      applyValue({ ...item, value: newValue });
    };

    return (
      <Component
        value={item.value ? new Date(item.value) : null}
        autoFocus
        label={apiRef.current.getLocaleText("filterPanelInputLabel")}
        slotProps={{
          textField: {
            variant: "standard",
          },
          inputAdornment: {
            sx: {
              "& .MuiButtonBase-root": {
                marginRight: -1,
              },
            },
          },
        }}
        onChange={handleFilterChange}
      />
    );
  }

  const columns = [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      headerClassName: "dataGridHeader",
      cellClassName: (params) => {
         /**support ticket */
         if (params.row.isSupportTicket) {
          return "supportColorCode";
        }
        /**ITIM */
        if (params.row.isITIM) {
          return "itemColorCode";
        }
        /**verified */
        if (params.row.isVerificationPending) {
          return "varifyColorCode";
        }
        /**Coded */
        if (params.row.bankEntryCodingResponseDtos.length > 0) {
          return "codeColorCode";
        }
        return "";
      },
    },
    {
      field: "transactionCode",
      headerName: "Transcation Id",
      type: "text",
      width: 80,
      editable: false,
      // align: "left",
      // headerAlign: "center",
      resizable: true,
      headerClassName: "dataGridHeader",
    },
    {
      field: "entryCode",
      headerName: "Entry",
      type: "text",
      width: 80,
      editable: false,
      // align: "left",
      // headerAlign: "center",
      resizable: true,
      headerClassName: "dataGridHeader",
    },
    {
      field: "date",
      ...dateColumnType,
      headerName: "Date",
      type: "Date",
      width: 120,
      editable: false,
      // align: "center",
      // headerAlign: "center",
      valueGetter: (params) => {
        return new Date(params);
      },
      resizable: true,
      headerClassName: "dataGridHeader",
    },
    {
      field: "amountSave",
      headerName: "Amount",
      type: "text",
      width: 80,
      editable: false,
      align: "left",
      resizable: true,
      headerClassName: "dataGridHeader",
    },
    {
      field: "narration",
      headerName: "Description",
      type: "text",
      width: 450,
      editable: false,
      resizable: true,
      headerClassName: "dataGridHeader",
    },
    {
      field: "balanceSave",
      headerName: "Balance",
      type: "text",
      width: 80,
      editable: false,
      headerClassName: "dataGridHeader",
    },
    {
      type: "boolean",
      field: "isSplit",
      headerName: "Is Split?",
      // type: "text",
      width: 80,
      editable: false,
      align: "center",
      headerClassName: "dataGridHeader",
      renderCell: (params) => {
        return (
          <div className="actionMapping">
            {params?.row?.bankEntryCodingResponseDtos[0]?.isSplit === true ? (
              <CTooltip content="Split Entry">
                <span
                  className="icon-split"
                  onClick={() => handleSplit(params.row)}
                ></span>
              </CTooltip>
            ) : (
              <Checkbox
                onClick={() => handleSplit(params.row)}
                type="checkbox"
                checked={
                  params.row.bankEntryCodingResponseDtos &&
                    params.row.bankEntryCodingResponseDtos[0]?.isSplit
                    ? true
                    : checkedRows[params.row.id] || false
                }
              ></Checkbox>
            )}
          </div>
        );
      },
    },
    {
      field: "codeName",
      headerName: "Code Name",
      width: 200,
      editable: true,
      headerClassName: "dataGridHeader",
      renderCell: (params) => {
        const businessCodeName =
          params?.row?.bankEntryCodingResponseDtos[0]?.businessCodeName;
        const isSplit = params?.row?.bankEntryCodingResponseDtos[0]?.isSplit;
        if (isSplit) {
          return "";
        }
        const valueOption = params?.valueOptions?.find(
          (option) => option.value === businessCodeName
        );
        return businessCodeName
          ? businessCodeName
          : valueOption
            ? valueOption.label
            : "";
      },
      renderEditCell: (params) => {
        let amt = params?.row.amountSave;
        let opt = options;
        if (amt > 0 && nowBas === "nowBas") {
          opt = optionsIn;
        }
        if (amt < 0 && nowBas === "nowBas") {
          opt = optionsOut;
        }
        return (
          <AutocompleteEditCells
            {...params}
            value={codeSelectData?.label}
            disable={params?.row?.bankEntryCodingResponseDtos[0]?.isSplit} //entryType
            options={opt}
            freeSolo={false}
            multiple={false}
            selectVal={params}
            fetchData={fetchData}
            funCodeData={(newValue) => {
              const updatedArr = codingData.map((item) => {
                if (params.row.id === item.id) {
                  const value =
                    item?.depositAmount !== "0"
                      ? item?.depositAmount
                      : item?.withdrawalAmount !== "0"
                        ? item?.withdrawalAmount
                        : null;
                  let gstAmount = 0;
                  let netAmount = Math.abs(value);
                  if (newValue.gst === "Normal") {
                    gstAmount = Math.abs(value) / 11;
                    netAmount = Math.abs(value) - gstAmount;
                  }
                  const updatedValue = {
                    ...item,
                    bankEntryCodingResponseDtos:
                      item.bankEntryCodingResponseDtos &&
                        item.bankEntryCodingResponseDtos.length > 0
                        ? [
                          {
                            ...newValue,
                            ...item.bankEntryCodingResponseDtos[0],
                            netAmount,
                            gstAmount,
                            businessCodeName: newValue.label,
                            businessCodeId: newValue.value,
                            gst: newValue.gst,
                            splitDefinition: JSON.stringify({
                              amount: value,
                              splitType: "$",
                              splitCount: 1,
                              split1_percent: 100,
                              split2_percent: 0,
                              split3_percent: 0,
                              split4_percent: 0,
                    
                              split1_amount: value,
                              split2_amount: 0,
                              split3_amount: 0,
                              split4_amount: 0,
                            }),
                            receiptId: item?.receiptId,
                            isInsurance: item?.isInsurance,
                            IsGSTAltered: false
                          },
                        ]
                        : [
                          {
                            ...newValue,
                            netAmount,
                            gstAmount,
                            businessCodeName: newValue.label,
                            businessCodeId: newValue.value,
                            gst: newValue.gst,
                            splitDefinition: JSON.stringify({
                              amount: value,
                              splitType: "$",
                              splitCount: 1,
                              split1_percent: 100,
                              split2_percent: 0,
                              split3_percent: 0,
                              split4_percent: 0,
                    
                              split1_amount: value,
                              split2_amount: 0,
                              split3_amount: 0,
                              split4_amount: 0,
                            }),
                            receiptId: item?.receiptId,
                            isInsurance: item?.isInsurance,
                            IsGSTAltered: false
                          },
                        ],
                  };
                  return updatedValue;
                }
                return item;
              });
              setCodingData(updatedArr);
              setCodeSelectData(newValue);
            }}
            disableClearable
            headers={headers}
            companyId={companyId}
          />
        );
      },
    },
    {
      type: "number",
      field: "grossAmountSave",
      headerName: "Gross",
      // type: "text",
      width: 80,
      editable: false,
      headerClassName: "dataGridHeader",
    },
    {
      type: "number",
      field: "netAmount",
      headerName: "Net",
      width: 60,
      editable: false,
      headerClassName: "dataGridHeader",
      renderCell: (params) => {
        let splitNetTotal = 0
        const totalNet = params?.row?.bankEntryCodingResponseDtos.map((item)=>{
          splitNetTotal += item?.netAmount
        })
        let netAmount = params.row.bankEntryCodingResponseDtos[0]?.netAmount;
        netAmount = netAmount ? parseFloat(netAmount).toFixed(2) : netAmount;
        return params?.row?.bankEntryCodingResponseDtos[0]?.isSplit
          ? splitNetTotal
          : netAmount;
      },
    },
    {
      type: "number",
      field: "gstAmount",
      headerName: "GST",
      width: 90,
      editable: true,
      headerClassName: "dataGridHeader",
      renderCell: (params) => {
        let splitGstTotal = 0
        const totalGst = params?.row?.bankEntryCodingResponseDtos.map((item)=>{
          splitGstTotal += item?.gstAmount
        })
        if (params?.row?.bankEntryCodingResponseDtos[0]?.isSplit) return splitGstTotal;
        const gstAmt = params?.row?.bankEntryCodingResponseDtos[0]?.gstAmount;
        return gstAmt ? gstAmt.toFixed(2) : gstAmt;
      },
      renderEditCell: (params) => {
        if (
          params.row.bankEntryCodingResponseDtos[0]?.isSplit ||
          params.row.bankEntryCodingResponseDtos[0]?.gst !== "Normal"
        ) {
          return <TextField id="myInput" disabled />;
        }
        const gstAmt = params?.row?.bankEntryCodingResponseDtos[0]?.gstAmount;
        return (
          <GridEditInputCell
            onValueChange={(event) => {
              setGstValue(event.target.value);
              let grossValue =
                params?.row?.depositAmount !== "0"
                  ? Math.abs(params?.row?.depositAmount)
                  : Math.abs(params?.row?.withdrawalAmount);
              grossValue = grossValue.toFixed(2);
              let gstAmount = parseFloat(event.target.value);
              let IsGSTAltered = false;
              let netAmount = grossValue - parseFloat(event.target.value);
              if (
                params.row.bankEntryCodingResponseDtos &&
                params.row.bankEntryCodingResponseDtos.length > 0 &&
                params.row.bankEntryCodingResponseDtos[0].gstAmount !==
                event.target.value
              ) {
                IsGSTAltered = true;
              } else {
                IsGSTAltered = false;
              }

              const updatedArr = codingData.map((item) => {
                if (params.row.id === item.id) {
                  return {
                    ...item,
                    IsGSTAltered,
                    bankEntryCodingResponseDtos:
                      item.bankEntryCodingResponseDtos &&
                        item.bankEntryCodingResponseDtos.length > 0
                        ? [
                          {
                            ...item.bankEntryCodingResponseDtos[0],
                            netAmount,
                            gstAmount,
                            IsGSTAltered,
                          },
                        ]
                        : [{ netAmount, gstAmount, IsGSTAltered }],
                  };
                }
                return item;
              });
              setCodingData(updatedArr);
            }}
            {...params}
            value={gstAmt ? gstAmt.toFixed(2) : gstAmt}
          />
        );
      },
      preProcessEditCellProps: (params) => {
        const isValid = validateGST(params);
        return {
          ...params.props,
          error: !isValid,
        };
      },
    },
    {
      type: "boolean",
      field: "isITIM",
      headerName: "Is ITIM?",
      // type: "text",
      width: 60,
      editable: false,
      align: "center",
      headerClassName: "dataGridHeader",
      renderCell: (params) => {
        return (
          <div>
            <Checkbox
              onClick={() => handleItim(params.row)}
              type="checkbox"
              checked={
                params.row.isITIM && params.row.isITIM
                  ? true
                  : checkedRows[params.row.codeID] || false
              }
            ></Checkbox>
          </div>
        );
      },
    },
    {
      field: "itim",
      headerName: "ITIM",
      type: "text",
      width: 100,
      editable: false,
      align: "left",
      headerClassName: "dataGridHeader",
      renderCell: (params) => {
        const abc = params?.row?.itim !== "0" ? params?.row?.itim : "";
        return params.row.isITIM ? abc : "";
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 120,
      headerClassName: "dataGridHeader",
      renderCell: (params) => {
        // const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        return (
          <div className="actionMapping">
            {support || verify || itim ? (
              ""
            ) : (
              <>
                {params?.row?.isSupportTicket ? (
                  <button
                    className="editIcon"
                    onClick={() => {
                      handleSupportDetails(params?.row);
                    }}
                  >
                    {/* <CImage src={supportRaised} alt="verified" style={{verticalAlign:"baseline"}} /> */}
                    {(params?.row?.supportStatus === "Raised" ||
                      params?.row?.supportStatus === "Pending") && (
                        <CTooltip content="Under Process">
                          <span className="icon-raised"></span>
                        </CTooltip>
                      )}
                    {params?.row?.supportStatus === "Reopen" && (
                      <CTooltip content="Reopen">
                        <span className="icon-reopen"></span>
                      </CTooltip>
                    )}
                    {params?.row?.supportStatus === "Resolved" && (
                      <CTooltip content="Resolved">
                        <span className="icon-Resolved"></span>
                      </CTooltip>
                    )}
                    {(params?.row?.supportStatus === "Closed" ||
                      params?.row?.supportStatus === "Close") && (
                        <CTooltip content="Closed">
                          <span className="icon-closed"></span>
                        </CTooltip>
                      )}
                  </button>
                ) : (
                  <>
                    {(params?.row?.supportStatus === "Raised" ||
                      params?.row?.supportStatus === "Pending") && (
                        <button
                          className="editIcon"
                          onClick={() => {
                            handleSupportDetails(params?.row);
                          }}
                        >
                          <CTooltip content="Under Process">
                            <span className="icon-raised"></span>
                          </CTooltip>
                        </button>
                      )}
                    {params?.row?.supportStatus === "Resolved" && (
                      <button
                        className="editIcon"
                        onClick={() => {
                          handleSupportDetails(params?.row);
                        }}
                      >
                        <CTooltip content="Resolved">
                          <span className="icon-Resolved"></span>
                        </CTooltip>
                      </button>
                    )}
                    {params?.row?.supportStatus === "Reopen" && (
                      <button
                        className="editIcon"
                        onClick={() => {
                          handleSupportDetails(params?.row);
                        }}
                      >
                        <CTooltip content="Reopen">
                          <span className="icon-reopen"></span>
                        </CTooltip>
                      </button>
                    )}
                    {(params?.row?.supportStatus === "Closed" ||
                      params?.row?.supportStatus === "Close") && (
                        <button
                          className="editIcon"
                          onClick={() => {
                            handleSupportDetails(params?.row);
                          }}
                        >
                          <CTooltip content="Closed">
                            <span className="icon-closed"></span>
                          </CTooltip>
                        </button>
                      )}
                    {(params?.row?.supportStatus === null ||
                      !params?.row?.supportStatus) && (
                        <button
                          className="editIcon"
                          onClick={() => handleHelpCanvas(params?.row)}
                        >
                          <CTooltip content="Need Support">
                            <span className="icon-toraise"></span>
                          </CTooltip>
                        </button>
                      )}
                  </>
                )}
                {params?.row?.isVerificationPending ? (
                  <>
                    {params?.row?.verificationStatus ===
                      "Under Verification" && (
                        <CTooltip content="Under Verification">
                          <button>
                            <span class="icon-underverification"></span>
                          </button>
                        </CTooltip>
                      )}
                    {params?.row?.verificationStatus === "Verified" && (
                      <CTooltip content="Verified">
                        <button>
                          <span class="icon-verified"></span>
                        </button>
                      </CTooltip>
                    )}
                    {(!params?.row?.verificationStatus ||
                      params?.row?.verificationStatus === null) && (
                        <CTooltip content="Under Verification">
                          <button>
                            <span class="icon-underverification"></span>
                          </button>
                        </CTooltip>
                      )}
                  </>
                ) : (
                  <>
                    {/* <CTooltip content="Verify it">
                        <button>
                          <span class="icon-Verifiyit" onClick={(e) => handleRequestToBookKeeper(e, params.row)}></span>
                        </button>
                      </CTooltip> */}
                    {(!params?.row?.verificationStatus ||
                      params?.row?.verificationStatus === null) && (
                        <CTooltip content="Verifiy It">
                          <button>
                            <span
                              class="icon-Verifiyit"
                              onClick={(e) =>
                                handleRequestToBookKeeper(e, params.row)
                              }
                            ></span>
                          </button>
                        </CTooltip>
                      )}
                    {params?.row?.verificationStatus ===
                      "Under Verification" && (
                        <CTooltip content="Under Verification">
                          <button>
                            <span class="icon-underverification"></span>
                          </button>
                        </CTooltip>
                      )}
                    {params?.row?.verificationStatus === "Verified" && (
                      <CTooltip content="Verified">
                        <button>
                          <span class="icon-verified"></span>
                        </button>
                      </CTooltip>
                    )}
                  </>
                )}
              </>
            )}
            {support && (
              <>
                {params?.row?.isSupportTicket ? (
                  <button
                    className="editIcon"
                    onClick={() => {
                      handleSupportDetails(params?.row);
                    }}
                  >
                    {/* <CImage src={supportRaised} alt="verified" style={{verticalAlign:"baseline"}} /> */}
                    {(params?.row?.supportStatus === "Raised" ||
                      params?.row?.supportStatus === "Pending") && (
                        <CTooltip content="Under Process">
                          <span className="icon-raised"></span>
                        </CTooltip>
                      )}
                    {params?.row?.supportStatus === "Reopen" && (
                      <CTooltip content="Reopen">
                        <span className="icon-reopen"></span>
                      </CTooltip>
                    )}
                    {params?.row?.supportStatus === "Resolved" && (
                      <CTooltip content="Resolved">
                        <span className="icon-Resolved"></span>
                      </CTooltip>
                    )}
                    {(params?.row?.supportStatus === "Closed" ||
                      params?.row?.supportStatus === "Close") && (
                        <CTooltip content="Closed">
                          <span className="icon-closed"></span>
                        </CTooltip>
                      )}
                  </button>
                ) : (
                  <>
                    {(params?.row?.supportStatus === "Raised" ||
                      params?.row?.supportStatus === "Pending") && (
                        <button
                          className="editIcon"
                          onClick={() => {
                            handleSupportDetails(params?.row);
                          }}
                        >
                          <CTooltip content="Under Process">
                            <span className="icon-raised"></span>
                          </CTooltip>
                        </button>
                      )}
                    {params?.row?.supportStatus === "Resolved" && (
                      <button
                        className="editIcon"
                        onClick={() => {
                          handleSupportDetails(params?.row);
                        }}
                      >
                        <CTooltip content="Resolved">
                          <span className="icon-Resolved"></span>
                        </CTooltip>
                      </button>
                    )}
                    {params?.row?.supportStatus === "Reopen" && (
                      <button
                        className="editIcon"
                        onClick={() => {
                          handleSupportDetails(params?.row);
                        }}
                      >
                        <CTooltip content="Reopen">
                          <span className="icon-reopen"></span>
                        </CTooltip>
                      </button>
                    )}
                    {(params?.row?.supportStatus === "Closed" ||
                      params?.row?.supportStatus === "Close") && (
                        <button
                          className="editIcon"
                          onClick={() => {
                            handleSupportDetails(params?.row);
                          }}
                        >
                          <CTooltip content="Closed">
                            <span className="icon-closed"></span>
                          </CTooltip>
                        </button>
                      )}
                    {(params?.row?.supportStatus === null ||
                      !params?.row?.supportStatus) && (
                        <button
                          className="editIcon"
                          onClick={() => handleHelpCanvas(params?.row)}
                        >
                          <CTooltip content="Need Support">
                            <span className="icon-toraise"></span>
                          </CTooltip>
                        </button>
                      )}
                  </>
                )}
              </>
            )}
            {verify && (
              <>
                {params?.row?.isVerificationPending ? (
                  <>
                    {params?.row?.verificationStatus ===
                      "Under Verification" && (
                        <CTooltip content="Verify it">
                          <button
                            onClick={() => {
                              setRowData(params?.row);
                              setIsConfirmModalVisible(true);
                            }}
                          >
                            <span class="icon-underverification"></span>
                          </button>
                        </CTooltip>
                      )}
                    {params?.row?.verificationStatus === "Verified" && (
                      <CTooltip content="Verified">
                        <button>
                          <span class="icon-verified"></span>
                        </button>
                      </CTooltip>
                    )}
                    {(!params?.row?.verificationStatus ||
                      params?.row?.verificationStatus === null) && (
                        <CTooltip content="Verify it">
                          <button
                            onClick={() => {
                              setRowData(params?.row);
                              setIsConfirmModalVisible(true);
                            }}
                          >
                            <span class="icon-underverification"></span>
                          </button>
                        </CTooltip>
                      )}
                  </>
                ) : (
                  <>
                    {(!params?.row?.verificationStatus ||
                      params?.row?.verificationStatus === null) && (
                        <CTooltip content="Verifiy It">
                          <button>
                            <span
                              class="icon-Verifiyit"
                              onClick={(e) =>
                                handleRequestToBookKeeper(e, params.row)
                              }
                            ></span>
                          </button>
                        </CTooltip>
                      )}
                    {params?.row?.verificationStatus ===
                      "Under Verification" && (
                        <CTooltip content="Verify">
                          <button>
                            <span class="icon-underverification"></span>
                          </button>
                        </CTooltip>
                      )}
                    {params?.row?.verificationStatus === "Verified" && (
                      <CTooltip content="Verified">
                        <button>
                          <span class="icon-verified"></span>
                        </button>
                      </CTooltip>
                    )}
                  </>
                )}
              </>
            )}
            <CTooltip content="Attachment">
              <button>
                <span
                  class="icon-attached"
                  onClick={() => handleAttachment(params.row)}
                ></span>
              </button>
            </CTooltip>
            <CTooltip content="Code Info">
              <button>
                <span
                  class="icon-more"
                  onClick={(e) => handleClick(e, params.row)}
                ></span>
              </button>
            </CTooltip>
          </div>
        );
      },
    },
  ];

  const handleEditCellChange = useCallback(
    ({ id, field, props }) =>
      (newValue) => {
        const updatedRows = codingData?.map((row) => {
          if (row.id === id) {
            return { ...row, [field]: newValue };
          }
          return row;
        });
        // console.log(updatedRows);
      },
    [codingData]
  );

  const handleContextMenu = (event) => {
    const arr = [];
    event.preventDefault();
    const cellContents =
      event.currentTarget.querySelectorAll(".MuiDataGrid-cell");
    cellContents.forEach((cellContent, index) => {
      const title = cellContent.getAttribute("title");
      // console.log('title', title)
      arr.push(title);
    });
    const newRows = codingData?.filter((row) => {
    //  console.log('row', row.transactionCode, arr)
      return row.transactionCode === arr[1];
    });
    setTitleNarration(newRows);
    if (
      newRows &&
      newRows.length > 0 &&
      newRows[0].bankEntryCodingResponseDtos &&
      newRows[0].bankEntryCodingResponseDtos.length > 0 &&
      selectData.length > 0
    ) {
      setContextMenu(
        contextMenu === null
          ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
          : null
      );
    }
  };

  const handleCloseMenu = () => {
    setContextMenu(null);
  };

  const handleModal = () => {
    setIsModalVisible(true);
    setContextMenu(null);
  };

  const getManualToolTip = () => {
    return (
      <MenuItem onClick={() => handleModal()}>
        Apply this code to selected transactions.
      </MenuItem>
    );
  };

  useEffect(() => {
    if (rowSelectionModel) {
      const rows = rowSelectionModel.map((selectedId) =>
        codingData.find((row) => row.id === selectedId)
      );
      setSelectData(rows);
    }
  }, [rowSelectionModel, codingData]);

  const handleSearch = (e) => {
    setSearchQuery1(e.target.value);
  };

  // Filter rows based on search query
  const filteredRows = codingData?.filter((row) => {
    const matchesSearch = Object.values(row).some((value) =>
      String(value).toLowerCase().includes(searchQuery1.toLowerCase())
    );

    let matchesFilter = true;
    if (alignment === 2) {
      matchesFilter = row.mappingStatus === "Coded";
    } else if (alignment === 3) {
      matchesFilter = row.mappingStatus?.toLowerCase() === "uncoded";
    }

    return matchesSearch && matchesFilter;
  });

  // Update rows to display based on search results and filter selection
  const rowsToDisplay = searchQuery1 ? filteredRows : codingData.filter(row => {
    let matchesFilter = true;
    if (alignment === 2) {
      matchesFilter = row.mappingStatus === "Coded";
    } else if (alignment === 3) {
      matchesFilter = row.mappingStatus?.toLowerCase() === "uncoded";
    }
    return matchesFilter;
  });

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        {/* <GridToolbarExport /> */}
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        {/* <GridToolbarDensitySelector /> */}
        {/* <StyledButton
        variant="contained"
        color="primary"
        // onClick={onRefresh}
      >
        Refresh
      </StyledButton> */}
        <div className="refresh">
        <Button onClick={()=>{fetchData()}}> <RefreshIcon className="refIcon" /> Refresh</Button>
        </div>
      </GridToolbarContainer>
    );
  }

  const updateVerifyStatus = async (VerificationId, status) => {
    setIsSpine(true);
    try {
      const res = await postApiWithHeader(
        `api/bankentrycodings/Change-Status/${companyId}?id=${VerificationId}&status=${status}`,
        {},
        headers
      );
      if (res && res.data && res.data.statusCode === 100) {
        toast.success("Verified Successfully", { autoClose: 1000 });
        fetchData();
        setIsConfirmModalVisible(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsSpine(false); // Reset loading state regardless of success or failure
    }
  };
  const filterme = (e, newAlignment) => {
    fetchData();
    if (newAlignment !== null) {
      setValue(newAlignment); // Update state
      setAlignment(newAlignment); // Update alignment state
      const customToggle = document.getElementById("custom-toggle");
      if (newAlignment === 2) {
        // setCodingData(codingData.map((item)=> (item.bankEntryCodingResponseDtos.length > 0 ? {...item, mappingStatus: "Coded"} : item)))
        customToggle?.classList?.remove("tgl-off");
        customToggle?.classList?.add("tgl-def");
        setLabel("Coded");
      } else if (newAlignment === 3) {
        customToggle?.classList?.remove("tgl-def");
        customToggle?.classList?.add("tgl-off");
        // setCodingData(codingData.map((item)=> (item.bankEntryCodingResponseDtos.length > 0 ? {...item, mappingStatus: "Coded"} : item)))
        setLabel("Uncoded");
      } else { // Handle case for "All" or other values
        // setCodingData(codingData.map((item)=> (item.bankEntryCodingResponseDtos.length > 0 ? {...item, mappingStatus: "Coded"} : item)))
        // Set label accordingly
        setLabel("All");
      }
    }
  };

  const handleAddClick = () => {
    setCanvasOpen(true);
    setCanValue("Add Code");
  };

  const getCategoryFun = async () => {
    try {
      const response = await GetApiwithHeader(`api/CodeCategory/Get`, headers);

      const filteredRoles = response?.data?.data.filter(
        (item) => item?.categoryName
      );
      setGetCategory(filteredRoles);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCategoryFun();
  }, [companyId]);

  return (
    <>
      <ToastContainer position="top-right" autoClose={2000} />
      <Box
        // className="dataGridTable"
        sx={{
          // height: 400,
          width: "100%",
          "& .MuiDataGrid-cell--editing": {
            bgcolor: "rgb(255,215,115, 0.19)",
            color: "#1a3e72",
            "& .MuiInputBase-root": {
              height: "100%",
            },
          },
          "& .Mui-error": {
            bgcolor: (theme) =>
              `rgb(126,10,15, ${theme.palette.mode === "dark" ? 0 : 0.1})`,
            color: (theme) => {
              theme.palette.mode === "dark" ? "#ff4343" : "#750f0f";
            },
          },
        }}
      >
        <div className="cardBody">
          <div className="cardHeader">
            {support && <h3>Support Tickets</h3>}
            {verify && <h3>Verify Entries</h3>}
            {itim && <h3>ITIM</h3>}
            {nowBas && <h3>Business Activity Statement</h3>}

            {!support && !verify && !itim && !nowBas && <h3>Coding</h3>}
            <div>Suggestion: For speed of entry use keyboard</div>
            <div className="mr-1 receiptTab codingTab">
            {/* <CTooltip content="Refresh">
              <RefreshIcon />
              </CTooltip> */}
              <CButton onClick={() => handleAddClick()}>Add Code</CButton>

              <ToggleButtonGroup
                value={alignment}
                className="tabBtn"
                exclusive
                onChange={filterme}
                aria-label="Platform"
              >
                <ToggleButton value={1}>All</ToggleButton>
                <ToggleButton value={2}>Coded</ToggleButton>
                <ToggleButton value={3}>Uncoded</ToggleButton>
              </ToggleButtonGroup>

              {/* <input
                type="text"
                placeholder="Search..."
                value={searchQuery1}
                onChange={(e) => setSearchQuery1(e.target.value)}
              /> */}
              <CTooltip content="Info">
                <span class="icon-menubar" onClick={handleClickOnStatusInfoEl}>
                  <span class="path1"></span>
                  <span class="path2"></span>
                  <span class="path3"></span>
                  <span class="path4"></span>
                </span>
              </CTooltip>
              <Menu
                id="basic-menu"
                anchorEl={statusInfoEl}
                open={statusInfoElOpen}
                onClose={handleCloseStatusInfoEl}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <Box
                  sx={{ p: 2 }}
                  onClick={handleCloseStatusInfoEl}
                  className="helpLegendsBox"
                >
                  <div className="legendsStatusBox">
                    <h4>Transaction Status</h4>
                    <Stack
                      direction="row"
                      alignItems="center"
                      className="legRow"
                    >
                      <Typography
                        gutterBottom
                        component="div"
                        color="text.secondary"
                        variant="body2"
                        className="legRowBox"
                      >
                        <span class="icon-verifyentries"></span>
                        Verify Entries
                      </Typography>
                      <Typography
                        gutterBottom
                        component="div"
                        color="text.secondary"
                        variant="body2"
                        className="legRowBox"
                      >
                        <span class="icon-codedentries"></span>
                        Coded Entries
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      className="legRow"
                    >
                      <Typography
                        gutterBottom
                        component="div"
                        color="text.secondary"
                        variant="body2"
                        className="legRowBox"
                      >
                        <span class="icon-itimentries"></span>
                        ITIM Entries
                      </Typography>
                      <Typography
                        gutterBottom
                        component="div"
                        color="text.secondary"
                        variant="body2"
                        className="legRowBox"
                      >
                        <span class="icon-supportentries"></span>
                        Support Entries
                      </Typography>
                    </Stack>
                  </div>
                  <div className="legendsStatusBox">
                    <h4>Support Status</h4>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      className="legRow"
                    >
                      <Typography
                        gutterBottom
                        component="div"
                        color="text.secondary"
                        variant="body2"
                        className="legRowBox"
                      >
                        <span class="icon-raised"></span>
                        Under Process
                      </Typography>
                      <Typography
                        gutterBottom
                        component="div"
                        color="text.secondary"
                        variant="body2"
                        className="legRowBox"
                      >
                        <span class="icon-Resolved"></span>
                        Resolved
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      className="legRow"
                    >
                      <Typography
                        gutterBottom
                        component="div"
                        color="text.secondary"
                        variant="body2"
                        className="legRowBox"
                      >
                        <span class="icon-reopen"></span>
                        Reopen
                      </Typography>
                      <Typography
                        gutterBottom
                        component="div"
                        color="text.secondary"
                        variant="body2"
                        className="legRowBox"
                      >
                        <span class="icon-closed"></span>
                        Closed
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      className="legRow"
                    >
                      <Typography
                        gutterBottom
                        component="div"
                        color="text.secondary"
                        variant="body2"
                        className="legRowBox"
                      >
                        <span class="icon-toraise"></span>
                        Need Support
                      </Typography>
                    </Stack>
                  </div>
                  <div className="legendsStatusBox">
                    <h4>Verification Status</h4>

                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      className="legRow"
                    >
                      <Typography
                        gutterBottom
                        component="div"
                        color="text.secondary"
                        variant="body2"
                        className="legRowBox"
                      >
                        <span class="icon-Verifiyit"></span>
                        Verify It
                      </Typography>
                      <Typography
                        gutterBottom
                        component="div"
                        color="text.secondary"
                        variant="body2"
                        className="legRowBox"
                      >
                        <span class="icon-verified"></span>
                        Verified
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      className="legRow"
                    >
                      <Typography
                        gutterBottom
                        component="div"
                        color="text.secondary"
                        variant="body2"
                        className="legRowBox"
                        sx={{ width: "100%" }}
                      >
                        <span class="icon-underverification"></span>
                        Under Verification
                      </Typography>
                    </Stack>
                  </div>
                </Box>
              </Menu>
            </div>
          </div>
          {codingData && (
            <div className="codingDataGrid">
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={locale}
              >
                <DataGridPro
                  rows={rowsToDisplay}
                  columns={columns}
                  // initialState={{
                  //   columns: {
                  //     columnVisibilityModel: {
                  //       // Hide columns status and traderName, the other columns will remain visible
                  //       transactionCode: false,
                  //     },
                  //   },
                  // }}
                  editMode="row"
                  density="compact"
                  rowModesModel={rowModesModel}
                  processRowUpdate={processRowUpdate}
                  onRowModesModelChange={handleRowModesModelChange}
                  // autoHeight
                  loading={loadingState}
                  components={{
                    toolbar: CustomToolbar,
                    style: { backgroundColor: "green" },
                  }}
                  tabIndex={0}
                  onEditCellChange={handleEditCellChange}
                  disableColumnResize={false}
                  slots={{ toolbar: CustomToolbar }}
                  slotProps={{
                    toolbar: {
                      setCodingData,
                      setRowModesModel,
                      style: { backgroundColor: "#d8dbe0" },
                    },
                    row: {
                      onContextMenu: (e) => handleContextMenu(e),
                      style: { cursor: "context-menu" },
                    },
                  }}
                  checkboxSelection
                  disableRowSelectionOnClick
                  onRowSelectionModelChange={(newRowSelectionModel) => {
                    setRowSelectionModel(newRowSelectionModel);
                  }}
                  rowSelectionModel={rowSelectionModel}
                  apiRef={apiRef}
                  onRowEditStop={(params, event) => {
                    if(params.reason === 'escapeKeyDown'){
                      fetchData(true);
                    }
                    if (params.reason === GridRowEditStopReasons.rowFocusOut && params.reason !== 'escapeKeyDown') {
                      event.defaultMuiPrevented = true;
                      // if(params.row.bankEntryCodingResponseDtos && params.row.bankEntryCodingResponseDtos.length > 0 && params.row.bankEntryCodingResponseDtos[0].isSplit === false){
                        processRowUpdate(params.row);
                      // }
                      apiRef.current.stopRowEditMode({
                        id: params.id,
                        ignoreModifications: true, // will also discard the changes made
                      });
                    }
                  }}
                />
                 {/* <CTooltip content="Refresh">
              <RefreshIcon />
              </CTooltip> */}
              </LocalizationProvider>
            </div>
          )}
        </div>
      </Box>
      <Menu
        open={contextMenu !== null}
        onClose={handleCloseMenu}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
        slotProps={{
          root: {
            onContextMenu: (e) => {
              e.preventDefault();
              handleCloseMenu();
            },
          },
        }}
      >
        {getManualToolTip()}
      </Menu>
      <CModal
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        aria-labelledby="ScrollingLongContentExampleLabel"
        className="large overlay transactionDefin"
      >
        <CModalHeader>
          <h3>Transaction Definition </h3>
        </CModalHeader>
        <div className="overflow sidebar-nav">
          <CModalBody>
            <HighFrequency
              titleNarration={titleNarration}
              modal={setIsModalVisible}
              fetchDataCoding={fetchData}
              selectData={selectData}
              updatedData={updatedTransactionData}
            />
          </CModalBody>
        </div>
      </CModal>
      <OffCanvas
        Open={canvasOpen}
        title={canValue}
        handleCloseClick={handleCloseClick}
        component={
          canValue === "Split" ? (
            <SplitCanvas
              saveSplit={saveSplit}
              CodedValue={CodedValue}
              fetchDataCoding={fetchData}
              bankTransactionCode={bankTransactionCode}
              amount={Value}
              codeData={codeData}
              handleCloseClick={handleCloseClick}
              nowBas={nowBas}
            />
          ) : canValue === "Attachment" ? (
            <UploadDoc
              rowdata={rowdata}
              handleCloseClick={handleCloseClick}
              fetchDataCoding={fetchData}
              uploadedDocuments={uploadedDocuments}
              setUploadedDocuments={setUploadedDocuments}
              uploadedDocumentsRef={uploadedDocumentsRef}
            />
          ) : canValue === "Support Request" ? (
            <Support
              rowdata={rowdata}
              handleCloseClick={handleCloseClick}
              fetchDataCoding={fetchData}
            />
          ) : canValue === "ITIM" ? (
            <IsItim
              bankTransactionCode={bankTransactionCode}
              handleCloseClick={handleCloseClick}
              fetchDataCoding={fetchData}
            />
          ) : canValue === "Support Details" ? (
            <SupportDetails
              rowdata={rowdata}
              handleCloseClick={handleCloseClick}
              fetchDataCoding={fetchData}
            />
          ) : canValue === "Add Code" ? (
            <Add
              apiCall={codeFetchApi}
              handleClose={handleCloseClick}
              items={codeData}
              getCategory={getCategory}
            />
          ) : (
            ''
          )
        }
      />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Typography sx={{ p: 2 }}>
          <More codeInfo={codeInfo} />
        </Typography>
      </Popover>

      <CModal
        visible={isConfirmModalVisible}
        onClose={() => setIsConfirmModalVisible(false)}
        aria-labelledby="ScrollingLongContentExampleLabel"
        className="overlay"
      >
        <CModalHeader>
          <h3>Confirm</h3>
        </CModalHeader>
        <CModalBody>
          <p className="text-center">
            Do you want to verify this {rowdata?.entryCode} ?
          </p>
        </CModalBody>
        <CModalFooter>
          <CButton
            onClick={() => setIsConfirmModalVisible(false)}
            className="btn-light mr-2"
          >
            Close
          </CButton>
          <CButton
            color="primary"
            onClick={() => {
              updateVerifyStatus(rowdata?.verificationId, "Verified");
            }}
          >
            {isSpine ? <CSpinner color="white" size="sm" /> : "Verify"}
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
};

export default BasicEditingGrid;
