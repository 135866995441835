import React, { useMemo, useState, useEffect } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { CButton, CCard, CFormInput, CImage, CTable, CTableBody, CTooltip } from '@coreui/react';
import { userAttributes } from "../../share/localData";
import { DeleteApiwithHeader, GetApiwithHeader, postApiWithHeader } from 'src/Api/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { deleteIcon } from 'src/components/Images/Images';

const main = {
  py: 0,
  width: '100%',
  borderRadius: 2,
  border: '1px solid',
  borderColor: 'divider',
  backgroundColor: 'background.paper',
  maxHeight: '450px',
//   overflowY: 'auto',
};

const Debitor=(props)=> {
  const codeInfo = props?.codeInfo
  const [items, setItems] = useState([])
  const { cmpID } = userAttributes();
  const accessToken = localStorage.getItem("token");
  const companyId = cmpID;
  const debit = codeInfo?.bankEntryCodingResponseDtos[0]?.categoryC === "Debitor" ? true : false

  const headers = useMemo(
    () => ({
      Authorization: `Bearer ${accessToken}`,
    }),
    [accessToken]
  );

  const fetchData = async () => {
    try {
      const res = await GetApiwithHeader(`/api/invoices/Get-Creditor-Debitor/${debit}`, headers);
      console.log('res', res)
      setItems(res?.data?.data || []);

    } catch (error) {
      console.log(error);
    }
  };

  console.log('items',items)

  useEffect(() => {
    fetchData();
  }, [headers]);
  return (
    
    <div>
      <ToastContainer />
        <CCard className="p-0 overflow-hidden dabitorCreaditor">
          <CTable className="m-0">
            <CTableBody>
              {items.map((item, index) => (
                <React.Fragment key={index}>
                  <tr>
                    <td className="w-100">{item.item1}</td>
                    <td className="w-100">{item.item2}</td>
                  </tr>
                  {/* {index !== items.length - 1 && <Divider component="li" />} */}
                </React.Fragment>
              ))}
            </CTableBody>
          </CTable>
        </CCard>
    </div>
  );
}

export default Debitor

