import React, { useMemo, useEffect, useState } from "react";
import {
  CCol,
  CCardBody,
  CButton,
  CFormInput,
  CRow,
  CSpinner,
} from "@coreui/react";
import { toast, ToastContainer } from "react-toastify";
import { GetApiwithHeader, postApiWithHeader } from "src/Api/Api";
import { userAttributes } from "../../share/localData";
import { useData } from "../../components/dataContext";
import { DataGridPro, GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid-pro";
import Checkbox from "@mui/material/Checkbox";
import { LicenseInfo } from '@mui/x-license';


const ApplyRule = (props) => {
  const { data } = useData();
  const { HighFreqRuleData, modalShow, fetchDataHighfreq } = props;
  const { cmpID, bankID } = userAttributes();
  const [similarData, setSimilarData] = useState([]);
  const accessToken = localStorage.getItem("token");
  const [loadingState, setLoadingState] = useState(false);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  LicenseInfo.setLicenseKey('248a71a5e79c492f4b13769843fcf6dcTz04OTIzMCxFPTE3NDU3NTY1ODYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');
  const headers = useMemo(
    () => ({
      Authorization: `Bearer ${accessToken}`,
    }),
    [accessToken]
  );

  const companyId = cmpID;
  const fetchData = async () => {
    try {
      const res = await GetApiwithHeader(
        `/api/BankTransactions/GetTransaction/${companyId}?bankAccountId=${bankID}&narration=${HighFreqRuleData?.narration}&ruleId=${HighFreqRuleData?.id}`,
        headers
      );
      setSimilarData(res.data && res.data.data || []);
      setRowSelectionModel(res?.data && res?.data?.data?.map((item) => item?.id));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [headers, cmpID, bankID]);

  const handleRule = async (e) => {
    const transactionRequest = similarData?.filter((item) => {
      const isSelected = rowSelectionModel.includes(item.id);
      
      if (HighFreqRuleData?.isFinancialInstrument) {
        const withdrawalAmount = item.withdrawalAmount;
        const installment = HighFreqRuleData.installment;
        
        if (installment !== 0) {
          const result = withdrawalAmount / installment;
          if (result.toFixed(1) === '0.0') {
            return false; // Exclude this item from the transactionRequest array
          }
        }
      }
      return isSelected;
    });
    const requestBody = {
      bankAccountId: bankID,
      ruleId: HighFreqRuleData?.id,
      applyRule: false, // rule not creat from hear (We can take applyRule: false)
      narration: HighFreqRuleData?.narration,
      transactionCode: null,
      transactionRequest: transactionRequest || [],
    };
 
    if (transactionRequest?.length === 0) {
      toast.warning(
        "Transcation amounts did not match the installment amount.",
        { autoClose: 3000 }
      );
      return;
    } else {
      setLoadingState(true);
      const response = await postApiWithHeader(
        `api/CodingRuleSet/Apply-Rule/${companyId}`,
        requestBody,
        headers
      );
      try {
        if (response?.data?.statusCode === 100) {
          toast.success(response?.data?.message, { autoClose: 1000 });
          fetchDataHighfreq();
          modalShow(false);
        }
      } catch (error) {
        console.error("Error:", error);
        toast.warning(response?.data?.message, { autoClose: 1000 });
      } finally {
        setLoadingState(false); // End form submission
      }
    }
  };

  // const handleRule = async (e) => {
  //   let transactionRequest = similarData?.filter(item => rowSelectionModel.includes(item.id));
  //   let allAmountsMatch = true;

  //   transactionRequest.forEach((item) => {
  //     if (item?.withdrawalAmount === HighFreqRuleData?.installment) {
  //       console.log('Amount matched');
  //     } else {
  //       console.log('Not matched');
  //       allAmountsMatch = false;
  //     }
  //   });

  //   if (!allAmountsMatch) {
  //     toast.warning('Transcation amounts did not match the installment amount.', { autoClose: 3000 });
  //     return;
  //   }

  //   const requestBody = {
  //     bankAccountId: bankID,
  //     ruleId: HighFreqRuleData?.id,
  //     applyRule: false, // rule not created from here (We can take applyRule: false)
  //     narration: HighFreqRuleData?.narration,
  //     transactionCode: null,
  //     transactionRequest: transactionRequest || []
  //   };

  //   console.log('Request', requestBody);

  //   setLoadingState(true);

  //   try {
  //     const response = await postApiWithHeader(
  //       `api/CodingRuleSet/Apply-Rule/${companyId}`,
  //       requestBody,
  //       headers
  //     );

  //     if (response?.data?.statusCode === 100) {
  //       toast.success(response?.data?.message, { autoClose: 1000 });
  //       fetchDataHighfreq();
  //       modalShow(false);
  //     } else {
  //       toast.warning(response?.data?.message, { autoClose: 1000 });
  //     }
  //   } catch (error) {
  //     console.error("Error:", error);
  //     toast.warning(response?.data?.message, { autoClose: 1000 });
  //   } finally {
  //     setLoadingState(false); // End form submission
  //   }
  // }

  const resetForm = () => {};

  const columns = [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      headerClassName: "dataGridHeader",
      renderCell: (params) => {
        let isDisabled = false;
        const isChecked = rowSelectionModel.includes(params.id) && !isDisabled;

        return (
          <Checkbox
            checked={isChecked}
            onChange={(event) => {
              const newSelectionModel = [...rowSelectionModel];
              if (event.target.checked) {
                newSelectionModel.push(params.id);
              } else {
                const index = newSelectionModel.indexOf(params.id);
                if (index > -1) {
                  newSelectionModel.splice(index, 1);
                }
              }
              setRowSelectionModel(newSelectionModel);
            }}
          />
        );
      },
    },
    {
      field: "entryCode",
      headerName: "Entry",
      width: 80,
      headerClassName: "dataGridHeader",
    },
    {
      field: "Date",
      headerName: "Date",
      width: 100,
      headerClassName: "dataGridHeader",
      sortable: false,
      renderCell: (params) =>
        `${new Date(params.row?.valueDate)?.toLocaleDateString("en-GB")}`,
    },
    {
      field: "Amount",
      headerName: "Amount",
      width: 100,
      headerClassName: "dataGridHeader",
      sortable: false,
      renderCell: (params) =>
        `${
          params.row?.depositAmount !== "0"
            ? params.row?.depositAmount
            : params.row?.withdrawalAmount
        }`,
    },
    {
      field: "narration",
      headerName: "Description",
      width: 300,
      headerClassName: "dataGridHeader",
      sortable: false,
    },
    {
      field: "balance",
      headerName: "Balance",
      width: 110,
      headerClassName: "dataGridHeader",
      sortable: false,
    },
  ];

  return (
    <>
      <CRow className="mb-2">
        <CCol md={5}>
          <CFormInput
            disabled
            type="text"
            name="narration"
            label="Transaction Description"
            value={HighFreqRuleData?.narration}
          />
        </CCol>
        <CCol md={5}>
          <CFormInput
            disabled
            type="text"
            name="codeName"
            label={
              HighFreqRuleData && HighFreqRuleData?.isFinancialInstrument
                ? "Financial Instrument"
                : "Code Name(s)"
            }
            value={HighFreqRuleData?.codeName}
          />
        </CCol>
        {HighFreqRuleData && HighFreqRuleData?.isFinancialInstrument ? (
          <CCol md={2}>
            <CFormInput
              disabled
              type="text"
              name="Installment"
              label="Installment"
              value={HighFreqRuleData?.installment}
            />
            <span> </span>
          </CCol>
        ) : (
          ""
        )}
      </CRow>
      <span>
        {HighFreqRuleData && HighFreqRuleData?.isFinancialInstrument
          ? 'Note : "Run rule will associate and move the selected entries to financial instrument." '
          : ""}
      </span>
      <CRow className="mt-4 justify-content-end">
        <CCol xs="auto">
          <CButton
            color="light"
            onClick={() => {
              resetForm();
              modalShow(false);
            }}
          >
            Cancel
          </CButton>
        </CCol>
        <CCol xs="auto">
          <CButton
            color="primary"
            onClick={handleRule}
            style={{ textTransform: "none" }}
          >
            {loadingState ? <CSpinner color="white" size="sm" /> : "Apply Rule"}
          </CButton>
        </CCol>
      </CRow>

      <hr className="mtb15" />
      <h3 className="mtb-10">Uncoded Similar Transaction Found</h3>
      <CCardBody className="overflow sidebar-nav">
        <div className="cardTable similarTransactionsTable">
          <DataGridPro
            rows={similarData}
            columns={columns}
            density="compact"
            checkboxSelection
            onRowSelectionModelChange={(newRowSelectionModel) => {
              console.log(newRowSelectionModel);
              setRowSelectionModel(newRowSelectionModel);
            }}
            rowSelectionModel={rowSelectionModel}
            hideFooter
            hideFooterPagination
          />
        </div>
      </CCardBody>
    </>
  );
};

export default ApplyRule;
