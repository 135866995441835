// import React, { useMemo, useEffect, useState } from "react";
// import {
//   DataGridPro,
//   GridToolbarContainer,
//   GridToolbarExport,
// } from "@mui/x-data-grid-pro";
// import { Box } from "@mui/material";
// import { GetApiwithHeader } from "src/Api/Api";
// import { userAttributes } from "../../share/localData";
// import { LicenseInfo } from "@mui/x-license";
// import { makeStyles } from "@mui/styles";
// import { useData } from "../../components/dataContext";

// const GSTReport = () => {
//   const { data, bankDataLocal } = useData();
//   const accessToken = localStorage.getItem("token");
//   LicenseInfo.setLicenseKey(
//     "248a71a5e79c492f4b13769843fcf6dcTz04OTIzMCxFPTE3NDU3NTY1ODYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
//   );
//   const [gstData, setGstData] = useState([]);
//   const { cmpID } = userAttributes();
//   const companyId = cmpID;

//   const headers = useMemo(
//     () => ({
//       Authorization: `Bearer ${accessToken}`,
//     }),
//     [accessToken]
//   );

//   const fetchData = async () => {
//     try {
//       const res = await GetApiwithHeader(
//         `api/Report/GST-Report?companyId=${companyId}&financialyear=2022-2023`,
//         headers
//       );
//       console.log("res?.data?.data", res?.data?.data);
//       setGstData(res?.data?.data || []);
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   useEffect(() => {
//     fetchData();
//   }, [companyId]);

//   const useStyles = makeStyles({
//     row: {
//       "@media print": {
//         innerHeight: "100px",
//         pageBreakInside: "avoid",
//       },
//     },
//   });

//   const columns = [
//     {
//       field: "transactionDate",
//       headerName: "Date",
//       width: 150,
//       sortable: false,
//     },
//     {
//       field: "codeName",
//       headerName: "Code Name",
//       width: 150,
//       sortable: false,
//     },
//     {
//         field: "Narration",
//         headerName: "Transaction Details",
//         width: 250,
//         sortable: false,
//         renderCell: (params) => <div className="wrap-cell">{params.value}</div>,
//       },
//     {
//       field: "NetAmount",
//       headerName: "Net Amount",
//       width: 100,
//       sortable: false,
//     },
//     {
//       field: "GstAmount",
//       headerName: "GST",
//       width: 100,
//       sortable: false,
//     },
//   ];

//   const preparedRows = [];
//   const uniqueCatogoryNames = {};

//   let grandTotalNetAmount = 0;
//   let grandTotalGstAmount = 0;

//   gstData &&
//     gstData.gstItem?.forEach((row, index) => {
//       if (!uniqueCatogoryNames[row.period]) {
//         uniqueCatogoryNames[row.period] = true;

//         preparedRows.push({
//           id: `header-${row.id}`,
//           period: row.period,
//           isHeader: true,
//         });
//       }
//       preparedRows.push({
//         id: row.id,
//         transactionDate: new Date(row.transactionDate).toLocaleDateString('en-GB'),
//         codeName: row.codeName,
//         Narration: row.narration,
//         NetAmount: Number(row.netAmount),
//         GstAmount: Number(row.gstAmount),
//         isHeader: false,
//       });

//       grandTotalNetAmount += Number(row.netAmount);
//       grandTotalGstAmount += Number(row.gstAmount);

//       if (gstData.gstItem[index + 1]?.period !== row?.period) {
//         preparedRows.push({
//           id: `subtotal-${row.id}`,
//           Narration: "Total",
//           NetAmount: row?.totalAmount,
//           GstAmount: row?.totalGST,
//           isSubtotal: true,
//         });
//       }
//     });

//   preparedRows.push({
//     id: "grandtotal",
//     Narration: "GrandTotal",
//     NetAmount: grandTotalNetAmount.toFixed(2),
//     GstAmount: grandTotalGstAmount.toFixed(2),
//     isGrandTotal: true,
//   });

//   const columnsWithCodeName = [
//     {
//       field: "period",
//       headerName: "Period ",
//       width: 250,
//       sortable: false,
//     },
//     ...columns,
//   ];

//   const getRowClassName = (params) => {
//     if (params.row.isHeader) {
//       return "header-row";
//     }
//     if (params.row.isSubtotal) {
//       return "subtotal-row";
//     }
//     if (params.row.isGrandTotal) {
//       return "grandtotal-row";
//     }
//     return "pageBreakRow";
//   };

//   const CustomToolbar = () => (
//     <div>
//       <div style={{ fontSize: "24px", padding: "5px", float: "left" }}>
//         GST Report
//       </div>
//       <GridToolbarContainer>
//         <div className="exportReport" style={{ justifyContent: "flex-start" }}>
//           <Box ml={2}></Box>
//           <Box ml={0}>
//             <GridToolbarExport
//               printOptions={{
//                 hideToolbar: true,
//               }}
//               csvOptions={{
//                 fileName: "GST Report ",
//                 // delimiter: ';',
//                 // utf8WithBom: true,
//               }}
//             />
//           </Box>
//         </div>
//       </GridToolbarContainer>
//     </div>
//   );

//   return (
//     <div className="cardBody reportTable">
//       <div className="datatableGrid autoHeightTable">
//         <div className="cardHeader">
//           <DataGridPro
//             rows={preparedRows}
//             columns={columnsWithCodeName}
//             getRowClassName={getRowClassName}
//             getRowHeight={() => "auto"}
//             rowSelection={false}
//             autoHeight
//             pagination={false}
//             disableSelectionOnClick
//             cellSelection
//             slots={{
//               toolbar: CustomToolbar,
//               style: { backgroundColor: "green" },
//             }}
//             hideFooterRowCount
//             classes={{
//               row: useStyles,
//             }}
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default GSTReport;

import React, { useMemo, useEffect, useState } from "react";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";
import { Box } from "@mui/material";
import { GetApiwithHeader } from "src/Api/Api";
import { userAttributes } from "../../share/localData";
import { LicenseInfo } from "@mui/x-license";
import { makeStyles } from "@mui/styles";
import { useData } from "../../components/dataContext";
import {  CNav, CNavItem, CNavLink, CRow, CTabContent, CTabPane } from '@coreui/react';

const GSTReport = (props) => {
  const {selectedYear, handleYearChange} = props
  const { data, bankDataLocal } = useData();
  const accessToken = localStorage.getItem("token");
  LicenseInfo.setLicenseKey(
    "248a71a5e79c492f4b13769843fcf6dcTz04OTIzMCxFPTE3NDU3NTY1ODYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
  );
  const [gstData, setGstData] = useState([]);
  const [activeKey, setActiveKey] = useState(1)
  const [loadingState, setLoadingState] = useState(true);

  const { cmpID } = userAttributes();
  const companyId = cmpID;

  const headers = useMemo(
    () => ({
      Authorization: `Bearer ${accessToken}`,
    }),
    [accessToken]
  );

  const fetchData = async () => {
    setLoadingState(true);
    try {
      const res = await GetApiwithHeader(
        `api/Report/GST-Report?companyId=${companyId}&financialyear=${selectedYear ? selectedYear :'2022-2023'}`,
        headers
      );
      setLoadingState(false);
      setGstData(res?.data?.data || []);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [companyId, selectedYear]);

  const useStyles = makeStyles({
    row: {
      "@media print": {
        innerHeight: "100px",
        pageBreakInside: "avoid",
      },
    },
  });

  const columns = [
    {
      field: "transactionDate",
      headerName: "Date",
      width: 150,
      sortable: false,
    },
    {
      field: "codeName",
      headerName: "Code Name",
      width: 150,
      sortable: false,
    },
    {
      field: "EntryCode",
      headerName: "Entry #",
      width: 100,
      sortable: false,
    },
    {
      field: "Narration",
      headerName: "Transaction Details",
      width: 250,
      sortable: false,
      renderCell: (params) => <div className="wrap-cell">{params.value}</div>,
    },
    {
      field: "NetAmount",
      headerName: "Net Amount",
      width: 100,
      sortable: false,
    },
    {
      field: "GstAmount",
      headerName: "GST",
      width: 100,
      sortable: false,
    },
  ];

  const preparedRows = [];
  const uniqueCatogoryNames = {};

  let grandTotalNetAmount = 0;
  let grandTotalGstAmount = 0;

  gstData &&
    gstData.nonGSTItem?.forEach((row, index) => {
      if (!uniqueCatogoryNames[row.period]) {
        uniqueCatogoryNames[row.period] = true;

        preparedRows.push({
          id: `header-${row.id}`,
          period: row.period,
          isHeader: true,
        });
      }
      preparedRows.push({
        id: row.id,
        transactionDate: new Date(row.transactionDate).toLocaleDateString('en-GB'),
        codeName: row.codeName,
        EntryCode: row.entryCode,
        Narration: row.narration,
        NetAmount: Number(row.netAmount),
        GstAmount: Number(row.gstAmount),
        isHeader: false,
      });

      grandTotalNetAmount = row.grandTotalNetAmount;
      grandTotalGstAmount = row.grandTotalGstAmount;

      if (gstData.nonGSTItem[index + 1]?.period !== row?.period) {
        preparedRows.push({
          id: `subtotal-${row.id}`,
          Narration: "Total",
          NetAmount: row?.totalNetAmount,
          GstAmount: row?.totalGstAmount,
          isSubtotal: true,
        });
      }
    });

  preparedRows.push({
    id: "grandtotal",
    Narration: "GrandTotal",
    NetAmount: grandTotalNetAmount,
    GstAmount: grandTotalGstAmount,
    isGrandTotal: true,
  });

  const columnsWithCodeName = [
    {
      field: "period",
      headerName: "Period ",
      width: 250,
      sortable: false,
    },
    ...columns,
  ];

  const getRowClassName = (params) => {
    if (params.row.isHeader) {
      return "header-row";
    }
    if (params.row.isSubtotal) {
      return "subtotal-row";
    }
    if (params.row.isGrandTotal) {
      return "grandtotal-row";
    }
    return "pageBreakRow";
  };

  const CustomToolbar = ({ title }) => (
    <div>
      <div style={{ fontSize: "24px", padding: "5px", float: "left" }}>
        {title}
      </div>
      <GridToolbarContainer>
        <div className="exportReport" style={{ justifyContent: "flex-start" }}>
          <Box ml={2}></Box>
          <Box ml={0}>
            <GridToolbarExport
              printOptions={{
                hideToolbar: true,
              }}
              csvOptions={{
                fileName: `${title} Report`,
              }}
            />
          </Box>
        </div>
      </GridToolbarContainer>
    </div>
  );

  return (
    <div className="cardBody reportTable">
      <div className="datatableGrid autoHeightTable">
        <div className="cardHeader">
              <DataGridPro
                rows={preparedRows}
                columns={columnsWithCodeName}
                getRowClassName={getRowClassName}
                getRowHeight={() => "auto"}
                rowSelection={false}
                loading={loadingState}

                autoHeight
                pagination={false}
                disableSelectionOnClick
                cellSelection
                slots={{
                  toolbar: () => <CustomToolbar title="Non GST Items" />,
                  style: { backgroundColor: "green" },
                }}
                hideFooterRowCount
                classes={{
                  row: useStyles,
                }}
              />
        </div>
      </div>
    </div>
  );
};

export default GSTReport;
