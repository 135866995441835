import React, { useEffect } from "react";
import CIcon from "@coreui/icons-react";
import {
  cilDescription,
  cilInstitution,
  cilMap,
  cilNotes,
} from "@coreui/icons";
import { CDropdownDivider, CImage, CNavItem, CNavGroup } from "@coreui/react";
import {
  aboutUs,
  assets,
  bank,
  businessCode,
  coding,
  company,
  invite,
  inviteFrd,
  portfolio,
  privacy,
  report,
  standard,
  supportTicket,
  term,
  verifyCoding,
} from "./components/Images/Images";
import StandardJournal from "./views/standardJournal/StandardJournal";
import { useData } from "./components/dataContext";
import { userAttributes } from "./share/localData";

const Divider = () => <hr className="nav-divider"></hr>;

// Common style for images
const imageStyle = {
  width: "20px",
  height: "20px",
  marginRight: "10px",
};


//  const roleFromStorage = JSON.parse(localStorage.getItem('companyData'))
//  const role = roleFromStorage?.company?.role
//  console.log('role', role)

export const bookkeeperNav = [
  {
    component: CNavItem,
    title: 'Company Details',
    name: <span className='menu-title'>Company Details</span>,
    to: '/companyDetails',
    icon: <span className='menu-icon icon-companydetails' title='Company Details'></span>,
  },
  // {
  //   component: CNavItem,
  //   title: 'Bank Accounts',
  //   name: <span className='menu-title'>Bank Accounts</span>,
  //   to: '/bank',
  //   icon: <span className='menu-icon icon-bankaccount' title='Bank Accounts'></span>
  // },
  // {
  //   component: CNavItem,
  //   title: 'Invites',
  //   name: <span className='menu-title' title='Invites'>Invites</span>,
  //   to: '/invites',
  //   icon:<span className='menu-icon icon-invite' title='Invites' alt='Invites'></span>,
  // },
  {
    component: CNavItem,
    title: 'Upload Bank Transaction',
    name: <span className='menu-title'>Upload Bank Transaction</span>,
    to: '/transaction',
    icon: <span className='menu-icon icon-uploadbanktransition' title='Upload Bank Transaction'></span>,
  },
  {
    component: CNavItem,
    title: 'Business Code',
    name: <span className='menu-title'>Business Code</span>,
    to: '/business_code',
    icon: <span className='menu-icon icon-businesscode' title='Business Code'></span>,
  },
  {
    component: CNavItem,
    title: 'High Frequency Coding',
    name: <span className='menu-title'>High Frequency Coding</span>,
    to: '/high_frequency',
    icon:<span className='menu-icon icon-highfrequency' title='High Frequency Coding'></span>,
  },

  {
    component: Divider,
  },
  // {
  //   component: CNavItem,
  //   title: 'Receipt',
  //   name: <span className='menu-title'>Receipt</span>,
  //   to: '/receipt',
  //   icon:<span className='menu-icon icon-receipt' title='Receipt'></span>,
  // },
  {
    component: CNavItem,
    title: 'Coding',
    name: <span className='menu-title'>Coding</span>,
    to: '/coding',
    icon:<span className='menu-icon icon-coding' title='Coding'></span>,
  },
  {
    component: CNavItem,
    title: 'Support Tickets',
    name: <span className='menu-title'>Support Tickets</span>,
    to: '/supports',
    icon: <span className='menu-icon icon-supporttickets' title='Support Tickets'></span>,
  },
  {
    component: CNavItem,
    title: 'Verify Entries',
    name: <span className='menu-title'>Verify Entries</span>,
    to: '/verifyCode',
    icon: <span className='menu-icon icon-verifycoding' title='Verify Entries'></span>,
  },
  {
    component: CNavItem,
    title: 'ITIM',
    name: <span className='menu-title'>ITIM</span>,
    to: '/itimCode',
    icon: <span className='menu-icon icon-itim' title='Itim Coding'></span>,
  },
  {
    component: CNavItem,
    title: 'Assest Entry',
    name: <span className='menu-title'>Assest Entry</span>,
    to: '/assest',
    icon: <span className='menu-icon icon-assetsentry' title='Assest Entry'></span>,
  },
  {
    component: CNavItem,
    title: 'Portfolio',
    name: <span className='menu-title'>Portfolio</span>,
    to: '/portfolio',
    icon: <span className='menu-icon icon-portfolio' title='Portfolio'></span>,
  },
  {
    component: CNavItem,
    title: 'Standing Journal',
    name: <span className='menu-title'>Standing Journal</span>,
    to: '/standingJournal',
    icon:<span className='menu-icon icon-standingjournal' title='Standing Journal'></span> ,
  },
  {
    component: Divider,
  },
  // {
  //   component: CNavItem,
  //   title: 'Reports',
  //   name: <span className='menu-title'>Reports</span>,
  //   to: '/reports',
  //   icon: <span className='menu-icon icon-report' title='Reports'></span>,
  // },
  // {
  //   component: Divider,
  // },
  {
    component: CNavItem,
    title: 'Terms & Conditions',
    name: <span className='menu-title'>Terms & Conditions</span>,
    to: '/termsAndcondition',
    icon: <span className='menu-icon icon-termsconditions' title='Terms And Conditions'></span>,
  },
  {
    component: CNavItem,
    title: 'Privacy Policy',
    name: <span className='menu-title'>Privacy Policy</span>,
    to: '/privacypolicy',
    icon: <span className='menu-icon icon-privacypolicy' title='Privacy Policy'></span>,
  },
  {
    component: CNavItem,
    title: 'About Us',
    name: <span className='menu-title'>About Us</span>,
    to: '/aboutus',
    icon:<span className='menu-icon icon-aboutus' title='About Us'></span>,
  },
  // {
  //   component: CNavItem,
  //   title: 'Invite A Friend',
  //   name: <span className='menu-title'>Invite A Friend</span>,
  //   to: '/inviteFriend',
  //   icon:<span className='menu-icon icon-invites' title='Invite A Friend'></span>,
  // },
 ]

export const _nav = [
  {
    component: CNavItem,
    title: 'Company Details',
    name: <span className='menu-title'>Company Details</span>,
    to: '/companyDetails',
    icon: <span className='menu-icon icon-companydetails' title='Company Details'></span>,
  },
  {
    component: CNavItem,
    title: 'Bank Accounts',
    name: <span className='menu-title'>Bank Accounts</span>,
    to: '/bank',
    icon: <span className='menu-icon icon-bankaccount' title='Bank Accounts'></span>
  },
  {
    component: CNavItem,
    title: 'Invites',
    name: <span className='menu-title' title='Invites'>Invites</span>,
    to: '/invites',
    icon:<span className='menu-icon icon-invite' title='Invites' alt='Invites'></span>,
  },
  {
    component: CNavItem,
    title: 'Upload Bank Transaction',
    name: <span className='menu-title'>Upload Bank Transaction</span>,
    to: '/transaction',
    icon: <span className='menu-icon icon-uploadbanktransition' title='Upload Bank Transaction'></span>,
  },
  {
    component: CNavItem,
    title: 'Business Code',
    name: <span className='menu-title'>Business Code</span>,
    to: '/business_code',
    icon: <span className='menu-icon icon-businesscode' title='Business Code'></span>,
  },
  {
    component: CNavItem,
    title: 'High Frequency Coding',
    name: <span className='menu-title'>High Frequency Coding</span>,
    to: '/high_frequency',
    icon:<span className='menu-icon icon-highfrequency' title='High Frequency Coding'></span>,
  },

  {
    component: Divider,
  },
  {
    component: CNavItem,
    title: 'Receipt',
    name: <span className='menu-title'>Receipt</span>,
    to: '/receipt',
    icon:<span className='menu-icon icon-receipt' title='Receipt'></span>,
  },
  {
    component: CNavItem,
    title: 'Coding',
    name: <span className='menu-title'>Coding</span>,
    to: '/coding',
    icon:<span className='menu-icon icon-coding' title='Coding'></span>,
  },
  {
    component: CNavItem,
    title: 'Support Tickets',
    name: <span className='menu-title'>Support Tickets</span>,
    to: '/supports',
    icon: <span className='menu-icon icon-supporttickets' title='Support Tickets'></span>,
  },
  {
    component: CNavItem,
    title: 'Verify Entries',
    name: <span className='menu-title'>Verify Entries</span>,
    to: '/verifyCode',
    icon: <span className='menu-icon icon-verifycoding' title='Verify Entries'></span>,
  },
  {
    component: CNavItem,
    title: 'ITIM',
    name: <span className='menu-title'>ITIM</span>,
    to: '/itimCode',
    icon: <span className='menu-icon icon-itim' title='Itim Coding'></span>,
  },
  {
    component: CNavItem,
    title: 'now Bas',
    name: <span className='menu-title'>nowBAS</span>,
    to: '/nowBas',
    icon: <span className='menu-icon icon-nowbas' title='now Bas'></span>,
  },
  {
    component: CNavItem,
    title: 'Financial Instrument',
    name: <span className='menu-title'>Financial Instrument</span>,
    to: '/financialInstrument',
    icon: <span className='menu-icon icon-financial-instrument' title='Financial Instrument'></span>,
  },
  // {
  //   component: CNavItem,
  //   title: 'Ordering',
  //   name: <span className='menu-title'>Ordering</span>,
  //   to: '/ordering',
  //   icon: <span className='menu-icon icon-ordering' title='Ordering'></span>,
  // },
  // {
  //   component: CNavItem,
  //   title: 'Assest Entry',
  //   name: <span className='menu-title'>Assest Entry</span>,
  //   to: '/assest',
  //   icon: <span className='menu-icon icon-assetsentry' title='Assest Entry'></span>,
  // },
  // {
  //   component: CNavItem,
  //   title: 'Product',
  //   name: <span className='menu-title'>Products</span>,
  //   to: '/product',
  //   icon: <span className='menu-icon icon-assetsentry' title='Product'></span>,
  // },
  //  {
  //   component: CNavItem,
  //   title: 'Customers & Contacts',
  //   name: <span className='menu-title'>Customers & Contacts</span>,
  //   to: '/customer',
  //   icon: <span className='menu-icon icon-assetsentry' title='Customers & Contacts'></span>,
  // },
  {
    component: CNavItem,
    title: 'Portfolio',
    name: <span className='menu-title'>Portfolio</span>,
    to: '/portfolio',
    icon: <span className='menu-icon icon-portfolio' title='Portfolio'></span>,
  },
  {
    component: CNavItem,
    title: 'Standing Journal',
    name: <span className='menu-title'>Standing Journal</span>,
    to: '/standingJournal',
    icon:<span className='menu-icon icon-standingjournal' title='Standing Journal'></span> ,
  },
  {
    component: Divider,
  },
  // {
  //   component: CNavGroup,
  //   // title: 'Reports',
  //   name: <span className='menu-title'>Reports</span>,
  //   icon: <span className='menu-icon icon-report' title='Reportsr'></span>,
  //   items : [
      {
        id: "GeneralLedger",
        component: CNavItem,
        title: 'General ledger',
        name: <span className='menu-title'>General Ledger</span>,
        to: '/ledger',
        icon: <span className='menu-icon icon-report' title='General ledger'></span>,
      },
      {
        id: "TrialBalance",
        component: CNavItem,
        title: 'Trial Balance Report',
        name: <span className='menu-title'>Trial Balance Report</span>,
        to: '/reports',
        icon: <span className='menu-icon icon-report' title='Trial Balance Report'></span>,
      },
      {
        id: "gstReport",
        component: CNavItem,
        title: 'Gst Report',
        name: <span className='menu-title'>Gst Report</span>,
        to: '/gstReport',
        icon: <span className='menu-icon icon-report' title='Gst Report'></span>,
      },
  //   ]
  // },
 
  {
    component: Divider,
  },
  {
    component: CNavItem,
    title: 'Terms & Conditions',
    name: <span className='menu-title'>Terms & Conditions</span>,
    to: '/termsAndcondition',
    icon: <span className='menu-icon icon-termsconditions' title='Terms And Conditions'></span>,
  },
  {
    component: CNavItem,
    title: 'Privacy Policy',
    name: <span className='menu-title'>Privacy Policy</span>,
    to: '/privacypolicy',
    icon: <span className='menu-icon icon-privacypolicy' title='Privacy Policy'></span>,
  },
  {
    component: CNavItem,
    title: 'About Us',
    name: <span className='menu-title'>About Us</span>,
    to: '/aboutus',
    icon:<span className='menu-icon icon-aboutus' title='About Us'></span>,
  },
  {
    component: CNavItem,
    title: 'Invite A Friend',
    name: <span className='menu-title'>Invite A Friend</span>,
    to: '/inviteFriend',
    icon:<span className='menu-icon icon-invites' title='Invite A Friend'></span>,
  },
]

