import React, { useMemo, useState, useEffect, useRef } from "react";
import {
  CRow,
  CCol,
  CButton,
  CFormInput,
  CFormSelect,
  CForm,
} from "@coreui/react";
import {
  GetApiwithHeader,
  postApiWithHeader,
  UpdateUserwithHeader,
} from "src/Api/Api";
import PropTypes from "prop-types";
import { userAttributes } from "../../share/localData";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useData } from '../../components/dataContext';

const AddBank = (props) => {
  const { setTriggerRenderHeaderOnBankAdd } = useData();
  const editValue = props?.editValue;
  const { cmpID } = userAttributes();
  const [bankData, setBankData] = useState([]);
  const [bankVal, setBankVal] = useState();
  const [bankTypeVal, setBankTypeVal] = useState();
  const [bankAccountType, setBankAccountType] = useState([]);
  const accessToken = localStorage.getItem("token");
  const formRef = useRef(null);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    bankId: "",
    accountNumber: "",
    accountTypeId: "",
    bsbNumber: "",
    accountName: "",
    branchAddress: "",
    routingNumber: "string",
  });
  

  AddBank.propTypes = {
    fetchData: PropTypes.func.isRequired,
    editValue: PropTypes.string.isRequired,
    handleClose: PropTypes.func, // Assuming 'handleCloseClick' is a function prop
  };

  const headers = useMemo(
    () => ({
      Authorization: `Bearer ${accessToken}`,
    }),
    [accessToken]
  );
  const companyId = cmpID;

  const bankApi = async () => {
    try {
      const response = await GetApiwithHeader(
        `/api/bankaccount?companyId=${0}`,
        headers
      );
      const filteredbankData =
        response?.data && response?.data?.data?.filter((item) => item?.bankName);
      setBankData(filteredbankData);
    } catch (error) {
      console.log(error);
    }
  };

  const accountTypeApi = async () => {
    try {
      const response = await GetApiwithHeader(
        `/api/bankaccount/bankbccounttypes?companyId=${0}`,
        headers
      );
      const filtered =
        response &&
        response?.data?.data?.filter((item) => item?.bankAccountTypes);
      setBankAccountType(filtered);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    bankApi();
    accountTypeApi();
  }, [headers, cmpID]);

  useEffect(() => {
    if (!editValue) {
      setBankVal(null);
      setBankTypeVal(null);
    }
  }, [editValue]);

  const validateForm = () => {

    const errors = {};
    if (!editValue && !bankVal) {
      errors.bank = "Bank is required";
    }
    if (!editValue && !bankTypeVal) {
      errors.accountType = "Account type is required";
    }
    if (!formRef.current.accountNumber.value.trim()) {
      errors.accountNumber = "Account number is required";
    }
    if (!formRef.current.bsbNumber.value.trim()) {
      errors.bsbNumber = "BSB number is required";
    }
    if (!formRef.current.accountName.value.trim()) {
      errors.accountName = "Account name is required";
    }
    if (!formRef.current.bankAddress.value.trim()) {
      errors.bankAddress = "Bank address is required";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    const formData = {
      bankId: formRef.current.bank.value ? bankData.find(item => item.bankName === formRef.current.bank.value)?.id : (editValue && editValue.bankId),
      accountNumber: formRef.current.accountNumber.value.trim(),
      accountTypeId: formRef.current.accountType.value ? bankAccountType.find(item => item.bankAccountTypes === formRef.current.accountType.value)?.id : (editValue && editValue.accountTypeId),
      bsbNumber: formRef.current.bsbNumber.value.trim(),
      accountName: formRef.current.accountName.value.trim(),
      branchAddress: formRef.current.bankAddress.value.trim(),
      routingNumber: "string",
    };

    try {
      const response = editValue
        ? await UpdateUserwithHeader(
          `/api/company/bankaccount/update/${editValue.id},${companyId}`,
          formData,
          headers
        )
        : await postApiWithHeader(
          `/api/company/bankaccount/create/${companyId}`,
          formData,
          headers
        );
      console.log('response', response)

      if (editValue ? response.statusCode === 100 : response.data.statusCode === 100) {
        toast.success(
          editValue ? "Updated successfully" : "Added successfully",
          {
            autoClose: 1000,
            onClose: () => {
              props?.handleClose();
              resetForm();
              props?.fetchData();
              setTriggerRenderHeaderOnBankAdd(true);
            },
          }
        );
      } else {
        toast.error(response.data.message,{autoClose:1000});
      }
    } catch (error) {
      console.log("Error:", error);
      toast.error("An error occurred while saving data");
    }
  };


  const handleBankChange = (e) => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value.trim();
    const selectedOption = bankData.find(
      (item) => item?.bankName === e.target.value
    );
    setBankVal(selectedOption);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: fieldValue ? '' : prevErrors[fieldName],
    }));
  };

  const handleBankTypeChange = (e) => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value.trim();
    const selectedOption = bankAccountType.find(
      (item) => item?.bankAccountTypes === e.target.value
    );
    setBankTypeVal(selectedOption);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: fieldValue ? '' : prevErrors[fieldName],
    }));
  };

  const handleChange = (e) => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value.trim();
    // Clear the error message for the corresponding field
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: fieldValue ? '' : prevErrors[fieldName],
    }));
  };
  const resetForm = () => {
    setFormData({
      bankId: "",
      accountNumber: "",
      accountTypeId: "",
      bsbNumber: "",
      accountName: "",
      branchAddress: "",
      routingNumber: "string",
    });
    setBankVal(null);
    setBankTypeVal(null);
    setErrors({});
  };

  useEffect(() => {
    if (editValue) {
      setFormData({
        bankId: editValue.bankId || "",
        accountNumber: editValue.accountNumber || "",
        accountTypeId: editValue.accountTypeId || "",
        bsbNumber: editValue.bsbNumber || "",
        accountName: editValue.accountName || "",
        branchAddress: editValue.branchAddress || "",
        routingNumber: "string",
      });
        // Set bankVal
    const selectedBank = bankData.find((bank) => bank.id === editValue.bankId);
    setBankVal(selectedBank);
    
    // Set bankTypeVal
    const selectedAccountType = bankAccountType.find((type) => type.id === editValue.accountTypeId);
    setBankTypeVal(selectedAccountType);
    } else {
      setFormData({
        bankId: "",
        accountNumber: "",
        accountTypeId: "",
        bsbNumber: "",
        accountName: "",
        branchAddress: "",
        routingNumber: "string",
      });
    }
    setBankVal(null);
    setBankTypeVal(null);
    setErrors({});
  }, [editValue]);

  return (
    <div className='addBox'>
      <CForm ref={formRef} onSubmit={handleSubmit}>
        <CRow className="mb-2">
          <hr />
          <CCol md={12}>
            <CFormSelect
              label={
                <>
                  Bank <span className="text-danger">*</span>
                </>
              }
              onChange={handleBankChange}
              name="bank"
              value={bankVal ? bankVal?.bankName : editValue?.bankName ?? ""}
              invalid={errors.bank ? true : false}
            >
              <option value="">Select Bank Name</option>
              {bankData
                ?.sort((a, b) => a.bankName.localeCompare(b.bankName))
                .map((item) => (
                  <option key={item.id} value={item.bankName}>
                    {item.bankName}
                  </option>
                ))}
            </CFormSelect>
            <div className="invalid-feedback">{errors.bank}</div>
          </CCol>
        </CRow>
        <div className="md-12 mt-3">
          <CCol md={12}>
            <CFormInput
              type="text"
              name="accountNumber"
              placeholder="Enter account number"
              label={
                <>
                  Account Number <span className="text-danger">*</span>
                </>
              }
              defaultValue={
                editValue?.accountNumber ? editValue?.accountNumber : ""
              }
              invalid={errors.accountNumber ? true : false}
              onChange={handleChange}
            />
            <div className="invalid-feedback">{errors.accountNumber}</div>

          </CCol>
        </div>
        <div className="md-12 mt-3">
          <CCol md={12}>
            <CFormSelect
              label={
                <>
                  Account Type <span className="text-danger">*</span>
                </>
              }
              onChange={(e) => handleBankTypeChange(e)}
              name="accountType"
              value={
                bankTypeVal
                  ? bankTypeVal?.bankAccountTypes
                  : editValue?.accountType ?? ""
              }
              invalid={errors.accountType ? true : false}
            >
              <option value="">Select Account Type</option>
              {bankAccountType?.map((item) => (
                <option key={item.id} value={item.bankAccountTypes}>
                  {item.bankAccountTypes}
                </option>
              ))}
            </CFormSelect>
            <div className="invalid-feedback">{errors.accountType}</div>
          </CCol>
        </div>
        <div className="md-12 mt-3">
          <CCol md={12}>
            <CFormInput
              type="text"
              name="bsbNumber"
              placeholder="Enter BSB name"
              label={
                <>
                 BSB Number <span className="text-danger">*</span>
                </>
              }
              defaultValue={editValue?.bsbNumber ? editValue?.bsbNumber : ""}
              invalid={errors.bsbNumber ? true : false}
              onChange={handleChange}
            />
            <div className="invalid-feedback">{errors.bsbNumber}</div>
          </CCol>
        </div>
        <div className="md-12 mt-3">
          <CCol md={12}>
            <CFormInput
              type="text"
              name="accountName"
              placeholder="Enter account name"
              label={
                <>
                Account Name <span className="text-danger">*</span>
                </>
              }
              defaultValue={editValue?.accountName ? editValue?.accountName : ""}
              invalid={errors.accountName ? true : false}
              onChange={handleChange}
            />
            <div className="invalid-feedback">{errors.accountName}</div>
          </CCol>
        </div>
        <div className="md-12 mt-3">
          <CCol md={12}>
            <CFormInput
              type="text"
              name="bankAddress"
              placeholder="Enter bank address"
              label={
                <>
                Bank Address <span className="text-danger">*</span>
                </>
              }
              defaultValue={
                editValue?.branchAddress ? editValue?.branchAddress : ""
              }
              invalid={errors.bankAddress ? true : false}
              onChange={handleChange}
            />
            <div className="invalid-feedback">{errors.bankAddress}</div>
          </CCol>
        </div>
        <CRow className="mt-4 justify-content-end">
          <CCol xs="auto">
            <CButton color="light" onClick={() => {
              resetForm()
              props.handleClose()
            }}>
              Cancel
            </CButton>
          </CCol>
          <CCol xs="auto">
            <CButton color="primary" type="submit">
              Save
            </CButton>
          </CCol>
        </CRow>
      </CForm>
    </div>
  );
};

export default AddBank;
