import React, { useState, useEffect } from 'react'
import { CImage, CRow, CCol, CCard, CCardBody, CForm, CButton, CFormInput, CFormLabel } from '@coreui/react';
import logo from 'src/assets/brand/CompanyLogo.png'
import { useLocation, useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import { postApi } from 'src/Api/Api';
import show from '../../../src/assets/images/show.png'
import eyeOff from '../../../src/assets/images/eyeoff.svg'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { cilCheckAlt } from '@coreui/icons';
import CIcon from '@coreui/icons-react';

const appKey = "b60c!329%a4f4233bVce2ea2515ayu24";
const initializationVector = "1503199371628005";

const decryptString = (cipherText) => {
    const appKeyBytes = CryptoJS.enc.Utf8.parse(appKey);
    const ivBytes = CryptoJS.enc.Utf8.parse(initializationVector);
    const cipherParams = CryptoJS.lib.CipherParams.create({
        ciphertext: CryptoJS.enc.Base64.parse(cipherText)
    });

    const decrypted = CryptoJS.AES.decrypt(
        cipherParams,
        appKeyBytes,
        { iv: ivBytes }
    );

    return decrypted.toString(CryptoJS.enc.Utf8);
};


const ForgetPassword = () => {
    const [form, setForm] = useState({
        password: '',
        resetPassword: ''
    });

    const [queryString, setQueryString] = useState("");
    const [queryParams, setQueryParams] = useState([]);
    const location = useLocation();
    const [token, setToken] = useState("");
    const [userId, setUserId] = useState("");
    const [email, setEmail] = useState("");
    const decryptedText = decryptString(queryString);
    const [errors, setErrors] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordTwo, setShowPasswordTwo] = useState(false);
    const [thank, setThank] = useState(true)

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: ''
        }));
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        let queryParamsString = searchParams.toString();
        queryParamsString = queryParamsString.replace(/%2F/g, '/');
        setQueryString(queryParamsString);
    }, [location.search]);

    useEffect(() => {
        const decryptedText = decryptString(queryString);
        const paramsArray = decryptedText.split('&');
        setQueryParams(paramsArray);
    }, [queryString]);

    useEffect(() => {
        queryParams.forEach(item => {
            const [key, value] = item.split('=');
            if (key === 'token') {
                setToken(value);
            }
            else if (key === 'userId') {
                setUserId(value);
            }
            else if (key === 'email') {
                setEmail(value);
            }
        });
    }, [queryParams]);

    const validateForm = () => {
        const newErrors = {};
        if (!form.password) {
            newErrors.password = "New password is required.";
        } else {
            // Check if the password is at least 8 characters long
            if (form.password.length < 8) {
                newErrors.password = "Password must be at least 8 characters.";
            }
            // Check if the password is alphanumeric
            const complexPasswordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/;
            if (!complexPasswordRegex.test(form.password)) {
                newErrors.password = "Password must contain 1 uppercase, 1 lowercase,1 symbol, 1 number and should be minimum of 8 digit."
            }
        }

        if (!form.resetPassword) {
            newErrors.resetPassword = "Confirm password is required.";
        }
        if (form.password && form.resetPassword && form.password !== form.resetPassword) {
            newErrors.resetPassword = "Passwords do not match.";
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) {
            return;
        }

        const payload = {
            userId: userId,
            token: token,
            newPassword: form.password
        }

        try {
            const response = await postApi('api/auth/ResetPasswordByToken', payload)
            if (!response?.data) {
                toast.error('Failed to submit form data');
            } else {
                if (response?.data?.statusCode === 100) {
                    toast.success(`${response.data.message}`, { autoClose: 1000 });
                }
                else
                    if (response?.data?.statusCode === 102) {
                        toast.error(`${response.data.message}`, { autoClose: 1000 });
                    }
            }
        } catch (err) {
            console.log('err', err)
        }
        setForm({
            password: '',
            resetPassword: ''
        })

        setTimeout(() => {
            setThank(false)
        }, 2000)
    };

    return (
        <div>
            {thank ?
                <div className='forget-password-container mt-4'>
                    <ToastContainer />
                    <CCol md={12} >
                        <CCard className='form-container' style={{ boxShadow: '0px 0px 6px 0px rgba(0,0,0,0.3)' }}>
                            <CCardBody>
                                <div className="clearfix logo">
                                    <CImage align="center" rounded src={logo} />
                                </div>
                                {/* <h6 style={{color:'grey'}}>{email}</h6> */}
                                <CForm onSubmit={handleSubmit}>
                                    <div >
                                        <h5 htmlFor="password" className="mt-3 mb-1">Email</h5>
                                        <CFormInput
                                            type="email"
                                            id="email"
                                            name="email"
                                            value={email}
                                            disabled
                                        />
                                    </div>
                                    <div style={{ position: 'relative' }}>
                                        <h5 htmlFor="password" className="mt-3 mb-1">New Password <span style={{ color: 'red'}}>*</span></h5>
                                        <CFormInput
                                            type={showPasswordTwo ? "text" : "password"}
                                            id="password"
                                            name="password"
                                            value={form.password}
                                            onChange={handleChange}
                                            placeholder='New Password'
                                            onPaste={(e) => e.preventDefault()}
                                        />
                                        <li
                                            type="button"
                                            onClick={() => setShowPasswordTwo(!showPasswordTwo)}
                                            style={{ position: 'absolute', right: '10px', top: '74%', transform: 'translateY(-50%)' }}
                                        >
                                            {showPasswordTwo ? <CImage src={show} width="19px" height="15px" /> : <CImage src={eyeOff} width="22px" height="22px" />}
                                        </li>
                                    </div>
                                    {errors.password && <div className="text-danger">{errors.password}</div>}
                                    <div style={{ position: 'relative' }}>
                                        <h5 htmlFor="resetPassword" className="mt-3 mb-1">Confirm Password <span style={{ color: 'red' }}>*</span></h5>
                                        <CFormInput
                                            type={showPassword ? "text" : "password"}
                                            id="resetPassword"
                                            name="resetPassword"
                                            value={form.resetPassword}
                                            onChange={handleChange}
                                            placeholder='Confirm Password'
                                            onPaste={(e) => e.preventDefault()}
                                        />
                                        <li
                                            type="button"
                                            onClick={() => setShowPassword(!showPassword)}
                                            style={{ position: 'absolute', right: '10px', top: '74%', transform: 'translateY(-50%)' }}
                                        >
                                            {showPassword ? <CImage src={show} width="19px" height="15px" /> : <CImage src={eyeOff} width="22px" height="22px" />}
                                        </li>
                                    </div>
                                    {errors.resetPassword && <div className="text-danger">{errors.resetPassword}</div>}
                                    <div className="text-center">
                                        <CButton type="submit" color="primary" className='mt-3 mb-2'>Submit</CButton>
                                    </div>
                                </CForm>
                            </CCardBody>
                        </CCard>
                    </CCol>
                </div> : <div style={containerStyle}>
                    <CIcon icon={cilCheckAlt} style={iconStyle} />
                    <h1 className='mt-2'>Thank you</h1>
                </div>}
        </div>

    )
}

export default ForgetPassword

const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    margin: '40px',
}

const iconStyle = {
    width: '60px',
    height: '60px',
    color: 'white',
    backgroundColor: 'green',
    borderRadius: '100%',
};