
import {
  CRow,
  CCol,
  CButton,
  CFormCheck,
  CTextarea,
  CFormTextarea,
  CFormInput,
  CFormSelect,
  CFormLabel,
  CImage,
  CCard,
  CCardTitle,
  CForm // Add CForm to your import statement
} from '@coreui/react'
import { useState } from 'react'
const File = (props) => {
 
  return (
    <div>
<CForm>
  <CFormTextarea
    id="exampleFormControlTextarea1"
   label="Enter Description"
    placeholder="Enter detail of physical file location..."
    rows={6}
  ></CFormTextarea>
  <CRow className="mt-4 justify-content-end">
          <CCol xs="auto">
             <CButton color="light" 
             onClick={() => {
            //   // resetForm()
             props.handleCloseClick()
            }}
            
            >
              Cancel
            </CButton> 
          </CCol>
          <CCol xs="auto">
            <CButton color="primary" 
            // type="submit"
            >
              Save
            </CButton>
          </CCol>
        </CRow>
</CForm>
    </div>
  )
}

export default File