import React from 'react'
import { CImage, CRow, CCol, CCard, CCardBody  } from '@coreui/react';
import logo from 'src/assets/brand/CompanyLogo.png'
import android from 'src/assets/brand/android.png'
// import iphone from 'src/assets/brand/Iphone.png'


const DownloadApk = () => {
  return (
    <div className='downloadPage'>
    <CCol md={12}>
    <CCard>
    <CCardBody>
         <div className="clearfix logo">
        <CImage align="center" rounded src={logo}  />
      </div>
      <h4>Download</h4>
      {/* <span>
        It will be a private portal to download the app
      </span> */}
      <div className='DownloadBtn'>
      <a href="https://www.cdnsolutionsgroup.com/Download/overzero.apk" target="_blank" rel="noopener noreferrer">
        <CImage align="center" rounded src={android} />
      </a>
      </div>
      {/* <div className="clearfix">
        <CImage align="center" rounded src={android} className='mb-3' />
      </div> */}
      {/* <div className="clearfix">
        <CImage align="center" rounded src={iphone} className='mb-3' />
      </div> */}
    </CCardBody>
    </CCard>
    </CCol>
    </div>
  )
}

export default DownloadApk