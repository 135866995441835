import React, { useMemo, useEffect, useState, Suspense, lazy } from "react";
import {
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CTableHead,
  CTableRow,
  CCardBody,
  CImage,
  CTooltip,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CButton,
  CCarouselItem,
  CCarousel,
  CCard,
} from "@coreui/react";
import { GetApiwithHeader, DeleteApiwithHeader } from "src/Api/Api";
import MapEntry from "./mapEntry";
import { view, pic } from "src/components/Images/Images";
import OffCanvas from "../../share/offCanvas";
import { userAttributes } from "../../share/localData";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useData } from "../../components/dataContext";
import { useNavigate } from "react-router-dom";
const LazyMapEntry = lazy(() => import("./mapEntry"));
import { DataGridPro } from "@mui/x-data-grid-pro";
import { LicenseInfo } from "@mui/x-license";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

const Receipt = () => {
  const { data } = useData();
  LicenseInfo.setLicenseKey(
    "248a71a5e79c492f4b13769843fcf6dcTz04OTIzMCxFPTE3NDU3NTY1ODYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
  );
  const [receiptData, setReceiptData] = useState([]); // Renamed from 'banckData' to 'receiptData' for clarity
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Number of items per page
  const { cmpID, bankID } = userAttributes();
  const accessToken = localStorage.getItem("token");
  const [visible, setVisible] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const companyId = cmpID;
  const [searchQuery, setSearchQuery] = useState("");
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
  const [activeTab, setActiveTab] = useState(0);
  const [isCoded, setIsCoded] = useState(false);
  const [toggleItem, setToggaleItem] = useState(false);
  const [value, setValue] = useState(2);
  const [label, setLabel] = useState("All");
  const SerialNumber = itemsPerPage * currentPage - itemsPerPage;
  const [loadingState, setLoadingState] = useState(true);
  const [alignment, setAlignment] = useState(2);
  // const [alignment, setAlignment] = React.useState("web");
  const navigate = useNavigate();
  const headers = useMemo(
    () => ({
      Authorization: `Bearer ${accessToken}`,
    }),
    [accessToken]
  );

  const fetchData = async () => {
    setLoadingState(true);
    try {
      const res = await GetApiwithHeader(
        `/api/receipt/${companyId}?bankAccountId=${bankID}`,
        headers
      );
      if (res.data) setLoadingState(false);
      if (res.data && res.data.data && res.data.data.length > 0) {
        setReceiptData(res.data && res.data.data);
      } else {
        setReceiptData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddClick = (item) => {
    setSelectedRows(item);
    if (cmpID) {
      setVisible(true);
    } else {
      toast.warning("Please select a company first");
    }
  };

  const filteredReceiptData = useMemo(() => {
    return receiptData?.filter((item) => {
      const matchesSearchQuery =
        item?.abn?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
        item.createdAt.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item?.valueAmount
          ?.toString()
          .toLowerCase()
          .includes(searchQuery?.toLowerCase()) ||
        item?.comments?.toLowerCase().includes(searchQuery?.toLowerCase());

      const isMapped = item?.mappingStatus === "Mapped";
      const isUnmapped = item?.mappingStatus !== "Mapped";
      const isCoded = item?.mappingStatus === "Coded";
      const isUncoded = item?.mappingStatus !== "Coded";

      if (value === 1) {
        // If customToggle is set to "Mapped"
        return matchesSearchQuery && isMapped;
      } else if (value === 2) {
        // If customToggle is set to "All"
        return matchesSearchQuery;
      } else if (value === 3) {
        // If customToggle is set to "Unmapped"
        return matchesSearchQuery && isUnmapped;
      } else if (value === 4) {
        // If customToggle is set to "Uncoded"
        return matchesSearchQuery && isUncoded;
      }
    });
  }, [receiptData, searchQuery, value]);

  const totalPages = Math.ceil(itemsPerPage);

  const handleSort = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const sortedItems = () => {
    const sortableItems = [...filteredReceiptData];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  };

  const getCurrentPageData = () => {
    const sortedData = sortedItems();
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = Math.min(
      startIndex + itemsPerPage,
      filteredReceiptData.length
    );
    return sortedData.slice(startIndex, endIndex);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handleView = (item) => {
    navigate("/entry", { state: { item } });
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const handleCloseClick = () => {
    setVisible(false);
  };

  function formatToUSDateTime(dateTimeString) {
    const dateObj = new Date(dateTimeString);
    const options = {
      month: "numeric",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    };
    return dateObj.toLocaleString("en-US", options);
  }

  useEffect(() => {
    fetchData();
  }, [headers, companyId, cmpID, bankID]);

  useEffect(() => {
    getCurrentPageData();
  }, []);

  useEffect(() => {
    setToggaleItem(false);
    setLabel("All");
    setValue(2);
  }, [activeTab]);


  const filterme = (e, newAlignment) => {
    if (newAlignment !== null) {
      const newValue = parseInt(newAlignment, 10);
      setValue(newValue); // Update state
      setAlignment(newAlignment); // Update alignment state

      const customToggle = document.getElementById("custom-toggle");
      if (newValue === 2) {
        customToggle?.classList?.remove("tgl-off");
        customToggle?.classList?.add("tgl-def");
        setLabel("All");
      } else if (newValue === 3) {
        customToggle?.classList?.remove("tgl-def");
        customToggle?.classList?.add("tgl-off");
        if (activeTab === 0) {
          setLabel("Unmapped");
        } else {
          setLabel("Uncoded");
        }
      }
    }
  };

  const column = [
    {
      field: "id",
      headerName: "Receipt #",
      width: 130,
      headerClassName: "dataGridHeader",
    },
    {
      field: "abn",
      headerName: "ABN number",
      width: 200,
      headerClassName: "dataGridHeader",
      renderCell: ({ row }) => {
        return row?.abn ? row?.abn : "ABN not entered";
      },
    },
    {
      field: "createdAt", headerName: "Created Date & Time", width: 200, headerClassName: 'dataGridHeader',
      valueGetter: (params) => {
        const date = new Date(params);
        return date.toLocaleString("en-GB", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        });
      }
    },
    {
      field: "valueAmount",
      headerName: "Amount",
      width: 200,
      headerClassName: "dataGridHeader",
    },
    {
      field: "comments",
      headerName: "Comments",
      width: 250,
      headerClassName: "dataGridHeader",
    },
    {
      field: "action",
      headerName: "Action",
      width: 180,
      headerClassName: "dataGridHeader",
      type: "actions",
      renderCell: ({ row }) => {
        return (
          <div className="actionMapping">
            <CTooltip content="View">
              <button>
                <CImage
                  src={row?.receiptImage[0]?.thumbnailFilePaths !== '' ? row?.receiptImage[0]?.thumbnailFilePaths : row?.mediaPath[0]}
                  onClick={() => handleView(row)}
                />
              </button>
            </CTooltip>
            {row.mappingStatus === "Mapped" ? (
              <div className="mapped_green">{row.mappingStatus}</div>
            ) : (
              <div className="map_red" onClick={() => handleAddClick(row)}>
                Map
              </div>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <ToastContainer position="top-right" />
      <div className="cardBody">
        <div className="cardHeader">
          <h3>Receipt</h3>
          <div className="mr-1 receiptTab">
            <input
              type="text"
              className="mr-2"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <ToggleButtonGroup
              value={alignment}
              className="tabBtn"
              exclusive
              onChange={filterme}
              aria-label="Platform"
            >
              <ToggleButton value={2}>All</ToggleButton>
              <ToggleButton value={3}>Unmapped</ToggleButton>
            </ToggleButtonGroup>

          </div>
        </div>
      </div>
      <div className="datatableGrid">
        <DataGridPro
          rows={filteredReceiptData}
          columns={column}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 15 },
            },
          }}
          pageSizeOptions={[5, 10]}
          density="compact"
          onRowClick={(row) => handleView(row.row)}
          disableRowSelectionOnClick
          loading={loadingState}
        />
      </div>
      <OffCanvas
        Open={visible}
        title={"Bank statments"}
        handleCloseClick={handleCloseClick}
        canvasWidth={"700px"}
        component={
          <Suspense fallback={<div>Loading...</div>}>
            <LazyMapEntry
              reciptID={selectedRows}
              handleClose={handleCloseClick}
              receiptApi={fetchData}
            />
          </Suspense>
        }
      />
    </>
  );
};

export default Receipt;
