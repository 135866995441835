import React, { useEffect, useState } from "react";
import {
  CImage,
  CButton,
  CTooltip,
} from "@coreui/react";
import {
  page1,
  out,
} from "src/components/Images/Images";
import { GetApiwithHeader, DeleteApiwithHeader } from "src/Api/Api";
// import { capital, revenue } from 'src/components/Images/Images';
import OffCanvas from "../../share/offCanvas";
import Add from "./Add";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { userAttributes } from "../../share/localData";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { LicenseInfo } from '@mui/x-license';
import { useData } from "../../components/dataContext";


const CodeManagement = () => {
  LicenseInfo.setLicenseKey('248a71a5e79c492f4b13769843fcf6dcTz04OTIzMCxFPTE3NDU3NTY1ODYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');
  const { data, bankDataLocal } = useData();
  const [items, setItems] = useState([]);
  const [editValue, setEditValue] = useState(null);
  const [viewValue, setViewValue] = useState(false);
  

  const [visible, setVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [getCategory, setGetCategory] = useState();
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(15); // Number of items per page
  const [clearFormDataFlag, setClearFormDataFlag] = useState(false);
  const { cmpID } = userAttributes();
  const companyId = cmpID;
  const [searchQuery, setSearchQuery] = useState("");
  const accessToken = localStorage.getItem("token");
  const [loadingState, setLoadingState] = useState(true)

  const [code, setCode] = useState();
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const fetchData = async () => {
    setLoadingState(true)
    const apiUrl = `/api/BusinessCodes?companyId=${companyId}`;
    try {
      const res = await GetApiwithHeader(apiUrl, headers);
      if(res?.data) setLoadingState(false)
      if (res.data && res.data.data && res.data.data.length > 0) {
        setItems(res?.data?.data || []);
      } else {
        setItems([]);
      }

    } catch (error) {
      console.log(error);
    }
  };

  const getCategoryFun = async () => {
    try {
      const response = await GetApiwithHeader(`api/CodeCategory/Get`, headers);

      const filteredRoles = response?.data?.data.filter(
        (item) => item?.categoryName
      );
      setGetCategory(filteredRoles);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
    getCategoryFun();
  }, [companyId]);

  const handleAddClick = () => {
    setVisible(true);
    setEditValue(null)

  };

  // const handleEditClick = (i) => {
  //   setEditValue(i);
  //   setVisible(true);
  // };

  const handleView = (i) => {
    setViewValue(true);
    setEditValue(i);
    setVisible(true);
  };
  // const handleDelete = (i) => {
  //   setValueData(i);
  //   setIsModalVisible(true);
  // };

  // const handleDeleteClick = async (id) => {
  //   const apiUrl = `/api/BusinessCodes/delete/${id}?companyId=${companyId}`;
  //   try {
  //     const response = await DeleteApiwithHeader(apiUrl, headers);
  //     if (response.message === "Success") {
  //       setIsModalVisible(false);
  //       const updatedIndustry = items.filter((entry) => entry.id !== id);
  //       setItems(updatedIndustry);
  //       toast.success("Delete successfully");
  //     } else {
  //       console.error("Unexpected response format:", response);
  //     }
  //   } catch (error) {
  //     console.error("Delete API Request Error:", error);
  //   }
  // };

  const handleSort = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const sortedItems = () => {
    const sortableItems = Array.isArray(items) ? [...items] : [];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearch = (item) => {
    return (
      item?.codeName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item?.id.toString().includes(searchQuery.toLowerCase()) ||
      item?.entryType.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item?.categoryA.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item?.categoryB.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item?.value.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item?.gst.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };

  // const filteredItems = sortedItems().filter(handleSearch);
  const filteredItems = sortedItems().filter(handleSearch);
  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const totalPages = Math.ceil(filteredItems.length / itemsPerPage);
  const getCurrentPageData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredItems.slice(startIndex, endIndex);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleCloseClick = () => {
    setVisible(false);
    setClearFormDataFlag((prev) => !prev);
    setEditValue('');
    setViewValue(false);
  };

  const column = [
    {
      field: " ",
      headerName: "",
      width: 5,
      resizable: false,
      sortable: false,
      // align: "center",
      // headerAlign: "center",
      headerClassName: 'dataGridHeader'
    },
    {
      field: "codeName",
      headerName: "Code Name",
      width: 200,
      headerClassName: 'dataGridHeader'
    },
    {
      field: "entryType",
      headerName: "Entry Type",
      width: 150,
      headerClassName: 'dataGridHeader',
      renderCell: ({ value, row }) => (
        <div>
          <>
            {value === "In" ? (
              <>
                {value} <CImage src={page1} />{" "}
              </>
            ) : (
              <>
                {value} <CImage src={out} />
              </>
            )}
          </>
        </div>
      ),
    },
    {
      field: "categoryA",
      headerName: "Category A",
      width: 150,
      headerClassName: 'dataGridHeader'
    },
    {
      field: "categoryB",
      headerName: "Category B",
      width: 150,
      headerClassName: 'dataGridHeader'
    },
    {
      field: 'value',
      headerName: 'Value',
      width: 150,
      headerClassName: 'dataGridHeader',
    },
    {
      field: "gst",
      headerName: "GST",
      width: 150,
      headerClassName: 'dataGridHeader'
    },
    {
      field: "categoryName",
      headerName: "Group",
      width: 200,
      headerClassName: 'dataGridHeader'
    },
    {
      field: "action",
      headerName: "Action",
      width: 70,
      headerClassName: 'dataGridHeader',
      type: "actions",
      renderCell: ({ row }) => (
        <div className="rowIcon">
          <CTooltip content="Edit">
            <button
              className="icon-editIcon"
              onClick={() => handleView(row)}
            ></button>
          </CTooltip>
        </div>
      ),
    },
  ];

  return (
    <div className="cardBody">
      <ToastContainer position="top-right" autoClose={1000} />
      <div className="cardHeader">
        <h3>Code Management</h3>
        <div className="mr-1">
          <input
            type="text"
            className="mr-2"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <CButton onClick={() => handleAddClick()}>Add Code</CButton>
        </div>
      </div>
      <OffCanvas
        Open={visible}
        title={
          (editValue === null)
            ? "Code Definition"
            : "Code Definition"
        }
        handleCloseClick={handleCloseClick}
        component={
          <Add
            apiCall={fetchData}
            handleClose={handleCloseClick}
            editValue={editValue}
            viewValue={viewValue}
            items={items}
            getCategory={getCategory}
            clearFormData={clearFormDataFlag}
          />
        }
      />
      <div className="datatableGrid">
        <DataGridPro
          rows={filteredItems}
          columns={column}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 15 },
            },
          }}
          pageSizeOptions={[5, 10]}
            onRowClick={(row) => handleView(row.row)}
          density="compact"
          disableRowSelectionOnClick
          loading={loadingState}
        />
      </div>
      {/* <CModal
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        aria-labelledby="ScrollingLongContentExampleLabel"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "auto",
        }}
      >
        <CModalHeader>
          <h3>Delete</h3>
        </CModalHeader>
        <CModalBody>
          Are you sure want to delete {editValue?.codeName} ?
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={() => setIsModalVisible(false)}>
            No
          </CButton>
          <CButton
            color="primary"
            onClick={() => handleDeleteClick(editValue?.id)}
          >
            Yes
          </CButton>
        </CModalFooter>
      </CModal> */}
    </div>
  );
};

export default CodeManagement;
