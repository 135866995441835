// import { useState } from "react";
// import {
//   CTable,
//   CTableBody,
//   CTableDataCell,
//   CTableHeaderCell,
//   CButton,
//   CTableHead,
//   CTableRow,
//   CCol,
//   CCardBody,
//   CCard,
//   CImage,
//   CModalBody,
//   CModalHeader,
//   CModalFooter,
//   CModal,
//   CTooltip,
//   CContainer,
//   CRow,
//   CListGroup,
//   CListGroupItem,
//   CCardHeader,
//   CCardTitle,
//   CCardText,
// } from "@coreui/react";
// // import { DataGrid } from "@mui/x-data-grid";
// import { DataGridPro } from "@mui/x-data-grid-pro";
// import Add from "../standardJournal/Addstandard";
// import OffCanvas from "../../share/offCanvas";
// import { LicenseInfo } from "@mui/x-license";

// const StandardJournal = () => {
//   LicenseInfo.setLicenseKey(
//     "248a71a5e79c492f4b13769843fcf6dcTz04OTIzMCxFPTE3NDU3NTY1ODYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
//   );

//   const [open, setOpen] = useState(false);
//   const [visible, setVisible] = useState(false);

//   const [carddata, setCardData] = useState([
//     { id: 0, title: "Fortune Forge Fund", text: "014563" },
//     { id: 1, title: "Santosh Limited", text: "12333" },
//     { id: 2, title: "Brambles Limited", text: "232233" },
//     { id: 3, title: "Aristocrat Leisure Limited", text: "232323" },
//     { id: 4, title: "Coles Group Limited", text: "785476" },
//   ]);
//   const [selectedCardData, setSelectedCardData] = useState(null);

//   const column = [
//     {
//       field: "id",
//       headerName: "S.no",
//       width: 10,
//       align: "center",
//       headerAlign: "center",
//       headerClassName: "dataGridHedaer",
//     },

//     {
//       field: "abn",
//       headerName: "ABN Number",
//       width: 200,
//       headerClassName: "dataGridHedaer",
//     },

//     {
//       field: "Id",
//       headerName: "ID Number",
//       width: 150,
//       headerClassName: "dataGridHedaer",
//     },

//     {
//       field: "created",
//       headerName: "Created Date and Time",
//       width: 200,
//       headerClassName: "dataGridHedaer",
//     },

//     {
//       field: "amount",
//       headerName: " Amount",
//       width: 150,
//       headerClassName: "dataGridHedaer",
//     },

//     {
//       field: "transaction",
//       headerName: "Transaction Type",
//       width: 150,
//       headerClassName: "dataGridHedaer",
//     },

//     {
//       field: "comments",
//       headerName: "Comments",
//       width: 150,
//       headerClassName: "dataGridHedaer",
//     },
//   ];

//   const rows = [
//     {
//       id: 1,
//       abn: "12378234",
//       Id: 2324,
//       created: "12/10/2024",
//       amount: 35,
//       transaction: "Out",
//       comments: "meal receipt",
//     },
//     {
//       id: 2,
//       abn: "3453234234",
//       Id: 5657,
//       created: "2/2/2024",
//       amount: 42,
//       transaction: "IN",
//       comments: "meal receipt",
//     },
//     {
//       id: 3,
//       abn: "878472834",
//       Id: 897,
//       created: "5/3/2024",
//       amount: 45,
//       transaction: "Out",
//       comments: "meal receipt",
//     },
//     {
//       id: 4,
//       abn: "23423423",
//       Id: 2988,
//       created: "7/3/2024",
//       amount: 16,
//       transaction: "IN",
//       comments: "meal receipt",
//     },
//   ];

//   // const handleClick = () => {
//   //   setOpen(!open);

//   // };

//   const handleCardClick = (card) => {
//     setSelectedCardData(card);
//     setOpen(true);
//   };
//   const handleAddclick = () => {
//     setVisible(true);
//   };
//   const handleCloseClick = () => {
//     setVisible(false);
//   };
//   const handleClosebuttonClick = () =>{
//     setOpen(false)
//   }
//   return (
//     <div className="cardBody">
//       {/* <ToastContainer position="top-right" /> */}

//       <div className="cardHeader">
//         {!open ? <h3>Standing Journal</h3> : <h3> Standing Journal</h3>}
//         <div className="mr-1">
//           {!open ? (
//             <input
//               type="text"
//               placeholder="Search..."
//               //  value={searchQuery}
//               //onChange={(e) => setSearchQuery(e.target.value)} // Step 2: Add input field for search query
//             />
//           ) : null}
//           {open ? <CButton onClick={handleAddclick}>Settings</CButton> : null}
//           {open ? <CButton onClick={handleClosebuttonClick}>Back</CButton> : null}
//         </div>
//       </div>
//       <hr />

//       {!open ? (
//         <CRow>
//           {carddata.map((card, index) => (
//             <CCol key={index} sm="3" md="6">
//               <div className="mt-3">
//                 <CCard style={{ border: "1px solid darkgrey", padding: "5px",cursor: "pointer" }} 
//                 onClick={() => handleCardClick(card)}
//                       >
//                   <CCardBody>
//                     <CCardTitle>
//                       {card.title}
//                     </CCardTitle>
//                     <CCardText>{card.text}</CCardText>
//                   </CCardBody>
//                 </CCard>
//               </div>
//             </CCol>
//           ))}
//         </CRow>
//       ) : null}

//       {open ? (
//         <CRow>
//           <CCol>
//             <CCol>
//               <div className="mt-3 mb-6">
//                 <CCard style={{ border: "1px solid #EAF0F2", padding: "5px",background:"#caced5",borderRadius:"8px"}}>
//                   <CCardBody>
//                     {/* <CCardTitle>{selectedCardData.title}</CCardTitle>{" "}
//                     <CCardText>{selectedCardData.text}</CCardText> */}

//                     <CRow>
//                       <CCol md={2}>
//                     <strong>    Folio No: </strong>
//                     <p className="mt-2 mb-2">FF676766gh</p>
//                       </CCol>
//                       <CCol md={3}>
//                     <strong>    Folio Name: </strong>
//                     <p className="mt-2 mb-2">Fortune Forge Fund</p>
//                       </CCol>
//                       <CCol md={2}>
//                     <strong>   Duration: </strong>
//                     <p className="mt-2 mb-2">2 Years</p>
//                       </CCol>
//                       <CCol md={3}>
//                     <strong>  Amount: </strong>
//                     <p className="mt-2 mb-2">$50000.0</p>
//                       </CCol>
//                       <CCol md={2}>
//                     <strong>  Interest rate: </strong>
//                     <p className="mt-2 mb-2">5%</p>
//                       </CCol>
//                     </CRow>
//                   </CCardBody>
//                 </CCard>
//               </div>
//             </CCol>
//             <div className="datatableGrid mt-3">
//               <DataGridPro
//                 rows={rows}
//                 columns={column}
//                 disableRowSelectionOnClick
//                 initialState={{
//                   pagination: {
//                     paginationModel: { page: 0, pageSize: 15 },
//                   },
//                 }}
//                 pageSizeOptions={[5, 10]}
//                 density="compact"
//               />
//             </div>
//           </CCol>
//         </CRow>
//       ) : null}

//       <OffCanvas
//         Open={visible}
//         title={"Configure Standing Journal"}
//         handleCloseClick={handleCloseClick}
//         component={
//           <Add
//             handleClose={handleCloseClick}
//             //  deprication={deprication}
//             //  handleDepricationClick={handleDepricationClick}
//           />
//         }
//       />
//     </div>
//   );
// };

// export default StandardJournal;

import React from 'react'

const StandardJournal = () => {
  return (
    <div>StandardJournal</div>
  )
}

export default StandardJournal
