import React, { useState, useEffect, useCallback, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  CRow,
  CCol,
  CButton,
  CFormInput,
  CForm,
  CSpinner,
  CFormTextarea,
  CFormLabel, CTooltip, CFormCheck, CFormSelect
} from "@coreui/react";
import { GetApiwithHeader, UpdateUserwithHeader, postApiWithHeader } from "src/Api/Api";
import PropTypes from "prop-types";
import { userAttributes } from "../../share/localData";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useData } from "../../components/dataContext";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ListItem from '@mui/material/ListItem';

const AddCustomer = (props) => {
  const [showOtherInput, setShowOtherInput] = useState(false);
  const { setTriggerRenderHeaderOnCompanyAdd } = useData();
  const { editValue, rowdata } = props;
  const { cmpID } = userAttributes();
  const [industry, setIndustry] = useState([]);
  const accessToken = localStorage.getItem("token");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const uploadedDocumentsRef = useRef([]);
  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const [newItemText, setNewItemText] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showSelectDropdown, setShowSelectDropdown] = useState(false);
  const [checkedValues, setCheckedValues] = useState([]);

  AddCustomer.propTypes = {
    fetchData: PropTypes.func.isRequired,
    editValue: PropTypes.object,
    handleClose: PropTypes.func,
    rowdata: PropTypes.object,
  };

  const [showOtherSelect, setShowOtherSelect] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [add, setAdd] = useState(false)
  const [save, setSave] = useState(true)
  const [dropdownOptions, setDropdownOptions] = useState([]);

  const handleAdd = () => {
    setAdd(true);
    setShowSelectDropdown(false);
    setSave(false);
  };

  const headers = {
    // 'Content-Type': 'multipart/form-data',
    Authorization: `Bearer ${accessToken}`,
  };
  const companyId = cmpID;


  const handleOnChange = (value, country) => {
    const sanitizedPhoneNumber = value.replace(/^0+/, "");
    const formattedPhoneNumber = `+${sanitizedPhoneNumber}`;
    formik.setFieldValue("phoneNumber", formattedPhoneNumber);
  };

  const formik = useFormik({
    initialValues: {
      customerName: editValue?.customerName || "",
      companyName: editValue?.companyName || "",
      ABN: editValue?.ABN || "",
      phoneNumber: editValue?.phoneNumber || "",
      email: editValue?.email || "",
      customerTypeId: editValue?.customerTypeId || ""
      // productDescription: "",
      // ProductFiles: ""
    },
    validationSchema: Yup.object({
      customerName: Yup.string().required("Customer Name is required"),
      companyName: Yup.string().required("company Name is required"),
      ABN: Yup.string().required("ABN is required"),
      phoneNumber: Yup.string().required("Mobile number is required"),
      email: Yup.string().required("Email is required"),
      // gstPercentage: Yup.number().required("GST % is required"),
      // productDescription: Yup.string().required("Product Description is required"),
    }),

    onSubmit: async (values, { resetForm }) => {
      const formData = {
        customerName: values.customerName,
        companyName: values.companyName,
        abnNumber: values.ABN,
        mobileNumber: values.phoneNumber,
        email: values.email,
        customerTypeId: values.customerTypeId === "other" ? 1 : 0,
        isCustomer: values.customerTypeId === "customer" ? true : false,
        isSupplier: values.customerTypeId === "supplier" ? true : false,
        isOther: values.customerTypeId === "other" ? true : false,
        otherTitle: values.customerTypeId === "other" ? selectedOption  :'',
      };

      const apiUrl = `/api/CustomerSupplier/create?companyId=${companyId}`;

      try {
        const response = editValue
          ? await UpdateUserwithHeader(apiUrl, formData, headers)
          : await postApiWithHeader(apiUrl, formData, headers);
        if (
          (editValue ? response.message : response.data.message) === "Success"
        ) {
          toast.success(
            `Company ${editValue ? "updated" : "added"} successfully`,
            {
              autoClose: 1000,
              onClose: () => {
                props?.handleClose();
                resetForm();
              },
            }
          );
          props?.fetchData();
        } else  {
          toast.error(response.data.message, { autoClose: 1000 });
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  })

  const resetFormErrors = () => {
    formik.setErrors({
      customerName: "",
      companyName: "",
      ABN: "",
      phoneNumber: "",
      email: "",
    });
  };

  useEffect(() => {
    if (editValue) {
      formik.setValues({
        customerName: editValue.customerName,
        companyName: editValue.companyName,
        ABN: editValue.abnNumber,
        phoneNumber: editValue.mobileNumber,
        email: editValue.email,
        


      });

    } else {
      formik.setValues({
        customerName: "",
        companyName: "",
        ABN: "",
        phoneNumber: "",
        email: "",
        // productDescription: "",
      });

      formik.resetForm();
      resetFormErrors();
    }
  }, [editValue]);


  const handleInputChange = (e) => {
    if (!showOtherSelect) {
      showOtherSelect(false)
    }
    setNewItemText(e.target.value);
  };

  const handleCancelButtonClick = () => {
    setAdd(false);
    setNewItemText("");
    setErrorMessage("");
    setShowOtherSelect(true);
    setShowSelectDropdown(true);
    setSave(true)
  };

  const handleSaveButtonClick = () => {
    if (newItemText.trim() === "") {
      setErrorMessage("Please enter a valid item");
      return;
    }

    // Add the new item to the dropdown options
    const newItem = { value: newItemText, label: newItemText };
    setDropdownOptions((prevOptions) => [...prevOptions, newItem]);
    setSelectedOption(newItem.value);

    setAdd(false);
    setNewItemText("");
    setErrorMessage("");
    setSave(true);
    setShowOtherSelect(true);
    setShowSelectDropdown(true);
  };
  const handleRadioChange = (e) => {
    const { value } = e.target;
    formik.setFieldValue('customerTypeId', value);
    console.log("customerTypeId", value)
    if (value === "other") {
      setShowOtherSelect(true);
      setShowSelectDropdown(true);
      setAdd(false);
      setSave(true);
    } else {
      setShowOtherSelect(false);
      setSave(true);
      setShowSelectDropdown(false);
      setAdd(false);
    }
  };

  return (
    <div className="addBox">
      <CForm onSubmit={formik.handleSubmit}>
        <CFormInput
          type="text"
          name="customerName"
          placeholder="Enter customer name"
          label={
            <>
              Customer Name <span className="text-danger">*</span>
            </>
          }
          onChange={formik.handleChange}
          value={formik.values.customerName}
        />
        {formik.touched.customerName && formik.errors.customerName && (
          <div className="text-danger">{formik.errors.customerName}</div>
        )}

        <div className="md-12 mt-3">
          <CFormInput
            type="text"
            name="companyName"
            placeholder="Enter product number"
            label={
              <>
                Company Name <span className="text-danger">*</span>
              </>
            }
            onChange={formik.handleChange}
            value={formik.values.companyName}
          />
          {formik.touched.companyName && formik.errors.companyName && (
            <div className="text-danger">{formik.errors.companyName}</div>
          )}
        </div>
        <div className="md-12 mt-3">
          <CFormInput
            type="string"
            name="ABN"
            placeholder="Enter product cost"
            label={
              <>
                ABN <span className="text-danger">*</span>
              </>
            }
            onChange={formik.handleChange}
            value={formik.values.ABN}
          />
          {formik.touched.ABN && formik.errors.ABN && (
            <div className="text-danger">{formik.errors.ABN}</div>
          )}
        </div>
        <div className="md-12 mt-3">
          <CFormLabel>
            Mobile Number <span className="text-danger">*</span>
          </CFormLabel>
          <PhoneInput
            name="phoneNumber"
            placeholder="Enter mobile number"
            onChange={(value, country) => handleOnChange(value, country)}
            country={"au"}
            value={formik.values.phoneNumber}
            inputStyle={{ width: "100%" }}
            containerStyle={{ width: "100%" }} // Added containerStyle to adjust the width
            inputProps={{
              className:
                formik.touched.phoneNumber && formik.errors.phoneNumber
                  ? "form-control is-invalid"
                  : "form-control",
            }}
          />
          {formik.touched.phoneNumber && formik.errors.phoneNumber && (
            <div className="invalid-feedback">{formik.errors.phoneNumber}</div>
          )}
        </div>
        <div className="md-12 mt-3">
          <CFormInput
            type="email"
            name="email"
            placeholder="Enter email"
            label={
              <>
                Email <span className="text-danger">*</span>
              </>
            }
            onChange={formik.handleChange}
            value={formik.values.email}
          />
          {formik.touched.email && formik.errors.email && (
            <div className="text-danger">{formik.errors.email}</div>
          )}
        </div>

        <div className="md-12 mt-3">
          <CFormLabel>Customer Type</CFormLabel>
          <CCol md={12}>
            <div className="form-check-group">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  id="inlineRadio1"
                  name="customerType"
                  value="customer"
                  onChange={handleRadioChange}
                />
                <label className="form-check-label" htmlFor="inlineRadio1">
                  Customer
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  id="inlineRadio2"
                  name="customerType"
                  value="supplier"
                  onChange={handleRadioChange}
                />
                <label className="form-check-label" htmlFor="inlineRadio2">
                  Supplier
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  id="inlineRadio3"
                  name="customerType"
                  value="other"
                  onChange={handleRadioChange}
                />
                <label className="form-check-label" htmlFor="inlineRadio3">
                  Other
                </label>
              </div>
            </div>
            {showOtherSelect && (
              <>
                {showSelectDropdown && (
                  <>
                    <CRow className="align-items-center justify-content-end itim-heading">
                      <CButton
                        color="primary"
                        style={{
                          backgroundColor: 'white',
                          color: '#1976d2',
                          paddingLeft: '0',
                          paddingRight: '0',
                          border: 'none'
                        }}
                        onClick={handleAdd}
                        className='additim'
                      >
                        + Add
                      </CButton>
                      <CFormLabel className="mb-0 mr-3">Select Others</CFormLabel>
                    </CRow>
                    <CFormSelect
                      onChange={(e) => setSelectedOption(e.target.value)}
                      value={selectedOption}
                    >
                      <option value="">Select an option</option>
                      {dropdownOptions?.map((option, index) => (
                        <option key={index} value={option.value}>{option.label}</option>
                      ))}
                    </CFormSelect>
                  </>
                )}
              </>
            )}
            {add && (
              <div className="ulliBox">
                <CFormLabel>Title</CFormLabel>
                <ListItem>
                  <CFormInput
                    value={newItemText}
                    onChange={handleInputChange}
                    placeholder="Enter Title"
                  />
                </ListItem>
                {errorMessage && (
                  <ListItem>
                    <p style={{ color: 'red' }}>{errorMessage}</p>
                  </ListItem>
                )}
                <ListItem className="d-flex justify-content-end">
                  <CButton onClick={handleCancelButtonClick} color="light" className="mr-2">Cancel</CButton>
                  <CButton onClick={handleSaveButtonClick} color="primary" type="submit">Save</CButton>
                </ListItem>
              </div>
            )}
          </CCol>
          {save ? <CRow className="mt-4 justify-content-end">
            <CCol xs="auto">
              <CButton
                color="light"
                onClick={() => {
                  props.handleClose();
                  formik.resetForm();
                }}
              >
                Cancel
              </CButton>
            </CCol>
            <CCol xs="auto">
              <CButton color="primary" type="submit">
                {isSubmitting ? <CSpinner color="white" size="sm" /> : "Save"}
              </CButton>
            </CCol>
          </CRow> : ''}
        </div>
      </CForm>
    </div>
  );
};

export default AddCustomer;

{/* <div className="md-12 mt-3">
          <CFormLabel>Customer Type</CFormLabel>
          <CCol md={12}>
            <div className="form-check-group">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox1"
                  value="customer"
                  onChange={handleCheckboxChange}
                />
                <label className="form-check-label" htmlFor="inlineCheckbox1">
                  Customer
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox2"
                  value="supplier"
                  onChange={handleCheckboxChange}
                />
                <label className="form-check-label" htmlFor="inlineCheckbox2">
                  Supplier
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox3"
                  value="other"
                  onChange={handleCheckboxChange}
                />
                <label className="form-check-label" htmlFor="inlineCheckbox3">
                  Other
                </label>
              </div>
            </div>
            {showOtherSelect && (
              <>
                {showSelectDropdown && (
                  <>
                    <CRow className="align-items-center justify-content-end itim-heading">
                      <CButton
                        color="primary"
                        style={{
                          backgroundColor: 'white',
                          color: '#1976d2',
                          paddingLeft: '0',
                          paddingRight: '0',
                          border: 'none'
                        }}
                        onClick={handleAdd}
                        className='additim'
                      >
                        + Add
                      </CButton>
                      <CFormLabel className="mb-0 mr-3">Select Others</CFormLabel>

                    </CRow>
                    <CFormSelect
                      onChange={(e) => setSelectedOption(e.target.value)}
                      value={selectedOption}

                    >
                      <option value="">Select an option</option>
                      {dropdownOptions?.map(option, index => (
                        <option key={index} value={option.value}>{option.label}</option>
                      ))}
                    </CFormSelect>
                  </>
                )}
              </>
            )}
            {add && (
              <div className="ulliBox">
                <CFormLabel>Title</CFormLabel>
                <ListItem>
                  <CFormInput
                    value={newItemText}
                    onChange={handleInputChange}
                    placeholder="Enter Title"
                  />
                </ListItem>
                {errorMessage && (
                  <ListItem>
                    <p style={{ color: 'red' }}>{errorMessage}</p>
                  </ListItem>
                )}
                <ListItem className="d-flex justify-content-end">
                  <CButton onClick={handleCancelButtonClick} color="light" className="mr-2">Cancel</CButton>
                  <CButton onClick={handleSaveButtonClick} color="primary" type="submit">Save</CButton>
                </ListItem>
              </div>
            )}

          </CCol>
          {save ? <CRow className="mt-4 justify-content-end">
            <CCol xs="auto">
              <CButton
                color="light"
                onClick={() => {
                  props.handleClose();
                  formik.resetForm()
                }}
              >
                Cancel
              </CButton>
            </CCol>
            <CCol xs="auto">
              <CButton color="primary" type="submit">
                {isSubmitting ? <CSpinner color="white" size="sm" /> : "Save"}
              </CButton>
            </CCol>
          </CRow> : ''}
        </div> */}






