import React, { useState, useEffect, useRef } from "react";
import {
  CRow,
  CCol,
  CButton,
  CFormCheck,
  CForm,
  CFormInput,
  CFormSelect,
  CFormLabel,
  CImage,
  CCard,
  CCardTitle,
  CSpinner,
} from "@coreui/react";
import PropTypes from "prop-types";
import { postApiWithHeader, UpdateUserwithHeader } from "src/Api/Api";
import { toast } from "react-toastify";
import { userAttributes } from "../../share/localData";
import {
  splitInsurance,
  disInsurance,
  money,
  moneyout,
  capital,
  nonAsset,
  revenue,
  revenue1,
  expense,
  debitors,
  creditors,
  creditor,
  normalGst,
  moneyout1,
} from "src/components/Images/Images";

const Add = (props) => {
  const isCap = useRef(false);
  const [selectVal, setSelectVal] = useState();
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const accessToken = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  const { cmpID } = userAttributes();
  const companyId = cmpID;
  const [errorMessage, setErrorMessage] = useState("");
  const [formData, setFormData] = useState({
    codeName: "",
    entryType: "In",
    categoryA: "NonCapital",
    categoryB: "Revenue",
    copyParameter: "",
    value: "Debtor",
    gst: "Normal",
    isAsset: false,
    categoryId: 0,
    codeId: 0,
    fringeBenifitTax: false,
    exportIncentiveProgram: false,
    isInsurance: false,
    bankTransactionCode: 0,
    xeroCode: 0,
    itemType: "None",
    isItem: false,
    isBascode: false,
    isFinancialInstrument: false,
  });

  Add.propTypes = {
    apiCall: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    editValue: PropTypes.string,
    items: PropTypes.string,
    getCategory: PropTypes.string,
    clearFormData: PropTypes.bool,
  };

  const getCategory = props.getCategory;
  const items = props.items;
  const editValue = props.editValue;
  let viewValue = props?.viewValue;

  useEffect(() => {
    if (editValue) {
      const isItemChecked =
        editValue.itemType === "Fixed" ||
        editValue.itemType === "Movable" ||
        editValue.isInsurance;
      setFormData({
        codeName: editValue?.codeName,
        entryType: editValue?.entryType,
        categoryA: editValue?.isCapital === true ? "Capital" : "NonCapital",
        copyParameter: editValue?.copyParam,
        isAsset: editValue?.isAsset,
        categoryId: editValue?.categoryId,
        categoryB: editValue?.categoryB,
        value: editValue?.value,
        gst: editValue?.gst,
        fringeBenifitTax: editValue?.fringeBenifitTax,
        exportIncentiveProgram: editValue?.exportIncentiveProgram,
        isInsurance: isItemChecked ? editValue?.isInsurance : false,
        bankTransactionCode: 0,
        xeroCode: 0,
        itemType: isItemChecked ? editValue?.itemType : "None",
        isItem: isItemChecked,
        isBascode: editValue.isBasCode,
        isFinancialInstrument: editValue.isFinancialInstrument,
      });
      isCap.current = editValue?.isCapital
      setSelectVal({
        entryType: editValue.entryType,
        categoryB: editValue.categoryB,
        value: editValue.value,
        gst: editValue.gst,
      });
    } else {
      setFormData({
        ...formData,
      });
    }
  }, [editValue]);

  useEffect(() => {
    if (props.clearFormData) {
      resetForm();
      props.handleClose();
    }
  }, [props.clearFormData]);

  const postData = {
    codeName: formData.codeName,
    entrytype: formData.entryType,
    isAsset: formData.isAsset,
    categoryA: isCap.current === true ? "Capital" : "NonCapital",
    categoryB: formData.categoryB,
    gst: formData.gst,
    copyParam: formData ? formData.copyParameter : "",
    value: formData.value,
    description: "",
    categoryId: JSON.parse(formData.categoryId),
    bankTransactionCode: 0,
    isInsurance: formData.isInsurance,
    itemType: formData.itemType,
    fringeBenifitTax: formData.fringeBenifitTax,
    exportIncentiveProgram: formData.exportIncentiveProgram,
    xeroCode: 0,
    isBasCode: formData.isBascode,
    isFinancialInstrument: formData.isFinancialInstrument,
  };

  const handleChange = (event, fieldName) => {
    let value;
    if (fieldName === "entryType") {
      value = event.target.value === "In" ? "In" : "Out";
    } else if (fieldName === "isBascode") {
      value = event.target.checked;
    } else if (fieldName === "isFinancialInstrument") {
      value = event.target.checked;
    } else if (fieldName === "categoryA") {
      value = event.target.checked;
      isCap.current = value;
    } else if (fieldName === "isAsset") {
      value = event.target.checked;
      formData.itemType = value ? "Fixed" : "None";
    } else if (fieldName === "isItem") {
      value = event.target.checked;
      if (!value) {
        setFormData({
          ...formData,
          itemType: "None",
          isInsurance: false,
        });
      }
    } else if (fieldName === "itemType") {
      value = event.target.value === "Fixed" ? "Fixed" : "Movable";
    } else if (fieldName === "categoryB") {
      value = event.target.value === "Revenue" ? "Revenue" : "Expense";
    } else if (fieldName === "gst") {
      value =
        event.target.value === "Normal"
          ? "Normal"
          : event.target.value === "Exempted"
          ? "Exempted"
          : event.target.value === "Free"
          ? "Free"
          : event.target.value === "FreeExport"
          ? "FreeExport"
          : "InputTax";
    } else if (fieldName === "value") {
      value = event.target.value === "Debtor" ? "Debtor" : "Creditor";
    } else if (fieldName === "fringeBenifitTax") {
      value = event.target.checked;
    } else if (fieldName === "exportIncentiveProgram") {
      value = event.target.checked;
    } else if (fieldName === "isInsurance") {
      value = event.target.checked;
    } else {
      value = event.target.value;
    }

    setFormData({
      ...formData,
      [fieldName]: value,
    });
    setErrors({
      ...errors,
      [fieldName]: "",
    });
    setErrorMessage("");
  };

  const handleSelectChange = (e) => {
    const selectedOption = items?.find(
      (role) => role?.codeName === e.target.value
    );
    setSelectVal(selectedOption);
    isCap.current = selectedOption?.isCapital;
    setFormData({
      ...formData,
      entryType: selectedOption?.entryType || "In",
      copyParameter: selectedOption?.codeName || "",
      categoryA: selectedOption?.categoryA,
      value: selectedOption?.value || "Debtor",
      gst: selectedOption?.gst || "Normal",
      categoryB: selectedOption?.categoryB || "Revenue",
      itemType:  selectedOption?.itemType ,
      isFinancialInstrument : selectedOption?.isFinancialInstrument,
      isBascode : selectedOption?.isBasCode,
      isAsset : selectedOption?.isAsset,
      isInsurance : selectedOption?.isInsurance,
      exportIncentiveProgram : selectedOption?.exportIncentiveProgram,
      fringeBenifitTax : selectedOption?.fringeBenifitTax
    });
  };

  const handleAdd = async (e) => {
    e.preventDefault();
    const validationErrors = {};
    if (!formData.codeName.trim()) {
      validationErrors.codeName = "Code Name is required";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    setIsLoading(true);
    try {
      const apiUrl = `/api/BusinessCodes/create?companyId=${cmpID}`;
      const response = await (editValue
        ? UpdateUserwithHeader(
            `/api/BusinessCodes/update/${editValue.id}?companyId=${cmpID}`,
            postData,
            headers
          )
        : postApiWithHeader(apiUrl, postData, headers));
      if (
        editValue
          ? response.message == "Success"
          : response.data.message == "Success"
      ) {
        resetForm();
        props.apiCall();
        props.handleClose();
        toast.success(`${editValue ? "Updated" : "Added"} successfully`, {
          autoClose: 1000,
        });
      } else if (
        editValue
          ? response.statusCode == 102
          : response.data.statusCode === 102
      ) {
        if (
          response.message !== "Business code already exists." &&
          response.message !== "Code name already exist." &&
          response.data.message !== "Business code already exists." &&
          response.data.message !== "Code name already exist."
        ) {
          toast.error(editValue ? response.message : response.data.message);
        }

        if (
          response.message === "Business code already exists." ||
          response.message === "Code name already exist." ||
          response.data.message === "Business code already exists." ||
          response.data.message === "Code name already exist."
        ) {
          setErrorMessage(editValue ? response.message : response.data.message);
        }
      }
    } catch {
      console.log("error");
    } finally {
      setIsLoading(false); // End form submission
    }
  };

  const resetForm = () => {
    setFormData({
      codeName: editValue ? editValue.codeName : "",
      entryType: editValue ? editValue.entryType : "In",
      categoryA: editValue ? editValue.categoryA : "",
      copyParameter: editValue ? editValue.copyParam : "",
      isAsset: editValue ? editValue.isAsset : false,
      categoryId: editValue ? editValue.categoryId : 0,
      categoryB: editValue ? editValue.categoryB : "Revenue",
      value: editValue ? editValue.value : "Debtor",
      gst: editValue ? editValue.gst : "Normal",
      fringeBenifitTax: editValue ? editValue.fringeBenifitTax : false,
      exportIncentiveProgram: editValue
        ? editValue.exportIncentiveProgram
        : false,
     
    });
    setErrors({});
    setErrorMessage("");
  };
  const handleItemClick = (itemId) => {
    if (viewValue ? viewValue : !editValue) {
      setErrors({ ...errors, categoryId: "" });
      setFormData({ ...formData, categoryId: itemId });
    }
  };
  return (
    <div className="">
      <CRow className="mb-2">
        <CCol>
          <CForm>
            <CFormInput
              type="text"
              label={
                <>
                  Code <span className="text-danger">*</span>
                </>
              }
              placeholder="Enter Code"
              aria-describedby="exampleFormControlInputHelpInline"
              value={formData.codeName}
              onChange={(event) => handleChange(event, "codeName")}
              className={`mb-2 ${errors.codeName ? "is-invalid" : ""}`}
              disabled={viewValue ? false : !!editValue}
            />
            {errors.codeName && (
              <div className="invalid-feedback">{errors.codeName}</div>
            )}
            {errorMessage && <div className="text-danger">{errorMessage}</div>}
            <div className="mb-3">
              <CFormSelect
                value={formData?.copyParameter}
                onChange={(e) => handleSelectChange(e)}
                label="Copy Parameter"
              >
                <option value="">Select Parameter</option>
                {items
                  ?.sort((a, b) => a.codeName.localeCompare(b.codeName))
                  .map((role, index) =>
                    editValue && editValue.codeName === role.codeName ? null : (
                      <option key={index} value={role?.codeName}>
                        {role?.codeName}
                      </option>
                    )
                  )}
              </CFormSelect>
            </div>
            <CFormCheck
              id="flexCheckDefault7565"
              label="Is it nowBAS?"
              checked={
                selectVal && selectVal?.isBasCode
                  ? selectVal?.isBasCode
                  : formData.isBascode
              }
              onChange={(event) => handleChange(event, "isBascode")}
            />
            <CFormCheck
              id="flexCheckDefault7566"
              label="Is it financial instrument?"
              checked={
                selectVal && selectVal?.isFinancialInstrument
                  ? selectVal?.isFinancialInstrument
                  : formData?.isFinancialInstrument
              }
              onChange={(event) => handleChange(event, "isFinancialInstrument")}
            />
            <div className="box">
              <h6>Entry Type </h6>
              <div className="">
                <CFormCheck
                  inline
                  type="radio"
                  name="inlineRadioOptions6"
                  id="inlineCheckbox11"
                  value="In"
                  label={
                    <>
                      <span>In</span>{" "}
                      <CImage
                        src={money}
                        style={{ width: "17px", height: "17px" }}
                        alt="In icon"
                      />
                    </>
                  }
                  checked={formData.entryType === "In"}
                  defaultChecked={
                    selectVal && selectVal?.entryType === "In"
                      ? selectVal?.entryType
                      : formData.entryType === "In"
                  }
                  onChange={(event) => handleChange(event, "entryType")}
                  disabled={viewValue ? false : !!editValue}
                />
                <CFormCheck
                  inline
                  type="radio"
                  name="inlineRadioOptions12"
                  id="inlineCheckbox2"
                  value="Out"
                  label={
                    <>
                      <span>Out</span>{" "}
                      <CImage
                        src={moneyout1}
                        style={{ width: "17px", height: "17px" }}
                        alt="In icon"
                      />
                    </>
                  }
                  checked={formData.entryType === "Out"}
                  defaultChecked={
                    selectVal && selectVal?.entryType === "Out"
                      ? selectVal?.entryType
                      : formData.entryType === "Out"
                  }
                  onChange={(event) => handleChange(event, "entryType")}
                  disabled={viewValue ? false : !!editValue}
                />
                <hr></hr>
              </div>

              <h6>Category Type A</h6>
              <CFormCheck
                id="flexCheckDefault"
                label={
                  <>
                    <span>Is it a capital?</span>{" "}
                    <CImage
                      src={capital}
                      style={{ width: "17px", height: "17px" }}
                      alt="In icon"
                    />
                  </>
                }
                onChange={(event) => handleChange(event, "categoryA")}
                // checked={ selectVal && selectVal?.isCapital
                //   ? selectVal?.isCapital
                //   : isCap.current}
                checked={isCap.current}
                disabled={viewValue ? false : !!editValue}
              />
              <CFormCheck
                id="flexCheckDefault097"
                label={
                  <>
                    <span>Is it a plant item/vehicle?</span>{" "}
                    <CImage
                      src={nonAsset}
                      style={{ width: "17px", height: "17px" }}
                      alt="In icon"
                    />
                  </>
                }
                onChange={(event) => handleChange(event, "isAsset")}
                checked={
                  selectVal && selectVal?.isAsset
                    ? selectVal?.isAsset
                    : formData.isAsset
                }
                disabled={viewValue ? false : !!editValue}
              />
              {(formData.isAsset || selectVal?.isAsset) && (
                  <>
                    <h6>Is the item:</h6>
                    <div>
                      <CFormCheck
                        inline
                        type="radio"
                        name="itemType"
                        id="inlineCheckbox111"
                        value="Fixed"
                        label="Fixed"
                        checked={
                          selectVal && selectVal?.itemType === "Fixed"
                            ? selectVal?.itemType
                            : formData.itemType === "Fixed"
                        }
                        defaultChecked={
                          selectVal && selectVal?.itemType === "Fixed"
                            ? selectVal?.itemType
                            : formData.itemType === "Fixed"
                        }
                        onChange={(event) => handleChange(event, "itemType")}
                        disabled={viewValue ? false : !!editValue}
                      />
                      <CFormCheck
                        inline
                        type="radio"
                        name="itemType"
                        id="inlineCheckbox1121"
                        value="Movable"
                        label="Movable"
                        checked={
                          selectVal && selectVal?.itemType === "Movable"
                            ? selectVal?.itemType
                            : formData.itemType === "Movable"
                        }
                        defaultChecked={
                          selectVal && selectVal?.itemType === "Movable"
                            ? selectVal?.itemType
                            : formData.itemType === "Movable"
                        }
                        onChange={(event) => handleChange(event, "itemType")}
                        disabled={viewValue ? false : !!editValue}
                      />

                      <CFormCheck
                        id="flexCheckDefault6"
                        label={
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <span style={{ marginRight: "5px" }}>
                              Is insurance required?
                            </span>
                            {formData.isInsurance ||
                            (selectVal && selectVal?.isInsurance) ? (
                              <CImage
                                src={splitInsurance}
                                alt="Insurance Icon"
                                style={{
                                  marginLeft: "30px",
                                  width: "23px",
                                  height: "23px",
                                }}
                              />
                            ) : (
                              <CImage
                                src={disInsurance}
                                alt="Insurance Icon"
                                style={{
                                  marginLeft: "30px",
                                  width: "23px",
                                  height: "23px",
                                }}
                              />
                            )}
                          </div>
                        }
                        onChange={(event) => handleChange(event, "isInsurance")}
                        checked={
                          selectVal && selectVal?.isInsurance
                            ? selectVal?.isInsurance
                            : formData.isInsurance
                        }
                        disabled={viewValue ? false : !!editValue}
                      />
                    </div>
                  </>
                )}
            </div>

            <div className="box">
              <h6>Category Type B</h6>
              <div>
                <CFormCheck
                  inline
                  type="radio"
                  name="categoryB"
                  id="inlineCheckbox50"
                  value="Revenue"
                  label={
                    <>
                      <span>Revenue</span>{" "}
                      <CImage
                        src={revenue1}
                        style={{ width: "17px", height: "17px" }}
                        alt="In icon"
                      />
                    </>
                  }
                  checked={formData.categoryB === "Revenue"}
                  defaultChecked={
                    selectVal && selectVal?.categoryB === "Revenue"
                      ? selectVal?.categoryB
                      : formData.categoryB === "Revenue"
                  }
                  onChange={(event) => handleChange(event, "categoryB")}
                  disabled={viewValue ? false : !!editValue}
                />
                <CFormCheck
                  inline
                  type="radio"
                  name="categoryB"
                  id="inlineCheckbox60"
                  value="Expense"
                  label={
                    <>
                      <span>Expense</span>{" "}
                      <CImage
                        src={expense}
                        style={{ width: "17px", height: "17px" }}
                        alt="In icon"
                      />
                    </>
                  }
                  checked={formData.categoryB === "Expense"}
                  defaultChecked={
                    selectVal && selectVal?.categoryB === "Expense"
                      ? selectVal?.categoryB
                      : formData.categoryB === "Expense"
                  }
                  onChange={(event) => handleChange(event, "categoryB")}
                  disabled={viewValue ? false : !!editValue}
                />
              </div>
              <h6>Category Type C</h6>
              <div>
                <CFormCheck
                  inline
                  type="radio"
                  name="value"
                  id="inlineCheckbox70"
                  value="Debtor"
                  label={
                    <>
                      <span>Debtor</span>{" "}
                      <CImage
                        src={debitors}
                        style={{ width: "17px", height: "17px" }}
                        alt="In icon"
                      />
                    </>
                  }
                  checked={formData.value === "Debtor"}
                  defaultChecked={
                    selectVal && selectVal?.value === "Debtor"
                      ? selectVal?.value
                      : formData.value === "Debtor"
                  }
                  onChange={(event) => handleChange(event, "value")}
                  disabled={viewValue ? false : !!editValue}
                />
                <CFormCheck
                  inline
                  type="radio"
                  name="value"
                  id="inlineCheckbox80"
                  value="Creditor"
                  label={
                    <>
                      <span>Creditor</span>{" "}
                      <CImage
                        src={creditor}
                        style={{ width: "17px", height: "17px" }}
                        alt="In icon"
                      />
                    </>
                  }
                  checked={formData.value === "Creditor"}
                  defaultChecked={
                    selectVal && selectVal?.value === "Creditor"
                      ? selectVal?.value
                      : formData.value === "Creditor"
                  }
                  onChange={(event) => handleChange(event, "value")}
                />
              </div>
              <CFormLabel>
                <>
                  <span>GST</span>{" "}
                  <CImage
                    src={normalGst}
                    style={{ width: "17px", height: "17px" }}
                    alt="In icon"
                  />
                </>
              </CFormLabel>
              <div>
                <CFormCheck
                  inline
                  type="radio"
                  name="Normal"
                  id="inlineCheckbox90"
                  value="Normal"
                  label="Normal"
                  checked={formData.gst === "Normal"}
                  defaultChecked={
                    selectVal && selectVal?.gst === "Normal"
                      ? selectVal?.gst
                      : formData.gst === "Normal"
                  }
                  onChange={(event) => handleChange(event, "gst")}
                  disabled={viewValue ? false : !!editValue}
                />
                <CFormCheck
                  inline
                  type="radio"
                  name="Normal"
                  id="inlineCheckbox100"
                  value="Exempted"
                  label="Exempted"
                  checked={formData.gst === "Exempted"}
                  defaultChecked={
                    selectVal && selectVal?.gst === "Exempted"
                      ? selectVal?.gst
                      : formData.gst === "Exempted"
                  }
                  onChange={(event) => handleChange(event, "gst")}
                  disabled={viewValue ? false : !!editValue}
                />
                <CFormCheck
                  inline
                  type="radio"
                  name="Normal"
                  id="inlineCheckbox110"
                  value="Free"
                  label="Free"
                  checked={formData.gst === "Free"}
                  defaultChecked={
                    selectVal && selectVal?.gst === "Free"
                      ? selectVal?.gst
                      : formData.gst === "Free"
                  }
                  onChange={(event) => handleChange(event, "gst")}
                  disabled={viewValue ? false : !!editValue}
                />
                <CFormCheck
                  inline
                  type="radio"
                  name="Normal"
                  id="inlineCheckbox120"
                  value="FreeExport"
                  label="Free Export"
                  checked={formData.gst === "FreeExport"}
                  defaultChecked={
                    selectVal && selectVal?.gst === "FreeExport"
                      ? selectVal?.gst
                      : formData.gst === "FreeExport"
                  }
                  onChange={(event) => handleChange(event, "gst")}
                  disabled={viewValue ? false : !!editValue}
                />
                <CFormCheck
                  inline
                  type="radio"
                  name="Normal"
                  id="inlineCheckbox130"
                  value="InputTax"
                  label="Input Tax"
                  checked={formData.gst === "InputTax"}
                  defaultChecked={
                    selectVal && selectVal?.gst === "InputTax"
                      ? selectVal?.gst
                      : formData.gst === "InputTax"
                  }
                  onChange={(event) => handleChange(event, "gst")}
                  disabled={viewValue ? false : !!editValue}
                />
              </div>
            </div>

            <div className="box">
              <h6>Will these amounts involve the following?</h6>
              <CFormCheck
                id="flexCheckDefault7"
                label="Fringe Benefit Tax"
                onChange={(event) => handleChange(event, "fringeBenifitTax")}
                checked={selectVal && selectVal?.fringeBenifitTax
                ? selectVal?.fringeBenifitTax
                : formData.fringeBenifitTax}
                disabled={viewValue ? false : !!editValue}
              />
              <CFormCheck
                id="flexCheckDefault8"
                label="Export Incentive Program"
                onChange={(event) =>
                  handleChange(event, "exportIncentiveProgram")
                }
                checked={selectVal && selectVal?.exportIncentiveProgram
                  ? selectVal?.exportIncentiveProgram
                  : formData.exportIncentiveProgram}
                disabled={viewValue ? false : !!editValue}
              />
            </div>

            <div className="box">
              <CRow>
                <CCol md={12}>
                  <h6>
                    Group Listing <span style={{ color: "red" }}>*</span>
                  </h6>
                  <div className="d-flex flex-wrap menuiconHover">
                    {getCategory?.map((item) => (
                      <div
                        key={item.id}
                        onClick={() => handleItemClick(item.id)}
                        disabled={viewValue ? false : !!editValue}
                      >
                        <CCard
                          className={
                            formData.categoryId === item.id
                              ? "border border-success border-3"
                              : ""
                          }
                          disabled={!!editValue}
                        >
                          <CImage src={item.logo}></CImage>
                          <CCardTitle component="p" className="m-0">
                            {item.categoryName}
                          </CCardTitle>
                        </CCard>
                      </div>
                    ))}
                  </div>
                </CCol>
              </CRow>
            </div>
          </CForm>
          <CRow className="mt-4 justify-content-end">
            {editValue && !viewValue ? null : (
              <>
                <CCol xs="auto">
                  <CButton
                    color="light"
                    onClick={() => {
                      resetForm();
                      props.handleClose();
                    }}
                  >
                    Cancel
                  </CButton>
                </CCol>
                <CCol xs="auto">
                  <CButton
                    color="primary"
                    onClick={(e) => handleAdd(e)}
                    style={{ textTransform: "none" }}
                  >
                    {isLoading ? <CSpinner color="white" size="sm" /> : "Save"}
                  </CButton>
                </CCol>
              </>
            )}
          </CRow>
        </CCol>
      </CRow>
    </div>
  );
};

export default Add;
