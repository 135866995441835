import React, { useMemo, useEffect, useState } from "react";
import {
  CButton,
  CTableHead,
  CTableRow,
  CCol,
  CCardBody,
  CCard,
  CImage,
  CModalBody,
  CModalHeader,
  CModalFooter,
  CModal,
  CTooltip,
  CSpinner,
  CModalTitle,
} from "@coreui/react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { LicenseInfo } from "@mui/x-license";
import Add from "./Add";
import OffCanvas from "src/share/offCanvas";
import { GetApiwithHeader } from "src/Api/Api";

const Ordering = () => {
  const [visible, setVisible] = useState(false);
  const [order, setOrder] = useState([]);
  const[view,setView] = useState(false);
  const[viewDetails,setViewDetails] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [modal, setModal] = useState(false);
  const [loadingState, setLoadingState] = useState(true);
  LicenseInfo.setLicenseKey(
    "248a71a5e79c492f4b13769843fcf6dcTz04OTIzMCxFPTE3NDU3NTY1ODYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
  );

  const handleAddClick = () => {
    setVisible(true);
  };
  const handleCloseClick = () => {
    setVisible(false);
    setViewDetails()
  };

  const handleviewclick =()=>{
    setVisible(true);
   
    setViewDetails(true)
  }

  const column = [
    {
      field: "title",
      headerName: "Title",
      width: 150,
      headerClassName: "dataGridHeader",
    },
    {
      field: "type",
      headerName: "Type",
      width: 150,
      headerClassName: "dataGridHeader",
    },
    {
      field: "date",
      headerName: "Date",
      width: 150,
      headerClassName: "dataGridHeader",
      valueGetter: (row) => {
        return new Date(row)?.toLocaleDateString("en-GB");
      },
    },
    {
      field: "purchaser",
      headerName: " Purchaser",
      width: 150,
      headerClassName: "dataGridHeader",
    },
    {
      field: "Totalamount",
      headerName: "Total Amount",
      width: 150,
      headerClassName: "dataGridHeader",
      sortable: true,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        parseFloat(v1) - parseFloat(v2),
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      headerClassName: "dataGridHeader",
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      headerClassName: "dataGridHeader",
      type: "actions",
      renderCell: ({ row }) => {
        return (
          <div>
            <CTooltip content="View Details">
              <CButton onClick={handleviewclick}>View Details</CButton>
            </CTooltip>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    setOrder([
      {
        id: 1,
        title: "product1",
        type: "Goods",
        date: "12/10/2024",
        purchaser: "John Copper",
        Totalamount: "110",
        status: "Perposal",
      },
      {
        id: 2,
        title: "product2",
        type: "Goods",
        date: "2/2/2024",
        purchaser: "John Copper",
        Totalamount: "30",
        status: "Perposal",
      },
      {
        id: 3,
        title: "product3",
        type: "Goods",
        date: "5/3/2024",
        purchaser: "Henry",
        Totalamount: "300",
        status: "Perposal",
      },
      {
        id: 4,
        title: "product4",
        type: "service",
        date: "7/3/2024",
        purchaser: "Devon Levon",
        Totalamount: "100",
        status: "Order",
      },
    ]);
  }, []);

  const filteredServices = useMemo(() => {
    return order?.filter(
      (service) =>
        service?.title?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
        service?.type?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
        service?.date?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
        service?.purchaser
          ?.toLowerCase()
          ?.includes(searchQuery?.toLowerCase()) ||
        service?.Totalamount?.toLowerCase()?.includes(
          searchQuery?.toLowerCase()
        ) ||
        service?.status?.toLowerCase()?.includes(searchQuery?.toLowerCase())
    );
  }, [order, searchQuery]);

  return (
    <div className="cardBody">
      <div className="cardHeader">
        <h3>Ordering</h3>
        <div className="mr-1">
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <CButton onClick={handleAddClick}>Create Ordering</CButton>
        </div>
      </div>

      <div className="datatableGrid">
        <DataGridPro
          rows={filteredServices}
          columns={column}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 15 },
            },
          }}
          pageSizeOptions={[5, 10]}
          density="compact"
          disableRowSelectionOnClick
        />
      </div>

      <OffCanvas
        Open={visible}
        title={viewDetails ? "View details":"Create Ordering"}
        handleCloseClick={handleCloseClick}
        canvasWidth="650px"
        component={<Add handleClose={handleCloseClick} 
        viewDetails={viewDetails}
        />}
      />
    </div>
  );
};

export default Ordering;
