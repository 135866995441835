import React, { useMemo, useEffect, useState, useCallback } from "react";
import {
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CButton,
  CTableHead,
  CTableRow,
  CCol,
  CCardBody,
  CCard,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CModal,
  CImage,
  CTooltip,
} from "@coreui/react";
import { GetApiwithHeader } from "src/Api/Api";
import FileUpload from "./import";
import Mapping from "./mapping";
import { download } from "src/components/Images/Images";
import { userAttributes } from "../../share/localData";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useData } from "../../components/dataContext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { LicenseInfo } from '@mui/x-license';
import { addDays } from 'date-fns';

const applyButtonStyles = {
  padding: "1px 15px",
  backgroundColor: "#E7F0FF",
  border: "dotted 1px #D9D9D9",
  color: "#353540",
  borderRadius: "4px",
  fontWeight: 500,
  letterSpacing: 0
};

const Transcation = (props) => {
  LicenseInfo.setLicenseKey('248a71a5e79c492f4b13769843fcf6dcTz04OTIzMCxFPTE3NDU3NTY1ODYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

  const { data, bankDataLocal } = useData();
  const { cmpID, bankID } = userAttributes();
  const accessToken = localStorage.getItem("token");
  const [visible, setVisible] = useState(false);
  const [csvData, setCsvData] = useState();
  const [importFile, setFileImport] = useState();
  const [trasactionData, setTrasactionData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const SerialNumber = itemsPerPage * currentPage - itemsPerPage;
  const totalPages = Math.ceil(trasactionData?.length / itemsPerPage);
  const [loadingState, setLoadingState] = useState(true);

  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
  const headers = useMemo(
    () => ({
      Authorization: `Bearer ${accessToken}`,
    }),
    [accessToken]
  );
  const companyId = cmpID;
  const BankID = bankID
  const fetchData = async () => {
    setLoadingState(true)

    const formattedFromDate = fromDate ? formatDate(fromDate) : '';
    const formattedToDate = toDate ? formatDate(toDate) : '';
    try {
      const res = await GetApiwithHeader(
        `/api/BankTransactions/GetTransaction/${companyId}?bankAccountId=${BankID ? BankID : 0}&fromDate=${formattedFromDate}&toDate=${formattedToDate}`,
        headers
      );
      const dataNew =
      res?.data &&
      res?.data?.data?.map((item) => ({
        
        ...item,
        amountSave:
        item?.depositAmount !== "0"
        ? JSON.parse(item?.depositAmount)
        : item?.withdrawalAmount !== "0"
        ? JSON.parse(item?.withdrawalAmount)
        : null,}))
        setTrasactionData(dataNew || []);
        setLoadingState(false)

    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
    if (bankID) {
      fetchData();
    }
  }, [headers, companyId, bankID]);

  const handleCloseClick = () => {
    setVisible(false);
  };
  const callBack = (data) => {
    setCsvData(data);
  };
  const callBackFile = (data) => {
    setFileImport(data);
    if (data) {
      handleCloseClick();
    }
  };

  const handleFromDateChange = (date) => {
    if (toDate && date > toDate) {
      setFromDate(toDate); 
    } else {
      setFromDate(date);
    }
  };

  const handleToDateChange = (date) => {
    if (fromDate && date < fromDate) {
      setToDate(fromDate);
    } else {
      setToDate(date);
    }
  };

  const handleApplyButtonClick = () => {
    fetchData()
  };

  function formatToUSDateTime(dateTimeString) {
    const dateObj = new Date(dateTimeString);
    const options = {
      month: "numeric",
      day: "numeric",
      year: "numeric",
    };
    return dateObj.toLocaleString("en-US", options);
  }

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = padZero(date.getMonth() + 1); // Adding 1 to get 1-based month
    const day = padZero(date.getDate());
    return `${year}-${month}-${day}`;
  };

  const resetFile = () => {
    setFileImport(null);
    setCsvData(null);
  };

  const padZero = (num) => {
    return num < 10 ? '0' + num : num;
  };

  const column = [
    { field: "transactionCode", headerName: "Transaction Code", width: 150, headerClassName: 'dataGridHeader' },
    {
      field: "valueDate", headerName: "Date", width: 150, headerClassName: 'dataGridHeader',
      renderCell: ({ row }) => {
        return (
          <>
            {row.valueDate ? new Date(row.valueDate).toLocaleDateString("en-GB") : "NA"}
          </>
        );
      },
    },
    { field: "narration", headerName: "Description", width: 450, headerClassName: 'dataGridHeader' },
    { field: "balance", headerName: "Balance", width: 150, headerClassName: 'dataGridHeader' },
    { field: "amountSave", headerName: "Amount", width: 150, headerClassName: 'dataGridHeader'},
  ];
  return (
    <div className="cardBody tarnBody">
      {importFile === undefined ? (
        <div className="">
          <ToastContainer />
          <div className="selectDateFilterSection">
            <div className="mr-2">
              <label>From : </label>
              <DatePicker
                selected={fromDate}
                onChange={handleFromDateChange}
                dateFormat="dd/MM/yyyy"
                placeholderText="Select From Date"
                maxDate={toDate ? addDays(toDate, -1) : null} 

              />
            </div>

            <div className="mr-2">
              <label>To : </label>
              <DatePicker
                selected={toDate}
                onChange={handleToDateChange}
                dateFormat="dd/MM/yyyy"
                placeholderText="Select To Date"
                minDate={fromDate ? addDays(fromDate, 1) : null}

              />
            </div>
            <div className="applyBtn">
              <CButton
                onClick={handleApplyButtonClick} >
                Apply
              </CButton>
            </div>
            <div>
            </div>
          </div>
          <div className="datatableGrid bank-transaction">
            <DataGridPro
              rows={trasactionData}
              columns={column}
              // initialState={{
              //   pagination: {
              //     paginationModel: { page: 0, pageSize: 15 },
              //   },
              // }}
              pageSizeOptions={[5, 10]}
              density="compact"
              disableRowSelectionOnClick
              loading={loadingState}

            />
          </div>
        </div>
      ) : (
        <Mapping
          fetchData={fetchData}
          importFile={importFile}
          csvData={csvData}
          setFileImport={setFileImport}
          resetFile={resetFile}
        />
      )}
      <CCol className="uploadCutomModal">
        <CModal
          className=""
          alignment="center"
          visible={visible}
          onClose={handleCloseClick}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle id="VerticallyCenteredExample">
              Import your bank statement in CSV format
            </CModalTitle>
          </CModalHeader>

          <CModalBody>
            <FileUpload
              handleCloseClick={handleCloseClick}
              callBackFile={callBackFile}
              callBack={callBack}
            />
          </CModalBody>
        </CModal>
      </CCol>
    </div>
  );
};

export default Transcation;
