import React, { useState, useRef, useMemo, useEffect } from "react";
import {
  CRow,
  CCol,
  CButton,
  CFormInput,
  CFormSelect,
  CCard,
  CCardBody,
  CForm,
  CSpinner,
  CFormLabel,
  CModalBody,
  CModalHeader,
  CModalFooter,
  CModal,
  CTooltip,
} from "@coreui/react";
import {
  postApiWithHeader,
  GetApiwithHeader,
  UpdateUserwithHeader,
} from "src/Api/Api";
import { toast } from "react-toastify";
import { userAttributes } from "../../share/localData";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Select from "react-select";
import * as Yup from "yup";
import AutocompleteComponent from "src/components/AutoComplete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Button } from "@mui/material";

const validationSchema = Yup.object().shape({
  documentName: Yup.string().required("Document Name is required."),
  documentType: Yup.string().required("Document Type is required."),
  assetValue: Yup.string().required("Asset Value is required."),
  durationUnits: Yup.string().required("Duration Units is required."),
  numPaymentPeriods: Yup.string().required("Term of Loan is required."),
  startDate: Yup.string().required("Start Date is required."),
  paymentType: Yup.string().required("Payment Type is required."),
  upfrontDepositAmount: Yup.string()
    .required("Deposit Amount is required.")
    .test(
      "upfrontDepositAmountNotGreaterThanAssetValue",
      "Deposit Amount should not be greater than Asset Value.",
      function (value, { parent }) {
        const upfrontDepositAmount = parseFloat(value);
        const assetValue = parseFloat(parent.assetValue);
        if (isNaN(upfrontDepositAmount) || isNaN(assetValue)) {
          return true;
        }
        return upfrontDepositAmount <= assetValue;
      }
    ),
  numPaymentPeriods: Yup.string().required("Payment Periods is required."),
  presentValue: Yup.string()
    .required("Present Value of Finance is required.")
    .test(
      "presentValueNotGreaterThanAssetValue",
      "Present Value should not be greater than Asset Value.",
      function (value, { parent }) {
        const presentValue = parseFloat(value);
        const assetValue = parseFloat(parent.assetValue);
        if (isNaN(presentValue) || isNaN(assetValue)) {
          return true;
        }
        return presentValue <= assetValue;
      }
    ),
  futureValue: Yup.string().required("Future Value is required."),
  ratePerAnnum: Yup.string().required("Rate Per Annum is required."),
});

const LoanCalculator = (props) => {
  const { fetchData, editValue, handleClose, narration, detailsEdit } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isNarrationModal, setisNarrationModal] = useState(false);
  const { cmpID, bankID } = userAttributes();
  const companyId = cmpID;
  const BankID = bankID;
  const accessToken = localStorage.getItem("token");
  const [getDocumentType, setGetDocumentType] = useState();
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [codeData, setCodeData] = useState([]);
  const [selectedNarration, setSelectedNarration] = useState(null);
  const [titilename, setTitilename] = useState("");
  const [codeNameDup, setCodeNameDup] = useState();
  const [selectedCode1, setSelectedCode1] = useState();
  const [selectedCode2, setSelectedCode2] = useState();
  const [message, setMessage] = useState("");
  const [codeOne, setCodeOne] = useState(false);
  const [codeTwo, setCodeTwo] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [submitNarrtion, setSubmitNarrtion] = useState(true);

  const headers = useMemo(
    () => ({
      Authorization: `Bearer ${accessToken}`,
    }),
    [accessToken]
  );

  const GetDocumentType = async () => {
    try {
      const response = await GetApiwithHeader(
        `api/portfolio/GetDocumentType`,
        headers
      );
      const filtered =
        response && response?.data?.data?.filter((item) => item?.title);
      setGetDocumentType(filtered);
    } catch (error) {
      console.log(error);
    }
  };

  const codeFetchApi = async () => {
    try {
      const response = await GetApiwithHeader(
        `/api/BusinessCodes?companyId=${companyId}`,
        headers
      );
      const businessCode = response?.data?.data;
      setCodeData(businessCode);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const formatDate = (date) => {
    if (!date) return "";
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    if (editValue && detailsEdit) {
      codeFetchApi();
      const startDate = editValue?.startDate
        ? new Date(editValue.startDate)
        : null;
      const Code1Selected =
        detailsEdit &&
        codeData &&
        codeData?.filter((item) => editValue?.codeId1 === item?.id);
      const Code2Selected =
        detailsEdit &&
        codeData &&
        codeData?.filter((item) => editValue?.codeId2 === item?.id);
      setForm((prevForm) => ({
        ...prevForm,
        assetValue: editValue?.assetValue,
        documentName: editValue?.documentName,
        documentType: editValue?.documentTypeId,
        durationUnits: editValue?.durationUnit,
        numPaymentPeriods: editValue?.numPaymentPeriods,
        startDate: startDate ? formatDate(startDate) : "",
        endDate: new Date(editValue?.endDate),
        numPaymentPeriods: editValue?.numOfPaymentPeriods,
        ratePerAnnum: editValue?.roi,
        upfrontDepositAmount: editValue?.upfrontDepositAmount,
        presentValue: editValue?.presentValue,
        futureValue: editValue?.futureValue,
        paymentType: editValue?.paymentType,
        paymentDateOfResidual: editValue?.residualDate,
        ratePerPeriod: editValue?.rateperPeriod,
        monthlyPayment: editValue?.installment,
        capital: "",
        interest: "",
        fvPayout: "",
        CodeId1: Code1Selected && Code1Selected[0]?.codeName,
        CodeId2: Code2Selected && Code2Selected[0]?.codeName,
        narration: editValue?.codingRuleSetResponseDtos[0]?.narration,
      }));
      const narrValue =
        editValue?.codingRuleSetResponseDtos &&
        editValue?.codingRuleSetResponseDtos.length > 0 &&
        editValue?.codingRuleSetResponseDtos[0]?.narration;
      if (narrValue) {
        setSelectedNarration({
          value:
            editValue?.codingRuleSetResponseDtos &&
            editValue?.codingRuleSetResponseDtos.length > 0 &&
            editValue?.codingRuleSetResponseDtos[0]?.narration,
        });
      } else {
        setSelectedNarration({
          value: "",
        });
      }
      setSelectedCode1({
        value: detailsEdit && Code1Selected && Code1Selected[0]?.id,
        label: detailsEdit && Code1Selected && Code1Selected[0]?.codeName,
      });
      setSelectedCode2({
        value: detailsEdit && Code2Selected && Code2Selected[0]?.id,
        label: detailsEdit && Code2Selected && Code2Selected[0]?.codeName,
      });
      setCodeOne(false);
      setCodeTwo(false);
    } else {
      setForm({
        documentName: "",
        documentType: "",
        assetValue: "",
        durationUnits: "",
        numPaymentPeriods: "",
        startDate: "",
        numPaymentPeriods: "",
        ratePerAnnum: "",
        upfrontDepositAmount: "",
        presentValue: "",
        futureValue: "",
        paymentType: "1",
        paymentDateOfResidual: "",
        ratePerPeriod: "",
        monthlyPayment: "",
        installmentMonth: "",
        capital: "",
        interest: "",
        fvPayout: "",
        CodeId1: "",
        CodeId2: "",
      });
      setSelectedCode1({ value: "", label: "" });
      setSelectedCode2({ value: "", label: "" });
      setSelectedNarration({
        value: "",
      });
      resetForm();
      setErrors({});
      setCodeOne(false);
      setCodeTwo(false);
    }

    GetDocumentType();

    codeFetchApi();
  }, [editValue]);

  const [form, setForm] = useState({
    documentName: "",
    documentType: "",
    assetValue: "",
    durationUnits: "",
    numPaymentPeriods: "",
    startDate: "",
    numPaymentPeriods: "",
    ratePerAnnum: "",
    upfrontDepositAmount: "",
    presentValue: "",
    futureValue: "",
    paymentType: "1",
    paymentDateOfResidual: "",
    ratePerPeriod: "",
    monthlyPayment: "",
    installmentMonth: "",
    capital: "",
    interest: "",
    fvPayout: "",
    CodeId1: "",
    CodeId2: "",
  });

  const validateForm = async () => {
    try {
      await validationSchema.validate(form, { abortEarly: false });
      setErrors({});
      return true;
    } catch (validationErrors) {
      const errorObject = validationErrors.inner.reduce((acc, currentError) => {
        acc[currentError.path] = currentError.message;
        return acc;
      }, {});
      setErrors(errorObject);
      return false;
    }
  };

  const calculateRatePerPeriod = (rpm, duration) => {
    // debugger
    if (rpm == "" || duration == "") return 0;

    rpm = parseFloat(rpm).toFixed(1);
    if(isNaN(rpm)){
      return 0;
    }
    let ratePerPeriod = 0;
    switch (duration) {
      case "Weekly":
        ratePerPeriod = JSON.parse(rpm) / 52;
        break;
      case "Monthly":
        ratePerPeriod = JSON.parse(rpm) / 12;
        break;
      case "Quarterly":
        ratePerPeriod = JSON.parse(rpm) / 4;
        break;
      case "Yearly":
        ratePerPeriod = JSON.parse(rpm) / 1;
        break;
      default:
        ratePerPeriod = 0;
        break;
    }
    return ratePerPeriod;
  };

  const calculatePaymentDate = (
    startDate,
    numPaymentPeriods,
    durationUnits
  ) => {
    // const { startDate, numPaymentPeriods, durationUnits } = form;
    const paymentDate = new Date(startDate);

    switch (durationUnits) {
      case "Weekly":
        paymentDate.setDate(
          (paymentDate.getDate() + 7) * JSON.parse(numPaymentPeriods)
        );
        break;
      case "Monthly":
        paymentDate.setMonth(
          paymentDate.getMonth() + JSON.parse(numPaymentPeriods)
        );
        break;
      case "Quarterly":
        paymentDate.setMonth(
          (paymentDate.getMonth() + 3) * JSON.parse(numPaymentPeriods)
        );
        break;
      case "Yearly":
        paymentDate.setFullYear(
          paymentDate.getFullYear() + JSON.parse(numPaymentPeriods)
        );
        break;
      default:
        break;
    }
    return paymentDate;
  };

  const calculatePMT = (rate, nper, pv, fv, type) => {
    if (rate && nper && pv && fv && type) {
      var ratePerPeriod = rate / 100;

      if (fv > 0) {
        fv *= -1;
      }
      // Calculate PMT
      var pvif = Math.pow(1 + ratePerPeriod, nper); // Present value interest factor
      var pmt;
      if (ratePerPeriod !== 0) {
        pmt = (ratePerPeriod * (pv * pvif + fv)) / (pvif - 1);
        pmt /= 1 + ratePerPeriod * type;
      } else {
        pmt = (pv + fv) / nper;
      }
      return -pmt; // Return negative value as PMT in Excel is typically negative
    }
  };

  const calculateMonthlyPayment = (
    ratePerAnnum,
    numPaymentPeriods,
    presentValue,
    futureValue,
    paymentType,
    durationUnits
  ) => {
    // const { ratePerAnnum, numPaymentPeriods, presentValue, futureValue, paymentType, durationUnits } = form;
    if (
      ratePerAnnum &&
      numPaymentPeriods &&
      presentValue &&
      futureValue &&
      paymentType &&
      durationUnits
    ) {
      const ratePerPeriod = calculateRatePerPeriod(ratePerAnnum, durationUnits);
      let monthlyPayment;
      if (ratePerPeriod) {
        monthlyPayment = calculatePMT(
          JSON?.parse(ratePerPeriod),
          JSON?.parse(numPaymentPeriods),
          JSON?.parse(presentValue),
          JSON?.parse(futureValue),
          paymentType
        );
      }
      return monthlyPayment;
    }
  };

  const calculateFV = (rate, nper, pmt, pv, type) => {
    if (rate && nper && pmt && pv && type) {
      rate /= 100;
      const pow = Math.pow(1 + rate, nper);
      let fv;

      if (rate) {
        fv = (pmt * ((1 + rate * type) * (1 - pow))) / rate - pv * pow;
      } else {
        fv = -1 * (pv + pmt * nper);
      }
      return fv;
    }
  };

  const calculateFVPayout = (
    ratePerAnnum,
    numPaymentPeriods,
    presentValue,
    futureValue,
    paymentType,
    installmentMonth,
    durationUnits
  ) => {
    //const { ratePerAnnum, numPaymentPeriods, presentValue, futureValue, paymentType, installmentMonth, durationUnits } = form;

    const ratePerPeriod = calculateRatePerPeriod(ratePerAnnum, durationUnits);
    const pmt = calculatePMT(
      ratePerPeriod,
      numPaymentPeriods,
      presentValue,
      futureValue,
      paymentType
    );
    const currentMonth = parseFloat(installmentMonth);
    const FVCurrent = calculateFV(
      ratePerPeriod,
      currentMonth,
      pmt,
      presentValue,
      paymentType
    );
    const FVBeginPeriod = calculateFV(
      ratePerPeriod,
      currentMonth - 1,
      pmt,
      presentValue,
      paymentType
    );
    const capital = FVCurrent - FVBeginPeriod;
    const interest = pmt + capital;

    setForm((prevForm) => ({
      ...prevForm,
      fvPayout: FVCurrent.toFixed(2),
      capital: capital.toFixed(2),
      interest: interest.toFixed(2),
    }));
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    if (name === "upfrontDepositAmount" || name === "presentValue") {
      try {
        const assetValue =
          form.assetValue !== undefined ? parseFloat(form.assetValue) : 0;
        const floatValue = parseFloat(value);
        if (floatValue > assetValue) {
          throw new Error(
            `${
              name === "upfrontDepositAmount"
                ? "Upfront Deposit Amount"
                : "Present Value"
            } should not be greater than Asset Value`
          );
        }

        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "",
        }));
      } catch (error) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: error.message,
        }));
      }
    }

    // Other handleChange logic
    setForm((prevForm) => {
      const updatedForm = {
        ...prevForm,
        [name]: value,
      };
      let rpm = '';
      rpm = updatedForm.ratePerAnnum && updatedForm.ratePerAnnum === '.' || updatedForm.ratePerAnnum === '0' ? '' : updatedForm.ratePerAnnum
      if (updatedForm.ratePerAnnum != "" && updatedForm.durationUnits != "") {
        let rpm = updatedForm.ratePerAnnum;
        let duration = updatedForm.durationUnits;
        let cal = calculateRatePerPeriod(
          rpm,
          duration
        );
        updatedForm.ratePerPeriod = cal ? cal.toFixed(4) : 0;
      }

      if (
        updatedForm.startDate &&
        updatedForm.numPaymentPeriods &&
        updatedForm.durationUnits
      ) {
        updatedForm.paymentDateOfResidual = calculatePaymentDate(
          updatedForm.startDate,
          updatedForm.numPaymentPeriods,
          updatedForm.durationUnits
        ).toLocaleDateString("en-GB");
      }

      if (
        updatedForm.ratePerAnnum &&
        updatedForm.numPaymentPeriods &&
        updatedForm.presentValue &&
        updatedForm.futureValue &&
        updatedForm.paymentType
      ) {
        let cal = calculateMonthlyPayment(
          rpm,
          updatedForm.numPaymentPeriods,
          updatedForm.presentValue,
          updatedForm.futureValue,
          updatedForm.paymentType,
          updatedForm.durationUnits
        );
        updatedForm.monthlyPayment = cal ? cal.toFixed(2) : 0;
      }

      /*if (updatedForm.ratePerAnnum && updatedForm.numPaymentPeriods && updatedForm.presentValue && updatedForm.futureValue) {
       
           const payoutDetails = calculateFVPayout(updatedForm);
           updatedForm.fvPayout = payoutDetails?.fvPayout;
           updatedForm.capital = payoutDetails?.capital;
           updatedForm.interest = payoutDetails?.interest;
       
     }*/
      //   console.log('updatedForm', updatedForm)
      return updatedForm;
    });
  };

  const narrationModelClose = (e) => {
    setisNarrationModal(false);
    setSubmitNarrtion(false);
    handleSubmit(e);
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   const isValid = await validateForm();
  //   setCodeOne(true);
  //   setCodeTwo(true);
  //   if (!isValid) return;
  //   detailsEdit &&
  //     selectedCode1?.label &&
  //     selectedCode2?.label &&
  //     setIsSubmitting(true);
  //   if (detailsEdit) {
  //     setIsSubmitting(true);
  //   }
  //   if (editValue && submitNarrtion) {
  //     if (
  //       editValue?.codingRuleSetResponseDtos[0]?.narration &&
  //       editValue?.codingRuleSetResponseDtos[0]?.narration?.trim() !==
  //         selectedNarration?.value?.trim()
  //     ) {
  //       setisNarrationModal(true);
  //       setIsSubmitting(false);
  //       setSubmitNarrtion(false);
  //       if (submitNarrtion) {
  //         return;
  //       }
  //     }
  //   }
  //   const Payload = {
  //     bankAccountId: BankID,
  //     documentName: form?.documentName,
  //     documentType: form?.documentType,
  //     assetValue: form?.assetValue,
  //     durationUnit: form?.durationUnits,
  //     // numPaymentPeriods: form?.numPaymentPeriods,
  //     startDate: form?.startDate,
  //     endDate: new Date(form?.paymentDateOfResidual) ?? '',
  //     upfrontDepositAmount: form?.upfrontDepositAmount,
  //     paymentType: form?.paymentType,
  //     numOfPaymentPeriods: form.numPaymentPeriods,
  //     presentValue: form?.presentValue,
  //     futureValue: form?.futureValue,
  //     roi: form?.ratePerAnnum,
  //     rateperPeriod: form?.ratePerPeriod,
  //     residualDate: new Date(form?.paymentDateOfResidual),
  //     installment: form.monthlyPayment,
  //     durationYears: 0,
  //     durationMonths: 0,
  //     durationDays: 0,
  //     CodeId1: selectedCode1.value,
  //     CodeId2: selectedCode2.value,
  //     isActive: true,
  //     narration: selectedNarration?.value ? selectedNarration?.value : "",
  //   };

  //   try {
  //     const response = (await editValue)
  //       ? UpdateUserwithHeader(
  //           `/api/portfolio/update/${editValue.id},${companyId}`,
  //           Payload,
  //           headers
  //         )
  //       : postApiWithHeader(
  //           `/api/portfolio/create?companyId=${companyId}`,
  //           Payload,
  //           headers
  //         );

  //     console.log('response',await response?.data?.message, await response)
  //     if (await response) {
  //       console.log('in')
  //       if (response?.data?.statusCode === 100) {
  //         toast.success("Added successfully", {
  //           autoClose: 1000,
  //           onClose: () => {
  //             handleClose();
  //             resetForm();
  //             fetchData();
  //             setIsSubmitting(false);
  //           },
  //         });
  //       } else if (await response?.data?.statusCode === 102) {
  //         console.log('detailsEdit', detailsEdit)
  //         if (detailsEdit) {
  //           // setTimeout(async () => {
  //             handleClose();
  //             resetForm();
  //             fetchData();
  //             setIsSubmitting(false);
  //             toast.success(await response?.data?.message, {
  //               autoClose: 1000,
  //             });
  //           // }, 1000);
  //         } else if (selectedCode1?.label && selectedCode2?.label) {
  //           toast.success(
  //             editValue ? "Update successfully" : "Added successfully",
  //             {
  //               autoClose: 1000,
  //               onClose: () => {
  //                 handleClose();
  //                 resetForm();
  //                 fetchData();
  //                 setIsSubmitting(false);
  //               },
  //             }
  //           );
  //         }
  //       }
  //     }
      
  //   } catch (error) {
  //     console.error("Error:", error);
  //     toast.error("An error occurred. Please try again.", {
  //       autoClose: 2000,
  //     });
  //     setIsSubmitting(false);
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = await validateForm();
    setCodeOne(true);
    setCodeTwo(true);
  
    if (!isValid) return;
  
    if (detailsEdit && selectedCode1?.label && selectedCode2?.label) {
      setIsSubmitting(true);
    }
  
    if (editValue && submitNarrtion) {
      const narrationChanged =
        editValue?.codingRuleSetResponseDtos[0]?.narration?.trim() !==
        selectedNarration?.value?.trim();
  
      if (narrationChanged) {
        setisNarrationModal(true);
        setIsSubmitting(false);
        setSubmitNarrtion(false);
        return;
      }
    }
  
    const payload = {
      bankAccountId: BankID,
      documentName: form?.documentName,
      documentType: form?.documentType,
      assetValue: form?.assetValue,
      durationUnit: form?.durationUnits,
      startDate: form?.startDate,
      endDate: new Date(form?.paymentDateOfResidual),
      upfrontDepositAmount: form?.upfrontDepositAmount,
      paymentType: form?.paymentType,
      numOfPaymentPeriods: form.numPaymentPeriods,
      presentValue: form?.presentValue,
      futureValue: form?.futureValue,
      roi: form?.ratePerAnnum,
      rateperPeriod: form?.ratePerPeriod,
      residualDate: new Date(form?.paymentDateOfResidual),
      installment: form.monthlyPayment,
      durationYears: 0,
      durationMonths: 0,
      durationDays: 0,
      CodeId1: selectedCode1.value,
      CodeId2: selectedCode2.value,
      isActive: true,
      narration: selectedNarration?.value ?? "",
    };
    try {
      const response = editValue
        ? await UpdateUserwithHeader(`/api/portfolio/update/${editValue.id},${companyId}`, payload, headers)
        : await postApiWithHeader(`/api/portfolio/create?companyId=${companyId}`, payload, headers);
  
      if (response) {
        if (response?.data?.statusCode === 100 || response?.statusCode === 100) {
          console.log('ine 100')
          toast.success(editValue ? "Update successfully" : "Added successfully",{
            autoClose: 1000,
            onClose: () => {
              handleClose();
              resetForm();
              fetchData();
              setIsSubmitting(false);
            },
          });
        } else if (response?.statusCode === 102) {
          if (detailsEdit) {
            setIsSubmitting(false);
            toast.warning(response?.message, {
              autoClose: 2000,
            });
          } else if (selectedCode1?.label && selectedCode2?.label) {
            toast.success(editValue ? "Update successfully" : "Added successfully", {
              autoClose: 1000,
              onClose: () => {
                handleClose();
                resetForm();
                fetchData();
                setIsSubmitting(false);
              },
            });
          }
        }
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again.", {
        autoClose: 2000,
      });
      setIsSubmitting(false);
    }
  };
  

  const CodeOption = codeData?.map((item) => ({
    value: item?.id,
    label: item?.codeName,
    isInsurance: item?.isInsurance,
    gst: item?.gst,
    entryType: item?.entryType,
    categoryA: item?.categoryA,
    copyParameter: item?.copyParam,
    isAsset: item?.isAsset,
    categoryB: item?.categoryB,
    categoryId: item?.categoryId,
    debCretorType: item?.value,
    fringeBenifitTax: item?.fringeBenifitTax,
    exportIncentiveProgram: item?.exportIncentiveProgram,
    isBasCode: item?.isBasCode,
    itemType: item?.itemType,
    isFinancialInstrument: item?.isFinancialInstrument,
  }));

  const { capitalArray, nonCapitalArray } = CodeOption?.reduce(
    (acc, item) => {
      if (item?.isFinancialInstrument) {
        if (item.categoryA === "Capital") {
          acc?.capitalArray.push(item);
        } else if (item?.categoryA === "NonCapital") {
          acc?.nonCapitalArray.push(item);
        }
      }
      return acc;
    },
    { capitalArray: [], nonCapitalArray: [] }
  );

  const handleNarrationChange = (e) => {
    setSelectedNarration(e);
    // setisNarrationModal(true)
  };

  const handleDuplicate = (type) => {
    if (type === "capital" && !selectedCode1) {
      setMessage("Please select capital");
    } else if (type === "interest" && !selectedCode2) {
      setMessage("Please select interest");
    } else {
      setMessage("");
      setTitilename(type);
      if (type === "capital" && selectedCode1?.label) {
        setIsModalVisible(true);
      } else if (type === "interest" && selectedCode2?.label) {
        setIsModalVisible(true);
      }
    }
  };

  const handleDuplicateCode = async () => {
    const codeSelect = titilename === "capital" ? selectedCode1 : selectedCode2;
    const Item = codeData.filter((item) => item.id === codeSelect.value);
    let status = false;
    if (!codeNameDup?.trim()) {
      setErrorMessage("Code Name cannot be empty");
      status = true;
    } else {
      setErrorMessage("");
      status = false;
    }
    const Payload = {
      codeName: codeNameDup,
      entrytype: Item[0]?.entryType,
      isAsset: Item[0]?.isAsset,
      categoryA: Item[0]?.categoryA,
      categoryB: Item[0]?.categoryB,
      gst: Item[0]?.gst,
      copyParam: Item ? Item[0]?.copyParam : "",
      value: Item[0]?.value,
      description: "",
      categoryId: Item[0]?.categoryId,
      bankTransactionCode: 0,
      isInsurance: Item[0]?.isInsurance,
      itemType: Item[0]?.itemType === true ? Item?.itemType : "None",
      fringeBenifitTax: Item[0]?.fringeBenifitTax,
      exportIncentiveProgram: Item[0]?.exportIncentiveProgram,
      xeroCode: Item[0]?.xeroCode,
      isBasCode: Item[0]?.isBasCode,
      isFinancialInstrument: Item[0]?.isFinancialInstrument,
    };
    if (!status) {
      try {
        const response = await postApiWithHeader(
          `/api/BusinessCodes/create?companyId=${companyId}`,
          Payload,
          headers
        );
        if (response?.data?.statusCode === 100) {
          // setTimeout(() => {
            setCodeData(prevCodeData => [...prevCodeData, response.data.data]);
          if (titilename === "capital" && detailsEdit) {
            setSelectedCode1({
              value: response?.data?.data?.id,
              label: response?.data?.data?.codeName,
            });
          } else {
            setSelectedCode2({
              value: response?.data?.data?.id,
              label: response?.data?.data?.codeName,
            });
          }
          setIsModalVisible(false);
          fetchData();
          status = false;
          // }, 1000);
          toast.success("Add successfully", {
            autoClose: 1000,
          });
        } else {
          toast.error(response?.data?.message, {
            autoClose: 2000,
          });
        }
      } catch (error) {
        console.error("Error adding code", error);
        toast.error("Failed to add code");
        setIsSubmitting(false);
      }
    }
  };

  const resetForm = () => {
    setForm({
      documentName: "",
      documentType: "",
      assetValue: "",
      durationUnits: "",
      numPaymentPeriods: "",
      startDate: "",
      numPaymentPeriods: "",
      ratePerAnnum: "",
      upfrontDepositAmount: "",
      presentValue: "",
      futureValue: "",
      paymentType: "1",
      paymentDateOfResidual: "",
      ratePerPeriod: "",
      monthlyPayment: "",
      installmentMonth: "",
      capital: "",
      interest: "",
      fvPayout: "",
      CodeId1: "",
      CodeId2: "",
    });
    // setBankVal(null);
    // setBankTypeVal(null);
    setErrors("");
  };

  // const handleInputChange = (e) => {
  //   const value = e.target.value;
  //   setCodeNameDup(value);
  //   if (!value.trim()) {
  //     setErrorMessage('Code Name cannot be empty');
  //   } else {
  //     setErrorMessage('');
  //   }
  // };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setCodeNameDup(value);
    setErrorMessage("");
  };

  return (
    <CCard className="card2">
      <CCardBody>
        <CForm onSubmit={handleSubmit}>
          <CRow>
            <CCol className="mb-2" md="12">
              <label htmlFor="documentName">
                Document Name <span className="text-danger">*</span>
              </label>
              <CFormInput
                id="documentName"
                name="documentName"
                value={form.documentName}
                onChange={handleChange}
                placeholder="Enter Document Name"
              />
              {errors.documentName && (
                <div className="text-danger">{errors.documentName}</div>
              )}
            </CCol>
            <CCol className="mb-2" md="12">
              <label htmlFor="documentType">
                Document Type <span className="text-danger">*</span>
              </label>
              <CFormSelect
                id="documentType"
                name="documentType"
                value={form.documentType}
                onChange={handleChange}
              >
                <option value="">Select Document Type</option>
                {getDocumentType
                  ?.sort((a, b) => a.title.localeCompare(b.title))
                  .map((item) => (
                    <option key={item.id} value={item?.id}>
                      {item?.title}
                    </option>
                  ))}
              </CFormSelect>
              {errors.documentType && (
                <div className="text-danger">{errors.documentType}</div>
              )}
            </CCol>
          </CRow>
          <CRow>
            <CCol className="mb-2" md="12">
              <label htmlFor="assetValue">
                Asset Value $<span className="text-danger"> *</span>
              </label>
              <CFormInput
                id="assetValue"
                name="assetValue"
                value={form.assetValue}
                onChange={handleChange}
                placeholder="Enter Asset Value"
              />
              {errors.assetValue && (
                <div className="text-danger">{errors.assetValue}</div>
              )}
            </CCol>
            <CCol className="mb-2" md="12">
              <label htmlFor="durationUnits">
                Duration Units <span className="text-danger">*</span>
              </label>
              <CFormSelect
                id="durationUnits"
                name="durationUnits"
                value={form.durationUnits}
                onChange={handleChange}
              >
                <option value="">Select Duration Units</option>
                <option value="Weekly">Weekly</option>
                <option value="Monthly">Monthly</option>
                <option value="Quarterly">Quarterly</option>
                <option value="Yearly">Yearly</option>
              </CFormSelect>
              {errors.durationUnits && (
                <div className="text-danger">{errors.durationUnits}</div>
              )}
            </CCol>
          </CRow>
          {/* <CRow>
            <CCol className="mb-2" md="12">
              <label htmlFor="numPaymentPeriods">Term of Loan</label>
              <CFormInput
                id="numPaymentPeriods"
                name="numPaymentPeriods"
                value={form.numPaymentPeriods}
                onChange={handleChange}
                placeholder="Enter Term of Loan"
              />
              {errors.numPaymentPeriods && (
                <div className="text-danger">{errors.numPaymentPeriods}</div>
              )}
            </CCol>
          </CRow> */}
          <CRow className="mb-2">
            <CCol className="mb-2" md="12">
              <label htmlFor="startDate">
                Start Date <span className="text-danger">*</span>
              </label>
              <CFormInput
                type="date"
                id="startDate"
                name="startDate"
                value={form.startDate}
                onChange={handleChange}
              />
              {errors.startDate && (
                <div className="text-danger">{errors.startDate}</div>
              )}
            </CCol>
          </CRow>
          <CCol className="mb-2" md="12">
            <label htmlFor="upfrontDepositAmount">
              Upfront Deposit Amount $<span className="text-danger"> *</span>
            </label>
            <CFormInput
              id="upfrontDepositAmount"
              name="upfrontDepositAmount"
              value={form.upfrontDepositAmount}
              onChange={handleChange}
              placeholder="Enter Upfront Deposit Amount"
            />
            {errors.upfrontDepositAmount && (
              <div className="text-danger">{errors.upfrontDepositAmount}</div>
            )}
          </CCol>
          <CCol className="mb-2" md={12}>
            <label htmlFor="paymentType">Payment Type</label>
            <CFormSelect
              id="paymentType"
              name="paymentType"
              value={form.paymentType}
              onChange={handleChange}
            >
              <option value="">Select payment type</option>
              <option value="1">1 - Advance</option>
              <option value="0">0 - Arrears</option>
            </CFormSelect>
            {errors.paymentType && (
              <div className="text-danger">{errors.paymentType}</div>
            )}
          </CCol>

          <CRow md={12}>
            <CCol className="mb-2" md="6">
              <label htmlFor="numPaymentPeriods">
                No. of Payment Periods <span className="text-danger">*</span>
              </label>
              <CFormInput
                id="numPaymentPeriods"
                name="numPaymentPeriods"
                value={form.numPaymentPeriods}
                onChange={handleChange}
                placeholder="Enter No. Of Payment Periods"
              />
              {errors.numPaymentPeriods && (
                <div className="text-danger">{errors.numPaymentPeriods}</div>
              )}
            </CCol>
            <CCol className="mb-2" md="6">
              <label htmlFor="presentValue">
                Present Value of Finance ${" "}
                <span className="text-danger">*</span>
              </label>
              <CFormInput
                id="presentValue"
                name="presentValue"
                value={form.presentValue}
                onChange={handleChange}
                placeholder="Enter Present Value of Finance"
              />
              {errors.presentValue && (
                <div className="text-danger">{errors.presentValue}</div>
              )}
            </CCol>
          </CRow>
          <CRow md={12}>
            <CCol className="mb-2" md="6">
              <label htmlFor="futureValue">
                Future Value (Balloon Value) ${" "}
                <span className="text-danger">*</span>
              </label>
              <CFormInput
                id="futureValue"
                name="futureValue"
                value={form.futureValue}
                onChange={handleChange}
                placeholder="Enter Future Value"
              />
              {errors.futureValue && (
                <div className="text-danger">{errors.futureValue}</div>
              )}
            </CCol>
            <CCol className="mb-2" md="6">
              <label htmlFor="ratePerAnnum">
                Rate Per Annum % <span className="text-danger">*</span>
              </label>
              <CFormInput
                id="ratePerAnnum"
                name="ratePerAnnum"
                value={form.ratePerAnnum}
                onChange={handleChange}
                placeholder="Enter Rate Per Annum"
              />
              {errors.ratePerAnnum && (
                <div className="text-danger">{errors.ratePerAnnum}</div>
              )}
            </CCol>
          </CRow>
          <CRow md="12">
            <CCol className="mb-2" md="6">
              <label htmlFor="ratePerPeriod">Rate Per Period </label>
              <CFormInput
                id="ratePerPeriod"
                name="ratePerPeriod"
                value={form.ratePerPeriod}
                disabled
              />
              {errors.ratePerPeriod && (
                <div className="text-danger">{errors.ratePerPeriod}</div>
              )}
            </CCol>

            <CCol md="6">
              <label htmlFor="monthlyPayment">Installment per period $ </label>
              <CFormInput
                id="monthlyPayment"
                name="monthlyPayment"
                value={form.monthlyPayment}
                disabled
              />
              {errors.monthlyPayment && (
                <div className="text-danger">{errors.monthlyPayment}</div>
              )}
            </CCol>
          </CRow>
          <CCol className="mb-2" md="12">
            <label htmlFor="paymentDateOfResidual">
              Payment Date of Residual
            </label>
            <CFormInput
              type="text"
              id="paymentDateOfResidual"
              name="paymentDateOfResidual"
              value={new Date(form.paymentDateOfResidual).toLocaleDateString('en-GB')}
              disabled
            />
            {errors.paymentDateOfResidual && (
              <div className="text-danger">{errors.paymentDateOfResidual}</div>
            )}
          </CCol>
          <CRow md="12">
            <CCol className="mb-2" md="11">
              <label htmlFor="CodeId1">
                Capital <span className="text-danger">*</span>
              </label>
              <Select
                className="mt-6 width400"
                id="combo-box-code1"
                options={capitalArray}
                value={selectedCode1}
                onChange={(newValue) => {
                  setSelectedCode1(newValue);
                  setCodeOne(false);
                }}
                getOptionLabel={(option) => option.label || ""}
                getOptionValue={(option) => option.value}
                placeholder="Select Capital"
                isSearchable
              />
              {/* {errors.CodeId1 && (
                <div className="text-danger">{errors.CodeId1}</div>
              )} */}
              {codeOne && selectedCode1?.label === "" && (
                <div className="text-danger">Capital code is required</div>
              )}
            </CCol>
            <CCol className="mt-2 copyIcon" md="1" sx={{ paddingLeft: "0" }}>
              <CTooltip content="Duplicate">
                <Button
                  onClick={() => handleDuplicate("capital")}
                  disabled={!selectedCode1}
                  className="copyBtn"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "unset",
                    padding: "0",
                  }}
                >
                  <ContentCopyIcon />
                </Button>
              </CTooltip>
            </CCol>
          </CRow>
          <CRow md="12">
            <CCol className="mb-2" md="11">
              <label htmlFor="CodeId2">
                Interest <span className="text-danger">*</span>
              </label>
              <Select
                className="mt-6 width400"
                id="combo-box-code2"
                options={nonCapitalArray}
                value={selectedCode2}
                onChange={(newValue) => {
                  setSelectedCode2(newValue);
                  setCodeTwo(false);
                }}
                getOptionLabel={(option) => option.label || ""}
                getOptionValue={(option) => option.value}
                placeholder="Select Interest"
                isSearchable
              />

              {codeTwo && selectedCode1?.label === "" && (
                <div className="text-danger">Interest code is required</div>
              )}
            </CCol>
            <CCol className="mt-2 copyIcon" md="1">
              <CTooltip content="Duplicate">
                <Button
                  onClick={() => handleDuplicate("interest")}
                  disabled={!selectedCode2}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "unset",
                    padding: "0",
                  }}
                >
                  <ContentCopyIcon />
                </Button>
              </CTooltip>
            </CCol>
          </CRow>
          {message && <div className="text-danger">{message}</div>}
          <CCol className="mb-2">
            <div className="md-12 mt-3 selectOption">
              <CFormLabel>Select Narration To Match The Transcation</CFormLabel>
              <AutocompleteComponent
                options={[
                  ...(narration && narration.length > 0
                    ? narration.map((item) => ({
                        value: item?.narration,
                      }))
                    : []),
                ]}
                onChange={(e) => handleNarrationChange(e)}
                value={selectedNarration}
                placeholder="Select Narration"
              />
            </div>
          </CCol>
          <CRow className="mt-4 justify-content-end">
            <CCol xs="auto">
              <CButton
                color="light"
                onClick={() => {
                  resetForm();
                  handleClose();
                }}
              >
                Cancel
              </CButton>
            </CCol>
            <CCol xs="auto">
              <CButton color="primary" type="submit" disabled={isSubmitting}>
                {isSubmitting ? <CSpinner color="light" size="sm" /> : "Save"}
              </CButton>
            </CCol>
          </CRow>
        </CForm>
        <CModal
          visible={isModalVisible}
          onClose={() => setIsModalVisible(false)}
          aria-labelledby="ScrollingLongContentExampleLabel"
          className="overley"
        >
          <CModalHeader>
            <h3>
              Duplicate Code :{" "}
              {titilename && titilename === "capital"
                ? selectedCode1?.label
                : selectedCode2?.label}
            </h3>
          </CModalHeader>
          <CModalBody>
            <label htmlFor="DuplicateCode">
              New Code Name <span className="text-danger">*</span>
            </label>
            <CFormInput
              placeholder="Enter Code Name"
              value={codeNameDup}
              onChange={handleInputChange}
            />
            <span className="text-danger">{errorMessage}</span>
          </CModalBody>
          <CModalFooter>
            <CButton
              className="btn-light mr-2"
              onClick={() => setIsModalVisible(false)}
            >
              Close
            </CButton>
            <CButton
              className="btn-primary"
              onClick={() => handleDuplicateCode()}
            >
              Save
            </CButton>
          </CModalFooter>
        </CModal>
        <CModal
          visible={isNarrationModal}
          onClose={() => setisNarrationModal(false)}
          aria-labelledby="ScrollingLongContentExampleLabel"
          className="overley"
        >
          <CModalHeader>
            Are you sure you want to make this change in Rule?
          </CModalHeader>
          <CModalFooter>
            <CButton
              className="btn-light mr-2"
              onClick={() => setisNarrationModal(false)}
            >
              No
            </CButton>
            <CButton
              className="btn-primary"
              onClick={(e) => narrationModelClose(e)}
            >
              Yes
            </CButton>
          </CModalFooter>
        </CModal>
      </CCardBody>
    </CCard>
  );
};

export default LoanCalculator;
