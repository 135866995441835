import React, {
  useEffect,
  useState,
  useMemo,
  useRef,
  useCallback,
} from "react";
import {
  CNav,
  CNavItem,
  CNavLink,
  CCard,
  CCardBody,
  CButton,
  CFormSelect,
  CCol,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CModal,
  CRow,
  CFormInput,
} from "@coreui/react";
import UploadTrans from "./uploadTranscation";
import Transcation from "./transcation";
import { userAttributes } from "../../share/localData";
import { useData } from "../../components/dataContext";
import FileUpload from "./import";
// import Select from 'react-select';
import { toast, ToastContainer } from "react-toastify";
import { postApiWithHeader, GetApiwithHeader } from "src/Api/Api";

const List = () => {
  const { data } = useData();
  const [activeTab, setActiveTab] = useState(0);
  const { cmpID, bankID } = userAttributes();
  const [toggleItem, setToggaleItem] = useState(false);
  const accessToken = localStorage.getItem("token");
  const [visible, setVisible] = useState(false);
  const [importFile, setFileImport] = useState();
  const [csvData, setCsvData] = useState();
  const [rowDatas, setRowDatas] = useState();
  const [openingBalanceData, setOpeningBalanceData] = useState([]);
  const [openingBalanceCheck, setOpeningBalanceCheck] = useState(false);
  const [transData, setTransData] = useState();
  const [selectedYear, setSelectedYear] = useState(null);
  const [openingBalance, setOpeningBalance] = useState(null);
  const [csvResult, setCsvResult] = useState(false);
  const [finStartDate, setFinStartDate] = useState(null);
  const [finEndDate, setFinEndDate] = useState(null);
  const [openingErr, setOpeningErr] = useState();
  const [financialErr, setfinancialErr] = useState();
  const [diffInDays, setDiffInDays] = useState(null);
  const [fileDataReverse, setFileDataReverse] = useState(false);

  const csvFileref = useRef();
  const headers = useMemo(
    () => ({
      Authorization: `Bearer ${accessToken}`,
    }),
    [accessToken]
  );
  const companyId = cmpID;
  const BankID = bankID;

  useEffect(() => {
    setFileImport(undefined);
    setCsvData(null);
    setRowDatas(null);
    setOpeningBalanceData([]);
    setOpeningBalance(null);
    setSelectedYear(null);
  }, [cmpID, bankID]);

  const callBack = (data, rowData) => {
    const arr = [];
    rowData.forEach((item) => {
      arr.push(item);
    });
    const ar = [];
    data.map((item) => {
      ar.push(item.trim());
    });
    setCsvData(ar);
    setRowDatas(rowData);
  };
  const callBackFile = (data) => {
    setFileImport(data);
    // if (data) {
    //   handleCloseClick();
    // }
  };

  const handleCloseClick = () => {
    setVisible(false);
  };

  const fetchOpeningBalanceData = async () => {
    const url = `/api/TransactionOpeningBalance/GetOpeningBalance?companyId=${cmpID}&bankAccountId=${bankID}`;
    try {
      const res = await GetApiwithHeader(url, headers);
      if (res?.data && res?.data?.data && res?.data?.data.length > 0) {
        setOpeningBalanceData(res?.data?.data || []);
        setOpeningBalance(res?.data?.data[0]?.openingBalance);
        setSelectedYear(res?.data?.data[0]?.financialYearValue);
      } else {
        setOpeningBalanceData([]);
        setOpeningBalance(null);
        setSelectedYear(null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setOpeningBalanceData([]);
    setOpeningBalance(null);
    setSelectedYear(null);
    fetchOpeningBalanceData();
  }, [cmpID, bankID]);

  useEffect(() => {
    setToggaleItem(false);
    setFileImport();
  }, [activeTab]);

  const handleOpenModal = () => {
    if (transData && transData.length === 0) setOpeningBalanceCheck(true);
    const bankData = JSON.parse(localStorage.getItem("bankData"));
    if (bankData === null) {
      toast.warning("Please Add Bank First", { autoClose: 1000 });
      return;
    }
    setCsvResult(false);
    setVisible(true);
  };

  const trascData = (data) => {
    setTransData(data);
    if (data && data.length > 0) setOpeningBalanceData([]);
    setOpeningBalanceCheck(data && data.length ? false : true);
  };

  const handleChange = (e) => {
    setOpeningBalance(e.target.value);
    setOpeningErr("");
  };

  const handleYearChange = useCallback((event) => {
    setfinancialErr("");
    setSelectedYear(event.target.value);
    // fetchData(event.target.value);
  }, []);

  const generateFinancialYears = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = 0; i < 5; i++) {
      const startYear = currentYear - i;
      const endYear = startYear + 1;
      years.push({
        value: `${startYear}-${endYear}`,
        title: ` 1st July ${startYear} to 30th June ${endYear} (FY${endYear})`,
      });
    }
    return years;
  };

  const handleSave = async () => {
    const balancePattern = /^[0-9,.]+$/;
    setOpeningErr("");
    setfinancialErr("");

    if (selectedYear === undefined && openingBalance === undefined) {
      setOpeningErr("Please enter opening balance.");
      setfinancialErr("Please select financial year.");
    } else if (
      openingBalance === undefined ||
      !balancePattern.test(openingBalance)
    ) {
      setOpeningErr("Please enter a valid opening balance.");
    } else if (selectedYear === undefined) {
      setfinancialErr("Please select financial year.");
    }

    const url = `/api/TransactionOpeningBalance/create?companyId=${companyId}`;
    const payload = {
      bankAccountid: bankID,
      financialYear: `fy${selectedYear}`,
      financialYearValue: selectedYear,
      openingBalance: openingBalance,
      id:
        openingBalanceData && openingBalanceData.length > 0
          ? openingBalanceData[0].id
          : 0,
    };
    try {
      const res = await postApiWithHeader(url, payload, headers);
      console.log("res", res?.data?.data);
      fetchOpeningBalanceData();
      setOpeningBalanceCheck(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getFinancialYearFromStartdate = (date) => {
    // Ensure the input is a valid Date object
    if (!(date instanceof Date) || isNaN(date)) {
      throw new Error("Invalid date");
    }

    const year = date.getFullYear();
    const month = date.getMonth(); // Months are zero-based, so January is 0 and December is 11
    const day = date.getDate(); // get day
    // Financial year starts in July and ends in June
    // If the month is June or before (0 to 5), the financial year is the previous year to current year
    // Otherwise, it is the current year to next year
    if(month === 5){
      if(day === 30){
        return `${year}-${year + 1}`;
      }else{
        return `${year - 1}-${year}`;
      }
    }else{
      if (month < 6) {
        // January to June
        return `${year - 1}-${year}`;
      } else {
        // July to December
        return `${year}-${year + 1}`;
      }
    }
  };
  function getDateDifference(startDate, endDate) {
    if (
      !(startDate instanceof Date) ||
      isNaN(startDate) ||
      !(endDate instanceof Date) ||
      isNaN(endDate)
    ) {
      throw new Error("Invalid date");
    }
    const diffInMs = endDate - startDate;
    const msInSecond = 1000;
    const msInMinute = msInSecond * 60;
    const msInHour = msInMinute * 60;
    const msInDay = msInHour * 24;

    const days = Math.floor(diffInMs / msInDay);
    const hours = Math.floor((diffInMs % msInDay) / msInHour);
    const minutes = Math.floor((diffInMs % msInHour) / msInMinute);
    const seconds = Math.floor((diffInMs % msInMinute) / msInSecond);
    if (days > 10) {
      return {
        days,
        hours,
        minutes,
        seconds,
      };
    }
    return null;
  }
  const hanldeExcelResult = (data, fileStartDate) => {
    csvFileref.current = data;
    // setCsvResult(true);
    if (transData && transData.length > 0) {
      const financialYear = getFinancialYearFromStartdate(fileStartDate);
      const financialYearOfLastTrans = getFinancialYearFromStartdate(
        new Date(
          transData[
            transData.length - 1
          ].closingTransactionDate
        )
      );
      if(financialYear === financialYearOfLastTrans){
        const diff = getDateDifference(
          new Date(transData[transData.length - 1].closingTransactionDate),
          fileStartDate
        );
        if (diff) {
          setCsvResult(true);
          setDiffInDays(diff?.days);
        } else {
          setFileImport(data);
          handleCloseClick();
        }
      }else{
        setDiffInDays(null);
        setCsvResult(true);
      }
    } else {
      setFileImport(data);
      handleCloseClick();
    }
  };

  const handleNo = () => {
    setCsvResult(false);
  };
  const handleYes = () => {
    setFileImport(csvFileref.current);
    handleCloseClick();
  };

  return (
    <div className="cardBody">
      <ToastContainer position="top-right" />
      <div
        className="tabSection cardHeader borderBtm"
        style={{ paddingBottom: 0 }}
      >
        <CCol md={6}>
          <CNav variant="tabs">
            <CNavItem>
              <CNavLink
                style={{ cursor: "pointer" }}
                active={activeTab === 0}
                onClick={() => {
                  setActiveTab(0);
                }}
              >
                Upload Bank Transaction
              </CNavLink>
            </CNavItem>
            <CNavItem>
              <CNavLink
                style={{ cursor: "pointer" }}
                active={activeTab === 1}
                onClick={() => {
                  setActiveTab(1);
                }}
              >
                Bank Transaction
              </CNavLink>
            </CNavItem>
          </CNav>
        </CCol>
        {activeTab !== 1 ? (
          <div className="">
            <CButton onClick={() => handleOpenModal()}>Import csv</CButton>
          </div>
        ) : (
          ""
        )}
      </div>
      <CCard>
        <CCardBody>
          {activeTab === 0 && (
            <div>
              <UploadTrans
                importFile={importFile}
                setFileImport={setFileImport}
                csvData={csvData}
                setCsvData={setCsvData}
                rowDatas={rowDatas}
                setActiveTab={setActiveTab}
                trascData={trascData}
                openingBalanceData={openingBalanceData}
                finStartDate={finStartDate}
                finEndDate={finEndDate}
                fileDataReverse={fileDataReverse}
              />
            </div>
          )}
          {activeTab === 1 && (
            <div>
              <Transcation />
            </div>
          )}
        </CCardBody>
      </CCard>
      <CCol>
        <CModal
          className="overflowBox"
          alignment="center"
          visible={visible}
          onClose={handleCloseClick}
          aria-labelledby="VerticallyCenteredExample"
          backdrop="static"
        >
          <CModalHeader>
            <CModalTitle id="VerticallyCenteredExample">
              {openingBalanceCheck
                ? "Opening Balance & Financial Year"
                : csvResult === true
                ? ""
                : " Import your bank statement in CSV format"}
            </CModalTitle>
          </CModalHeader>

          <CModalBody>
            {openingBalanceCheck ? (
              <CRow className="mb-2">
                <CCol md={4}>
                  <CFormInput
                    type="text"
                    name="openningBalance"
                    placeholder="Enter Opening Balance"
                    value={openingBalance}
                    label={
                      <>
                        Opening Balance <span className="text-danger">*</span>
                      </>
                    }
                    onChange={handleChange}
                  />
                  {openingErr && (
                    <span style={{ color: "red" }}>{openingErr}</span>
                  )}
                </CCol>

                <CCol md={6}>
                  <CFormSelect
                    label={
                      <>
                        Financial Year <span className="text-danger">*</span>
                      </>
                    }
                    onChange={handleYearChange}
                    name="financialYear"
                    value={selectedYear}
                  >
                    <option value="">Select financial year</option>
                    {generateFinancialYears()?.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.title}
                      </option>
                    ))}
                  </CFormSelect>
                  {financialErr && (
                    <span style={{ color: "red" }}>{financialErr}</span>
                  )}
                </CCol>

                <CCol md={2} mt={20}>
                  <br />
                  <CButton type="submit" onClick={handleSave}>
                    Proceed
                  </CButton>
                </CCol>
              </CRow>
            ) : csvResult === true ? (
              <h5>
                {diffInDays ? <> We noticed a {diffInDays}-day gap between the last recorded
                transaction and the transaction you are currently uploading. Are
                you sure you want to proceed?</> : 

                <> Please confirm is this for financial year [{finStartDate} -{" "}
                {finEndDate}]</>}
                <CRow>
                  <CCol>
                    <br />
                    <CButton onClick={handleYes}>Yes</CButton>&nbsp;
                    <CButton ml={4} onClick={handleNo}>
                      No
                    </CButton>
                  </CCol>
                </CRow>
              </h5>
            ) : (
              <FileUpload
                handleCloseClick={handleCloseClick}
                callBackFile={callBackFile}
                callBack={callBack}
                hanldeExcelResult={hanldeExcelResult}
                setFinStartDate={setFinStartDate}
                setFinEndDate={setFinEndDate}
                trascData={transData}
                openingBalanceData={openingBalanceData}
                setFileDataReverse={setFileDataReverse}
              />
            )}
          </CModalBody>
        </CModal>
      </CCol>
    </div>
  );
};

export default List;
