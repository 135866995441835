import React, { useState, useEffect,useMemo } from 'react'
import { CCard, CImage, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react';
import { capital, creditors, debitor, disInsurance, expense, money, normal, out, revenue1, splitInsurance } from 'src/components/Images/Images';
import { GetApiwithHeader } from "../../../src/Api/Api";
import { userAttributes } from "../../share/localData";

const Defination = (props) => {
    const codeInfo = props?.codeInfo
    console.log('codeInfo?.bankEntryCodingResponseDtos', codeInfo?.bankEntryCodingResponseDtos[0]?.businessCodeId, codeInfo)
    const [codeId, setCodeId] = useState()
    const { cmpID, bankID, companyName } = userAttributes();
    const accessToken = localStorage.getItem("token");
    const headers = useMemo(
        () => ({
          Authorization: `Bearer ${accessToken}`,
        }),
        [accessToken]
      );

    // const getId = async () => {
    //     try {
    //         const response = await GetApiwithHeader(`api/BusinessCodes?companyId=${cmpID}/${codeInfo?.bankEntryCodingResponseDtos[0]?.businessCodeId}`, headers);
    //         console.log('response', response)
    //         setCodeId(response?.data?.data);
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    // useEffect(() => {
    //     getId();
    // }, [cmpID]);

    return (
        <div className="definationTable">
            <CCard className='p-0'>
                <CTable className='m-0'>
                    {codeInfo?.bankEntryCodingResponseDtos?.map((item, index) => {
                        // if (index === 0) {
                            return (
                                <>
                                    {console.log('item', item)}
                                    <CTableHead>
                                        <CTableRow >
                                            <CTableDataCell>Code Name :</CTableDataCell>
                                            <CTableDataCell>{item.businessCodeName}</CTableDataCell>
                                        </CTableRow>
                                    </CTableHead>
                                    <CTableBody>
                                        <CTableRow>
                                            <CTableDataCell>{item?.entryType === "In" ? <CImage src={money} alt="revenue1 Icon" /> :
                                                <span><CImage src={out} alt="revenue1 Icon" /> </span>
                                            } Entry Type</CTableDataCell>
                                            <CTableDataCell>{item?.entryType}</CTableDataCell>
                                        </CTableRow>
                                        <CTableRow>
                                            <CTableDataCell>
                                                <span><CImage src={capital} alt="revenue1 Icon" /> </span> Is it a capital</CTableDataCell>
                                            <CTableDataCell>{item?.categoryA === "Capital" ? "Yes" : "No"}</CTableDataCell>
                                        </CTableRow>
                                        <CTableRow>
                                            <CTableDataCell> <span></span> Is it a plant item/vehicle?</CTableDataCell>
                                            <CTableDataCell>{item?.isAsset ? "Yes" : "No"}</CTableDataCell>
                                        </CTableRow>
                                        <CTableRow>
                                            <CTableDataCell><span></span> Is the item</CTableDataCell>
                                            <CTableDataCell>{item?.itemType}</CTableDataCell>
                                        </CTableRow>
                                        <CTableRow>
                                            <CTableDataCell>  {item?.isInsurance ? (
                                                <span> <CImage src={splitInsurance} alt="revenue1 Icon" /></span>
                                            ) : (
                                                <span> <CImage src={disInsurance} alt="revenue1 Icon" /></span>
                                            )} Is insurance required?</CTableDataCell>
                                            <CTableDataCell>{item?.isInsurance ? "Yes" : "No"}</CTableDataCell>
                                        </CTableRow>
                                        <CTableRow>
                                            <CTableDataCell>
                                                <span> <CImage src={revenue1} alt="revenue1 Icon" /> </span> Revenue</CTableDataCell>
                                            <CTableDataCell>{item?.categoryB === 'Revenue' ? "Yes" : 'No'}</CTableDataCell>
                                        </CTableRow>
                                        <CTableRow>
                                            <CTableDataCell>  <span> <CImage src={expense} alt="revenue1 Icon" /> </span> Expense</CTableDataCell>
                                            <CTableDataCell>{item?.categoryB === "Expense" ? "Yes" : 'No'}</CTableDataCell>
                                        </CTableRow>
                                        <CTableRow>
                                            <CTableDataCell> <span><CImage src={creditors} alt="revenue1 Icon" /> </span> Creditor</CTableDataCell>
                                            <CTableDataCell>{item?.categoryC === "Creditor" ? "Yes" : 'No'}</CTableDataCell>
                                        </CTableRow>
                                        <CTableRow>
                                            <CTableDataCell> <span><CImage src={debitor} alt="revenue1 Icon" /> </span> Debtor</CTableDataCell>
                                            <CTableDataCell>{item?.categoryC === "Debtor" ? "Yes" : 'No'}</CTableDataCell>
                                        </CTableRow>
                                        <CTableRow>
                                            <CTableDataCell> <span> <CImage src={normal} alt="revenue1 Icon" /> </span> GST</CTableDataCell>
                                            <CTableDataCell>{item?.gst}</CTableDataCell>
                                        </CTableRow>
                                    </CTableBody>
                                </>)
                        // }
                    })}
                </CTable>
            </CCard>
        </div>
    )
}
export default Defination