import React, { useState } from "react";
import {
  normal,
  revenue1,
  money,
  debitor,
  defination,
  abn,
  pFile,
  entries,
  debitors,
  creditors,
  out,
} from "src/components/Images/Images";
import { CCol, CImage, CCard, CCardTitle, CRow } from "@coreui/react";
import OffCanvas from "src/share/offCanvas";
import Debitor from "./Debitor";
import Creditor from "./Creditor";
import { expense } from "src/components/Images/Images";
import { capital } from "src/components/Images/Images";
import { splitInsurance } from "src/components/Images/Images";
import { disInsurance } from "src/components/Images/Images";
import Defination from "./Defination";
import Abn from "./Abn";
import Entries from "./Entries";
import File from "./File"

const more = (props) => {
  const [canValue, setCanValue] = useState();
  const [canvasOpen, setCanvasOpen] = useState(false);
  const codeInfo = props?.codeInfo

  const handleCloseClick = () => {
    setCanvasOpen(false);
  }
  return (
    <>
      <div className="menuiconHover">
        {codeInfo.bankEntryCodingResponseDtos.length !== 0 ?
          <CRow>
            <CCol>
              <CCard className="text-center" onClick={() => { setCanValue("Code Information"); setCanvasOpen(true) }}>
                <CImage src={defination} alt="defination Icon" ></CImage>
                <CCardTitle component="p" className="m-0" >
                Information
                </CCardTitle>
              </CCard>
            </CCol>
              {codeInfo?.abn !== "" && codeInfo?.abn !== null? (
            <CCol>
                <CCard className="text-center" onClick={() => { setCanValue("ABN Details"); setCanvasOpen(true) }}>
                  <CImage src={abn} alt="abn Icon"></CImage>
                  <CCardTitle component="p" className="m-0">
                    ABN
                  </CCardTitle>
                </CCard>
            </CCol>
              ) : (''
              )}
            <CCol>
              <CCard className="text-center" onClick={() => { setCanValue("P-File"); setCanvasOpen(true) }}>
                <CImage src={pFile} alt="pFile Icon"></CImage>
                <CCardTitle component="p" className="m-0">
                  P-File
                </CCardTitle>
              </CCard>
            </CCol>
            <CCol>
              <CCard className="text-center" onClick={() => { setCanValue("Entries"); setCanvasOpen(true) }}>
                <CImage src={entries} alt="entries Icon"></CImage>
                <CCardTitle component="p" className="m-0">
                  Entries
                </CCardTitle>
              </CCard>
            </CCol>
            {codeInfo.bankEntryCodingResponseDtos[0]?.categoryC === "Debitor" ?
              <CCol>
                <CCard className="text-center" onClick={() => { setCanValue("Debtor"); setCanvasOpen(true) }}>
                  <CImage src={debitors} alt="debitor Icon"></CImage>
                  <CCardTitle component="p" className="m-0">
                    Debtor
                  </CCardTitle>
                </CCard>
              </CCol> :
              <CCol>
                <CCard className="text-center" onClick={() => { setCanValue("Creditor"); setCanvasOpen(true) }}>
                  <CImage src={creditors} alt="creditors Icon"></CImage>
                  <CCardTitle component="p" className="m-0">
                    Creditors
                  </CCardTitle>
                </CCard>
              </CCol>}
          </CRow> : "Transaction is not coded"}
      </div>

            <hr ></hr>

      <div className="menuNormalIcon">
        <CRow>
          {codeInfo?.bankEntryCodingResponseDtos?.map((item, index) => {
            if (index === 0 && !codeInfo?.bankEntryCodingResponseDtos[0]?.isSplit) {
              return (
                <>
                  <CCol key={index}>
                    <CCard className="text-center">
                      {item?.entryType === "In" ? (
                        <>
                          <CImage src={money} alt="revenue1 Icon" />
                          <CCardTitle component="p" className="m-0">
                            Money
                          </CCardTitle>
                        </>
                      ) : (
                        <>
                          <CImage src={out} alt="revenue1 Icon" />
                          <CCardTitle component="p" className="m-0">
                            Money
                          </CCardTitle>
                        </>
                      )}
                    </CCard>
                  </CCol>
                  <CCol key={index + 1}>
                    <CCard className="text-center">
                      {item?.categoryB === "Revenue" ? (
                        <>
                          <CImage src={revenue1} alt="revenue1 Icon" />
                          <CCardTitle component="p" className="m-0">
                            Revenue
                          </CCardTitle>
                        </>
                      ) : (
                        <>
                          <CImage src={expense} alt="revenue1 Icon" />
                          <CCardTitle component="p" className="m-0">
                            Expense
                          </CCardTitle>
                        </>
                      )}
                    </CCard>
                  </CCol>
                  <CCol key={index + 2}>
                    <CCard className="text-center">
                      {item?.isInsurance ? (
                        <>
                          <CImage src={splitInsurance} alt="revenue1 Icon" />
                          <CCardTitle component="p" className="m-0">
                            Asset
                          </CCardTitle>
                        </>
                      ) : (
                        <>
                          <CImage src={disInsurance} alt="revenue1 Icon" />
                          <CCardTitle component="p" className="m-0">
                            Asset
                          </CCardTitle>
                        </>
                      )}
                    </CCard>
                  </CCol>
                  {item?.gst === "Normal" ? (
                    <CCol key={index + 3}>
                      <CCard className="text-center">
                        <CImage src={normal} alt="normal Icon" />
                        <CCardTitle component="p" className="m-0">
                          Normal
                        </CCardTitle>
                      </CCard>
                    </CCol>
                  ) : null}
                  {item?.categoryA === "Capital" ? (
                    <CCol key={index + 4}>
                      <CCard className="text-center">
                        <CImage src={capital} alt="debitor Icon" />
                        <CCardTitle component="p" className="m-0">
                          Capital
                        </CCardTitle>
                      </CCard>
                    </CCol>
                  ) : null}
                  <CCol key={index + 5}></CCol>
                </>
              );
            } else {
              return null;
            }
          })}
        </CRow>
        <OffCanvas
          Open={canvasOpen}
          title={canValue}
          handleCloseClick={handleCloseClick}
          component={
            canValue === "Code Information" ? (
              <Defination codeInfo={codeInfo} />
            ) : canValue === "ABN Details" ? (
              <Abn codeInfo={codeInfo} />
            ) : canValue === "P-File" ? (
              <File 
              handleCloseClick={handleCloseClick}
              />
            ) : canValue === "Entries" ? (
              <Entries codeInfo={codeInfo} />
            ) : canValue === "Debtor" ? (
              <Debitor codeInfo={codeInfo} />
            ) : canValue === "Creditor" ? (
              <Creditor codeInfo={codeInfo} />
            )
              : (
                ''
              )
          }
        />
      </div>
    </>
  );
};

export default more;
