export const calculateRatePerPeriod = (rpm, duration) => {
    if (rpm == "" || duration == "") return 0.0000;

    const regex = /^(\d+)(\.)?$/;
    // Replace using the regex
    const rpmString = typeof rpm === 'string' ? rpm : String(rpm);
    rpm = rpmString && rpmString?.replace(regex, '$1$20');

    let ratePerPeriod = 0;
    switch (duration) {
      case 'Weekly':
        ratePerPeriod = JSON.parse(rpm) / 52;
        break;
      case 'Monthly':
        ratePerPeriod = JSON.parse(rpm) / 12;
        break;
      case 'Quarterly':
        ratePerPeriod = JSON.parse(rpm) / 4;
        break;
      case 'Yearly':
        ratePerPeriod = JSON.parse(rpm) / 1;
        break;
      default:
        ratePerPeriod = 0;
        break;

    }
    return ratePerPeriod
  };

  export const calculatePaymentDate = (startDate, numPaymentPeriods, durationUnits) => {
    // const { startDate, numPaymentPeriods, durationUnits } = form;
    const paymentDate = new Date(startDate);

    switch (durationUnits) {
      case 'Weekly':
        paymentDate.setDate((paymentDate.getDate() + 7) * JSON.parse(numPaymentPeriods));
        break;
      case 'Monthly':
        paymentDate.setMonth(paymentDate.getMonth() + JSON.parse(numPaymentPeriods));
        break;
      case 'Quarterly':
        paymentDate.setMonth((paymentDate.getMonth() + 3) * JSON.parse(numPaymentPeriods));
        break;
      case 'Yearly':
        paymentDate.setFullYear(paymentDate.getFullYear() + JSON.parse(numPaymentPeriods));
        break;
      default:
        break;
    }
    return paymentDate;
  };

  export const calculatePMT = (rate, nper, pv, fv, type) => {

    var ratePerPeriod = rate / 100;

    if (fv > 0) {
      fv *= -1
    }
    // Calculate PMT
    var pvif = Math.pow((1 + ratePerPeriod), nper); // Present value interest factor
    var pmt;
    if (ratePerPeriod !== 0) {
      pmt = (ratePerPeriod * (pv * pvif + fv)) / (pvif - 1)
      pmt /= (1 + ratePerPeriod * type);
    } else {
      pmt = (pv + fv) / nper;
    }

    return -pmt // Return negative value as PMT in Excel is typically negative

  };

  export const calculateMonthlyPayment = (ratePerAnnum, numPaymentPeriods, presentValue, futureValue, paymentType, durationUnits) => {
    // const { ratePerAnnum, numPaymentPeriods, presentValue, futureValue, paymentType, durationUnits } = form;

    const ratePerPeriod = calculateRatePerPeriod(ratePerAnnum, durationUnits);
    let monthlyPayment;
    if (ratePerPeriod) {
      monthlyPayment = calculatePMT(JSON.parse(ratePerPeriod),
        JSON.parse(numPaymentPeriods),
        JSON.parse(presentValue),
        JSON.parse(futureValue),
        JSON.parse(paymentType));
    }
    return monthlyPayment
  };

  export const calculateFV = (rate, nper, pmt, pv, type) => {
    rate /= 100;
    const pow = Math.pow(1 + rate, nper);
    let fv;

    if (rate) {
      fv = (pmt * ((1 + rate * type) * (1 - pow)) / rate) - pv * pow;
    } else {
      fv = -1 * (pv + pmt * nper);
    }

    return fv;
  };

  export const calculateFVPayout = (ratePerAnnum, numPaymentPeriods, presentValue, futureValue, paymentType, installmentMonth, durationUnits) => {
    //const { ratePerAnnum, numPaymentPeriods, presentValue, futureValue, paymentType, installmentMonth, durationUnits } = form;
    
    const ratePerPeriod = calculateRatePerPeriod(ratePerAnnum, durationUnits);
    const pmt = calculatePMT(ratePerPeriod, numPaymentPeriods, presentValue, futureValue, paymentType);
    const currentMonth = parseFloat(installmentMonth);
    const FVCurrent = calculateFV(ratePerPeriod, currentMonth, pmt, presentValue, paymentType);
    const FVBeginPeriod = calculateFV(ratePerPeriod, currentMonth - 1, pmt, presentValue, paymentType);
    const capital = FVCurrent - FVBeginPeriod;
    const interest = pmt + capital;

    return {'capital':capital, 'interest':interest, 'futureValue':FVCurrent};

    setForm((prevForm) => ({
      ...prevForm,
      fvPayout: FVCurrent.toFixed(2),
      capital: capital.toFixed(2),
      interest: interest.toFixed(2),
    }));
  };