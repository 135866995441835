// import { useState } from "react";
// import {
//   CNav,
//   CNavItem,
//   CNavLink,
//   CCard,
//   CCardBody,
//   CButton,
//   CCol,
//   CRow,
// } from "@coreui/react"; 
// import Lic from "../portfolio/Lic";
// const Portfolio = () => {

//   const [activeTab, setActiveTab] = useState(0);

//   return (
//     <div className="cardBody">
//     <div className="tabSection cardHeader borderBtm" style={{paddingBottom:0}}>
//       <CCol md={6}>
//         <CNav variant="tabs">
//           <CNavItem>
//             <CNavLink
//               style={{ cursor: "pointer" }}
//               active={activeTab === 0}
//               onClick={() => {
//                 setActiveTab(0);
//                 // setSearchQuery("");
//               }}
//             >
//               LIC
//             </CNavLink>
//           </CNavItem>
//           <CNavItem>
//             <CNavLink
//               style={{ cursor: "pointer" }}
//               active={activeTab === 1}
//               onClick={() => {
//                 setActiveTab(1);
//                 // setSearchQuery("");
//               }}
//             >
//              Share
//             </CNavLink>
//           </CNavItem>
//           <CNavItem>
//             <CNavLink
//               style={{ cursor: "pointer" }}
//               active={activeTab === 2}
//               onClick={() => {
//                 setActiveTab(2);
//                 // setSearchQuery("");
//               }}
//             >
//               Property
//             </CNavLink>
//           </CNavItem>
//           <CNavItem>
//             <CNavLink
//               style={{ cursor: "pointer" }}
//               active={activeTab === 3}
//               onClick={() => {
//                 setActiveTab(3);
//                 // setSearchQuery("");
//               }}
//             >
//               Mutual Fund
//             </CNavLink>
//           </CNavItem>
//           <CNavItem>
//             <CNavLink
//               style={{ cursor: "pointer" }}
//               active={activeTab === 4}
//               onClick={() => {
//                 setActiveTab(4);
//                 // setSearchQuery("");
//               }}
//             >
//             Loan
//             </CNavLink>
//           </CNavItem>
//         </CNav>
//       </CCol>
       
//     </div>
//     <CCard>
//       <CCardBody>
//         {activeTab === 0 && (
//           <div>
//             <Lic/>
//           </div>
//         )}
//         {activeTab === 1 && (
//           <div>
//             {/* <Transcation
//             /> */}
//           </div>
//         )}
//       </CCardBody>
//     </CCard>
   
//   </div>
// );
  
// }

// export default Portfolio

import React from 'react'

const Portfolio = () => {
  return (
    <div>Portfolio</div>
  )
}

export default Portfolio