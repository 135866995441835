import React, { useState, useEffect, useMemo } from 'react';
import { userAttributes } from "../../share/localData";
import { toast } from "react-toastify";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  CContainer,
  CRow,
  CCol,
  CFormTextarea,
  CButton,
  CFormLabel,
  CModal,
  CModalHeader,
  CModalBody,
  CTooltip,
  CFormInput
} from "@coreui/react";
import Select from "react-select";
import { postApiWithHeader } from "src/Api/Api";

const supportDetails = (props) => {
  const {rowdata, handleCloseClick, fetchDataCoding} = props
  console.log("rowdata--",rowdata);
  const { cmpID, bankID, role } = userAttributes();
  const [selectedStatus, setSelectedStatus] = useState({});
  const [openImageViewer, setOpenImageViewer] = useState(false);
  const [imageForView, setImageForView] = useState("false");
  const [isVisibleStatusNote, setIsVisibleStatusNote] = useState(true);
  const [imageIndex, setImageIndex] = useState(0);

  const accessToken = localStorage.getItem("token");
  const headersFile = useMemo(
    () => ({
      Authorization: `Bearer ${accessToken}`,
    }),
    [accessToken]
  );

  const options = useMemo(()=>{
    console.log("role--",role);
    if(role === 'BusinessOwner'){
      return [
        {
          value: 'Reopen',
          label: 'Reopen',
          isDisabled: rowdata.supportStatus !== 'Resolved'
        },
        {
          value: 'Closed',
          label: 'Closed',
          isDisabled: rowdata.supportStatus !== 'Resolved'
        },
      ]
    }else{
      return [
        {
          value: 'Resolved',
          label: 'Resolved'
        },
      ]
    }
    return [
      {
        value: 'Raised',
        label: 'Raised'
      },
      {
        value: 'Resolved',
        label: 'Resolved'
      },
      {
        value: 'Reopen',
        label: 'Reopen'
      },
      {
        value: 'Closed',
        label: 'Closed'
      },
    ]
  })

  useMemo(()=>{
    if(rowdata && rowdata.supportStatus){
      setSelectedStatus({value: rowdata.supportStatus === 'Pending' ? 'Raised' : rowdata.supportStatus, label: rowdata.supportStatus === 'Pending' ? 'Raised' : rowdata.supportStatus});
    }
  }, [rowdata]);

  const handleCloseImageViewer = () => {
    setOpenImageViewer(false);
  };

  const handleImagePreview = (index) => {
    const currentIndex = imageIndex + index;
    console.log("currentIndex---",currentIndex);
    setImageIndex(currentIndex);
    setImageForView(rowdata.supportMediaPath[currentIndex]);
  }
  const handleImageViewer = (value, currentIndex) => {
    setImageIndex(currentIndex);
    console.log(value);
    setImageForView(value);
    setOpenImageViewer(true);
  };

  const handleChange = (value)=>{
    setSelectedStatus(value);
  }

  const handleSave = async () => {
    const payload = {};
    try {
      const response = await postApiWithHeader(
        `api/support-tickets/Change-Status/${cmpID}?id=${rowdata?.supportId}&status=${selectedStatus.value}`,
        payload,
        headersFile
      );
      if (response?.data?.statusCode === 100) {
        fetchDataCoding();
        toast.success("Status updated.", { autoClose: 1000, onClose: ()=> { 
          handleCloseClick();
        } });
      }
    } catch (error) {
      console.log("Error:", error);
      toast.error("Error occurred while updating", { autoClose: 1000 });
    }
  };

  const toggleVisibilityStatusNote = () => {
    setIsVisibleStatusNote(!isVisibleStatusNote);
  };

  return (
    <div className="addBox">
      <CContainer>
        <CRow>
          <CCol md="12">
            <CFormInput label="Topic" value={rowdata.requestType} disabled/>
          </CCol>
        </CRow>
        <CRow>
          <CCol md="12">
            <div className="mb-3 mt-3">
              <CFormTextarea
                id="Description"
                label="Description"
                rows={3}
                disabled
              >{rowdata.supportComment}</CFormTextarea>
            </div>
          </CCol>
        </CRow>
        <CRow>
          <CCol md="12">
            {rowdata && rowdata.supportMediaPath.length > 0 && (
              <>
                <CFormLabel>Attachment</CFormLabel>
                  <div className="dropzone-multiple-img">
                  {rowdata.supportMediaPath.map((url, index) => (
                    <div className="thumb" key={index}>
                      <div className="thumbInner">
                        <CTooltip content="Click for preview">
                          <img
                            src={url}
                            className="img"
                            onLoad={() => { URL.revokeObjectURL(url); } }
                            onClick={(e) => handleImageViewer(url, index)} />
                        </CTooltip>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </CCol>
        </CRow>
        <CRow className="mt-1 justify-content-end">
          <CFormLabel>Status</CFormLabel>
          <CCol xs="12">
            <Select
              label="Status"
              className="mb-3"
              onChange={(e) => handleChange(e)}
              name="status"
              value={selectedStatus}
              options={options}
              isSearchable
              placeholder="Select Status"
              isDisabled={rowdata.supportStatus === 'Closed'}
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol className='mt-2 justify-content-end'>
            <div className="infoDescription">
              {isVisibleStatusNote ? (
                <CTooltip content="Info">
                  <div className="bgOrange">
                    <p>
                      <span></span>Resolve- Support ticket completed but not closed, and ticket will remain open.
                    </p>
                    <p>
                      <span></span>Closed-  Support ticket is closed and no more action required.
                    </p>
                    <div className="text-right">
                      <span class="icon-info" onClick={toggleVisibilityStatusNote}></span>
                    </div>
                  </div>
                </CTooltip>
              ) : (
                  <div className="text-right">
                    <CTooltip content="Info">
                        <span class="icon-info" onClick={toggleVisibilityStatusNote}></span>
                    </CTooltip>
                  </div>
              )}
            </div>
          </CCol>
        </CRow>
        <CRow className="mt-2 justify-content-end">
          <CCol xs="auto">
            <CButton color="primary" onClick={handleSave}>
              Save
            </CButton>
          </CCol>
        </CRow>
        {/* Model for preview the image */}
        <CModal
          className={"image-viewer modal overflowBox large"}
          visible={openImageViewer}
          onClose={handleCloseImageViewer}
        >
          <CModalHeader>
            <h3>Image Preview </h3>
          </CModalHeader>
          <CModalBody>
            <div className="imagePreviewButton">
              <button onClick={()=>handleImagePreview(-1)} disabled={imageIndex === 0}>
                <ChevronLeftIcon />
              </button>
              <button onClick={()=>handleImagePreview(1)} disabled={imageIndex === rowdata.supportMediaPath.length - 1}>
                <ChevronRightIcon />
              </button>
            </div>
            <div className="text-center">
              <img
                className={"image-viewer img"}
                src={imageForView}
                alt="asd"
              />
            </div>
          </CModalBody>
        </CModal>
      </CContainer>
    </div>
  )
}

export default supportDetails