import React, { useEffect, useMemo, useState, useRef, Fragment } from "react";
import {
  CCol,
  CForm,
  CRow,
  CFormCheck,
  CButton,
  CFormInput,
  CImage,
  CCard,
  CCardTitle,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
} from "@coreui/react";
import { postApiWithHeader } from "src/Api/Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { userAttributes } from "../../share/localData";
import {
  normal,
  revenue1,
  money,
  debitor,
  out,
  disInsurance,
  capital,
  expense,
  splitInsurance,
  creditors,
  moneyout1

} from "src/components/Images/Images";
import Select from "react-select";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import Creditor from "./Creditor";
import Debitor from "./Debitor";
const Split = (props) => {
  const { codeData, bankTransactionCode, CodedValue, nowBas } = props;
  const [selectedSplit, setSelectedSplit] = useState("2");
  const [selectedPerSplit, setSelectedPerSplit] = useState("Dollar");
  const [splitAmounts, setSplitAmounts] = useState([]);
  const [splitPercents, setSplitPercents] = useState([]);
  const [gstAmount, setGstAmount] = useState("");
  const [saveSplit, setSaveSplit] = useState(false);
  const accessToken = localStorage.getItem("token");
  const { cmpID } = userAttributes();
  const companyId = cmpID;
  const [businessCode, setBusinessCode] = useState([]);
  const [selectedOptionCode, setSelectedOptionCode] = useState([]);
  const [isSplitEntry, setIsSplitEntry] = useState(true);
  const isManual = useRef([0, 0]);
  const [gstAmounts, setGstAmounts] = useState([]);
  const [gstErrors, setGstErrors] = useState([]);
  const isManualAltrGstAmt = useRef([0, 0, 0, 0]);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const codeInfo = props?.codeInfo

  const headers = useMemo(
    () => ({
      Authorization: `Bearer ${accessToken}`,
    }),
    [accessToken]
  );

  const calculatePercent = (totalAmt, amounts) => {
    return amounts.map(amount => ((parseFloat(amount).toFixed(2) / totalAmt) * 100).toFixed(2));
  }
  const calculateAmount = (totalAmt, percentages) => {
    return percentages.map(percentage => ((parseFloat(percentage) / 100) * totalAmt).toFixed(2));
  }

  useEffect(() => {
    if (bankTransactionCode?.bankEntryCodingResponseDtos
      && bankTransactionCode?.bankEntryCodingResponseDtos?.length > 0
      && bankTransactionCode?.bankEntryCodingResponseDtos[0]?.isSplit
    ) {
      /** Set All Intitial Value */
      const split = bankTransactionCode?.bankEntryCodingResponseDtos?.length.toString();
      setSelectedSplit(split);
      let splitDefinition;
      if (bankTransactionCode?.bankEntryCodingResponseDtos[0]?.splitDefinition === "Dollar" || bankTransactionCode?.bankEntryCodingResponseDtos[0]?.splitDefinition === "Percentage") {
        splitDefinition = bankTransactionCode?.bankEntryCodingResponseDtos[0]?.splitDefinition;
      } else {
        splitDefinition = JSON.parse(bankTransactionCode?.bankEntryCodingResponseDtos[0]?.splitDefinition);
        if (splitDefinition?.splitType === "$") {
          splitDefinition = "Dollar";
        } else {
          splitDefinition = "Percentage";
        }
      }
      setSelectedPerSplit(splitDefinition);

      const splitAmount = bankTransactionCode?.bankEntryCodingResponseDtos.map((item) => {
        return parseFloat(item.splitAmount).toFixed(2);
      });
      setSplitAmounts(splitAmount);
      isManual.current = Array.from({ length: split }, () => 0);
      // console.log("calculatePercent(props.amount, splitAmount)---",calculatePercent(props.amount, splitAmount));
      setSplitPercents(calculatePercent(props.amount, splitAmount))

      /**Set bussines code */
      let businessCd = [];
      const selectedBussinesCode = bankTransactionCode?.bankEntryCodingResponseDtos?.map((item) => {
        businessCd.push(codeData.find((i) => i?.id === item.businessCodeId))
        return { label: item.businessCodeName, value: item.businessCodeId }
      });
      setSelectedOptionCode(selectedBussinesCode);
      setBusinessCode(businessCd);

      const gstAmt = bankTransactionCode?.bankEntryCodingResponseDtos?.map((item) => item.gstAmount);
      setGstAmounts(gstAmt);
    } else {
      if (props.amount) {
        setSplitAmounts(Array.from({ length: 2 }, () => props.amount / 2));
        setSplitPercents(Array.from({ length: 2 }, () => 100 / 2));
      }
      setSelectedSplit("2");
      setSelectedPerSplit("Dollar");
      isManual.current = [0, 0];
      setSelectedOptionCode([]);
      setGstAmounts([0, 0]);
      setBusinessCode([]);
    }
  }, [props.amount, bankTransactionCode]);

  const handleSplitSave = async (e) => {
    e.preventDefault();
    let payload = [];
    splitAmounts.forEach((item, index) => {
      const splitDefinition = {
        amount: props.amount,
        splitType: selectedPerSplit === "Dollar" ? '$' : '%',
        splitCount: splitAmounts.length,
        split1_percent: splitPercents[0] ? splitPercents[0] : 0,
        split2_percent: splitPercents[1] ? splitPercents[1] : 0,
        split3_percent: splitPercents[2] ? splitPercents[2] : 0,
        split4_percent: splitPercents[3] ? splitPercents[3] : 0,

        split1_amount: splitAmounts[0] ? splitAmounts[0] : 0,
        split2_amount: splitAmounts[1] ? splitAmounts[1] : 0,
        split3_amount: splitAmounts[2] ? splitAmounts[2] : 0,
        split4_amount: splitAmounts[3] ? splitAmounts[3] : 0,
      }
      const abc = {
        transactionCode: bankTransactionCode?.transactionCode,
        businessCodeId: businessCode[index]?.id,
        splitDefinition: JSON.stringify(splitDefinition), //selectedPerSplit,
        splitAmount: item,
        gstAmount: gstAmounts[index],
        IsGSTAltered: isManualAltrGstAmt.current[index] ? true : false,
        netAmount: NetValueCalculation(
          splitAmounts[index],
          businessCode[index]?.gst,
          gstAmounts[index]
        ),
        receiptId: 0,
        narration: '', // bankTransactionCode?.narration
        isVerificationPending: bankTransactionCode?.isVerificationPending,
        isInsurance: businessCode[index]?.isInsurance,
      };
      payload.push(abc);
    });
    if (!isSplitEntry) {
      payload = [payload[0]];
    }
    try {
      const response = await postApiWithHeader(
        `/api/bankentrycodings/create/${companyId}`,
        payload,
        headers
      );

      if (response?.data?.statusCode === 100) {
        toast.success("Split Successfully", { autoClose: 1000, onClose: () => (props?.handleCloseClick()) });
        setIsSplitEntry(true);
        props?.fetchDataCoding();
      } else if (response?.data?.statusCode === 102) {
        toast.error("Please provide code for all splits.", { autoClose: 1000 });
      }
    } catch (error) {
      toast.warning(response?.data?.message, { autoClose: 1000 });
    }
  };

  const handleSplit = async (e) => {
    e.preventDefault();
    setSaveSplit(true);
  };

  /**Execute when we changes the Preference (Dollar, Percentage) */
  const handleChangePerfrenceSplit = (event, value) => {
    setSelectedPerSplit(value);
    const updatedSplitPercents = Array.from({ length: splitAmounts.length }, () =>
      (100 / splitAmounts.length).toFixed(2)
    );
    setSplitPercents(updatedSplitPercents)
    const updatedSplitAmount = Array.from({ length: splitAmounts.length }, () =>
      (props.amount / splitAmounts.length).toFixed(2)
    );
    setSplitAmounts(updatedSplitAmount);
    isManual.current = isManual.current.map((item) => 0);
  };

  /**Execute when we changes the Split (2,3,4) */
  const handleChangeSplit = (event, value) => {
    setSelectedSplit(value);
    const count = parseInt(value);
    const updatedSplitPercents = Array.from({ length: count }, () =>
      (100 / count).toFixed(2)
    );
    setSplitPercents(updatedSplitPercents);
    const updatedSplitAmount = Array.from({ length: count }, () =>
      (props.amount / count).toFixed(2)
    );
    setSplitAmounts(updatedSplitAmount);
    isManual.current = Array.from({ length: count }, () => 0);

    /**Calculate gst */
    if (updatedSplitAmount.length > 0 && businessCode.length > 0) {
      const gstAmts = updatedSplitAmount.map((amt, idx) => GstValueCalulation(parseFloat(amt), businessCode[idx]?.gst));
      setGstAmounts(gstAmts);
    }
  };

  /**Execute when we change in split amount field */
  const handleAmountSplit = (e, index) => {
    const amount = e.target.value;
    const regex = /^(?:\d+(\.\d*)?)?$/;
    if (!regex.test(amount)) return;
    let totalAmt = parseFloat(e.target.value);
    let totalPercent = parseFloat(e.target.value);
    if (totalAmt === 0) return;
    splitAmounts.map((item, idx) => {
      if (idx === splitAmounts.length - 1) return 0;
      if (index !== idx) totalAmt = totalAmt + parseFloat(item)
    })
    splitPercents.map((item, idx) => {
      if (idx === splitPercents.length - 1) return 0;
      if (index !== idx) totalPercent = totalPercent + parseFloat(item)
    })
    console.log("totalAmt---", totalAmt);
    if (selectedPerSplit === "Dollar" && props.amount <= totalAmt) {
      // setSplitAmounts(splitAmounts.map((item, idx) => (index === idx ? 0 : parseFloat(item).toFixed(2))));
      return;
    }
    if (selectedPerSplit === "Percentage" && 100 <= totalPercent) {
      // setSplitPercents(splitPercents.map((item, idx) => (index === idx ? 0 : parseFloat(item).toFixed(2))));
      return;
    }
    isManual.current[index] = 1;
    const isManualArrayZero = isManual.current.filter((i) => i === 0);
    if (selectedPerSplit === "Percentage") {
      let amountToBeMinus = amount ? parseFloat(amount) : 0;
      for (let i = 0; i < splitPercents.length; i++) {
        if (isManual.current[i] === 1 && i !== index) {
          amountToBeMinus = amountToBeMinus + parseFloat(splitPercents[i]);
        }
      }
      let updatedPercents = 100 - amountToBeMinus;
      const updatedSplitPercents = splitPercents.map((item, idx) => {
        if (idx === index) {
          return amount;
        } else {
          if (isManual.current[idx] === 1) {
            return parseFloat(item).toFixed(2);
          }
        }
        return parseFloat(updatedPercents / isManualArrayZero.length).toFixed(2);
      });
      setSplitPercents(updatedSplitPercents);
      const splAmt = calculateAmount(props.amount, updatedSplitPercents);
      if (splAmt.length === businessCode.length) {
        const gstAmts = businessCode.map((code, idx) => GstValueCalulation(splAmt[idx], code?.gst));
        setGstAmounts(gstAmts);
      }
      setSplitAmounts(splAmt);
    } else {
      let amountToBeMinus = amount ? parseFloat(amount) : 0;
      for (let i = 0; i < splitAmounts.length; i++) {
        if (isManual.current[i] === 1 && i !== index) {
          amountToBeMinus = amountToBeMinus + parseFloat(splitAmounts[i]);
        }
      }
      let updatedAmount = props.amount - amountToBeMinus;
      const updatedSplitAmount = splitAmounts.map((item, idx) => {
        if (idx === index) {
          return amount;
        } else {
          if (isManual.current[idx] === 1) {
            return parseFloat(item).toFixed(2);
          }
        }
        return parseFloat(updatedAmount / isManualArrayZero.length).toFixed(2);
      });
      if (updatedSplitAmount.length === businessCode.length) {
        const gstAmts = businessCode.map((code, idx) => GstValueCalulation(parseFloat(updatedSplitAmount[idx]), code?.gst));
        setGstAmounts(gstAmts);
      }
      setSplitAmounts(updatedSplitAmount);
      setSplitPercents(calculatePercent(props.amount, updatedSplitAmount));
    }
  };

  useEffect(() => {
    setSaveSplit(props?.saveSplit);
  }, [props?.saveSplit]);

  const handleSplitEntryChange = (e) => {
    if (businessCode.length > 0) {
      const gstAmts = businessCode.map((code, idx) => GstValueCalulation(e.target.checked ? splitAmounts[idx] : props.amount, code?.gst));
      setGstAmounts(gstAmts);
    }
    setIsSplitEntry(e.target.checked);
    setSaveSplit(false);
  };

  const handleChange = (e, index) => {
    console.log("index--", index);
    const selectedOption = codeData.find((item) => item?.codeName === e.value);
    const updatedCodeVal = [...businessCode];
    updatedCodeVal[index] = selectedOption;
    setBusinessCode(updatedCodeVal);
    const updatedselectedOptionCode = [...selectedOptionCode];
    updatedselectedOptionCode[index] = e;
    setSelectedOptionCode(updatedselectedOptionCode);
    const gstAmt = GstValueCalulation(isSplitEntry ? splitAmounts[index] : props.amount, updatedCodeVal[index]?.gst);
    if (gstAmounts.length === splitAmounts.length) {
      setGstAmounts([...gstAmounts.map((i, idx) => (index === idx ? gstAmt : i))]);
    } else {
      const newGstAmtArr = Array.from({ length: splitAmounts.length }, () => 0);
      setGstAmounts([...newGstAmtArr.map((i, idx) => (index === idx ? gstAmt : i))]);
    }
  };
  const options = codeData &&
    codeData?.map((item) => ({
      value: item.codeName,
      label: item.codeName,
      gst: item.gst,
    }));

  const optionsIn = codeData?.filter((itm) => itm.entryType === 'In').map((item) => ({
    value: item.id,
    label: item.codeName,
    isInsurance: item.isInsurance,
    gst: item.gst,
  }));

  const optionsOut = codeData?.filter((itm) => itm.entryType === 'Out').map((item) => ({
    value: item.id,
    label: item.codeName,
    isInsurance: item.isInsurance,
    gst: item.gst,
  }));

  const handleCardClick = (value) => {
    setModalContent(value);
    setShowModal(true);
  };

  const codeDropDown = (index) => {
    let amt = bankTransactionCode && bankTransactionCode?.amountSave;
    let opt = options;
    if (amt > 0 && nowBas === 'nowBas') {
      opt = optionsIn;
    }
    if (amt < 0 && nowBas === 'nowBas') {
      opt = optionsOut;
    }
    const sortedOpt = opt.sort((a, b) => a.label.localeCompare(b.label));

    return (
      <>
        <div className="d-flex">
          <div className="code--select">
            <Select
              label="Code"
              className="mb-3"
              onChange={(e) => handleChange(e, index)}
              name="code"
              defaultValue=""
              value={
                selectedOptionCode?.length !== 0
                  ? // && selectedOptionCode[index] !== undefined
                  selectedOptionCode[index]
                  : selectedOptionCode
              }
              options={sortedOpt}
              isSearchable
              placeholder="Select code name"
            />
            {renderEntryDetails(index)}
          </div>
        </div>
      </>
    );
  };

  const renderInputGroups = () => {
    const count = parseInt(selectedSplit);
    const inputs = [];

    for (let i = 0; i < count; i++) {
      inputs.push(
        <CCol key={i} md="auto" sm="12">
          <div className="inputW">
            <CFormInput
              type="text"
              name={`amount${i + 1}1`}
              placeholder={`Enter amount ${i + 1}`}
              onChange={(e) => handleAmountSplit(e, i)}
              value={selectedPerSplit === "Dollar" ? splitAmounts[i] : splitPercents[i]}
              disabled={i === count - 1}
            />
          </div>
        </CCol>
      );
    }

    return inputs;
  };

  const renderEntryDetails = (index) => {
    if (!businessCode || !businessCode[index] || !selectedOptionCode || !selectedOptionCode[index]) return null;

    const item = businessCode[index];
    console.log('item', item)
    return (
      <Fragment key={index}>
        <CRow>
          <CCol md='3'>
            <CCard className="text-center">
              {item?.entryType === "In" ? (
                <CImage src={money} alt="Money Icon" />
              ) : (
                <CImage src={moneyout1} alt="Out Icon" />
              )}
              <CCardTitle component="p" className="m-0">
                Money
              </CCardTitle>
            </CCard>
          </CCol>
          {
            item?.isAsset ? 
            <CCol md='3'>
            <CCard className="text-center">
              {item?.isInsurance ? (
                <CImage src={splitInsurance} alt="Split Insurance Icon" />
              ) : (
                <CImage src={disInsurance} alt="Dis Insurance Icon" />
              )}
              <CCardTitle component="p" className="m-0">
                Asset
              </CCardTitle>
            </CCard>
          </CCol> : ''
          }
          
          {item?.categoryB !== "None" && (
              <CCol md='3'>
              <CCard className="text-center">
                {item?.categoryB === "Revenue" ? (
                  <CImage src={revenue1} alt="Revenue Icon" />
                ) : (
                  <CImage src={expense} alt="Expense Icon" />
                )}
                <CCardTitle component="p" className="m-0">
                  {item?.categoryB === "Revenue" ? "Revenue" : "Expense"}
                </CCardTitle>
              </CCard>
            </CCol>
          )}
          {item?.value !== 'None' && (
              <CCol md='3'>
              <CCard className="text-center" onClick={() => handleCardClick(item?.value)}>
                {item?.value === 'Debtor' ? (
                  <CImage src={debitor} alt="Money Icon" />
                ) : (
                  <CImage src={creditors} alt="Out Icon" />
                )}
                <CCardTitle component="p" className="m-0">
                  {item?.value === 'Debtor' ? 'Debitor' : 'Creditor'}
                </CCardTitle>
              </CCard>
            </CCol>
          )}
          <CModal
            visible={showModal}
            onClose={() => setShowModal(false)}
          >
            <CModalHeader closeButton>
              {modalContent === 'Debitor' ? 'Debitor Details' : 'Creditor Details'}
            </CModalHeader>
            <CModalBody>
              {modalContent === 'Debitor' ? <Debitor /> : <Creditor />}
            </CModalBody>
            <CModalFooter>
              <CButton color="secondary" onClick={() => setShowModal(false)}>
                Close
              </CButton>
              
            </CModalFooter>
          </CModal>
          {item?.gst === "Normal" && (
             <CCol md='3'>
              <CCard className="text-center">
                <CImage src={normal} alt="Normal Icon" />
                <CCardTitle component="p" className="m-0">
                  Normal
                </CCardTitle>
              </CCard>
            </CCol>
          )}
          {item?.categoryA === "Capital" && (
            <CCol>
              <CCard className="text-center">
                <CImage src={capital} alt="Capital Icon" />
                <CCardTitle component="p" className="m-0">
                  Capital
                </CCardTitle>
              </CCard>
            </CCol>
          )}
        </CRow>
      </Fragment>
    );
  };

  let GstCalValue;
  function GstValueCalulation(splitAmounts, businessCode) {
    const dividedValue =
      Math.round(
        (splitAmounts?.splitAmount ? splitAmounts?.splitAmount : splitAmounts) *
        100
      ) / 100;
    if (businessCode === "Normal") {
      const minVal = dividedValue / 11;
      GstCalValue = minVal.toFixed(2);
    } else {
      GstCalValue = 0;
    }
    return GstCalValue;
  }

  const handleGstChange = (e, index) => {
    const { value } = e.target;
    const regex = /^[0-9]*\.?[0-9]*$/;
    if (!regex.test(value)) {
      return;
    }
    let newGstAmounts = [...gstAmounts];
    newGstAmounts = newGstAmounts.map((i, idx) => idx === index ? value : i);
    setGstAmounts(newGstAmounts);
    isManualAltrGstAmt.current[index] = 1;
    const netValue = parseFloat(
      NetValueCalculation(splitAmounts[index], businessCode[index]?.gst)
    );
    const gstValue = parseFloat(value);

    if (isNaN(gstValue) || gstValue < 0) {
      setGstErrors((prevErrors) => {
        const newErrors = [...prevErrors];
        newErrors[index] = "Invalid GST amount";
        return newErrors;
      });
    } else if (gstValue > netValue) {
      setGstErrors((prevErrors) => {
        const newErrors = [...prevErrors];
        newErrors[index] = "GST amount cannot exceed net value";
        return newErrors;
      });
    } else {
      setGstErrors((prevErrors) => {
        const newErrors = [...prevErrors];
        newErrors[index] = "";
        return newErrors;
      });
    }
  };

  function NetValueCalculation(splitAmounts, businessCode, gstValue) {
    splitAmounts = isSplitEntry ? splitAmounts : props.amount;
    const dividedValue =
      Math.round(
        (splitAmounts?.splitAmount ? splitAmounts?.splitAmount : splitAmounts) *
        100
      ) / 100;
    let calculatedValue;
    if (businessCode === "Normal" && gstValue) {
      calculatedValue =
        (splitAmounts?.splitAmount ? splitAmounts?.splitAmount : splitAmounts) -
        parseFloat(gstValue); // Parse gstValue
    } else {
      calculatedValue = dividedValue;
    }
    return parseFloat(calculatedValue).toFixed(2);
  }

  const callBack = (file) => { };
  return (
    <div className="canvasCardBox">
      <CRow>
        <CCol md="auto" sm="12" className="me-3 mt-1">
          <div className="mb-3">
            <CFormCheck
              className="mb-2"
              id="flexCheckDefault6"
              label="Is it a split entry?"
              checked={isSplitEntry}
              onChange={handleSplitEntryChange}
            />
          </div>
        </CCol>
      </CRow>
      {isSplitEntry ? (
        <CForm onSubmit={handleSplit}>
          <div className="splitCard">
            <>
              <CRow>
                <CCol md="auto" sm="12" className="me-3 mt-1">
                  <div className="wrapRow">
                    <span>Total GST Amount</span>
                    <div>
                      <CFormInput
                        // value={newItemText}
                        // onChange={handleInputChange}
                        placeholder="Enter GST"
                      // label="Total GST Amount"
                      />
                    </div>
                  </div>
                </CCol>
              </CRow>
              <CRow>
                <div className="wrapRow">
                  <span className="spacer">Select Split</span>
                  {[2, 3, 4].map((number) => (
                    <CCol key={number} md="auto" sm="12" className="me-3 mt-1">
                      <div className="mb-3">
                        <CFormCheck
                          key={number}
                          type="radio"
                          className="mb-3"
                          id={`flexRadioDefault${number}`}
                          label={number.toString()}
                          onChange={(event) =>
                            handleChangeSplit(event, number.toString())
                          }
                          checked={selectedSplit === number.toString()}
                        />
                      </div>
                    </CCol>
                  ))}
                </div>
              </CRow>
              <CRow>
                <div className="wrapRow">
                  <span className="spacer">Preference</span>
                  {["Dollar", "Percentage"].map((option) => (
                    <CCol key={option} md="2" sm="12" className="mt-1">
                      <div>
                        <CFormCheck
                          type="radio"
                          className="mb-3"
                          id={`flexCheckDefault${option}`}
                          label={option}
                          onChange={(event) =>
                            handleChangePerfrenceSplit(event, option)
                          }
                          checked={selectedPerSplit === option}
                        />
                      </div>
                    </CCol>
                  ))}
                </div>
              </CRow>
              <CRow className="wrapRow">
                <CCol md="auto">
                  <span className="spacer">Split Amount</span>
                </CCol>
                {renderInputGroups()}
              </CRow>
              <CButton className="mt-3" type="submit">
                Split
              </CButton>{" "}
            </>
          </div>
        </CForm>
      ) : (
        <CForm onSubmit={handleSplitSave}>
          <>
            <div className="splitCardAc">
              <Accordion key={0} defaultExpanded>
                <AccordionDetails>
                  <CRow>
                    <CCol md="12" sm="12">
                      <div className="mb-3">
                        {codeDropDown(0)}
                      </div>
                    </CCol>
                    <CCol md="6" sm="12">
                      <div className="mb-3">
                        <CFormInput
                          type="text"
                          name="value"
                          placeholder="Enter value amount"
                          label="Value $"
                          disabled
                          value={NetValueCalculation(
                            splitAmounts[0],
                            businessCode[0]?.gst,
                            gstAmounts[0]
                          )}
                        />
                      </div>
                    </CCol>
                    <CCol md="6" sm="12">
                      <div className="mb-3">
                        <CFormInput
                          type="text"
                          name="gst"
                          placeholder="Enter GST Amount"
                          label="Gst Amount $"
                          value={gstAmounts[0] || ""}
                          disabled={businessCode[0]?.gst !== "Normal"}
                          onChange={(e) => handleGstChange(e, 0)}
                          onKeyDown={(e) =>
                            e.key === "Enter" && e.preventDefault()
                          }
                        />
                        {gstErrors[0] && (
                          <div className="text-danger">
                            {gstErrors[0]}
                          </div>
                        )}
                      </div>
                    </CCol>
                  </CRow>
                </AccordionDetails>
                <AccordionActions />
              </Accordion>
            </div>
          </>
          <CButton className="mt-2" type="submit">
            Save
          </CButton>
        </CForm>
      )}
      {saveSplit ? (
        <>
          <div>
            <CForm onSubmit={handleSplitSave}>
              {Array.from({ length: selectedSplit }, (_, index) => (
                <>
                  <div className="splitCardAc">
                    <Accordion key={index} defaultExpanded>
                      <AccordionSummary
                        expandIcon={<GridExpandMoreIcon />}
                        aria-controls={`panel${index + 1}-content`}
                        id={`panel${index + 1}-header`}
                      >
                        Split Amount {index + 1} :
                        {Math.round(
                          (splitAmounts[index]?.splitAmount
                            ? splitAmounts[index]?.splitAmount
                            : splitAmounts[index]) * 100
                        ) / 100}{" "}
                        $
                      </AccordionSummary>
                      <AccordionDetails>
                        <CRow>
                          <CCol md="12" sm="12">
                            <div className="mb-3">
                              {codeDropDown(index ? index : 0)}
                            </div>
                          </CCol>
                          <CCol md="6" sm="12">
                            <div className="mb-3">
                              <CFormInput
                                type="text"
                                name="value"
                                placeholder="Enter value amount"
                                label="Value $"
                                disabled
                                value={NetValueCalculation(
                                  splitAmounts[index],
                                  businessCode[index]?.gst,
                                  gstAmounts[index]
                                )}
                              />
                            </div>
                          </CCol>
                          <CCol md="6" sm="12">
                            <div className="mb-3">
                              <CFormInput
                                type="text"
                                name="gst"
                                placeholder="Enter GST Amount"
                                label="Gst Amount $"
                                value={gstAmounts[index] || ""}
                                disabled={businessCode[index]?.gst !== "Normal"}
                                onChange={(e) => handleGstChange(e, index)}
                                onKeyDown={(e) =>
                                  e.key === "Enter" && e.preventDefault()
                                }
                              />
                              {gstErrors[index] && (
                                <div className="text-danger">
                                  {gstErrors[index]}
                                </div>
                              )}
                            </div>
                          </CCol>
                        </CRow>
                      </AccordionDetails>
                      <AccordionActions />
                    </Accordion>
                  </div>
                </>
              ))}
              <CButton className="mt-2" type="submit">
                Save
              </CButton>
            </CForm>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default Split;
