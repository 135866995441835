import React, { useMemo, useEffect, useState, useRef } from "react";
import {
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CButton,
  CTableHead,
  CTableRow,
  CCol,
  CForm,
  CFormSelect,
  CRow,
  CFormLabel,
  CFormCheck,
  CFormInput,
  CModalHeader,
  CModalFooter,
  CModal,
} from "@coreui/react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import Form from "react-bootstrap/Form";
import {
  GetApiwithHeader,
  postApiWithHeader,
  putApiWithHeader,
} from "src/Api/Api";
import { userAttributes } from "../../share/localData";
import Select from "react-select";
import Checkbox from "@mui/material/Checkbox";
import { toast, ToastContainer } from "react-toastify";
import { useData } from "../../components/dataContext";
import AutocompleteComponent from "src/components/AutoComplete";

const AddRule = (props) => {
  const { data } = useData();
  const { narration } = props;
  const highFreqRuleData = props?.highFreqRuleData;
  const [selectedNarration, setSelectedNarration] = useState(null);
  const accessToken = localStorage.getItem("token");
  const { cmpID, bankID } = userAttributes();
  const [codeData, setCodeData] = useState([]);
  const [selectedSplit, setSelectedSplit] = useState(2); // Split Count
  const [selectedCode, setSelectedCode] = useState([]); // Selected Code Data
  const [percentages, setPercentages] = useState([50, 50]);
  const [financialOrSplit, setFinancialOrSplit] = useState("");
  const [selectedFinacial, setSelectedFinacial] = useState({});
  const [financialData, setFinacialData] = useState([]);
  const [isNarrationModal, setisNarrationModal] = useState(false);
  const intFinancialOrSplit = useRef("");
  const [submitNarrtion, setSubmitNarrtion] = useState(true);

  const headers = useMemo(
    () => ({
      Authorization: `Bearer ${accessToken}`,
    }),
    [accessToken]
  );
  const companyId = cmpID;
  const BankID = bankID;

  const codeFetchApi = async () => {
    try {
      const response = await GetApiwithHeader(
        `/api/BusinessCodes?companyId=${companyId}`,
        headers
      );
      const filteredBankData = response?.data?.data;
      if (filteredBankData) {
        setCodeData(filteredBankData);
      } else {
        console.error("Data not found in the response:", response);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchData = async () => {
    // setLoadingState(true)
    try {
      const res = await GetApiwithHeader(
        `/api/portfolio/Get/${companyId}?bankAccountId=${BankID}`,
        headers
      );
      // if (res?.data) setLoadingState(false)
      setFinacialData(res?.data?.data || []);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    codeFetchApi();
    fetchData();
  }, [headers]);


  useMemo(() => {
    if (highFreqRuleData && highFreqRuleData.id) {
      const narrationIntialValue =
        highFreqRuleData && highFreqRuleData?.narration
          ? {
              value: highFreqRuleData?.narration,
            }
          : null;
      setSelectedNarration(narrationIntialValue);
      const ruleConfiguration = highFreqRuleData.ruleConfiguration
        ? JSON.parse(highFreqRuleData.ruleConfiguration)
        : [];

      /**check for finacial entry */
      if (highFreqRuleData.isFinancialInstrument) {
        setFinancialOrSplit("financial");
        intFinancialOrSplit.current = "financial";
        setSelectedFinacial({
          value: highFreqRuleData?.financialId,
          label: highFreqRuleData?.codeName,
        });
      }
      if (ruleConfiguration.length > 1) {
        intFinancialOrSplit.current = "split";
        setFinancialOrSplit("split");
        setSelectedSplit(ruleConfiguration.length);
        // console.log('ruleConfiguration?.map((item) => item?.Percent ?? 0)', ruleConfiguration?.map((item) => item?.Percent.toFixed(2) ?? 0))
        setPercentages(ruleConfiguration?.map((item) => item?.Percent ?? 0));
        const code = ruleConfiguration?.map((item) => {
          const filteredData = codeData?.find((i) => i?.id === item?.CodeId);
          return {
            value: filteredData?.id,
            label: filteredData?.codeName,
            isInsurance: filteredData?.isInsurance,
            gst: filteredData?.gst,
          };
        });
        setSelectedCode(code);
      } else {
        if (ruleConfiguration.length === 1) {
          /**check for finacial entry */
          if (highFreqRuleData.isFinancialInstrument) {
            setFinancialOrSplit("financial");
            setSelectedFinacial({
              value: highFreqRuleData?.financialId,
              label: highFreqRuleData?.codeName,
            });
            return;
          }
          setSelectedSplit(2);
          setFinancialOrSplit("");
          setPercentages(ruleConfiguration?.map((item) => item?.Percent ?? 0));
          const code = ruleConfiguration?.map((item) => {
            const filteredData = codeData?.find((i) => i?.id === item?.CodeId);
            return {
              value: filteredData?.id,
              label: filteredData?.codeName,
              isInsurance: filteredData?.isInsurance,
              gst: filteredData?.gst,
            };
          });
          setSelectedCode(code);
        }
      }
    } else {
      setSelectedNarration(null);
      setFinancialOrSplit("");
      setSelectedSplit(2);
      setPercentages([]);
      setSelectedCode([]);
    }
  }, [highFreqRuleData]);

  const options = codeData?.map((item) => ({
    value: item.id,
    label: item.codeName,
    isInsurance: item.isInsurance,
    gst: item.gst,
  }));

  const handleFinancialOrSplit = (value) => {
    if (value === "split") {
      setSelectedSplit(parseFloat(2));
      setPercentages(Array.from({ length: 2 }, () => 100 / 2));
    }
    if (financialOrSplit === value) {
      setFinancialOrSplit("");
      return;
    }
    setFinancialOrSplit(value);
  };

  const handleChangeSplit = (event, number) => {
    setSelectedSplit(parseFloat(number));
    setPercentages(
      Array.from({ length: parseFloat(number) }, () => 100 / parseFloat(number))
    );
    // setPercentages(
    //   Array.from({ length: parseFloat(number) || 1 }, () => {
    //     const percentage = 100 / (parseFloat(number) || 1);
    //     return percentage % 1 !== 0 ? parseFloat(percentage.toFixed(2)) : percentage;
    //   })
    // );

    const updated = [...Array(parseInt(number))].map((_, index) => {
      return selectedCode[index];
    });
    console.log("updated", updated);
    setSelectedCode(updated);
  };

  const handlefinancialInstmntChange = (event) => {
    setSelectedFinacial(event);
  };

  // const handlePercentChange = (event, index) => {
  //   let totalPercent = event.target.value;
  //   const updatedPercentages = [...percentages];
  //   updatedPercentages.map((i, idx) => {
  //     if (idx !== index) {
  //       totalPercent = parseFloat(totalPercent) + parseFloat(i);
  //     }
  //   });
  //   if (totalPercent > 100) return;
  //   updatedPercentages[index] = event.target.value;
  //   setPercentages(updatedPercentages);
  // };

  const handlePercentChange = (event, index) => {
    const value = event.target.value;
    const regex = /^[0-9]*\.?[0-9]*$/;
    if (!regex.test(value)) {
      return;
    }

    let totalPercent = value;
    const updatedPercentages = [...percentages];
    updatedPercentages.forEach((i, idx) => {
      if (idx !== index) {
        totalPercent = parseFloat(totalPercent) + parseFloat(i);
      }
    });

    if (totalPercent > 100) return;

    updatedPercentages[index] = value;
    setPercentages(updatedPercentages);
  };

  const handleCodeChange = (event, index) => {
    let tempCodeArr = [...selectedCode];
    tempCodeArr[index] = event;
    setSelectedCode(tempCodeArr);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    if (financialOrSplit === "") {
      if (!selectedCode[0]) {
        toast.warning("Please Select Apply Code", { autoClose: 1000 });
        return;
      }
    }
    if (
      intFinancialOrSplit.current &&
      intFinancialOrSplit.current !== financialOrSplit &&
      submitNarrtion
    ) {
      setisNarrationModal(true);
      setSubmitNarrtion(false);
      if (submitNarrtion) {
        return;
      }
    }
    if (financialOrSplit === "financial") {
    }
    if (financialOrSplit === "split") {
      if (
        percentages &&
        percentages.length > 0 &&
        percentages.length === parseInt(selectedSplit)
      ) {
        let totalPercent = 0;
        percentages.map((i, idx) => {
          totalPercent = parseFloat(totalPercent) + parseFloat(i);
        });
        if (totalPercent !== 100) {
          toast.warning("Please Enter Total 100% (Divided Into All Field)", {
            autoClose: 1000,
          });
          return;
        }
      } else {
        toast.warning("Please Enter Total 100% (Divided Into All Field)", {
          autoClose: 1000,
        });
        return;
      }
      if (
        selectedCode &&
        selectedCode.length > 0 &&
        selectedCode.length === parseInt(selectedSplit)
      ) {
        [...Array(parseInt(selectedSplit))].map((_, index) => {
          if (!selectedCode[index]) {
            toast.warning("Please Select Code In All Dropdown", {
              autoClose: 1000,
            });
            return;
          }
        });
      } else {
        toast.warning("Please Select Code In All Dropdown", {
          autoClose: 1000,
        });
        return;
      }
    }
    if (selectedNarration && !selectedNarration.value) {
      toast.warning("Please Select Narration", { autoClose: 1000 });
      return;
    }

    let ruleConf = [];
    if (financialOrSplit === "split") {
      ruleConf = [...Array(parseInt(selectedSplit))].map((_, index) => {
        return {
          CodeId: selectedCode[index].value,
          IsPercentage: true,
          Percent: percentages[index],
          SplitCount: selectedSplit,
        };
      });
    }
    if (financialOrSplit === "financial") {
      ruleConf = [
        {
          CodeId: 0,
          IsPercentage: true,
          Percent: 100,
          SplitCount: 1,
        },
      ];
    }
    if (financialOrSplit === "") {
      ruleConf = [
        {
          CodeId: selectedCode[0].value,
          IsPercentage: true,
          Percent: 100,
          SplitCount: 1,
        },
      ];
    }
    let financialId = selectedFinacial ? selectedFinacial?.value : 0;
    if (financialOrSplit === "split") {
      financialId = 0;
    }
    const payload = {
      title: selectedFinacial ? selectedFinacial?.label : "",
      narration: selectedNarration.value,
      financialId: financialId,
      isSplit: financialOrSplit === "split",
      ruleConfiguration: JSON.stringify(ruleConf),
      bankAccountId: bankID,
      isFinancialInstrument: financialOrSplit === "financial",
    };
    try {
      let res;
      if (highFreqRuleData && highFreqRuleData.id) {
        res = await putApiWithHeader(
          `/api/CodingRuleSet/update/${highFreqRuleData.id},${companyId}`,
          payload,
          headers
        );
      } else {
        res = await postApiWithHeader(
          `/api/CodingRuleSet/create/${companyId}`,
          payload,
          headers
        );
      }
      if (res && res.data && res.data.statusCode === 100) {
        toast.success("Added Successfully", { autoClose: 1000 });
        props.fetchData();
        props.handleClose();
        setSelectedNarration(null);
        setFinancialOrSplit("");
        setSelectedCode([]);
        setPercentages([]);
      } else {
        toast.error(res?.data?.message, { autoClose: 1000 });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleNarrationChange = (e) => {
    setSelectedNarration(e);
  };
  const [isVisible, setIsVisible] = useState(true);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  const narrationModelClose = (e) => {
    setisNarrationModal(false);
    setSubmitNarrtion(false);

    handleFormSubmit(e);
  };

  return (
    <div className="addBox">
      <CForm onSubmit={handleFormSubmit}>
        <div className="infoDescription">
          {isVisible ? (
            <div className="bgOrange">
              <span>
                We identified multiple Transcations with common narration, you
                can pick the narration and set the code against it to make the
                rule. once is created system will identified the uncdode
                Transcation belongs to rule and help you to code it quickly.
              </span>
              <span class="icon-info" onClick={toggleVisibility}></span>
            </div>
          ) : (
            <div className="text-right">
              <span class="icon-info" onClick={toggleVisibility}></span>
            </div>
          )}
        </div>
        <div></div>
        <CRow className="mb-2">
          <div className="md-12 mt-3 selectOption">
            <CFormLabel>Select Transcation Description</CFormLabel>
            <AutocompleteComponent
              options={[
                ...(narration && narration.length > 0
                  ? narration.map((item) => ({
                      value: item?.narration,
                    }))
                  : []),
              ]}
              onChange={(e) => handleNarrationChange(e)}
              value={selectedNarration}
              placeholder="Select Narration"
            />
          </div>
        </CRow>
        {selectedNarration && selectedNarration?.value && (
          <>
            <CCol md={12}>
              <div className="md-12 mt-3">
                {financialOrSplit === "financial" || financialOrSplit === "" ? (
                  <CFormCheck
                    key={0}
                    className="mb-3 addRule"
                    id={`financialOrSplitCheckbox0`}
                    label="Is it a financial Instrument ?"
                    onChange={() => handleFinancialOrSplit("financial")}
                    checked={financialOrSplit === "financial"}
                  />
                ) : null}

                {financialOrSplit === "split" || financialOrSplit === "" ? (
                  <CFormCheck
                    key={1}
                    className="mb-3 addRule"
                    id={`financialOrSplitCheckbox1`}
                    label="Is it a split entry ?"
                    onChange={() => handleFinancialOrSplit("split")}
                    checked={financialOrSplit === "split"}
                  />
                ) : null}
              </div>
            </CCol>
            {financialOrSplit === "financial" && (
              <div className="md-12 mt-3 selectOption">
                <CFormLabel>Select Financial Instrument</CFormLabel>
                <Select
                  name="financialId"
                  // options={[{ value: 0, label: "Car Loan" }]}
                  options={financialData.map((item) => ({
                    value: item.id,
                    label: item.documentName,
                  }))}
                  placeholder="Select Financial Instrument"
                  isSearchable={false}
                  value={selectedFinacial}
                  onChange={(event) => handlefinancialInstmntChange(event)}
                />
              </div>
            )}
            {financialOrSplit === "split" && (
              <div className="md-12 mt-3">
                <div className="mb-3 wrapRow">
                  <CFormLabel>Select Split</CFormLabel>
                  <CRow>
                    {[2, 3, 4].map((number) => (
                      <CCol key={number} md="auto" sm="12" className="mt-1">
                        <div className="mb-3">
                          <CFormCheck
                            key={number}
                            type="radio"
                            className="mb-3 addRule"
                            id={`splitRadio${number}`}
                            label={number.toString()}
                            onChange={(event) =>
                              handleChangeSplit(event, number)
                            }
                            checked={selectedSplit === number}
                          />
                        </div>
                      </CCol>
                    ))}
                  </CRow>
                </div>

                {selectedSplit && (
                  <div className="highFreqAddRuleSplitEntryTable">
                    <CRow className="tableHeader">
                      <CCol md={3} className="tableColumn">
                        Percent %
                      </CCol>
                      <CCol md={9} className="tableColumn">
                        Code Name
                      </CCol>
                    </CRow>
                    <div className="tableBody">
                      {[...Array(parseInt(selectedSplit))].map((_, index) => (
                        <CRow className="tableRow">
                          <CCol md={3} className="tableColumn">
                            <CFormInput
                              type="text"
                              placeholder={`Type here`}
                              value={percentages[index] || ""}
                              onChange={(event) =>
                                handlePercentChange(event, index)
                              }
                            />
                          </CCol>
                          <CCol md={9} className="tableColumn bdr">
                            <Select
                              options={options}
                              value={
                                selectedCode[index] ? selectedCode[index] : {}
                              } // Update the value prop to match the format of the options
                              onChange={(event) =>
                                handleCodeChange(event, index)
                              }
                              isSearchable={true}
                            />
                          </CCol>
                        </CRow>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )}
            {financialOrSplit === "" && (
              <>
                <CFormLabel>Apply Code</CFormLabel>
                <Select
                  options={options}
                  value={selectedCode[0] ? selectedCode[0] : {}} // Update the value prop to match the format of the options
                  onChange={(event) => handleCodeChange(event, 0)}
                  isSearchable={true}
                />
              </>
            )}
          </>
        )}
        {selectedNarration && selectedNarration?.value ? (
          <CRow className="mt-4 justify-content-end">
            <CCol xs="auto">
              <CButton
                color="light"
                onClick={() => {
                  props.handleClose();
                  setFinancialOrSplit("");
                }}
              >
                Cancel
              </CButton>
            </CCol>
            <CCol xs="auto">
              <CButton color="primary" type="submit">
                Save
              </CButton>
            </CCol>
          </CRow>
        ) : null}
      </CForm>
      <CModal
        visible={isNarrationModal}
        onClose={() => setisNarrationModal(false)}
        aria-labelledby="ScrollingLongContentExampleLabel"
        className="overley"
      >
        <CModalHeader>
          Are you sure you want to make this change in Rule
        </CModalHeader>
        <CModalFooter>
          <CButton
            className="btn-light mr-2"
            onClick={() => setisNarrationModal(false)}
          >
            No
          </CButton>
          <CButton
            className="btn-primary"
            onClick={(e) => narrationModelClose(e)}
          >
            Yes
          </CButton>
        </CModalFooter>
      </CModal>
    </div>
  );
};

export default AddRule;
