import React, { useState, useMemo, useEffect } from 'react'
import { userAttributes } from "../../share/localData";
import { DeleteApiwithHeader, GetApiwithHeader, UpdateUserwithHeader, postApiWithHeader } from 'src/Api/Api';
import { CCard, CCol, CImage, CRow } from '@coreui/react';
import { moneyout, moneyin } from 'src/components/Images/Images';
import { page1 } from 'src/components/Images/Images';

const Entries = (props) => {
    const codeInfo = props?.codeInfo
    const accessToken = localStorage.getItem("token");
    const [abn, setAbn] = useState([])
    const { cmpID } = userAttributes();
    const companyId = cmpID;
    console.log(codeInfo)
    const code = codeInfo?.bankEntryCodingResponseDtos[0]
    const businessCodeId = code?.businessCodeId
    const codeName = code?.businessCodeName


    const headers = useMemo(
        () => ({
            Authorization: `Bearer ${accessToken}`,
        }),
        [accessToken]
    );


    const fetchData = async () => {
        try {
            const res = await GetApiwithHeader(`/api/bankentrycodings/GetByCode?businessCodeId=${businessCodeId}&companyId=${companyId}`, headers);
            setAbn(res?.data?.data || []);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className="entriesWrapper">
            <div className="border-bottom">
                <h5 className="fw-bold">{codeName} code is used in folllowing type of entries.</h5>
                {/* <p className="mb-1">{codeName}</p> */}
            </div>
            {abn && abn.length > 0 && abn?.map((item, index) => {
                return (
                    <div key={item?.id} className={`${index === 0 ? 'mt-3' : '' } mb-3`}   >
                        <CCard>
                            <CRow className="w-100">
                                <CCol sm={9}>
                                    <p className="text-start">Entry Code#: <b>{item?.entryCode}</b> </p>
                                    <p className="text-start">{item?.narration}</p>
                                </CCol>
                                <CCol sm={3}>
                                    <div className="d-flex gap-1 justify-content-end">
                                        <h5 className="fw-bold">{item?.depositAmount === '0' ? `$${Math.abs(item?.withdrawalAmount)}` : `$${item?.depositAmount}`}</h5>
                                        {item?.depositAmount === '0' ? <CImage src={moneyout} /> : <CImage src={moneyin} />}
                                    </div>
                                </CCol>
                                
                            </CRow>
                        </CCard>
                    </div>
                )
            }
            )
            }
        </div >)
}
export default Entries

