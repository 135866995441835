import React, { useMemo, useEffect, useState } from "react";
import { useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { GetApiwithHeader } from "src/Api/Api";
import {
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderNav,
  CHeaderToggler,
  CNavLink,
  CNavItem,
  CImage,
  CCol,
  CRow,
  CFormSelect,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import MenuItem from "@mui/material/MenuItem";
import {
  cilMenu,
} from "@coreui/icons";
import { AppHeaderDropdown } from "./header/index";
import { help, notification } from "./Images/Images";
import { userAttributes } from "../share/localData";
import { useData } from "./dataContext";
import Select from 'react-select';


const AppHeader = ({ toggleSidebar }) => {
  const { companyName, cmpID, bankName, bankID, role } = userAttributes();
  const { 
    updateData, 
    updateBank, 
    triggerRenderHeaderOnCompanyAdd, 
    setTriggerRenderHeaderOnCompanyAdd, 
    triggerRenderHeaderOnBankAdd, 
    setTriggerRenderHeaderOnBankAdd } = useData();
  const [companyVal, setCompanyVal] = useState();
  const [bankNameVal, setBankNameVal] = useState()
  const dispatch = useDispatch();
  const accessToken = localStorage.getItem("token");
  const [companyData, setCompanyData] = useState([]);
  const [bankData, setBankData] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState({});
  const [selectedBank, setSelectedBank] = useState({});
  const unfoldable = useSelector((state) => state.sidebarUnfoldable)
  const sidebarShow = useSelector((state) => state.sidebarShow)
  const [bankValue, setBankVal] = useState(null);
  const storedBankData = localStorage.getItem("bankData");
  let initialOption = null;
  const location = useLocation();

  const fetchData = async () => {
    try {
      const res = await GetApiwithHeader(`/api/company`, headers);
      if(res && res?.data && res?.data?.data && res?.data?.data.length > 0){
        setCompanyData(res?.data?.data);
        let Data = {
          company: {
            Id: res?.data?.data[0]?.id,
            Name: res?.data?.data[0]?.name,
            role: res?.data?.data[0]?.role
          },
        };
        if(companyName && cmpID){
          bankApi(cmpID);
          setSelectedCompany({value: cmpID, label: companyName});
          updateData({
            company: {
              Id: cmpID,
              Name: companyName,
              role: role
            },
          });
        }else{
          bankApi(res?.data?.data[0].id);
          setSelectedCompany({value: res?.data?.data[0].id, label: res?.data?.data[0].name});
          updateData(Data);
          localStorage.setItem("companyData", JSON.stringify(Data));
        }
      }else{
        setCompanyData([]);
      }
      
    } catch (error) {
      console.log(error);
    }
  };


  const bankApi = async (e) => {
    try {
      const response = await GetApiwithHeader(
        `/api/company/bankaccounts/${e ? e : cmpID}`,
        headers
      );
      const filteredbankData =
        response?.data &&
        response?.data?.data?.filter((item) => item?.bankName);
        setBankData(filteredbankData);
      if (response && response?.data && response?.data?.data && response?.data?.data.length > 0) {
        const Bank = {
          Id: response?.data?.data[0].id,
          Name: response?.data?.data[0].accountName,
        };
        if(bankName && bankID && cmpID === e){
          setSelectedBank({value: bankID, label: bankName});
          updateBank({Id: bankID, Name: bankName});
        }else{
          setSelectedBank({value: response?.data?.data[0].id, label: response?.data?.data[0].accountName});
          updateBank(Bank);
          localStorage.setItem("bankData", JSON.stringify(Bank));
        }
      }else{
        setSelectedBank({})
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCompanyChange = (e) => {
    localStorage.removeItem("companyData");
    localStorage.removeItem("bankData");
    const selectedOption = companyData.find(
      (item) => item?.id === e.value
    );
    const Data = {
      company: {
        Id: selectedOption?.id,
        Name: selectedOption?.name,
        role: selectedOption.role
      },
    };
    setSelectedCompany({value: e.value, label : selectedOption.name});
    updateData(Data);
    bankApi(selectedOption?.id)
    localStorage.setItem("companyData", JSON.stringify(Data));
    setCompanyVal(selectedOption?.name);
  };

  const handleBankChange = (selectedOption) => {
    const selectedBank = bankData.find((bank) => bank.id === selectedOption?.value);
    if (selectedBank) {
      const Bank = {
        Id: selectedBank.id,
        Name: selectedBank.accountName,
      };
      updateBank(Bank);
      setSelectedBank({value: selectedBank.id, label: selectedBank.accountName});
      setBankNameVal(selectedBank.accountName);
      localStorage.setItem("bankData", JSON.stringify(Bank));
    }
  };

  const headers = useMemo(
    () => ({
      Authorization: `Bearer ${accessToken}`,
    }),
    [accessToken]
  );

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if(triggerRenderHeaderOnCompanyAdd === true){
      fetchData();
      setTriggerRenderHeaderOnCompanyAdd(false);
    }
  }, [triggerRenderHeaderOnCompanyAdd]);

  useEffect(() => {
    if(triggerRenderHeaderOnBankAdd === true){
      if(cmpID){
        bankApi(cmpID);
      }
      setTriggerRenderHeaderOnBankAdd(false);
    }
  }, [triggerRenderHeaderOnBankAdd]);

  if (storedBankData) {
    const parsedBankData = JSON.parse(storedBankData);
    initialOption = { value: parsedBankData.Id, label: parsedBankData.Name };
  } else {
    initialOption = null; // Set initialOption to null if no bank data is stored
  }
  // const initialOption = { value: bankData && bankData[0]?.id, label: bankData && bankData[0]?.accountName };
  // const initialOption = { value: localStorage.getItem(bankNameVal), label: localStorage.getItem(bankNameVal) };
  // console.log('bankNameVal',bankNameVal)

  // console.log('initialOption', initialOption)
  return (
    <CHeader
      position="sticky"
      className=""
      style={{ backgroundColor: "#FFFFFF" }}
    >
      <CContainer fluid>
        <CHeaderToggler
          className="ps-1"
          onClick={() => {
            toggleSidebar();
          }}
        >
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler>
        {location?.pathname !== '/companyDetails' ?  
        <CRow className="mb-2">
        <CCol md={12}>
          <div className="selectCompany w-320">
          <Select
            name="company"
            options={[...(companyData ? companyData.map((item) => ({
              value: item?.id,
              label: item?.name
            })) : [])]}

            value={selectedCompany} // Update the value prop to match the format of the options
            onChange={(e) => handleCompanyChange(e)}
            placeholder="Select Company"
            isSearchable={false}
          />
          </div>
        </CCol>
      </CRow>
        : ''
      }
        {location?.pathname !== '/bank' && location?.pathname !== '/companyDetails' && location?.pathname !== '/business_code' && location?.pathname !== '/invites' && location?.pathname !== '/ledger' && location?.pathname !== '/reports'  && location?.pathname !== '/gstReport' ?
        <CRow className="mb-2">
        <CCol md={12}>
          <div className="selectCompany w-320">
            <Select
              name="bank"
              options={[...(bankData ? bankData.map((item) => ({
                value: item?.id,
                 label: (
                  <div>
                    <p>{item?.accountName}</p>
                    <p>{item?.bankName} - {item?.accountNumber}</p>
                  </div>
                ),
              })) : [])]}
              value={selectedBank} // Update the value prop to match the format of the options
              onChange={handleBankChange}
              placeholder="Select Bank"
              isSearchable={false}
            />

          </div>
          </CCol>
        </CRow>
   : ''}
        <CHeaderBrand className="mx-auto d-md-none" to="/">
        </CHeaderBrand>
        <CHeaderNav className="d-none d-md-flex me-auto">
        </CHeaderNav>
        <CHeaderNav>
          <CNavItem>
            <CNavLink href="#">
              <CImage src={help} className="helpIcon" />
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink href="#">
              <CImage src={notification} className="bellIcon" />
            </CNavLink>
          </CNavItem>
        </CHeaderNav>
        <CHeaderNav>
          <AppHeaderDropdown role={role}/>
        </CHeaderNav>
      </CContainer>
    </CHeader>
  );
};
AppHeader.propTypes = {
  toggleSidebar: PropTypes.func.isRequired,
};

export default AppHeader;
