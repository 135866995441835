import React, {useState, useEffect, useMemo, useRef} from "react";
import {
  CRow,
  CCol,
  CButton,
  CTooltip,
  CModal,
  CModalBody,
  CModalHeader,
  CModalFooter,
} from "@coreui/react";
import { DataGridPro, GridEditSingleSelectCell, GridRowEditStopReasons} from "@mui/x-data-grid-pro";
import { LicenseInfo } from '@mui/x-license';
import { MenuItem, Checkbox, ListItemText, Select, FormControl, InputLabel } from '@mui/material';
import { useLocation, useNavigate } from "react-router-dom";
import Split from "./Split";
import OffCanvas from "../../share/offCanvas";
import { GetApiwithHeader } from "src/Api/Api";
import { userAttributes } from "../../share/localData";
import ApplyRule from "../high/ApplyRule";
import AddFinancial from './Add'
import { BorderAll } from "@mui/icons-material";
import { useData } from "../../components/dataContext";
import { toast, ToastContainer } from "react-toastify";


const FinancialInstrumentCard = ({ details, transactions }) => {
  LicenseInfo.setLicenseKey('248a71a5e79c492f4b13769843fcf6dcTz04OTIzMCxFPTE3NDU3NTY1ODYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');
  const location = useLocation();
  const { data } = useData();
  const financeDetailData = location.state?.item;
  const [canvasOpen, setCanvasOpen] = useState(false);
  const [rowCodeID, setRowCodeID] = useState();
  const [financeDetails, setFinacialData] = useState([]);
  const accessToken = localStorage.getItem("token");
  const { cmpID,bankID } = userAttributes();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [titleCanvas, setTitleCanvas] = useState('')
  const [narration, setNarration] = useState([]);
  const prevCmpID = useRef();
  const prevBankID = useRef();

  const navigate = useNavigate();
  
  const handleBackClick = () =>{
    navigate('/financialInstrument')
  }
  const headers = useMemo(
    () => ({
      Authorization: `Bearer ${accessToken}`,
    }),
    [accessToken]
  );
  
  const fetchData = async () => {
    // setLoadingState(true)
    try {
      const res = await GetApiwithHeader(
        `/api/portfolio/Get/${cmpID}?bankAccountId=${bankID}&id=${financeDetailData?.id}`,
        headers
        );
        if (res?.data) 
        setFinacialData(res?.data?.data || []); 
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDataNarration = async () => {
    try {
      const res = await GetApiwithHeader(`/api/BankTransactions/Narration/${cmpID}?bankAccountId=${bankID}`, headers);
      setNarration(res?.data?.data || []);
    } catch (error) {
      console.log(error);
    }
  };

  const TranscationData = financeDetails[0]?.bankTransactions

  useEffect(() => {
    fetchData()
    fetchDataNarration()
   if (prevCmpID.current !== undefined && prevCmpID.current !== cmpID || prevBankID.current !== undefined && prevBankID.current !== bankID) {
      navigate('/financialInstrument');
    }
    prevCmpID.current = cmpID;
    prevBankID.current = bankID
  }, [cmpID, bankID])
  

  const CustomEditMultiSelectCell = (props) => {
    const { id, field, value, api, colDef } = props;


    const handleChange = (event) => {
      const { value: newValue } = event.target;
      api.setEditCellValue({ id, field, value: newValue }, event);
    };

  
    // Get previous row's value
    const previousRow = api.getRow(id - 1);
    let options = colDef.valueOptions;
  
    if (previousRow) {
      const previousValue = previousRow[field];
      if (previousValue && previousValue.length > 0) {
        const lastValue = parseInt(previousValue[previousValue.length - 1], 10);
        options = colDef.valueOptions.filter(
          (option) => parseInt(option, 10) > lastValue && parseInt(option, 10) <= lastValue + 3
        );
      }
    }
  
    return (
      <FormControl fullWidth>
        <InputLabel>Department</InputLabel>
        <Select
          multiple
          value={value || []}
          onChange={handleChange}
          renderValue={(selected) => selected.join(', ')}
          autoFocus
        >
          {options.map((option) => (
            <MenuItem key={option} value={option}>
              <Checkbox checked={value && value.indexOf(option) > -1} />
              <ListItemText primary={option} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

const handlePeriodCanvas = (row) => {
  setRowCodeID(row)
  setCanvasOpen(true);
  setTitleCanvas('Split')
}

const handleCloseClick = () => {
  setCanvasOpen(false);
  setTitleCanvas('')
  }
  
  const handleEditClick = (i) => {
    setTitleCanvas('Edit')
    setCanvasOpen(true);
    fetchData()
  };

  const column = [
    { field: "entryCode", headerName: "Entry", width: 100, headerClassName: 'dataGridHeader' },
    { field: "date", headerName: "Date", width: 120, headerClassName: 'dataGridHeader',
    valueGetter: (params) =>
    new Date(params).toLocaleDateString("en-GB")
  },
    { field: "amount", headerName: "Amount", width: 120, headerClassName: 'dataGridHeader' },
    { field: "narration", headerName: "Description", width: 250, headerClassName: 'dataGridHeader' },
    {
      field: "period",
      headerName: "Period",
      width: 120,
      headerClassName: 'dataGridHeader',
    },
    { field: "capital", headerName: "Capital", width: 120, headerClassName: 'dataGridHeader' },
    { field: "intrest", headerName: "Intrest", width: 120, headerClassName: 'dataGridHeader' },
    { field: "futureValue", headerName: "Future Value", width: 120, headerClassName: 'dataGridHeader' },
    {
      field: "codeIT",
      headerName: "Code",
      width: 150,
      headerClassName: 'dataGridHeader',
      // editable: true,
      renderCell: ({ row }) => (
       row?.capital === 0 ? 
        <CButton onClick={() => handlePeriodCanvas(row)}>Code It</CButton>
        : 
        <CTooltip content="Split Entry">
                <span
                  className="icon-split"
                  onClick={() => handlePeriodCanvas(row)}
                ></span>
       </CTooltip>
      )
    },
  ]

  

    return (
            <><ToastContainer position="top-right" /><div className="financial-instrument">
        <div className="card">
          <div className="card-header">
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <h2 className="financial-text">Financial Instrument Details: <strong>{financeDetails && financeDetails[0]?.documentName}</strong></h2>
              <CButton onClick={handleBackClick} color="black" variant="ghost">
                Back
              </CButton>
            </div>
          </div>
          <div className="card-body">
            <div className="card-details">
              <CRow>
                <CCol className="mb-2 text-right" md="12">
                  <CTooltip content="Edit">
                    <button className="icon-editIcon btnEdit" onClick={() => handleEditClick(financeDetails[0])}></button>
                  </CTooltip>
                </CCol>
              </CRow>
              <CRow md={12}>
                <CCol className="mb-2" md="4">
                  <p>
                    <strong>Document Type:</strong> {financeDetails && financeDetails[0]?.documentType}
                  </p>
                </CCol>
                <CCol className="mb-2" md="2">
                  <p>
                    <strong>Duration Units:</strong> {financeDetails && financeDetails[0]?.durationUnit}
                  </p>
                </CCol>
                <CCol className="mb-2" md="3">
                  <p>
                    <strong>Asset Value:</strong> ${(financeDetails && financeDetails[0]?.assetValue)?.toLocaleString('en-AU')}
                  </p>
                </CCol>
                <CCol className="mb-2" md="3">
                  <p>
                    <strong>Interest:</strong> {financeDetails?.[0]?.roi?.toFixed(2) ?? "0.00"}% p.a.
                  </p>
                </CCol>
              </CRow>
              <CRow md={12}>
                <CCol className="mb-2" md="4">
                  <p>
                    <strong>Start Date:</strong> {financeDetails && new Date(financeDetails[0]?.startDate).toLocaleDateString('en-GB')}
                  </p>
                </CCol>
                <CCol className="mb-2" md="2">
                  <p>
                    <strong>End Date:</strong> {financeDetails && new Date(financeDetails[0]?.endDate).toLocaleDateString('en-GB')}
                  </p>
                </CCol>
                <CCol className="mb-2" md="3">
                  <p>
                    <strong>Upfront Deposit Amount:</strong>{" $"}
                    {(financeDetails && financeDetails[0]?.upfrontDepositAmount)?.toLocaleString('en-AU')}
                  </p>
                </CCol>
                <CCol className="mb-2" md="3">
                  <p>
                    <strong>Payment Periods:</strong> {financeDetails && financeDetails[0]?.numOfPaymentPeriods}
                  </p>
                </CCol>
              </CRow>
              <CRow>
                <CCol className="mb-2" md="4">
                  <p>
                    <strong>Future Value (Balloon Payment):</strong>{" $"}
                    {(financeDetails && financeDetails[0]?.futureValue)?.toLocaleString('en-AU')}
                  </p>
                </CCol>
                <CCol className="mb-2" md="2">
                  <p>
                    <strong>Residual Date:</strong>{" "}
                    {financeDetails && new Date(financeDetails[0]?.residualDate).toLocaleDateString('en-GB')}
                  </p>
                </CCol>
                <CCol className="mb-2" md="3">
                  <p>
                    <strong>Present Value of Finance:</strong>{" $"}
                    {(financeDetails && financeDetails[0]?.presentValue)?.toLocaleString('en-AU')}
                  </p>
                </CCol>
                <CCol className="mb-2" md="3">
                  <p>
                    <strong>Installment:</strong> ${(financeDetails && financeDetails[0]?.installment)?.toLocaleString('en-AU')}
                  </p>
                </CCol>
              </CRow>
              {financeDetails && financeDetails[0]?.codingRuleSetResponseDtos?.length > 0 ?
                <>
                  <hr />
                  <CRow>
                    <strong className="financial-text">Financial Instruments Rule</strong>
                    <CCol className="mb-2 alignRowFinancil" md="6">
                      <p>
                        <strong>Narration:</strong>{" "}
                        {financeDetails && financeDetails[0]?.codingRuleSetResponseDtos?.length > 0 && financeDetails[0]?.codingRuleSetResponseDtos[0]?.narration}
                      </p>
                    </CCol>
                    <CCol className="mb-2 alignRowFinancil" md="3">
                      <p>
                        <strong>New transaction found :</strong>{" "}
                        {financeDetails && financeDetails[0]?.codingRuleSetResponseDtos?.length > 0 && financeDetails[0]?.codingRuleSetResponseDtos[0]?.count}
                      </p>
                    </CCol>
                    <CCol className="mb-2 alignRowFinancil" md="2">
                      <p>
                        {financeDetails && financeDetails[0]?.codingRuleSetResponseDtos?.length > 0 && financeDetails[0]?.codingRuleSetResponseDtos[0]?.count !== 0
                          ?
                          <CTooltip content="Run">
                            <CButton onClick={() => {
                              setIsModalVisible(true);
                            } }
                              className="runBtn"
                            >

                              Run
                            </CButton>
                          </CTooltip>
                          : <CTooltip content="Run">
                            <CButton disabled>
                              Run
                            </CButton>
                          </CTooltip>}
                      </p>
                    </CCol>
                  </CRow>
                </> : ''}

            </div>
          </div>
        </div>
        <div className="transaction-details">
          <h2 className="financial-text">Transaction Details </h2>
          <div className="datatableGrid">
            {TranscationData &&
              <DataGridPro
                rows={TranscationData}
                columns={column}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 15 },
                  },
                }}
                pageSizeOptions={[5, 10]}
                density="compact"
                disableRowSelectionOnClick />}

          </div>
        </div>
        <OffCanvas
          Open={canvasOpen}
          title={titleCanvas === 'Split' ? 'Split' : "Edit Financial Instrument"}
          handleCloseClick={handleCloseClick}
          component={titleCanvas === 'Split' ?

            <Split financeDetails={financeDetailData}
              transcationData={TranscationData}
              fetchData={fetchData}
              rowCodeID={rowCodeID}
              handleCloseClick={handleCloseClick} />
            : <AddFinancial
              editValue={financeDetails && financeDetails[0]}
              handleClose={handleCloseClick}
              fetchData={fetchData}
              narration={narration}
              detailsEdit='detailsEdit' />} />
        <CModal
          visible={isModalVisible}
          onClose={() => setIsModalVisible(false)}
          aria-labelledby="ScrollingLongContentExampleLabel"
          className="overflowBox"
        >
          <CModalHeader>
            <h3>Transaction Definition </h3>
          </CModalHeader>
          <div className="overflow sidebar-nav">
            <CModalBody>
              <ApplyRule HighFreqRuleData={financeDetails && financeDetails[0]?.codingRuleSetResponseDtos?.length > 0 && financeDetails[0]?.codingRuleSetResponseDtos[0]} modalShow={setIsModalVisible} fetchDataHighfreq={fetchData} />
            </CModalBody>
          </div>
        </CModal>
      </div></>
  );
};

export default FinancialInstrumentCard;
