import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useRef,
} from "react";
import {
  CContainer,
  CRow,
  CCol,
  CFormTextarea,
  CFormInput,
  CButton,
  CFormLabel,
  CModal,
  CModalHeader,
  CModalBody,
  CTooltip,
  CCarouselItem,
  CCarousel,
  CImage,
} from "@coreui/react";
import { useDropzone } from "react-dropzone";
import { postApiWithHeader, GetApiwithHeader } from "src/Api/Api";
import { userAttributes } from "../../share/localData";
import { toast } from "react-toastify";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';


const UploadDoc = (props) => {
  const {
    rowdata,
    handleCloseClick,
    fetchDataCoding,
    uploadedDocuments,
    setUploadedDocuments,
    uploadedDocumentsRef,
  } = props; // Corrected variable name
  // const [uploadedDocuments, setUploadedDocuments] = useState([]);
  // const uploadedDocumentsRef = useRef([]);
  console.log("rowdata----",rowdata);
  const [description, setDescription] = useState("");
  const [narrationAttch, setNarrationAttch] = useState("");
  const [imageIndex, setImageIndex] = useState(0);
  const [openImageViewer, setOpenImageViewer] = useState(false);
  const [imageForView, setImageForView] = useState("false");
  const [verifyMsg, setverifyMsg] = useState("");
  const { cmpID } = userAttributes();
  const companyId = cmpID;
  const accessToken = localStorage.getItem("token");
  const [abnValue, setAbnValue] = useState(rowdata.abn);
  const [isAbnVerified, setIsAbnVerified] = useState(rowdata.abn ? true : false);
  const [attachmentError, setAttachmentError] = useState("");
  const [existingDocuments, setExistingDocuments] = useState(rowdata?.bankEntryMediaDto);

  const headersFile = useMemo(
    () => ({
      Authorization: `Bearer ${accessToken}`,
      'accept': '*/*',
      'Content-Type': 'multipart/form-data'
    }),
    [accessToken]
  );

  const headers = useMemo(
    () => ({
      Authorization: `Bearer ${accessToken}`,
    }),
    [accessToken]
  );

  useEffect(() => {
    if(rowdata && rowdata?.bankEntryMediaDto){
      setExistingDocuments(rowdata?.bankEntryMediaDto);
    }
    if (
      rowdata &&
      rowdata.bankEntryCodingResponseDtos
      &&
      rowdata.bankEntryCodingResponseDtos[0]?.narration
    ) {
      console.log('rowdata.bankEntryCodingResponseDtos[0]', rowdata)
      setNarrationAttch(rowdata.bankEntryCodingResponseDtos[0].narration);
    } else {
      setNarrationAttch("");
    }
    // console.log('rowdata', rowdata)
    setAbnValue(rowdata?.abn !== null && rowdata?.abn ? rowdata?.abn : '');
    setIsAbnVerified(rowdata?.abn ? true : false);
    setverifyMsg('');
  }, [rowdata, rowdata?.bankEntryMediaDto]);

  const onDrop = useCallback((acceptedFiles) => {
    const filesArray = Array.from(acceptedFiles);
    const uploadedFiles = filesArray.map((file) => ({
      url: URL.createObjectURL(file),
      fileUpload: file,
    }));
    if (uploadedDocumentsRef.current.length > 0) {
      uploadedDocumentsRef.current =
        uploadedDocumentsRef.current.concat(uploadedFiles);
      setUploadedDocuments(uploadedDocumentsRef.current);
    } else {
      uploadedDocumentsRef.current = uploadedFiles;
      setUploadedDocuments(uploadedFiles);
    }
  }, []);

  useEffect(() => {
    if (uploadedDocumentsRef.current.length > 0) {
      setAttachmentError(""); // Clear error when attachments are available
    }
  }, [uploadedDocumentsRef.current]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: { "image/*": [] },
    onDrop,
  });

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleCloseImageViewer = () => {
    setOpenImageViewer(false);
  };

  const handleImageViewer = (value, currentIndex) => {
    setImageIndex(currentIndex);
    setImageForView(value);
    setOpenImageViewer(true);
  };

  const handleImagePreview = (index) => {
    const currentIndex = imageIndex + index;
    setImageIndex(currentIndex);
    setImageForView(rowdata.filePath[currentIndex]);
  }

  const handleNarrationChange = (e) => {
    setNarrationAttch(e.target.value);
  };

  const handleDeleteImage = (index, type) => {
    if(type === 'new'){
      const updatedArr = uploadedDocumentsRef.current
      .filter((_, i) => i !== index)
      .map((item) => ({ ...item, url: URL.createObjectURL(item.fileUpload) }));
      uploadedDocumentsRef.current = updatedArr;
      setUploadedDocuments(updatedArr);
    } else {
      if(existingDocuments && existingDocuments.length > 0){
        const updatedArr = existingDocuments.map((row, idx) => {
          if(index === idx) return { ...row, isDeleted: true };
          return row;
        });
        setExistingDocuments(updatedArr);
      }
    }
  };

  const handleSave = async () => {
    if (uploadedDocumentsRef.current.length === 0 && existingDocuments.filter((e) => e.isDeleted === false).length === 0) {
      setAttachmentError("Please Upload Attachment"); // Set error message
      return;
    }
    const formData = new FormData();
    uploadedDocuments.forEach((documentData, index) => {
      if (documentData.url) {
        formData.append(`files`, documentData.fileUpload);
      }
    });
    if(existingDocuments && existingDocuments.length > 0){
      existingDocuments?.forEach((item, index) => {
        formData.append(`ImageData[${index}].id`, item.id);
        formData.append(`ImageData[${index}].filePaths`, item.filePaths);
        formData.append(`ImageData[${index}].thumbnailFilePaths`, item.thumbnailFilePaths);
        formData.append(`ImageData[${index}].isDeleted`, item.isDeleted);
      })
    }

    try {
      const response = await postApiWithHeader(
        `api/bankentrycodings/fileUpload/${companyId}?transactionCode=${rowdata?.transactionCode
        }&narration=${narrationAttch ? narrationAttch : ""}&abn=${abnValue ? abnValue : ''}`,
        formData,
        headersFile
      );
      if (response?.data?.statusCode === 100) {
        toast.success("Attachment Uploaded Successfully", {
          autoClose: 1000,
          onClose: () => {
            handleCloseClick();
            fetchDataCoding();
            setUploadedDocuments([]);
          },
        });
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error occurred while uploading", { autoClose: 1000 });
    }
  };

  const handleChangeAbn = (e) => {
    setAbnValue(e.target.value);
    setverifyMsg("");
    setIsAbnVerified(false);
  };

  const handleVerify = async () => {
    if (!abnValue) {
      setverifyMsg("Please Enter ABN"); // Corrected typo: setVerifyMsg
      return; // Added return to exit the function
    }
    try {
      const res = await GetApiwithHeader(
        `/api/abn/ABN-Details?companyId=${companyId}&abn=${abnValue}`,
        headers
      );
      if (res.data.data.length === 0) {
        setverifyMsg("Please enter correct ABN number"); // Corrected typo: setVerifyMsg
      } else {
        setIsAbnVerified(true);
        setverifyMsg("ABN Verified."); // Corrected typo: setVerifyMsg
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="addBox">
      <CContainer>
        <CRow>
          <CCol md="12">
            <div className="mb-3">
              <CFormTextarea
                id="Narration"
                label="Narration"
                rows={3}
                value={narrationAttch || ""}
                disabled={
                  rowdata && rowdata.bankEntryCodingResponseDtos.length === 0
                    ? true
                    : false
                }
                onChange={handleNarrationChange}
              ></CFormTextarea>
            </div>
          </CCol>
          <CCol md="12">
            <div className="form-group CheckBoxAbn">
              <CFormInput
                type="text"
                id="input1"
                name="ABN"
                label="ABN"
                placeholder="ABN"
                disabled={rowdata && rowdata.bankEntryCodingResponseDtos.length === 0}
                value={abnValue || ""}
                onChange={handleChangeAbn}
              />
              <div className="CheckIcon">
                {isAbnVerified ? (
                  <CTooltip content="ABN verified!">
                    <CheckCircleOutlineOutlinedIcon style={{ color: "green" }} />
                  </CTooltip>
                ) : (
                  rowdata && rowdata.bankEntryCodingResponseDtos.length === 0 ? (
                    <CheckCircleOutlineOutlinedIcon style={{ color: "red" }} />
                  ) : (
                    <CTooltip content="Sent For Verification!">
                      <CheckCircleOutlineOutlinedIcon style={{ color: "red" }} onClick={handleVerify} />
                    </CTooltip>
                  )
                )}

              </div>
            </div>

            <span style={{ color: verifyMsg === 'ABN Verified.' ? "green" : "red" }}>{verifyMsg}</span>

          </CCol>
        </CRow>
        <CRow>
          <CCol md="12">
            <CFormLabel>Attachment</CFormLabel>
            <div className="dropzone-multiple-img">
              <div
                {...getRootProps()}
                className={`thumb thumb2 dropzone-container}`}
              >
                <input {...getInputProps()} />
                <div className="addFileBtn">
                  <span className="addIcon">
                    <AddIcon />
                  </span>
                  <span>Select Image</span>
                </div>
              </div>
              {existingDocuments &&
                existingDocuments?.length > 0 &&
                existingDocuments.map((url, index) => (
                  url?.isDeleted === false && <>
                    <div className="thumb" key={index}>
                      <div className="thumbInner">
                        <CTooltip content="Click for preview">
                          <img
                            src={url?.filePaths}
                            className="img"
                            onLoad={() => {
                              URL.revokeObjectURL(url?.filePaths);
                            }}
                            onClick={(e) => handleImageViewer(url?.filePaths, index)}
                          />
                        </CTooltip>
                        <button
                          className="closeButton"
                          onClick={() => handleDeleteImage(index, 'existing')}
                        >
                          <CloseIcon />
                        </button>
                      </div>
                    </div>
                  </>
                ))}
              {uploadedDocuments.length > 0 &&
                uploadedDocuments.map((documentData, index) => (
                  <div className="thumb" key={index}>
                    <div className="thumbInner">
                      <CTooltip content="Click for preview">
                        <img
                          src={documentData?.url}
                          className="img"
                          // Revoke data uri after image is loaded
                          onLoad={() => {
                            URL.revokeObjectURL(documentData?.url);
                          }}
                          onClick={(e) =>
                            handleImageViewer(
                              URL.createObjectURL(documentData.fileUpload), index
                            )
                          }
                        />
                      </CTooltip>
                      <button
                        className="closeButton"
                        onClick={() => handleDeleteImage(index, 'new')}
                      >
                        <CloseIcon />
                      </button>
                    </div>
                  </div>
                ))}
            </div>
          </CCol>
        </CRow>
        {attachmentError && <span style={{ color: "red" }}>{attachmentError}</span>}
        <CRow className="mt-4 justify-content-end">
          <CCol xs="auto">
            <CButton color="light" onClick={handleCloseClick}>
              Cancel
            </CButton>
          </CCol>
         
          <CCol xs="auto">
            <CButton color="primary" onClick={handleSave} >
              Save
            </CButton>
          </CCol>
        </CRow>
        {/* Model for preview the image */}
        <CModal
          className={"image-viewer modal large overflowBox"}
          visible={openImageViewer}
          onClose={handleCloseImageViewer}
        >
          <CModalHeader>
            <h3>Image Preview </h3>

          </CModalHeader>
          <CModalBody>

            <div className="text-center">
              <img
                className={"image-viewer img"}
                src={imageForView}
                alt="asd"
              />
            </div>

            <div className="imagePreviewButton mr-2">
              <button onClick={() => handleImagePreview(-1)} disabled={imageIndex === 0}>
                <ChevronLeftIcon />
              </button>
              <button onClick={() => handleImagePreview(1)} disabled={imageIndex === rowdata.filePath.length - 1}>
                <ChevronRightIcon />
              </button>
            </div>

          </CModalBody>
        </CModal>
      </CContainer>
    </div>
  );
};

export default UploadDoc;
