import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  CRow,
  CCol,
  CButton,
  CFormInput,
  CFormSelect,
  CForm,
  CFormLabel,
  CSpinner,
  CTooltip,
} from "@coreui/react";
import {
  GetApi,
  GetApiwithHeader,
  postApiWithHeader,
  UpdateUserwithHeader,
} from "src/Api/Api";
import PropTypes from "prop-types";
import { userAttributes } from "../../share/localData";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useData } from "../../components/dataContext";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import CIcon from "@coreui/icons-react";
import { cilCheckCircle } from "@coreui/icons";

const AddComany = (props) => {
  const { setTriggerRenderHeaderOnCompanyAdd } = useData();
  const editValue = props?.editValue;
  const { cmpID } = userAttributes();
  const [industry, setIndustry] = useState([]);
  const accessToken = localStorage.getItem("token");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [verifyMsg, setVerifyMsg] = useState("");
  const [abnValid, setAbnValid] = useState(true);
  const [iconStatus, setIconStatus] = useState(false);
  const [abnValue, setAbnValue] = useState("");

  AddComany.propTypes = {
    fetchData: PropTypes.func.isRequired,
    editValue: PropTypes.string.isRequired,
    handleClose: PropTypes.func,
  };

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  const companyId = cmpID;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await GetApiwithHeader(`/api/industry`, headers);
        setIndustry(res?.data?.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);
  const handleOnChange = (value, country) => {
    const sanitizedPhoneNumber = value.replace(/^0+/, "");
    const formattedPhoneNumber = `+${sanitizedPhoneNumber}`;
    formik.setFieldValue("phoneNumber", formattedPhoneNumber);
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      industryId: "",
      abn: "",
      address1: "",
      address2: "",
      companyEmail: "",
      phoneNumber: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Company name is required"),
      industryId: Yup.string().required("Industry type is required"),
      abn: Yup.string().required("ABN number is required").length(11, "ABN number must be exactly 11 digits"),
      phoneNumber: Yup.string().required("Mobile number is required"),
      address1: Yup.string().required("Address is required"),
      // address2: Yup.string().required("Address 2 is required"),
      companyEmail: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
    }),

    onSubmit: async (values, { resetForm }) => {
      setIsSubmitting(true);
      const apiUrl = editValue
        ? `/api/company/update/${editValue.id}`
        : "/api/company/create";

      const formData = {
        name: values.name,
        industryId: values.industryId,
        abn: values.abn,
        address1: values.address1,
        address2: values.address2,
        isPromotor: true,
        companyEmail: values.companyEmail,
        phoneNumber: values.phoneNumber,
      };

      try {
        const response = editValue
          ? await UpdateUserwithHeader(apiUrl, formData, headers)
          : await postApiWithHeader(apiUrl, formData, headers);
        if (
          editValue
            ? response.message === "Success"
            : response.data.message === "Success"
        ) {
          toast.success(
            `Company ${editValue ? "Updated" : "Added"} successfully`,
            {
              autoClose: 1000,
              onClose: () => {
                props?.handleClose();
                resetForm();
                setTriggerRenderHeaderOnCompanyAdd(true);
              },
            }
          );
          props?.fetchData();
        } else if ( editValue ? response.statusCode === 101 : response.data.statusCode === 102) {
          toast.error(response.data.message, { autoClose: 1000 });
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      } finally {
        setIsSubmitting(false); // End form submission
      }
    },
  });

  const resetFormErrors = () => {
    formik.setErrors({
      name: "",
      industryId: "",
      abn: "",
      phoneNumber: "",
      address1: "",
      address2: "",
      companyEmail: "",
    });
  };

  useEffect(() => {
    if (editValue) {
      formik.setValues({
        name: editValue.name,
        industryId: editValue.industryId,
        abn: editValue.abn,
        address1: editValue.address1,
        address2: editValue.address2,
        companyEmail: editValue.companyEmail,
        phoneNumber: editValue.phoneNumber,
      });
      setAbnValue(editValue.abn);
      setIconStatus(true)
    } else {
      formik.setValues({
        name: "",
        industryId: "",
        abn: "",
        address1: "",
        address2: "",
        companyEmail: "",
        phoneNumber: "",
      });
      setAbnValue("");
      setIconStatus(false)
      resetFormErrors();
    }
  }, [editValue]);

  const handleVerify = async (companyId, abnValue) => {
    try {
      const res = await GetApi(
        `api/abn/ABN-Details?companyId=${companyId}&abn=${abnValue}`
      );
      console.log('res', res);
      if (res.statusCode === 101) {
        setVerifyMsg("Please enter correct ABN number");
        setIconStatus(false);
      } else {
        setIconStatus(true);
        setVerifyMsg("ABN Verified.");
      }
      setTimeout(() => {
        setVerifyMsg("");
      }, 3000);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeAbn = (e) => {
    const value = e.target.value;
    setAbnValue(value);
    formik.setFieldValue("abn", value);
    setAbnValue(value)
    setAbnValid(true); // Reset the ABN validation state
    setIconStatus(false);

    if (value.length < 11) {
      setVerifyMsg("");
    } else {
      // Check if ABN is verified, if not, set error message
      if (iconStatus === false) {
        setVerifyMsg("Please verify ABN");
        setTimeout(() => {
          setVerifyMsg("");
        }, 3000);
      } else {
        setVerifyMsg(""); // Clear error message if ABN is verified
      }
    }
  };

  return (
    <div className="addBox">
      <CForm onSubmit={formik.handleSubmit}>
        <CCol md={12}>
          <CFormInput
            type="text"
            name="name"
            placeholder="Enter company name"
            label={
              <>
                Company Name <span className="text-danger">*</span>
              </>
            }
            onChange={formik.handleChange}
            value={formik.values.name}
          />
          {formik.touched.name && formik.errors.name && (
            <div className="text-danger">{formik.errors.name}</div>
          )}
        </CCol>
        <div className="mt-3">
          <CCol md={12}>
            <CFormSelect
              label={
                <>
                  Industry Type <span className="text-danger">*</span>
                </>
              }
              name="industryId"
              onChange={formik.handleChange}
              value={formik.values.industryId}
            >
              <option value="">Select Industry Type</option>
              {industry
                ?.sort((a, b) => a.title.localeCompare(b.title))
                .map((industry) => (
                  <option key={industry.id} value={industry.id}>
                    {industry.title}
                  </option>
                ))}
            </CFormSelect>
            {formik.touched.industryId && formik.errors.industryId && (
              <div className="text-danger">{formik.errors.industryId}</div>
            )}
          </CCol>
        </div>
        <div className="md-12 mt-3 CheckBoxAbn">
          <CFormInput
            type="text"
            name="abn"
            placeholder="Enter ABN"
            label={
              <>
                Company ABN <span className="text-danger">*</span>
              </>
            }
            onChange={handleChangeAbn}
            value={abnValue}
          />
          {abnValue.length === 11 && (
            <div className="CheckIcon">
              <CTooltip
                content={iconStatus ? "Verified" : "Send for verification!"}
              >
                <CIcon
                  icon={cilCheckCircle}
                  width="20px"
                  height="20px"
                  style={{
                    color: iconStatus ? "green" : "red",
                    cursor: "pointer",
                  }}
                  onClick={() => handleVerify(companyId, abnValue)}
                />
              </CTooltip>
            </div>
          )}
          {verifyMsg === "ABN Verified." ? (
            <span className="mt-10" style={{ color: "green" }}>
              {verifyMsg}
            </span>
          ) : (
            <span style={{ color: "red" }}>
              {verifyMsg}
              {/* Empty the error message when verifyMsg is displayed */}
              {verifyMsg && <span className="invalid-feedback"></span>}
            </span>
          )}
          {formik.touched.abn && formik.errors.abn && !verifyMsg && (
            <div className="text-danger">{formik.errors.abn}</div>
          )}
        </div>

        <div className="md-12 mt-3">
          <CFormLabel>
            Mobile Number <span className="text-danger">*</span>
          </CFormLabel>
          <PhoneInput
            name="phoneNumber"
            placeholder="Enter mobile number"
            onChange={(value, country) => handleOnChange(value, country)}
            country={"au"}
            value={formik.values.phoneNumber}
            inputStyle={{ width: "100%" }}
            containerStyle={{ width: "100%" }} 
            inputProps={{
              className:
                formik.touched.phoneNumber && formik.errors.phoneNumber
                  ? "form-control is-invalid"
                  : "form-control",
            }}
          />
          {formik.touched.phoneNumber && formik.errors.phoneNumber && (
            <div className="invalid-feedback">{formik.errors.phoneNumber}</div>
          )}
        </div>
        <div className="md-12 mt-3">
          <CFormInput
            type="text"
            name="companyEmail"
            placeholder="Enter email"
            label={
              <>
                Company Email <span className="text-danger">*</span>
              </>
            }
            onChange={formik.handleChange}
            value={formik.values.companyEmail}
          />
          {formik.touched.companyEmail && formik.errors.companyEmail && (
            <div className="text-danger">{formik.errors.companyEmail}</div>
          )}
        </div>
        <div className="md-12 mt-3">
          <CFormInput
            type="text"
            name="address1"
            placeholder="Enter address"
            label={
              <>
                Address1 <span className="text-danger">*</span>
              </>
            }
            onChange={formik.handleChange}
            value={formik.values.address1}
          />
          {formik.touched.address1 && formik.errors.address1 && (
            <div className="text-danger">{formik.errors.address1}</div>
          )}
        </div>

        <div className="md-12 mt-3">
          <CFormInput
            type="text"
            name="address2"
            placeholder="Enter address"
            label={
              <>
                Address2 
              </>
            }
            onChange={formik.handleChange}
            value={formik.values.address2}
          />
        </div>
        <CRow className="mt-4 justify-content-end">
          <CCol xs="auto">
            <CButton
              color="light"
              onClick={() => {
                props.handleClose();
              }}
            >
              Cancel
            </CButton>
          </CCol>
          <CCol xs="auto">
            <CButton color="primary" type="submit">
              {isSubmitting ? <CSpinner color="white" size="sm" /> : "Save"}
            </CButton>
          </CCol>
        </CRow>
      </CForm>
    </div>
  );
};

export default AddComany;
