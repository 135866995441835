import React, { useMemo, useEffect, useState } from "react";
import {
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CButton,
  CTableHead,
  CTableRow,
  CCol,
  CCardBody,
  CCard,
  CImage,
  CModalBody,
  CModalHeader,
  CModalFooter,
  CModal,
  CTooltip,
  CContainer,
  CRow,
  CListGroup,
  CListGroupItem,
  CCardHeader,
} from "@coreui/react";
import { GetApiwithHeader, DeleteApiwithHeader } from "src/Api/Api";
import { toast, ToastContainer } from "react-toastify";
import { ConstructionOutlined } from "@mui/icons-material";
import OffCanvas from "../../share/offCanvas";
import Add from "./Addasset";
// import { DataGrid } from "@mui/x-data-grid";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { LicenseInfo } from "@mui/x-license";
const AssetEntry = () => {
  LicenseInfo.setLicenseKey(
    "248a71a5e79c492f4b13769843fcf6dcTz04OTIzMCxFPTE3NDU3NTY1ODYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
  );

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState([]);
  const [visible, setVisible] = useState(false);
  const [deprication, setDeprication] = useState(false);
  const items = [
    { name: "Ledger Id", value: "677668" },
    { name: "Asset Name", value: "Motor car 2023" },
    { name: "Category", value: "Motor" },
    { name: "Purchase", value: "thursday nov 16" },
  ];
  const data = [
    { name: "immediate write off?", value: 10 },
    { name: "Asset Name", value: 20 },
    { name: "Write off amount", value: 30 },
    { name: "start date", value: 30 },
    { name: "Method", value: 30 },
    { name: "Deprication rate", value: 30 },
    { name: "interval", value: 30 },
  ];

  const handleClick = () => {
    setOpen(!open);
  };
  const handleAddclick = () => {
    setVisible(true);
  };
  const handleCloseClick = () => {
    setVisible(false);
    setDeprication();
  };
  const handleDepricationClick = () => {
    setVisible(true);
    setDeprication(true);
  };
  // const fetchdata = async ()=>{
  // try{
  // const res = GetApiwithHeader( `/api/company/bankaccounts/${companyId}`,
  // headers)
  // setValue(res.data.data || [])
  // console.log(res.data)
  // }catch(error)
  // {
  //   console.log(error);
  // }
  // }

  const column = [
    {
      field: "abn",
      headerName: "ABN Number",
      width: 200,
      sortable: true,
      // Custom sort comparator function for total amount column
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        parseFloat(v1) - parseFloat(v2),
    },
    { field: "id", headerName: "ID Number", width: 200 },
    {
      field: "created",
      headerName: "Created Date and Time",
      width: 200,
      valueGetter: (row) => {
        return new Date(row)?.toLocaleDateString("en-GB");
      },
    },
    {
      field: "amount",
      headerName: " Amount",
      width: 200,
      sortable: true,
      // Custom sort comparator function for total amount column
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        parseFloat(v1) - parseFloat(v2),
    },
    {
      field: "transaction",
      headerName: "Transaction Type",
      width: 300,
      sortable: true,
      // Custom sort comparator function for total amount column
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        parseFloat(v1) - parseFloat(v2),
    },
  ];

  const rows = [
    {
      id: 1,
      abn: "12378234",
      created: "12/10/2024",
      amount: 35,
      transaction: "30000",
    },
    {
      id: 2,
      abn: "3453234234",
      created: "2/2/2024",
      amount: 42,
      transaction: "30000",
    },
    {
      id: 3,
      abn: "878472834",
      created: "5/3/2024",
      amount: 45,
      transaction: "30000",
    },
    {
      id: 4,
      abn: "23423423",
      created: "7/3/2024",
      amount: 16,
      transaction: "30000",
    },
  ];

  return (
    <div className="cardBody">
      <ToastContainer position="top-right" />

      <div className="cardHeader">
        {!open ? <h3>Asset Entry</h3> : <h3> Asset Details</h3>}
        <div className="mr-1">
          {/* <input
          type="text"
          placeholder="Search..."
        //  value={searchQuery}
          //onChange={(e) => setSearchQuery(e.target.value)} // Step 2: Add input field for search query
        /> */}
          {open ? <CButton onClick={handleClick}>Back</CButton> : null}
          {open ? <CButton onClick={handleAddclick}>Edit</CButton> : null}
        </div>
      </div>

      {open ? (
        <>
          {/* <CRow>
            <CCol md={6}>
              <CCardHeader>
                <strong>Motor Vechicle</strong>
              </CCardHeader>
              <div className="md-8 mt-3">
                <CListGroup>
                  {items.map((item, index) => (
                    <CListGroupItem key={index}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>{item.name}</span> <span>{item.value}</span>
                      </div>
                    </CListGroupItem>
                  ))}
                </CListGroup>
              </div>
            </CCol>
            <div className="md-8 mt-3">
              <CCol md={6}>
                <CCardHeader>
                  <strong>Deprication Details</strong>
                </CCardHeader>
                <div className="md-12 mt-3">
                  <CListGroup>
                    {data.map((item, index) => (
                      <CListGroupItem key={index}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span> {item.name}</span> <span>{item.value}</span>
                        </div>
                      </CListGroupItem>
                    ))}
                  </CListGroup>
                </div>
              </CCol>
            </div>
            </CRow> */}
          {/* <CCol>
              <div className="md-8 mt-3">
                <CCardHeader>
                  <strong>Summary</strong>
                </CCardHeader>
                <div className="md-12 mt-3">
                  <CListGroup>
                    {items.map((item, index) => (
                      <CListGroupItem key={index}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span> {item.name}</span> <span>{item.value}</span>
                        </div>
                      </CListGroupItem>
                    ))}
                  </CListGroup>
                </div>

                <CRow className="mb-5 mt-4">
                  <CCol xs="auto">
                    <CButton color="primary" onClick={handleDepricationClick}>
                      View schedule
                    </CButton>
                  </CCol>
                </CRow>
              </div>
            </CCol> */}

          <CRow>
            <CCol>
              <CCardHeader>
                <strong>Motor Vechicle</strong>
              </CCardHeader>
              <div className="md-6 mt-2">
                <CListGroup>
                  {items.map((item, index) => (
                    <CListGroupItem key={index}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>{item.name}</span> <span>{item.value}</span>
                      </div>
                    </CListGroupItem>
                  ))}
                </CListGroup>
              </div>
            </CCol>
            <CCol>
              <CCardHeader>
                <strong>Deprication Details</strong>
              </CCardHeader>
              <div className="md-6 mt-2">
                <CListGroup>
                  {data.map((item, index) => (
                    <CListGroupItem key={index}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span> {item.name}</span> <span>{item.value}</span>
                      </div>
                    </CListGroupItem>
                  ))}
                </CListGroup>
              </div>
            </CCol>
          </CRow>
          <CRow>
          <CCol md={6} style={{marginTop:"-106px"}}>
            <CCardHeader>
              <strong>Summary</strong>
            </CCardHeader>
            <CListGroup>
              {items.map((item, index) => (
                <CListGroupItem key={index}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span> {item.name}</span> <span>{item.value}</span>
                  </div>
                </CListGroupItem>
              ))}
            </CListGroup>
          </CCol>
          </CRow>
          <CRow className="mb-5 mt-4">
            <CCol
              xs="auto"
           style={{marginLeft:"870px",marginTop:"-71px"}}
            >
              <CButton color="primary" onClick={handleDepricationClick}>
                View schedule
              </CButton>
            </CCol>
          </CRow>
        </>
      ) : null}

      <OffCanvas
        Open={visible}
        title={deprication ? "Deprication Schedule" : "Deprication Note"}
        handleCloseClick={handleCloseClick}
        component={
          <Add
            handleClose={handleCloseClick}
            deprication={deprication}
            handleDepricationClick={handleDepricationClick}
          />
        }
      />

      {!open ? (
        <div className="datatableGrid">
          {/* <DataGrid
         rows={rows}
        columns={column}
        style={{ cursor: "pointer" }}
        onRowClick={(params) => {
          // Extract the clicked row's ID
          const clickedRowId = params.id;
          // Call your handleClick function here or perform any other action
          handleClick();
        }}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 15 },
          },
        }}
        pageSizeOptions={[5, 10]}
        density="compact"
        // checkboxSelection
      /> */}

          <DataGridPro
            rows={rows}
            columns={column}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 15 },
              },
            }}
            pageSizeOptions={[5, 10]}
            density="compact"
            onRowClick={(params) => {
              // Extract the clicked row's ID
              const clickedRowId = params.id;
              // Call your handleClick function here or perform any other action
              handleClick();
            }}
          />
        </div>
      ) : null}
    </div>
  );
};

export default AssetEntry;

// import React from 'react'

// const AssetEntry = () => {
//   return (
//     <div>AssetEntry</div>
//   )
// }

// export default AssetEntry
