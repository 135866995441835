import React, { useMemo, useEffect, useState } from "react";
import {
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CButton,
  CTableHead,
  CTableRow,
  CRow,
  CCol,
} from "@coreui/react";
import pako from 'pako';
import { GetApiwithHeader, postApiWithHeader } from "src/Api/Api";
import { userAttributes } from "../../share/localData";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useData } from "../../components/dataContext";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { LicenseInfo } from "@mui/x-license";

const MapEntry = (props) => {
  const { data } = useData();
  LicenseInfo.setLicenseKey(
    "248a71a5e79c492f4b13769843fcf6dcTz04OTIzMCxFPTE3NDU3NTY1ODYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
  );
  const [mapData, setMapData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const { cmpID, bankID } = userAttributes();
  const accessToken = localStorage.getItem("token");
  const companyId = cmpID;
  const headers = useMemo(
    () => ({
      Authorization: `Bearer ${accessToken}`,
    }),
    [accessToken]
  );

  const handleRowSelect = (index) => {
    console.log('index', index)
    setSelectedRows([index]);
  };

  function formatToUSDateTime(dateTimeString) {
    const dateObj = new Date(dateTimeString);
    const options = {
      month: "numeric",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true, // Use 12-hour clock with AM/PM
    };
    return dateObj.toLocaleString("en-US", options);
  }

  // Function to decompress Gzip data to a json array
  const decompressGzipToJson = (data) => {
    try {
      // Decode base64 string to Uint8Array
      const binaryString = atob(data);
      const uint8Array = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        uint8Array[i] = binaryString.charCodeAt(i);
      }

      // Unzip the Uint8Array using pako
      const decompressedData = pako.inflate(uint8Array, { to: 'string' });

      // Parse the decompressed data as JSON
      const jsonData = JSON.parse(decompressedData);
      return jsonData;
    } catch (e) {
      console.log('Error decompressing Gzip data:', e);
      return [];
    }
  };

  const fetchData = async () => {
    try {
      const res = await GetApiwithHeader(
        `/api/BankTransactions/GetAll_new/${companyId},12-01-2024?bankAccountId=${bankID}`,
        headers
      );

      // Convert base64 json zip string into json array
      const statementList = await decompressGzipToJson(res?.data?.data);
      // const statementList = res?.data?.data;
      // console.log("statementList-----",res?.data?.data, statementList);
      const filteredData = (statementList || []).filter(
        (item) => item.isMapped === false
      );
      setMapData(filteredData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [headers, companyId, cmpID, bankID]);

  const handleMapEntry = async () => {
    if (selectedRows.length === 0) {
      toast.warning("Please select a receipt before mapping.");
      return;
    }

    const payload = {
      transactionCode: selectedRows[0]?.transactionCode, // Accessing transactionCode from the first selected row
      receiptId: props?.reciptID?.id, // Accessing receipt ID from props
    };

    try {
      const response = await postApiWithHeader(
        `/api/BankTransactionMapping/create/${companyId}`,
        payload,
        headers
      );
      if (response?.data?.statusCode === 100) {
        setTimeout(() => {
          props?.receiptApi();
          fetchData();
          props?.handleClose();
        }, 1000);
        toast.success("Entry mapped successfully", { autoClose: 1000 });
      } else {
        toast.warning(response?.data?.message, { autoClose: 1000 });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const column = [
    {
      field: "a",
      headerName: "Check",
      width: 100,
      headerClassName: "dataGridHeader",
      renderCell:(({row})=>{
        return <input
        type="radio"
        checked={selectedRows.includes(row)}
      />
      })
    },
    {
      field: "entryCode",
      headerName: "Entry",
      width: 150,
      headerClassName: "dataGridHeader",
    },
    {
      field: "b",
      headerName: "Date",
      width: 150,
      headerClassName : "dataGridHeader",
      renderCell: ({ row }) =>{
     return (new Date(
        row?.createdAt
      )?.toLocaleDateString("en-US")
     )
    }
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 100,
      headerClassName: "dataGridHeader",
      renderCell: ({row}) => {
      return  (
          row?.depositAmount !== "0"
            ? row?.depositAmount
            : row?.withdrawalAmount !== "0"
            ? row?.withdrawalAmount
            : null
            )
      },
    },
    {
      field: "balance",
      headerName: "Balance",
      width: 115,
      headerClassName: "dataGridHeader",
    },
  ];
  return (
    <>
      <div className="mb-2">
        <div className="datatableGrid">
          <DataGridPro
            rows={mapData}
            columns={column}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 15 },
              },
            }}
            pageSizeOptions={[5, 10]}
            density="compact"
            onRowClick={(e)=>{handleRowSelect(e?.row)}}
            // disableRowSelectionOnClick
            // loading={loadingState}
          />
            <CRow className="mt-4 justify-content-end wrap">
                <CButton type="submit" onClick={() => handleMapEntry()}>
                  Map Entry
                </CButton>
              </CRow>
        </div>
      </div>
    </>
  );
};

export default MapEntry;
