import React, { useMemo, useEffect, useState } from "react";
import {
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CButton,
  CTableHead,
  CTableRow,
  CCol,
  CCardBody,
  CCard,
  CImage,
  CModalBody,
  CModalHeader,
  CModalFooter,
  CModal,
  CTooltip,
  CSpinner,
} from "@coreui/react";
import { GetApiwithHeader, DeleteApiwithHeader } from "src/Api/Api";
import AddBank from "./addBank";
import { deleteIcon, edit } from "src/components/Images/Images";
import OffCanvas from "../../share/offCanvas";
import { userAttributes } from "../../share/localData";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useData } from "../../components/dataContext";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { LicenseInfo } from '@mui/x-license';


const List = () => {
  const { data, setTriggerRenderHeaderOnCompanyAdd, removeBank } = useData();
  LicenseInfo.setLicenseKey('248a71a5e79c492f4b13769843fcf6dcTz04OTIzMCxFPTE3NDU3NTY1ODYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [bankData, setBankData] = useState([]); // Renamed from 'banckData' to 'bankData' for clarity
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Number of items per page
  const [valueData, setValueData] = useState();
  const totalPages = Math.ceil(bankData.length / itemsPerPage);
  const { cmpID } = userAttributes();
  const accessToken = localStorage.getItem("token");
  const [visible, setVisible] = useState(false);
  const [editValue, setEditValue] = useState(false);
  const SerialNumber = itemsPerPage * currentPage - itemsPerPage; // Number of items per page
  const [searchQuery, setSearchQuery] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [loadingState, setLoadingState] = useState(true)
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
  const headers = useMemo(
    () => ({
      Authorization: `Bearer ${accessToken}`,
    }),
    [accessToken]
  );
  const companyId = cmpID;

  const fetchData = async () => {
    setLoadingState(true)
    try {
      const res = await GetApiwithHeader(
        `/api/company/bankaccounts/${companyId}`,
        headers
      );
      if(res?.data) setLoadingState(false)
      setBankData(res?.data?.data || []); 
    } catch (error) {
      console.log(error);
    }
  };

  const filteredServices = useMemo(() => {
    return bankData.filter(
      (service) =>
        service?.accountName
          ?.toLowerCase()
          .includes(searchQuery?.toLowerCase()) ||
        parseFloat(service?.id)
          .toString()
          .includes(searchQuery?.toLowerCase()) ||
        service?.bankName.toLowerCase().includes(searchQuery?.toLowerCase()) ||
        service?.accountType
          .toLowerCase()
          .includes(searchQuery?.toLowerCase()) ||
        service?.accountNumber
          .toLowerCase()
          .includes(searchQuery?.toLowerCase()) ||
        service?.bsbNumber.toLowerCase().includes(searchQuery?.toLowerCase()) ||
        service?.branchAddress
          .toLowerCase()
          .includes(searchQuery?.toLowerCase())
    );
  }, [bankData, searchQuery]);

  useEffect(() => {
    fetchData();
  }, [headers, companyId, cmpID]);

  const handleDelete = (i) => {
    setValueData(i);
    setIsModalVisible(true);
  };
  const handleDeleteApi = async (id) => {
    setIsDeleting(true);
    const apiUrl = `/api/company/bankaccount/delete/${id},${companyId}`;
    try {
      const response = await DeleteApiwithHeader(apiUrl, headers);
      if (response.message === "Success") {
        setIsModalVisible(false);
        fetchData();
        await removeBank();
        setTriggerRenderHeaderOnCompanyAdd(true);
        toast.success("Deleted successfully",{autoClose:1000});
      } else {
        console.error("Unexpected response format:", response);
      }
    } catch (error) {
      console.error("Delete API Request Error:", error);
    } finally {
      setIsDeleting(false); // Reset loading state regardless of success or failure
    }
  };
  const handleAddClick = () => {
    if (cmpID) {
      setVisible(true);
    } else {
      toast.warning("Please select a company first",{autoClose:1000});
    }
  };
  const handleCloseClick = () => {
    setEditValue();
    setVisible(false);
  };
  const handleEditClick = (i) => {
    setVisible(true);
    setEditValue(i);
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const sortedItems = () => {
    const sortableItems = [...filteredServices];
    if (sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        const aValue = typeof a[sortConfig.key] === 'string' ? a[sortConfig.key].toLowerCase() : a[sortConfig.key];
        const bValue = typeof b[sortConfig.key] === 'string' ? b[sortConfig.key].toLowerCase() : b[sortConfig.key];

        if (aValue < bValue) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  };


  // Function to get the current page's data
  const getCurrentPageData = () => {
    const sortedData = sortedItems();
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return sortedData.slice(startIndex, endIndex);
  };

  // Function to handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  // Function to handle previous page
  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  // Function to handle next page
  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const column = [
    { field: "accountName", headerName: "Account Name", width: 180, headerClassName: 'dataGridHeader' },
    { field: "bankName", headerName: "Bank Name", width: 200, headerClassName: 'dataGridHeader' }, 
    { field: "accountType", headerName: "Account Type", width: 150, headerClassName: 'dataGridHeader' },
    { field: "accountNumber", headerName: " Account Number", width: 150, headerClassName: 'dataGridHeader' },
    { field: "bsbNumber", headerName: "BSB Number", width: 150, headerClassName: 'dataGridHeader' },
    { field: "branchAddress", headerName: "Branch Address", width: 240, headerClassName: 'dataGridHeader' },
   
    { field: "action", headerName: "Action", width: 100, headerClassName: 'dataGridHeader',
    type: "actions",
      renderCell : (({row}) =>{
        console.log('row', row)
        return (
          <div className="rowIcon">
            <CTooltip content="Edit">
              <button className="icon-editIcon"  onClick={() => handleEditClick(row)}></button>
            </CTooltip>
            {/* <CTooltip content="Delete">
              <button className="icon-deleteIcon" onClick={() => handleDelete(row)}></button>
            </CTooltip> */}
          </div>
        )
      })
    },
  ];


  return (
    <div className="cardBody">
      <ToastContainer position="top-right" />
      <div className="cardHeader">
        <h3>Bank List</h3>
        <div className="mr-1">
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)} // Step 2: Add input field for search query
          />
          <CButton onClick={() => handleAddClick()}>Add Bank</CButton>
        </div>
      </div>

      <OffCanvas
        Open={visible}
        title={editValue ? "Edit Bank" : "Add Bank"}
        handleCloseClick={handleCloseClick}
        component={
          <AddBank
            editValue={editValue}
            handleClose={handleCloseClick}
            fetchData={fetchData}
          />
        }
      />
      <div className="datatableGrid">
      <DataGridPro
        rows={filteredServices}
        columns={column}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 15 },
          },
        }}
        pageSizeOptions={[5, 10]}
        density="compact"
        disableRowSelectionOnClick
        loading={loadingState}
      />
      </div>
      <CModal
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        aria-labelledby="ScrollingLongContentExampleLabel"
        className="overley"
      >

        <CModalHeader>
          <h3>Delete</h3>
        </CModalHeader>
        <CModalBody>
          Are you sure want to delete {valueData?.accountName} ?
        </CModalBody>
        <CModalFooter>
          <CButton className="btn-light mr-2" onClick={() => setIsModalVisible(false)}>
            Close
          </CButton>
          <CButton
            className="btn-primary"
            onClick={() => handleDeleteApi(valueData?.id)}
          >
             {isDeleting ? (
              <CSpinner color="white" size="sm" />
            ) : (
              "Delete"
            )}
          </CButton>
        </CModalFooter>
      </CModal>
    </div>
  );
};

export default List;
