import { cilCheckAlt } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { CButton } from '@coreui/react'
import React from 'react'

const Thankyou = () => {
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh', // Set the container height to full viewport height
    margin: '40px', // Add margin to the container
  };

  const iconStyle = {
    width: '60px',
    height: '60px',
    color: 'white', // Icon color
    backgroundColor: 'green', // Icon background color
    borderRadius: '100%', // Makes the icon circular
  };
  return (
    <div style={containerStyle}>
      <CIcon icon={cilCheckAlt} style={iconStyle} />
        <h5 className='mt-2'>Thank you</h5>
        <p>Thank you for registering with us. Please check your SMS we just send to download your application.</p>
        <CButton style={{width:'125px'}}>
          Ok
        </CButton>
    </div>
  )
}

export default Thankyou