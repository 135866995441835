import React from 'react';
import { CForm, CFormInput, CFormTextarea, CButton, CCol, CRow, CCard, CCardBody, CImage, CFormLabel, CTable, CTableHead, CTableBody, CTableRow, CTableHeaderCell, CTableDataCell } from '@coreui/react';
import { useFormik } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from 'src/assets/brand/logo.png'
import * as Yup from 'yup';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const validationSchema = Yup.object().shape({
    recipientName: Yup.string().required('Recipient name is required'),
    date: Yup.date().required('Date is required'),
    // recipientMobile: Yup.string().required('Recipient mobile is required'),
    recipientEmail: Yup.string().email('Invalid email').required('Recipient email is required'),
    companyName: Yup.string().required('Company name is required'),
    abnNumber: Yup.string().required('ABN number is required'),
    serviceDescription: Yup.string().required('Service description is required'),
    // serviceCharge: Yup.number().required('Service charge is required'),
    // gst: Yup.number().required('GST is required'),
    // total: Yup.number().required('Total is required'),
    address: Yup.string().required('Address is required'),
    deliveryAddress: Yup.string().required('Delivery address is required'),
    // paymentTerms: Yup.string().required('Payment terms are required'),
    termsConditions: Yup.string().required('Terms and conditions are required'),
    discussionBoard: Yup.string().required('Discussion board is required')
});

const OrderForm = () => {
    const formik = useFormik({
        initialValues: {
            recipientName: '',
            date: '',
            recipientMobile: '',
            recipientEmail: '',
            companyName: '',
            abnNumber: '',
            itemType: 'Service',
            serviceDescription: 'Dummy text this site is provided as a service to our visitors and may be used for informational purposes only.',
            serviceCharge: '1000',
            gst: '100',
            total: '1100',
            address: '',
            deliveryAddress: '',
            paymentTerms: '',
            termsConditions: 'Dummy text this site is provided as a service to our visitors and may be used for informational purposes only.',
            discussionBoard: ''
        },
        validationSchema: validationSchema,
        onSubmit: values => {
            // console.log(JSON.stringify(values, null, 2));
            console.log('formik', formik.values)
            toast.success('Form submitted successfully');
        },


    });

    const handleOnChange = (value, country) => {
        const sanitizedPhoneNumber = value.replace(/^0+/, "");
        const formattedPhoneNumber = `+${sanitizedPhoneNumber}`;
        formik.setFieldValue("phoneNumber", formattedPhoneNumber);
    };
    return (
        <div className='mt-4'>
            <ToastContainer />
            <CCol md={6}  >
                <CCard className='form-container' style={{ boxShadow: '0px 0px 6px 0px rgba(0,0,0,0.3)' ,width: "650px" }}>
                    <CCardBody>"
                        <div className="clearfix logo mb-3">
                            <CImage align="center" rounded src={logo} />
                        </div>
                        <h5>Create Ordering</h5>
                        <hr />
                        <CForm onSubmit={formik.handleSubmit} >
                            <CRow className="mb-3">
                                <CCol md={6}>
                                    <CFormInput
                                        id="recipientName"
                                        name="recipientName"
                                        label="Name of Recipient"
                                        placeholder="Jane Cooper"
                                        value={formik.values.recipientName}
                                        onChange={formik.handleChange}
                                    />
                                    {formik.touched.recipientName && formik.errors.recipientName ? (
                                        <div className="text-danger">{formik.errors.recipientName}</div>
                                    ) : null}
                                </CCol>
                                <CCol md={6}>
                                    <CFormInput
                                        id="date"
                                        name="date"
                                        type="date"
                                        label="Date"
                                        value={formik.values.date}
                                        onChange={formik.handleChange}
                                    />
                                    {formik.touched.date && formik.errors.date ? (
                                        <div className="text-danger">{formik.errors.date}</div>
                                    ) : null}
                                </CCol>
                            </CRow>
                            <CRow className="mb-3">
                                <CCol md={6}>
                                    <CFormLabel>
                                        Recipient Mobile (to send SMS) <span className="text-danger">*</span>
                                    </CFormLabel>
                                    <PhoneInput
                                        id="recipientMobile"
                                        name="recipientMobile"
                                        placeholder="Enter mobile number"
                                        onChange={(value, country) => handleOnChange(value, country)}
                                        country={"au"}
                                        value={formik.values.recipientMobile}
                                        inputStyle={{ width: "100%" }}
                                        containerStyle={{ width: "100%" }} // Added containerStyle to adjust the width
                                    // inputProps={{
                                    //     className:
                                    //         formik.touched.recipientMobile && formik.errors.recipientMobile
                                    //             ? "form-control is-invalid"
                                    //             : "form-control",
                                    // }}
                                    />
                                    {/* {formik.touched.recipientMobile && formik.errors.recipientMobile && (
                                        <div className="text-danger">{formik.errors.recipientMobile}</div>
                                    )} */}
                                </CCol>
                                <CCol md={6}>
                                    <CFormInput
                                        id="recipientEmail"
                                        name="recipientEmail"
                                        type="email"
                                        label="Recipient Email"
                                        placeholder="janecooper@gmail.com"
                                        value={formik.values.recipientEmail}
                                        onChange={formik.handleChange}
                                    />
                                    {formik.touched.recipientEmail && formik.errors.recipientEmail ? (
                                        <div className="text-danger">{formik.errors.recipientEmail}</div>
                                    ) : null}
                                </CCol>
                            </CRow>
                            <CRow className="mb-3">
                                <CCol md={6}>
                                    <CFormInput
                                        id="companyName"
                                        name="companyName"
                                        label="Company Name"
                                        value={formik.values.companyName}
                                        onChange={formik.handleChange}
                                        placeholder='Enter Company Name'
                                    />
                                    {formik.touched.companyName && formik.errors.companyName && (
                                        <div className="text-danger">{formik.errors.companyName}</div>
                                    )}
                                </CCol>
                                <CCol md={6}>
                                    <CFormInput
                                        id="abnNumber"
                                        name="abnNumber"
                                        label="ABN Number"
                                        placeholder="123467978231"
                                        value={formik.values.abnNumber}
                                        onChange={formik.handleChange}
                                    />
                                    {formik.touched.abnNumber && formik.errors.abnNumber && (
                                        <div className="text-danger">{formik.errors.abnNumber}</div>
                                    )}
                                </CCol>
                            </CRow>
                            <div style={{ display: 'flex', alignItems: 'center' }} className='mb-2'>
                                <span>Item type :</span>
                                <h6 style={{ margin: '0 0 0 10px' }}>Services</h6>
                            </div>
                            <CFormTextarea
                                id="serviceDescription"
                                name="serviceDescription"
                                label="Service Description"
                                rows="4"
                                value={formik.values.serviceDescription}
                                onChange={formik.handleChange}
                                className='mb-3'
                            />
                            {formik.touched.serviceDescription && formik.errors.serviceDescription && (
                                <div className="text-danger">{formik.errors.serviceDescription}</div>
                            )}
                            <CTable>
                                <CTableHead>
                                    <CTableRow>
                                        <CTableHeaderCell style={{ backgroundColor: '#ebedef' }}>Service Charge</CTableHeaderCell>
                                        <CTableHeaderCell style={{ backgroundColor: '#ebedef' }}>GST</CTableHeaderCell>
                                        <CTableHeaderCell style={{ backgroundColor: '#ebedef' }}>Total</CTableHeaderCell>
                                    </CTableRow>
                                </CTableHead>
                                <CTableBody>
                                    <CTableRow>
                                        <CTableDataCell>{formik.values.serviceCharge}</CTableDataCell>
                                        <CTableDataCell>{formik.values.gst}</CTableDataCell>
                                        <CTableDataCell>{formik.values.total}</CTableDataCell>
                                    </CTableRow>
                                    {/* <CTableRow>
                                        <CTableDataCell>
                                            {formik.touched.serviceCharge && formik.errors.serviceCharge && (
                                                <div className="text-danger">{formik.errors.serviceCharge}</div>
                                            )}
                                        </CTableDataCell>
                                        <CTableDataCell>
                                            {formik.touched.gst && formik.errors.gst && (
                                                <div className="text-danger">{formik.errors.gst}</div>
                                            )}
                                        </CTableDataCell>
                                        <CTableDataCell>
                                            {formik.touched.total && formik.errors.total && (
                                                <div className="text-danger">{formik.errors.total}</div>
                                            )}
                                        </CTableDataCell>
                                    </CTableRow> */}
                                </CTableBody>
                            </CTable>
                            <CRow className="mb-3">
                                <CCol md={6}>
                                    <CFormTextarea
                                        id="address"
                                        name="address"
                                        label="Address"
                                        value={formik.values.address}
                                        onChange={formik.handleChange}
                                    />
                                    {formik.touched.address && formik.errors.address && (
                                        <div className="text-danger">{formik.errors.address}</div>
                                    )}
                                </CCol>
                                <CCol md={6}>
                                    <CFormTextarea
                                        id="deliveryAddress"
                                        name="deliveryAddress"
                                        label="Delivery Address"
                                        value={formik.values.deliveryAddress}
                                        onChange={formik.handleChange}
                                    />
                                    {formik.touched.deliveryAddress && formik.errors.deliveryAddress && (
                                        <div className="text-danger">{formik.errors.deliveryAddress}</div>
                                    )}
                                </CCol>
                            </CRow>
                            {/* <CFormTextarea
                                id="paymentTerms"
                                name="paymentTerms"
                                label="Payment Terms: Other Terms"
                                rows="2"
                                value={formik.values.paymentTerms}
                                onChange={formik.handleChange}
                            />
                            {formik.touched.paymentTerms && formik.errors.paymentTerms && (
                                <div className="text-danger">{formik.errors.paymentTerms}</div>
                            )} */}
                            <div style={{ display: 'flex', alignItems: 'center' }} className='mb-2'>
                                <span>paymentTerms :</span>
                                <h6 style={{ margin: '0 0 0 10px' }}>Other Terms</h6>
                            </div>
                            <CFormTextarea
                                id="termsConditions"
                                name="termsConditions"
                                label="Terms & Conditions"
                                rows="4"
                                value={formik.values.termsConditions}
                                onChange={formik.handleChange}
                            />
                            {formik.touched.termsConditions && formik.errors.termsConditions && (
                                <div className="text-danger">{formik.errors.termsConditions}</div>
                            )}
                            <CRow>
                                <CCol className="text-end">
                                    <CButton color="primary" className="mt-3">
                                        Accept & Place Order
                                    </CButton>
                                </CCol>
                            </CRow>

                            {/* <CFormInput
                                id="discussionBoard"
                                name="discussionBoard"
                                label="Discussion Board"
                                rows="2"
                                value={formik.values.discussionBoard}
                                onChange={formik.handleChange}
                                className="mt-3"
                            />
                            {formik.touched.discussionBoard && formik.errors.discussionBoard && (
                                <div className="text-danger">{formik.errors.discussionBoard}</div>
                            )}
                            <CRow>
                                <CCol className="text-end">
                                    <CButton type="submit" color="primary" className="mt-3">
                                        Submit
                                    </CButton>
                                </CCol>
                            </CRow> */}

                            <CFormLabel htmlFor="discussionBoard">Discussion Board</CFormLabel>
                            <div className="input-with-button mt-3">
                                <CFormInput
                                    id="discussionBoard"
                                    name="discussionBoard"
                                    rows="2"
                                    value={formik.values.discussionBoard}
                                    onChange={formik.handleChange}
                                />
                                <CButton type="submit" color="primary" className="inside-button">
                                    Submit
                                </CButton>
                            </div>
                            {formik.touched.discussionBoard && formik.errors.discussionBoard && (
                                <div className="text-danger">{formik.errors.discussionBoard}</div>
                            )}
                        </CForm>
                    </CCardBody>
                </CCard>
            </CCol>
        </div>
    );
};

export default OrderForm;


