import React, { useEffect, useState } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Box,
} from "@mui/material";
import {
  CCardBody,
  CCard,
  CRow,
  CCol,
  CForm,
  CFormLabel,
  CFormInput,
  CFormText,
  CFormCheck,
  CFormSelect,
  CButton,
  CCardHeader,
  CListGroup,
  CListGroupItem,
} from "@coreui/react";
import { CheckRounded } from "@mui/icons-material";
import CDatePicker from "react-datepicker";
import { List, ListItem, ListItemText } from "@mui/material";
import { GetApiParam } from "src/Api/Api";
import { addDays } from "date-fns";
import { useFormik } from "formik";
import * as Yup from "yup";

const validationSchemaGenerator = (activeStep) => {
  // console.log("sssss");
  switch (activeStep) {
    case 0:
      return Yup.object().shape({
        ledgerId: Yup.string().required("Ledger ID is required"),
        assetName: Yup.string().required("Asset Name is required"),
      });
    case 1:
      return Yup.object().shape({
        // purchasePrice: Yup.number().required("Purchase Price is required"),
        // dateOfPurchase: Yup.date().required("Date of Purchase is required"),
        // CalculationInterval:Yup.string().required("calcaulation interval is required"),
        CalculationInterval: Yup.string().required(
          "Calculation interval is required"
        ),
        depricationmethod: Yup.string().required(
          "Depreciation Method is required"
        ),
        depricationrate: Yup.string().required("Depreciation rate is required"),
        totaldepricationclaimed: Yup.string().required(
          "Total Depreciation claimed is required"
        ),
        currentwrittendown: Yup.string().required(
          "Current Written-down value is required"
        ),
      });
    default:
      return Yup.object().shape({});
  }
};

const Add = (props) => {
  const [activeStep, setActiveStep] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const deprication = props.deprication;
  const handleClose = props.handleClose;
  const [dpstate, setDPstate] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleFromDateChange = (e) => {
    setFromDate(e.target.value);
    if (toDate && new Date(e.target.value) >= new Date(toDate)) {
      setToDate(""); // Reset toDate if fromDate is after or equal to toDate
    }
  };

  const handleToDateChange = (e) => {
    setToDate(e.target.value);
  };

  const formik = useFormik({
    initialValues: {
      ledgerId: "",
      assetName: "",
      isMotorVehicle: false,
      assetCategory: "",
      purchasePrice: "",
      CalculationInterval: "",
      depricationmethod: "",
      depricationrate: "",
      totaldepricationclaimed: "",
      currentwrittendown: "",
      date: "",
      assetimmediate: "",
      dateofpurchase: "",
    },
    validationSchema: validationSchemaGenerator(activeStep),
    onSubmit: (values,{resetForm}) => {
      console.log("Form values:", values);
      // You can send the form data to the server here

      const payload = {
        ledgerId: values.ledgerId,
        assetName: values.assetName,
        isMotorVehicle: values.isMotorVehicle,
        assetCategory: values.assetCategory,
        purchasePrice: values.purchasePrice,
        dateofpurchase: values.dateofpurchase,
        CalculationInterval: values.CalculationInterval,
        depricationmethod: values.depricationmethod,
        depricationrate: values.depricationrate,
        totaldepricationclaimed: values.totaldepricationclaimed,
        currentwrittendown: values.currentwrittendown,
        assetCategory: values.assetCategory,
        purchasePrice: values.purchasePrice,
        date: values.date,
        assetimmediate: values.assetimmediate,
      };

      setTimeout(() => {
        // setIsSubmitting(false);
        resetForm();
        handleClose();
        console.log("Form submission completed."); // Log completion message to console
      }, 1000);
    },
  });
 
  const handleNext = async () => {
    const isValid = await formik.validateForm();
    formik.setTouched({
      ledgerId: true,
      assetName: true,
      depricationrate: false,
      totaldepricationclaimed: false,
      currentwrittendown: false,
    });

    if (Object.keys(isValid).length === 0) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleNext1 = async () => {
    const isValid = await formik.validateForm();
    formik.setTouched({
      CalculationInterval: true,
      depricationmethod: true,
      depricationrate: true,
      totaldepricationclaimed: true,
      currentwrittendown: true,
    });

    if (Object.keys(isValid).length === 0) {
      // Call form submission if no errors
      formik.handleSubmit(); // This will submit the form
      // setActiveStep((prevActiveStep) => prevActiveStep + 1); // Move to the next step
    }
  };
// rest form
  const handleCancel = () => {
    formik.resetForm();
    handleClose();
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleDpChange = () => {
    setDPstate(!dpstate);
  };

  const schedule = [
    { name: "Year", value: "Amount" },
    { name: "2021", value: "$72000" },
    { name: "2022", value: "$4450" },
    { name: "2023", value: "$40000" },
  ];
  return (
    <Box sx={{ width: "100%" }}>
      {!deprication ? (
        <Stepper activeStep={activeStep} alternativeLabel>
          <Step>
            <StepLabel>Step 1</StepLabel>
          </Step>
          <Step>
            <StepLabel>Step 2</StepLabel>
          </Step>
        </Stepper>
      ) : null}
      <CForm onSubmit={formik.handleSubmit}>
        {!deprication && activeStep === 0 ? (
          <>
            <strong>Ledger Details</strong>
            <CRow>
              <div className="md-12 mt-3">
                <CCol>
                  <CFormInput
                    type="text"
                    id="ledgerId"
                    placeholder="Enter Ledger ID"
                    label={
                      <>
                        Ledger ID <span className="text-danger">*</span>
                      </>
                    }
                    name="ledgerId"
                    value={formik.values.ledgerId}
                    onChange={formik.handleChange}
                    // onBlur={formik.handleBlur}
                  />
                  {formik.touched.ledgerId && formik.errors.ledgerId && (
                    <div className="text-danger">{formik.errors.ledgerId}</div>
                  )}
                </CCol>
              </div>
            </CRow>
            <div className="md-12 mt-3">
              <CCol md={12}>
                <CFormInput
                  type="text"
                  placeholder="Enter Asset Name"
                  label={
                    <>
                      Asset Name <span className="text-danger">*</span>
                    </>
                  }
                  name="assetName"
                  value={formik.values.assetName}
                  onChange={formik.handleChange}
                  // onBlur={formik.handleBlur}
                />
                {formik.touched.assetName && formik.errors.assetName && (
                  <div className="text-danger">{formik.errors.assetName}</div>
                )}
              </CCol>
            </div>
            <CCol md={12}>
              <div className="md-12 mt-3">
                <CFormCheck
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
                &nbsp; &nbsp;<span>Is the asset a motor vehicle?</span>
              </div>
            </CCol>
            {isChecked && (
              <CCol md={12}>
                <div className="md-12 mt-3">
                  <CFormSelect
                    aria-label="Default select example"
                    options={[
                      "Select asset category",
                      { label: "One", value: "One" },
                      { label: "Two", value: "Two" },
                    ]}
                    label={<>Asset Category</>}
                    name="assetCategory"
                    value={formik.values.assetCategory}
                    onChange={formik.handleChange}
                  />
                </div>
                <div className="md-12 mt-3">
                  <CRow>
                    <CCol>
                      <CFormInput
                        type="text"
                        id="exampleFormControlInput1"
                        placeholder="Purchase Price"
                        label="Purchase Price"
                        name="purchasePrice"
                        value={formik.values.purchasePrice}
                        onChange={formik.handleChange}
                      />
                    </CCol>
                    <CCol md="6" mb="2">
                      <label htmlFor="startDate">Date of Purchase</label>
                      <CFormInput
                        type="date"
                        id="Date"
                        name="dateofpurchase"
                        value={formik.values.dateofpurchase}
                        onChange={formik.handleChange}
                      />
                    </CCol>
                  </CRow>
                </div>
              </CCol>
            )}
            <div className="md-12 mt-3">
              <CButton
                color="primary"
                onClick={handleNext}
                type="button"
                sx={{ marginRight: 1 }}
              >
                Next
              </CButton>
              <CButton
                color="light"
                onClick={handleCancel}
                type="button"
                style={{ marginLeft: "20px" }}
              >
                Cancel
              </CButton>
            </div>
          </>
        ) : null}

        {!deprication && activeStep === 1 ? (
          <>
            <span>Is this an asset immediate write off?</span>
            <CRow>
              <CCol md={3}>
                <CFormCheck
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                  label="Yes"
                />
              </CCol>
              <CCol>
                <CFormCheck
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  label="No"
                />
              </CCol>
            </CRow>
            <div className="md-12">
              <CRow>
                <CCol md="6" mb="2">
                  <label htmlFor="startDate">Date</label>
                  <CFormInput
                    type="date"
                    id="Date"
                    name="date"
                    value={formik.values.date}
                    onChange={formik.handleChange}
                  />
                </CCol>
                <CCol>
                  <CFormSelect
                    aria-label="Default select example"
                    options={[
                      " Select Flat Rate Depreciation Method",
                      { label: "One", value: "1" },
                      { label: "Two", value: "2" },
                    ]}
                    label={
                      <>
                        Depreciation Method{" "}
                        <span className="text-danger">*</span>
                      </>
                    }
                    name="depricationmethod"
                    value={formik.values.depricationmethod}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.depricationmethod &&
                    formik.errors.depricationmethod && (
                      <div className="text-danger">
                        {formik.errors.depricationmethod}
                      </div>
                    )}
                </CCol>
              </CRow>
            </div>
            <CCol className="mt-2">
              <CFormSelect
                aria-label="Default select example"
                options={[
                  "Select Calculation Interval",
                  { label: "Yearly", value: "1" },
                  { label: "Monthly", value: "2" },
                ]}
                name="CalculationInterval"
                label={
                  <>
                    Calculation Interval <span className="text-danger">*</span>
                  </>
                }
                value={formik.values.CalculationInterval}
                onChange={formik.handleChange}
              />
              {formik.touched.CalculationInterval &&
                formik.errors.CalculationInterval && (
                  <div className="text-danger">
                    {formik.errors.CalculationInterval}
                  </div>
                )}
            </CCol>
            <div className="md-12 mt-3">
              <span>Do you wish to elect useful life method?</span>
            </div>
            <CRow>
              <CCol md={3}>
                <CFormCheck
                  type="radio"
                  name="name"
                  id="flexRadioDefault1"
                  label="Yes"
                />
              </CCol>
              <CCol>
                <CFormCheck
                  type="radio"
                  name="name"
                  id="flexRadioDefault2"
                  label="No"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol>
                <CFormInput
                  type="text"
                  id="exampleFormControlInput1"
                  placeholder="Enter Depreciation Rate"
                  label={
                    <>
                      Depreciation Rate <span className="text-danger">*</span>
                    </>
                  }
                  name="depricationrate"
                  value={formik.values.depricationrate}
                  onChange={formik.handleChange}
                />
                {formik.touched.depricationrate &&
                  formik.errors.depricationrate && (
                    <div className="text-danger">
                      {formik.errors.depricationrate}
                    </div>
                  )}
              </CCol>
              <div className="md-12 mt-3">
                <strong>Summary</strong>
              </div>
              <div className="md-12 mt-3">
                <CRow>
                  <CCol>
                    <CFormInput
                      type="text"
                      id="exampleFormControlInput1"
                      placeholder="Total Depreciation Claimed"
                      name="totaldepricationclaimed"
                      label={
                        <>
                          Total Depreciation Claimed{" "}
                          <span className="text-danger">*</span>
                        </>
                      }
                      value={formik.values.totaldepricationclaimed}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.totaldepricationclaimed &&
                      formik.errors.totaldepricationclaimed && (
                        <div className="text-danger">
                          {formik.errors.totaldepricationclaimed}
                        </div>
                      )}
                  </CCol>
                  <CCol>
                    <CFormInput
                      type="text"
                      id="exampleFormControlInput1"
                      placeholder="Current Written-Down Value"
                      name="currentwrittendown"
                      label={
                        <>
                          Current Written-down Value{" "}
                          <span className="text-danger">*</span>
                        </>
                      }
                      value={formik.values.currentwrittendown}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.currentwrittendown &&
                      formik.errors.currentwrittendown && (
                        <div className="text-danger">
                          {formik.errors.currentwrittendown}
                        </div>
                      )}
                  </CCol>
                </CRow>
              </div>
            </CRow>
            <div className="md-12 mt-3">
              <CButton
                color="primary"
                onClick={handleNext1}
                type="submit"
                sx={{ marginRight: 1 }}
              >
                Submit
              </CButton>
              <CButton
                color="light"
                onClick={handleBack}
                style={{ marginLeft: "20px" }}
              >
                Back
              </CButton>
            </div>
          </>
        ) : null}
      </CForm>

      {deprication ? (
        <CRow>
          <CCol>
            <CRow>
              <CCol md="6" mb="2">
                <div>
                  <CFormLabel className="w-100">From Date</CFormLabel>
                  <CFormInput
                    type="date"
                    id="fromDate"
                    name="fromDate"
                    value={fromDate}
                    onChange={handleFromDateChange}
                    max={
                      toDate
                        ? new Date(new Date(toDate).getTime() - 86400000)
                            .toISOString()
                            .split("T")[0]
                        : null
                    }
                    placeholder="Select From Date"
                  />
                </div>
              </CCol>
              <CCol md="6" mb="2">
                <div>
                  <CFormLabel className="w-100">To Date</CFormLabel>
                  <CFormInput
                    type="date"
                    id="toDate"
                    name="toDate"
                    value={toDate}
                    onChange={handleToDateChange}
                    min={
                      fromDate
                        ? new Date(new Date(fromDate).getTime() + 86400000)
                            .toISOString()
                            .split("T")[0]
                        : null
                    }
                    placeholder="Select To Date"
                  />
                </div>
              </CCol>
            </CRow>
            <div className=" mt-5">
              <ListItem
                style={{
                  display: "flex",
                  paddingLeft: "0",
                  paddingRight: "0",
                  justifyContent: "flex-end",
                }}
                className="additim"
              >
                {!dpstate ? (
                  <Button onClick={handleDpChange}>View Schedule</Button>
                ) : (
                  <Button onClick={handleDpChange}>Hide Schedule</Button>
                )}
              </ListItem>
              <hr />
            </div>
            {dpstate ? (
              <CCol>
                <CCardHeader>
                  <strong>Deprication diminishing method</strong>
                </CCardHeader>
                <div className="md-12 mt-3">
                  <CListGroup>
                    {schedule.map((item, index) => (
                      <CListGroupItem key={index}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span> {item.name}</span> <span>{item.value}</span>
                        </div>
                      </CListGroupItem>
                    ))}
                  </CListGroup>
                </div>
                <div className="mt-4">
                  {/* <CButton onClick={props.handleClose} color="light">
                    Back
                  </CButton> */}
                </div>
              </CCol>
            ) : null}
          </CCol>
        </CRow>
      ) : null}
    </Box>
  );
};
export default Add;
