import {
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CTableHead,
  CTableRow,
  CButton,
  CCardBody,
  CCard,
  CImage,
  CModalBody,
  CModalHeader,
  CModalFooter,
  CModal,
  CTooltip,
  CSpinner
} from "@coreui/react";
import React, { useEffect, useState, useMemo } from "react";
import { DeleteApiwithHeader, GetApiwithHeader } from "src/Api/Api";
import { deleteIcon, edit, download } from "src/components/Images/Images";
import OffCanvas from "../../share/offCanvas";
import Add from "./AddInvitation";
// import FileUpload from '../../../components/share/import'
import { postApiWithHeader } from "src/Api/Api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { userAttributes } from "../../share/localData";
import { useData } from "../../components/dataContext";
import { DataGrid } from "@mui/x-data-grid";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { LicenseInfo } from '@mui/x-license';

const Invitation = () => {
  LicenseInfo.setLicenseKey('248a71a5e79c492f4b13769843fcf6dcTz04OTIzMCxFPTE3NDU3NTY1ODYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');
  const [services, setServices] = useState([]);
  const [editValue, setEditValue] = useState();
  const [visible, setVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isUploadModel, setIsUploadModel] = useState(false);
  const [csvData, setCsvData] = useState();
  const [importFile, setFileImport] = useState();
  const [isDeleting, setIsDeleting] = useState(false);
  const [clearFormDataFlag, setClearFormDataFlag] = useState(false);
  const [loadingState, setLoadingState] = useState(true)
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(15);
  const accessToken = localStorage.getItem("token");
  const [searchQuery, setSearchQuery] = useState("");
  const [getRolesOpt, setGetRolesOpt] = useState([]);
  const { cmpID, bankID } = userAttributes();
  const { data } = useData();

  const headers = useMemo(
    () => ({
      Authorization: `Bearer ${accessToken}`,
    }),
    [accessToken]
  );

  const getRoles = async () => {
    try {
      const response = await GetApiwithHeader(
        `api/auth/RolesByInviter?compnayId=${cmpID}&inviter=BusinessOwner`,
        headers
      );
      const filteredRoles =
        response?.data?.data &&
        response?.data?.data?.filter((item) => item?.name);
      console.log("filteredRoles", filteredRoles);
      setGetRolesOpt(filteredRoles);
    } catch (error) {
      console.log(error);
    }
  };

  const headersFile = useMemo(
    () => ({
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "multipart/form-data",
    }),
    [accessToken]
  );
  const fetchData = async () => {
    setLoadingState(true)
    try {
      const res = await GetApiwithHeader(
        `/api/invitation?companyId=${cmpID}`,
        headers
      );
      if (res.data) setLoadingState(false)
      if (res.data && res.data.data && res.data.data.length > 0) {
        setServices(res.data && res.data.data);
      } else {
        setServices([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const filteredServices = useMemo(() => {
    return services?.filter(
      (service) =>
        service?.receiverName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        service?.receiverMobile?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        service?.role?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        service?.status?.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [services, searchQuery]);
  

  useEffect(() => {
    fetchData();
    getRoles();
  }, [headers, cmpID, bankID]);

  const handleAddClick = () => {
    getRoles();
    setEditValue();
    setVisible(true);
  };

  const callBack = (data) => {
    setCsvData(data);
  };

  const callBackFile = (data) => {
    setFileImport(data);
  };

  // const handleUpload = () => {
  //   console.log('ee')
  //   setIsUploadModel(true)
  // }

  const handleCloseClick = () => {
    setVisible(false);
    setClearFormDataFlag((prev) => !prev);
    setEditValue();
  };

  const handleEditClick = (i) => {
    setEditValue(i);
    setVisible(true);
  };

  const handleDelete = (i) => {
    setEditValue(i);
    setIsModalVisible(true);
  };

  const handleDeleteClick = async (value) => {
    setIsDeleting(true);
    const apiUrl = `/api/invitation/delete/${value?.id},${cmpID}?userId=${value?.receiverId}&role=${value?.role}`;
    try {
      const response = await DeleteApiwithHeader(apiUrl, headers);
      if (response.message === "Success") {
        setIsModalVisible(false);
        // Fetch data again after deletion
        fetchData();
        toast.success("Deleted Successfully", { autoClose: 1000 });
      } else {
        console.error("Unexpected response format:", response);
      }
    } catch (error) {
      console.error("Delete API Request Error:", error);
    } finally {
      setIsDeleting(false); // Reset loading state regardless of success or failure
    }
  };

  const handleSampleDownload = async (item) => {
    try {
      const res = await GetApiwithHeader(
        `/api/BankTransactions/GetCsv/0,OverZero_Invitee_List.csv`,
        headers
      );
      const fileUrl = res?.data?.data?.url;
      console.log("res?.data?.data?.url", res?.data?.data?.url);
      if (fileUrl) {
        window.open(fileUrl, "_blank");
      } else {
        console.log("File URL not found");
      }
      // console.log("view", res?.data?.data?.url);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUploads = async () => {
    const formData = new FormData();
    formData.append("file", importFile);
    try {
      const response = await postApiWithHeader(
        `/api/invitation/invitation`,
        formData,
        headersFile
      );
      console.log("response", response);
      if (response) {
        fetchData();
        setIsUploadModel(false);
        // toast.success('File Uploaded Sucessfully')
      }
      // return response;
    } catch (error) {
      console.error("Error creating file:", error);
      throw error; // Re-throwing the error to propagate it further if necessary
    }
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  

  // const sortedItems = () => {
  //   const sortableItems = [...filteredServices];
  //   if (sortConfig !== null) {
  //     sortableItems.sort((a, b) => {
  //       if (a[sortConfig.key] < b[sortConfig.key]) {
  //         return sortConfig.direction === "ascending" ? -1 : 1;
  //       }
  //       if (a[sortConfig.key] > b[sortConfig.key]) {
  //         return sortConfig.direction === "ascending" ? 1 : -1;
  //       }
  //       return 0;
  //     });
  //   }
  //   return sortableItems;
  // };

  // const sortedItems = useMemo(() => {
  //   const sortableItems = [...filteredServices];
  //   if (sortConfig.key !== null) {
  //     sortableItems.sort((a, b) => {
  //       if (a[sortConfig.key] < b[sortConfig.key]) {
  //         return sortConfig.direction === "ascending" ? -1 : 1;
  //       }
  //       if (a[sortConfig.key] > b[sortConfig.key]) {
  //         return sortConfig.direction === "ascending" ? 1 : -1;
  //       }
  //       return 0;
  //     });
  //   }
  //   return sortableItems;
  // }, [filteredServices, sortConfig]);
  

  const handlePrevPage = (e) => {
    setCurrentPage(currentPage - 1);
  };

  // Function to handle next page
  const handleNextPage = (e) => {
    setCurrentPage(currentPage + 1);
  };

  const totalPages = Math.ceil(services?.length / itemsPerPage);

  const getCurrentPageData = () => {
    const sortedData = sortedItems();
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return sortedData?.slice(startIndex, endIndex);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  console.log("services", services);
  const column = [
    { field: "receiverName", headerName: "User Name", width: 200, headerClassName: 'dataGridHeader' },
    { field: "receiverMobile", headerName: "Mobile Number", width: 200, headerClassName: 'dataGridHeader' },
    { field: "role", headerName: " Role", width: 250, headerClassName: 'dataGridHeader' },
    { field: "status", headerName: "Status", width: 180, headerClassName: 'dataGridHeader' },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      type: "actions",
      headerClassName: 'dataGridHeader',
      renderCell: ({ row }) => {
        return (
          <div className="rowIcon">
            {/* <CTooltip content="Edit">
              <button
                className="icon-editIcon"
                onClick={() => handleEditClick(row)}
              ></button>
            </CTooltip> */}
            <CTooltip content="Delete">
              <button
                className="icon-deleteIcon"
                onClick={() => handleDelete(row)}
              ></button>
            </CTooltip>
            {row?.status === "Pending" ? (
              <CButton> Resend</CButton>
            ) : (
              <CButton>Chat</CButton>
            )}
          </div>
        );
      },
    },
  ];
  return (
    <>
      <div className="cardBody">
        <ToastContainer position="top-right" autoClose={1000} />
        <div className="cardHeader">
          <h3>Invitation</h3>
          <div className="mr-1">
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)} // Step 2: Add input field for search query
            />
            <CButton className="mr-1" onClick={() => handleAddClick()}>
              Add Invitation
            </CButton>
            {/* <CButton onClick={() => setIsUploadModel(!isUploadModel)}>Import</CButton> */}
            {/* <CTooltip content="Sample Download">
                  <button className='editIcon'>
                    <CImage
                      src={download}
                      onClick={handleSampleDownload}
                       />
                  </button>
                </CTooltip> */}
          </div>
        </div>
        <OffCanvas
          Open={visible}
          title={editValue ? "Edit Invitation" : "Add Invitation"}
          handleCloseClick={handleCloseClick}
          component={
            <Add
              apiCall={fetchData}
              handleClose={handleCloseClick}
              editValue={editValue}
              clearFormData={clearFormDataFlag}
              getRolesOpt={getRolesOpt}
            />
          }
        />
        <div className="datatableGrid">
          <DataGridPro
            rows={filteredServices}
            columns={column}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 15 },
              },
            }}
            pageSizeOptions={[5, 10]}
            density="compact"
            disableRowSelectionOnClick
            loading={loadingState}
          />
        </div>
        <CModal
          visible={isModalVisible}
          onClose={() => setIsModalVisible(false)}
          aria-labelledby="ScrollingLongContentExampleLabel"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflow: "auto",
          }}
        >
          <CModalHeader>
            <h3>Delete</h3>
          </CModalHeader>
          <CModalBody>
            Are you sure want to delete {editValue?.receiverName}...?
          </CModalBody>
          <CModalFooter>
            <CButton color="secondary" onClick={() => setIsModalVisible(false)}>
              No
            </CButton>
            <CButton
              color="primary"
              onClick={() => handleDeleteClick(editValue)}
            >
              {isDeleting ? (
              <CSpinner color="white" size="sm" />
            ) : (
              "Yes"
            )}
            </CButton>
          </CModalFooter>
        </CModal>
        {/* <CModal
          alignment="center"
          visible={isUploadModel}
          onClose={() => setIsUploadModel(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader></CModalHeader>
          <CModalBody>
            <FileUpload
              handleCloseClick={handleCloseClick}
              callBackFile={callBackFile}
              callBack={callBack}
              handleUpload={handleUploads}
            />
          </CModalBody>
        </CModal> */}
      </div>
    </>
  );
};

export default Invitation;
