// import React, { useCallback, useState } from 'react';
// import { CForm, CFormInput, CButton, CRow, CCol, CFormTextarea, CFormLabel, CImage, CContainer, CCardGroup, CCard, CCardBody } from '@coreui/react';
// import logo from "src/assets/brand/logo.png";
// import { useDropzone } from "react-dropzone";
// import CIcon from '@coreui/icons-react';
// import uploads from '../../assets/images/upload_icon.png'

// const CreditApplicationForm = () => {

//     const [fileData, setFileData] = useState(null);

//     const handleFileChange = (event) => {
//         const file = event.target.files[0];
//         setFileData(file);
//     };

//     //   const parseCSVData = useCallback(
//     //     (csvData) => {
//     //       const rowData = [];
//     //       const rows = csvData.split("\n");
//     //       const headers = rows[0].split(",").map((header) => header);
//     //       props.callBack(headers, rows);
//     //     },
//     //     [props.callBack]
//     //   );

//     const onDrop = () => { }
//     //    useCallback(
//     //     (acceptedFiles) => {
//     //       const file = acceptedFiles[0];
//     //       if (file.type !== "text/csv") {
//     //         toast.error("Please upload a .CSV file",{autoClose:1000});
//     //         return;
//     //       }
//     //       const reader = new FileReader();

//     //       reader.onload = (event) => {
//     //         const fileData = event.target.result;
//     //         const data = fileData.split('\n').map(row => row.split(','));
//     //         parseCSVData(fileData);
//     //         setFileData(file);
//     //       };
//     //       reader.readAsText(file);
//     //     },
//     //     [parseCSVData]
//     //   );

//     const { getRootProps, getInputProps, isDragActive } = useDropzone({
//         onDrop,
//         accept: { "text/csv": ['.csv'] },
//     });

//     //   const handleSave = () => {
//     //     if (fileData && fileData.type === "text/csv") {
//     //       props.callBackFile(fileData);
//     //       props.handleCloseClick();
//     //     } else {
//     //       toast.warning("Please Upload a file", {autoClose:1000});
//     //     }
//     //   };

//     return (
//         <div className="addBox" >
//             <CContainer>
//                 <CRow className="justify-content-center">
//                     <CCol md={6}>
//                         <CCardGroup>
//                             <CCard className="p-4">
//                                 <CCardBody>
//                                     <CForm>
//                                         <div className="clearfix">
//                                             <CImage
//                                                 align="center"
//                                                 rounded
//                                                 src={logo}
//                                                 className="mb-3"
//                                             />
//                                         </div>
//                                         <h2 style={{ marginBottom: '20px' }}>Application for Credit</h2>
//                                         <CRow className="mb-3">
//                                             <CCol>
//                                                 <CFormLabel>Name of Purchaser</CFormLabel>
//                                                 <CFormInput type="text" placeholder="Enter purchase name" />
//                                             </CCol>
//                                             <CCol>
//                                                 <CFormLabel>ABN Number</CFormLabel>
//                                                 <CFormInput type="text" placeholder="12345678978" />
//                                             </CCol>
//                                         </CRow>
//                                         <CRow className="mb-3">
//                                             <CCol>
//                                                 <CFormLabel>Company Name</CFormLabel>
//                                                 <CFormInput type="text" placeholder="Enter company name" />
//                                             </CCol>
//                                             <CCol>
//                                                 <CFormLabel>Recipient email</CFormLabel>
//                                                 <CFormInput type="email" placeholder="Select product name" />
//                                             </CCol>
//                                         </CRow>

//                                         <CRow className="mb-3">
//                                             <CCol>
//                                                 <CFormTextarea placeholder="Type address" label="Address"></CFormTextarea>
//                                                 {/* <CFormInput type="text" placeholder="Type address" /> */}
//                                             </CCol>
//                                         </CRow>
//                                         <CRow className="mb-3">
//                                             <CCol md={6}>
//                                                 <CFormLabel>Driving License Number</CFormLabel>
//                                                 <CFormInput type="text" placeholder="Enter license number" />
//                                             </CCol>
//                                             {/* <CCol md={6}>
//                                                 <CFormLabel>Upload Driving License</CFormLabel>
//                                                 <div className="custom-file">
//                                                     <input type="file" className="custom-file-input" id="customFile" />
//                                                     <label className="custom-file-label" htmlFor="customFile">
//                                                         Upload Driving License
//                                                     </label>
//                                                 </div>
//                                             </CCol> */}
//                                             <CCol md={6} style={{ width: '269px' }} className="d-flex flex-column uploadBox">
//                                                 <div
//                                                     {...getRootProps()}
//                                                     className={`dropzone ${isDragActive ? "active" : ""}`} >
//                                                     <input {...getInputProps()} />
//                                                     <CImage src={uploads} height="50px" />
//                                                     <CButton > Upload Driving License</CButton>
//                                                     {/* <p className="mt-2 text-center">or drag and drop here</p> */}
//                                                 </div>
//                                             </CCol>
//                                         </CRow>
//                                         <CRow>
//                                         </CRow>
//                                         <CRow>
//                                             <CCol className="text-end">
//                                                 <CButton type="submit">Submit</CButton>
//                                             </CCol>
//                                         </CRow>
//                                     </CForm>
//                                 </CCardBody>
//                             </CCard>
//                         </CCardGroup>
//                     </CCol>
//                 </CRow>
//             </CContainer>
//         </div>
//     );
// };

// export default CreditApplicationForm;

import React, { useCallback, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CForm, CFormInput, CButton, CRow, CCol, CFormTextarea, CFormLabel, CImage, CContainer, CCardGroup, CCard, CCardBody } from '@coreui/react';
import logo from "src/assets/brand/logo.png";
import { useDropzone } from "react-dropzone";
import uploads from '../../assets/images/upload_icon.png';

const CreditApplicationForm = () => {
    const [fileData, setFileData] = useState(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setFileData(file);
    };

    const onDrop = (acceptedFiles) => {
        const file = acceptedFiles[0];
        setFileData(file);
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: { "image/*": ['.jpeg', '.jpg', '.png'] },
    });

    const formik = useFormik({
        initialValues: {
            nameOfPurchaser: '',
            abnNumber: '',
            companyName: '',
            recipientEmail: '',
            address: '',
            drivingLicenseNumber: '',
        },
        // validationSchema: Yup.object({
        //     nameOfPurchaser: Yup.string().required('Required'),
        //     abnNumber: Yup.string().required('Required'),
        //     companyName: Yup.string().required('Required'),
        //     recipientEmail: Yup.string().email('Invalid email address').required('Required'),
        //     address: Yup.string().required('Required'),
        //     drivingLicenseNumber: Yup.string().required('Required'),
        // }),
        onSubmit: values => {
            console.log(values);
            if (fileData) {
                console.log(fileData);
            }
        },
    });

    return (
        <div className="addBox">
            <CContainer>
                <CRow className="justify-content-center">
                    <CCol md={6}>
                        <CCardGroup>
                            <CCard className="p-4">
                                <CCardBody>
                                    <div className="clearfix">
                                        <CImage
                                            align="center"
                                            rounded
                                            src={logo}
                                            className="mb-3"
                                        />
                                    </div>
                                    <CForm onSubmit={formik.handleSubmit}>

                                        <h2 style={{ marginBottom: '20px' }}>Application for Credit</h2>
                                        <hr />
                                        <CRow className="mb-3">
                                            <CCol>
                                                <CFormLabel>Name of Purchaser</CFormLabel>
                                                <CFormInput
                                                    type="text"
                                                    placeholder="Enter purchase name"
                                                    {...formik.getFieldProps('nameOfPurchaser')}
                                                />
                                                {formik.touched.nameOfPurchaser && formik.errors.nameOfPurchaser ? (
                                                    <div className="text-danger">{formik.errors.nameOfPurchaser}</div>
                                                ) : null}
                                            </CCol>
                                            <CCol>
                                                <CFormLabel>ABN Number</CFormLabel>
                                                <CFormInput
                                                    type="text"
                                                    placeholder="12345678978"
                                                    {...formik.getFieldProps('abnNumber')}
                                                />
                                                {formik.touched.abnNumber && formik.errors.abnNumber ? (
                                                    <div className="text-danger">{formik.errors.abnNumber}</div>
                                                ) : null}
                                            </CCol>
                                        </CRow>
                                        <CRow className="mb-3">
                                            <CCol>
                                                <CFormLabel>Company Name</CFormLabel>
                                                <CFormInput
                                                    type="text"
                                                    placeholder="Enter company name"
                                                    {...formik.getFieldProps('companyName')}
                                                />
                                                {formik.touched.companyName && formik.errors.companyName ? (
                                                    <div className="text-danger">{formik.errors.companyName}</div>
                                                ) : null}
                                            </CCol>
                                            <CCol>
                                                <CFormLabel>Recipient email</CFormLabel>
                                                <CFormInput
                                                    type="email"
                                                    placeholder="Select product name"
                                                    {...formik.getFieldProps('recipientEmail')}
                                                />
                                                {formik.touched.recipientEmail && formik.errors.recipientEmail ? (
                                                    <div className="text-danger">{formik.errors.recipientEmail}</div>
                                                ) : null}
                                            </CCol>
                                        </CRow>

                                        <CRow className="mb-3">
                                            <CCol>
                                                <CFormLabel>Address</CFormLabel>
                                                <CFormTextarea
                                                    placeholder="Type address"
                                                    {...formik.getFieldProps('address')}
                                                />
                                                {formik.touched.address && formik.errors.address ? (
                                                    <div className="text-danger">{formik.errors.address}</div>
                                                ) : null}
                                            </CCol>
                                        </CRow>
                                        <CRow className="mb-3">
                                            <CCol md={6}>
                                                <CFormLabel>Driving License Number</CFormLabel>
                                                <CFormInput
                                                    type="text"
                                                    placeholder="Enter license number"
                                                    {...formik.getFieldProps('drivingLicenseNumber')}
                                                />
                                                {formik.touched.drivingLicenseNumber && formik.errors.drivingLicenseNumber ? (
                                                    <div className="text-danger">{formik.errors.drivingLicenseNumber}</div>
                                                ) : null}
                                            </CCol>
                                            <CCol md={6} style={{ width: '269px' }} className="d-flex flex-column uploadBox">
                                                <div
                                                    {...getRootProps()}
                                                    className={`dropzone ${isDragActive ? "active" : ""}`} >
                                                    <input {...getInputProps()} />
                                                    <CImage src={uploads} height="50px" />
                                                    <CButton>Upload Driving License</CButton>
                                                </div>
                                            </CCol>
                                        </CRow>
                                        <CRow>
                                            <CCol className="text-end">
                                                <CButton type="submit">Submit</CButton>
                                            </CCol>
                                        </CRow>
                                    </CForm>
                                </CCardBody>
                            </CCard>
                        </CCardGroup>
                    </CCol>
                </CRow>
            </CContainer>
        </div>
    );
};

export default CreditApplicationForm;

