import React, { useMemo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CButton,
  CTableHead,
  CTableRow,
  CCol,
  CCardBody,
  CCard,
  CImage,
  CModalBody,
  CModalHeader,
  CModalFooter,
  CModal,
  CTooltip,
  CSpinner,
} from "@coreui/react";
import { GetApiwithHeader, DeleteApiwithHeader } from "src/Api/Api";
import { deleteIcon, edit } from "src/components/Images/Images";
import OffCanvas from "../../share/offCanvas";
import { userAttributes } from "../../share/localData";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useData } from "../../components/dataContext";
import AddComany from "./AddCompany";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { LicenseInfo } from '@mui/x-license';

const CompanyDetail = () => {
  const { data, updateData, removeBank, removeData, setTriggerRenderHeaderOnCompanyAdd } = useData();
  const navigate = useNavigate();
  LicenseInfo.setLicenseKey('248a71a5e79c492f4b13769843fcf6dcTz04OTIzMCxFPTE3NDU3NTY1ODYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [companyData, setCompanyData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const totalPages = Math.ceil(companyData.length / itemsPerPage);
  const { cmpID } = userAttributes();
  const accessToken = localStorage.getItem("token");
  const [visible, setVisible] = useState(false);
  const [editValue, setEditValue] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const SerialNumber = itemsPerPage * currentPage - itemsPerPage;
  const [loadingState, setLoadingState] = useState(true)
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
  const headers = useMemo(
    () => ({
      Authorization: `Bearer ${accessToken}`,
    }),
    [accessToken]
  );
  const companyId = cmpID;

  const filteredServices = useMemo(() => {
    return companyData.filter(
      (service) =>
        service.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        parseFloat(service?.id)
          .toString()
          .includes(searchQuery.toLowerCase()) ||
        service.role.toLowerCase().includes(searchQuery.toLowerCase()) ||
        service.abn.toLowerCase().includes(searchQuery.toLowerCase()) ||
        service.phoneNumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
        service.address1.toLowerCase().includes(searchQuery.toLowerCase()) ||
        service.companyEmail
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        service.createdAt.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [companyData, searchQuery]);

  const fetchData = async () => {
    setLoadingState(true)
    try {
      const res = await GetApiwithHeader(`/api/company`, headers);
      if (res?.data)  setLoadingState(false)
      setCompanyData(res?.data?.data || []); // Set default value to empty array if data is undefined
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [headers, companyId, cmpID]);

  const [valueData, setValueData] = useState();
  const handleDelete = (i) => {
    setValueData(i);
    setIsModalVisible(true);
  };

  const handleDeleteApi = async (id) => {
    setIsDeleting(true);
    const apiUrl = `/api/company/delete/${id}`;
    try {
      const response = await DeleteApiwithHeader(apiUrl, headers);
      if (response.message === "Success") {
        setIsModalVisible(false);
        fetchData();
        await removeData();
        setTriggerRenderHeaderOnCompanyAdd(true);
        toast.success("Company deleted successfully", { autoClose: 1000 });
      } else {
        console.error("Unexpected response format:", response);
      }
    } catch (error) {
      console.error("Delete API Request Error:", error);
    } finally {
      setIsDeleting(false); // Reset loading state regardless of success or failure
    }
  };

  const handleAddClick = () => {
    setVisible(true);
  };

  const handleCloseClick = () => {
    setEditValue();
    setVisible(false);
  };
  const handleEditClick = (i) => {
    setVisible(true);
    setEditValue(i);
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const sortedItems = () => {
    const sortableItems = [...filteredServices];
    if (sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        const aValue =
          typeof a[sortConfig.key] === "string"
            ? a[sortConfig.key].toLowerCase()
            : a[sortConfig.key];
        const bValue =
          typeof b[sortConfig.key] === "string"
            ? b[sortConfig.key].toLowerCase()
            : b[sortConfig.key];

        if (aValue < bValue) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  };

  // Function to get the current page's data
  const getCurrentPageData = () => {
    const sortedData = sortedItems();
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return sortedData.slice(startIndex, endIndex);
  };
  // Function to handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  // Function to handle previous page
  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  // Function to handle next page
  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const formatDate = (dateString) => {
    const options = { day: "numeric", month: "numeric", year: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-AU", options);
  };

  const redirectToCoding = async(row) => {
    const Data = {
      company: {
        Id: row?.id,
        Name: row?.name,
        role: row?.role
      },
    };
    await updateData(Data);
    await removeBank();
    setTriggerRenderHeaderOnCompanyAdd(true);
    navigate("/coding");
  }

  console.log('filteredServices', filteredServices)
  const column = [
    { field: "name", headerName: "Company Name", width: 150, headerClassName: 'dataGridHeader' },
    { field: "abn", headerName: " ABN", width: 150, headerClassName: 'dataGridHeader' },
    { field: "industryName", headerName: "Industry Type", width: 150, headerClassName: 'dataGridHeader' },
    { field: "phoneNumber", headerName: "Mobile Number", width: 150, headerClassName: 'dataGridHeader' },
    { field: "address1", headerName: "Address", width: 150, headerClassName: 'dataGridHeader' },
    { field: "companyEmail", headerName: "Email", width: 150, headerClassName: 'dataGridHeader' },
    {
      field: "createdAt", headerName: "Created At", width: 150, headerClassName: 'dataGridHeader',
      valueGetter: ((row) => {
        return new Date(row)?.toLocaleDateString("en-GB")
      })
    },
    {
      field: "action", headerName: "Action", width: 160, headerClassName: 'dataGridHeader',
      type: "actions",
      renderCell: (({ row }) => {
        return (
          <div className="rowIcon">
            <CTooltip content="Edit">
              <button className="icon-editIcon" onClick={() => handleEditClick(row)}></button>

            </CTooltip>
            <CTooltip content="Delete">
              <button className="icon-deleteIcon" onClick={() => handleDelete(row)}></button>
            </CTooltip>
            <CTooltip content="Coding">
              <CButton className="btn-light" onClick={() => redirectToCoding(row)}>Select</CButton>
            </CTooltip>
          </div>
        )
      })
    },
  ];
  return (
    <div className="cardBody">
      <ToastContainer position="top-right" />
      <div className="cardHeader">
        <h3>Company Information</h3>
        <div className="mr-1">
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)} // Step 2: Add input field for search query
          />
          <CButton onClick={() => handleAddClick()}>Add Company</CButton>
        </div>
      </div>

      <OffCanvas
        Open={visible}
        title={editValue ? "Edit Company" : "Add Company"}
        handleCloseClick={handleCloseClick}
        component={
          <AddComany
            editValue={editValue}
            handleClose={handleCloseClick}
            fetchData={fetchData}
          />
        }
      />
      <div className="datatableGrid">
        <DataGridPro
          rows={filteredServices}
          columns={column}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 15 },
            },
          }}
          pageSizeOptions={[5, 10]}
          density="compact"
          disableRowSelectionOnClick
          loading={loadingState}
          sx={{
            '& .MuiDataGrid-columnHeaderRow': {
              background: '#EAF0F2'
            }
          }}
        />
      </div>
      <CModal
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        aria-labelledby="ScrollingLongContentExampleLabel"
        className="overley"
      >
        <CModalHeader>
          <h3>Delete</h3>
        </CModalHeader>
        <CModalBody>
          Are you sure want to delete {valueData?.name} ...?
        </CModalBody>
        <CModalFooter>
          <CButton className="btn-light mr-2" onClick={() => setIsModalVisible(false)}>
            Close
          </CButton>
          <CButton
            className="btn-primary"
            onClick={() => handleDeleteApi(valueData?.id)}
          >
            {isDeleting ? (
              <CSpinner color="white" size="sm" />
            ) : (
              "Delete"
            )}

          </CButton>
        </CModalFooter>
      </CModal>
    </div>
  );
};

export default CompanyDetail;
