// Importing necessary dependencies and components
import React, { useState, useEffect } from 'react';
import { CButton, CForm, CFormInput, CFormLabel, CImage, CTooltip } from '@coreui/react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { postApi, generateOtp, GetApi, GetApiParam } from '../../Api/Api';
import logo from 'src/assets/brand/logo.png'
import CryptoJS from 'crypto-js';
import CIcon from '@coreui/icons-react';
import { cilCheckCircle } from '@coreui/icons';
import axios from 'axios';

const appKey = "b60c!329%a4f4233bVce2ea2515ayu24";
const initializationVector = "1503199371628005";

const decryptString = (cipherText) => {
  const appKeyBytes = CryptoJS.enc.Utf8.parse(appKey);
  const ivBytes = CryptoJS.enc.Utf8.parse(initializationVector);
  const cipherParams = CryptoJS.lib.CipherParams.create({
    ciphertext: CryptoJS.enc.Base64.parse(cipherText)
  });

  const decrypted = CryptoJS.AES.decrypt(
    cipherParams,
    appKeyBytes,
    { iv: ivBytes }
  );

  return decrypted.toString(CryptoJS.enc.Utf8);
};

// Signup component
const Signup = () => {
  const [companyIds, setCompanyIds] = useState()
  const [getRole, setGetRole] = useState()
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  const [queryString, setQueryString] = useState("");
  const [invitaionCode, setInvitaionCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const decryptedText = decryptString(queryString);
  const [abnValue, setAbnValue] = useState();
  const [iconStatus, setIconStatus] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [verifyMsg, setVerifyMsg] = useState("");
  const [abnValid, setAbnValid] = useState(true);

  useEffect(() => {
    const queryParams = decryptedText.split('&');

    let extractedInvitationCode = '';
    let extractedPhoneNumber = '';
    let extractedCompanyId = '';
    let extractedRole = '';

    queryParams.forEach(param => {
      const [key, value] = param.split('=');
      if (key === 'invitaionCode') {
        extractedInvitationCode = value;
      } else if (key === 'mobileNo') {
        extractedPhoneNumber = value;
      } else if (key === 'companyId') {
        extractedCompanyId = value;
      } else if (key === 'role') {
        extractedRole = value;
      }
    });

    // Set the values in the component state
    setInvitaionCode(extractedInvitationCode);
    setPhoneNumber(extractedPhoneNumber);
    setCompanyIds(extractedCompanyId);
    setGetRole(extractedRole);
  }, [decryptedText]);




  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    taxAccountantNumber: '',
    phoneNumber: '',
    isBusiness: '',
    isPromotor: '',
    promoterId: 0,
    referredBy: '',
    address: '',
    role: '',
    CompanyId: 0,
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    let queryParamsString = searchParams.toString();
    queryParamsString = queryParamsString.replace(/%2F/g, '/');
    setQueryString(queryParamsString);
  }, [location.search]);


  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      CompanyId: companyIds,
      role: getRole ? getRole : getRole,
      referredBy: invitaionCode ? invitaionCode : '',
      phoneNumber: phoneNumber,
    }));

  }, [companyIds, getRole]);


  useEffect(() => {

    if (getRole === "BusinessOwner") {
      setFormData(prevFormData => ({ ...prevFormData, isBusiness: true, isPromotor: false }));
    } else if (getRole === "Promoter") {
      setFormData(prevFormData => ({ ...prevFormData, isBusiness: false, isPromotor: true }));
    } else {
      setFormData(prevFormData => ({ ...prevFormData, isBusiness: false, isPromotor: false }));
    }
  }, [getRole]);

  const handleOnChange = (value, country) => {
    const sanitizedPhoneNumber = value.replace(/^0+/, '');
    const countryCode = country?.dialCode || '';
    const formattedPhoneNumber = `+${sanitizedPhoneNumber}`;
    setFormData(prevData => ({ ...prevData, phoneNumber: formattedPhoneNumber }));
  };

  const handleInputChange = ({ target: { name, value } }) => {
    setErrors(prevErrors => ({ ...prevErrors, [name]: '' }));
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      console.log('formData', formData)
      // return
      try {
        const response = await postApi('api/auth/register', formData);
        if (!response?.data) {
          toast.error('Failed to submit form data');
        } else {
          if (response?.data?.statusCode === 101) {
            toast.error(`${response.data.message}`, { autoClose: 1000 });
          }
          else
            if (response?.data?.statusCode === 102) {
              toast.error(`${response.data.message}`, { autoClose: 1000 });
            }

            else {
              const userId = response?.data?.data?.id;
              localStorage.setItem('userId', userId);

              // Generating OTP for the user
              const otpResponse = await generateOtp(`api/auth/GenerateOtp/${userId}`);
              console.log('OTP generation response:', otpResponse.data);

              // Handling OTP generation response
              if (otpResponse && otpResponse?.data) {
                if (otpResponse?.data?.statusCode === 101 && otpResponse?.data?.message === 'User is not valid') {
                  // console.error('User is not valid during OTP generation:', otpResponse.message);
                  toast.error('Please enter valid user');
                } else {
                  navigate('/verifyOtp');
                }
              } else {
                toast.error('Failed to generate OTP. Response:', otpResponse);
              }
            }
        }
      } catch (error) {
        console.error('Error submitting form data:', error);
      }
    }
  };

  // Form validation function
  const validateForm = () => {
    const requiredFields = new Map([
      ['firstName', 'First Name'],
      ['lastName', 'Last Name'],
      ['email', 'Email Address'],
      ['password', 'Password'],
      ['confirmPassword', 'Confirm Password'],
      ['address', 'Address'],
      // ['phoneNumber', 'Mobile Number'],
      // ['registrationCode', 'Registration Code']
    ]);

    // Object to store validation errors
    const newErrors = {};

    // Checking each required field for empty values
    requiredFields.forEach((label, field) => {
      if (!formData[field].trim()) {
        newErrors[field] = `${label} is required`;
      }
    });


    // Checking if passwords match
    if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = 'Passwords do not match';
    }

    // Checking if password is empty
    if (!formData.password.trim()) {
      newErrors.password = 'Password is required';
    } else {
      // Checking if password contains at least one alphabetic and one numeric character
      if (!/(?=.*[A-Za-z])(?=.*\d)/.test(formData.password)) {
        newErrors.password = 'Password must contain at least one letter and one number';
      }
    }

    // if (getRole) {
    //   const taxNumberLength = formData.taxAccountantNumber.trim().length;
    //   if (taxNumberLength !== 10) {
    //     newErrors.taxAccountantNumber = (getRole === 'PrincipalAccountant') ? 'Tax account number must be exactly 10 digits' : 'ABN number must be exactly 10 digits';
    //   }
    // }

    if (getRole) {
      // Validate ABN or Tax Account Number length
      const taxNumberLength = formData.taxAccountantNumber.trim().length;
      if (getRole === 'PrincipalAccountant') {
        if (taxNumberLength !== 10) {
          newErrors.taxAccountantNumber = 'Tax account number must be exactly 10 digits';
        }
      } else {
        // For ABN Number validation (9-11 digits)
        if (taxNumberLength < 9 || taxNumberLength > 11) {
          newErrors.taxAccountantNumber = 'ABN number must be between 9 to 11 digits';
        }
        else if (iconStatus === false) {
          // toast.error('Please verify ABN number', { autoClose: 1000 })
          newErrors.taxAccountantNumber = 'Please verify ABN number'
        }
      }
    }
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };
  console.log('companyIds', companyIds, abnValue)
  
  const handleVerify = async (companyIds, abnValue) => {
    // return
    try {

      const res = await GetApi(
        `api/abn/ABN-Details?companyId=${companyIds}&abn=${abnValue}`
      );
      console.log('res', res)
      if (res.statusCode === 101) {
        setVerifyMsg("Please enter correct ABN number");
        setSaveDisabled(true);
        setIconStatus(false);
      } else {
        setIconStatus(true);
        setVerifyMsg("ABN Verified.");
        setSaveDisabled(false);
      }
      setTimeout(() => {
        setVerifyMsg("");
      }, 3000);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeAbn = (e) => {
    const value = e.target.value;
    setFormData(prevData => ({ ...prevData, taxAccountantNumber: value }));
    setErrors(prevErrors => ({ ...prevErrors, taxAccountantNumber: '' }));
    setAbnValid(true); // Reset the ABN validation state
    setIconStatus(false)
    setAbnValue(value);
    setFormData(prevData => ({ ...prevData, taxAccountantNumber: value }));
    if (value.length < 11) {
      setVerifyMsg("");
    }
    // else {
      // Check if ABN is verified, if not, set error message
      // if (iconStatus === false) {
      //   setVerifyMsg("Please verify ABN");
      //   setTimeout(() => {
      //     setVerifyMsg("");
      //   }, 3000);
      // } 
      else {
        setVerifyMsg(""); // Clear error message if ABN is verified
      }
    // }
    setAbnValid(true);
  };

  return (
    <div className='m-5'>
      <ToastContainer />
      <div className="clearfix">
        <CImage align="center" rounded src={logo} className='mb-3' />
      </div>
      <CForm onSubmit={handleSubmit}>
        <h5 className='mb-4'>User Registration</h5>
        <div className="mb-3">
          <CFormLabel>First Name<span className="text-danger">*</span></CFormLabel>
          <CFormInput
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleInputChange}
            className={errors.firstName ? 'is-invalid' : ''}
          />
          {errors.firstName && <div className="invalid-feedback">{errors.firstName}</div>}
        </div>
        <div className="mb-3">
          <CFormLabel >Last Name<span className="text-danger">*</span></CFormLabel>
          <CFormInput type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleInputChange}
            className={errors.lastName ? 'is-invalid' : ''} />
          {errors.lastName && <div className="invalid-feedback">{errors.lastName}</div>}
        </div>
        <div className="mb-3">
          <CFormLabel >Address<span className="text-danger">*</span></CFormLabel>
          <CFormInput type="text"
            name="address"
            value={formData.address}
            onChange={handleInputChange}
            className={errors.address ? 'is-invalid' : ''} />
          {/* {errors.email && <div className="invalid-feedback">{errors.email}</div>} */}
          {errors.address && <div className="invalid-feedback">{errors.address}</div>}
        </div>
        <div className="mb-3">
          <CFormLabel >Email Address<span className="text-danger">*</span></CFormLabel>
          <CFormInput type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            className={errors.email ? 'is-invalid' : ''} />
          {/* {errors.email && <div className="invalid-feedback">{errors.email}</div>} */}
          {errors.email && <div className="invalid-feedback">{errors.email}</div>}
        </div>
        <div className="mb-3">
          <CFormLabel >Password<span className="text-danger">*</span></CFormLabel>
          <CFormInput type="password"
            name="password"
            value={formData.password}
            onChange={handleInputChange}
            className={errors.password ? 'is-invalid' : ''} />
          {errors.password && <div className="invalid-feedback">{errors.password}</div>}
        </div>
        <div className="mb-3">
          <CFormLabel >Confirm Password<span className="text-danger">*</span></CFormLabel>
          <CFormInput type="password"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleInputChange}
            className={errors.confirmPassword ? 'is-invalid' : ''} />
          {errors.confirmPassword && <div className="invalid-feedback">{errors.confirmPassword}</div>}
        </div>
        {
          getRole === 'PrincipalAccountant' ?
            <div className="mb-3">
              <CFormLabel >Tax Account Number<span className="text-danger">*</span></CFormLabel>
              <CFormInput type="text"
                name="taxAccountantNumber"
                value={formData.taxAccountantNumber}
                onChange={handleInputChange}
                className={errors.taxAccountantNumber ? 'is-invalid' : ''}
              />
              {errors.taxAccountantNumber && <div className="invalid-feedback">{errors.taxAccountantNumber}</div>}
            </div>
            :
          <div className="mb-3 CheckBoxAbn">
              <CFormLabel >ABN Number<span className="text-danger">*</span></CFormLabel>
              <CFormInput type="text"
                name="taxAccountantNumber"
                value={formData.taxAccountantNumber}
                onChange={handleChangeAbn}
                className={errors.taxAccountantNumber ? 'is-invalid' : ''}
              />
             {(abnValue?.length >= 9 && abnValue?.length <= 11)   &&  <div className="CheckIcon">
                <CTooltip
                  content={
                    iconStatus ? "Verified" : "Send for verification!"
                  }
                >
                  <CIcon icon={cilCheckCircle} width="20px" height="20px"
                    style={{ color: iconStatus ? "green" : "red" }}
                    onClick={() => handleVerify(companyIds, abnValue)}
                  />
                </CTooltip>
              </div> }
              {/* {errors.taxAccountantNumber && <div className="invalid-feedback">{errors.taxAccountantNumber}
              </div>}
              {verifyMsg === "ABN Verified." ? (
                      <span className="mt-10" style={{ color: "green" }}>
                        {verifyMsg}
                      </span>
                    ) : (
                     
                      <span style={{ color: "red" }}>{verifyMsg}</span>
                     
                      {errors.taxAccountantNumber && <div className="invalid-feedback">""</div>}
                    )} */}
              {(errors.taxAccountantNumber && !verifyMsg) && (
                <div className="invalid-feedback">{errors.taxAccountantNumber}</div>
              )}
              {verifyMsg === "ABN Verified." ? (
                <span className="mt-10" style={{ color: "green" }}>
                  {verifyMsg}
                </span>
              ) : (
                <span style={{ color: "red" }}>
                  {verifyMsg}
                  {/* Empty the error message when verifyMsg is displayed */}
                  {verifyMsg && <span className="invalid-feedback"></span>}
                </span>
              )}

            </div> 
        }
        <div className='mb-3'>
          <CFormLabel>
            Mobile Number <span className="text-danger">*</span>
          </CFormLabel>
          <PhoneInput name="mobileNumber"
            disabled
            // onChange={(value, country) => handleOnChange(value, country)}
            value={phoneNumber}
            inputStyle={{ width: '100%' }} // Adjust the width as needed
          // className={errors.phoneNumber ? 'is-invalid' : ''}
          />
          {/* {errors.phoneNumber && <div className="invalid-feedback">{errors.phoneNumber}</div>} */}
        </div>
        <div className="mb-3">
          <CFormLabel >Registration Code<span className="text-danger">*</span></CFormLabel>
          <CFormInput type="text"
            disabled
            // name="referredBy"
            value={invitaionCode}
          // onChange={handleInputChange}
          // className={errors.registrationCode ? 'is-invalid' : ''}
          />
          {/* {errors.registrationCode && <div className="invalid-feedback">{errors.registrationCode}</div>} */}
        </div>
        <CButton type="submit" color="primary" style={{ width: '-webkit-fill-available' }} className='mb-3'>
          Submit
        </CButton>

      </CForm>
    </div>
  )
}

export default Signup
