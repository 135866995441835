import React, { useState, useMemo, useEffect } from 'react'
import { userAttributes } from "../../share/localData";
import { DeleteApiwithHeader, GetApiwithHeader, UpdateUserwithHeader, postApiWithHeader } from 'src/Api/Api';
import { CButton, CCard, CCardBody, CCol, CFormTextarea, CNav, CNavItem, CNavLink, CRow, CTabContent, CTabPane } from '@coreui/react';
import Notes from './Notes';

const Abn = (props) => {
    const codeInfo = props?.codeInfo
    const accessToken = localStorage.getItem("token");
    const { cmpID } = userAttributes();
    const companyId = cmpID;
    const [abn, setAbn] = useState([])
    const [abnInfo, setAbnInfo] = useState()
    const [activeKey, setActiveKey] = useState(1)
    const [showTextarea, setShowTextarea] = useState(false);
    const [newAddress, setNewAddress] = useState("");
    const [editingAddressId, setEditingAddressId] = useState(null);

    const handleAddAddress = () => {
        setShowTextarea(true);
        setEditingAddressId(null);
    };

    const handleChange = (event) => {
        setNewAddress(event.target.value);
    };

    const fetchData = async () => {
        try {
            const res = await GetApiwithHeader(`/api/abn/GetAddress?abn=${codeInfo?.abn}`, headers);
            setAbn(res?.data?.data || []);
        } catch (error) {
            console.log(error);
        }
    };

    const fetchABNInfo = async () => {
        try {
            const res = await GetApiwithHeader(`/api/abn/ABN-Details?companyId=${companyId}&abn=${codeInfo?.abn}`, headers);
            setAbnInfo(res?.data?.data || []);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchData();
        fetchABNInfo();
    }, []);

    const requestData = {
        address: newAddress,
        abn: codeInfo ? codeInfo?.abn  : 0 
    };

    const handleSaveButtonClick = async () => {
        try {
            const res = editingAddressId ?
                await UpdateUserwithHeader(`/api/abn/Update-Address/${editingAddressId}`, requestData, headers) :
                await postApiWithHeader(`/api/abn/Create-Address`, requestData, headers);
            console.log(res);
            if (editingAddressId ? res.statusCode === 100 : res.data.statusCode === 100) {
                setShowTextarea(false);
                setNewAddress("");
                setEditingAddressId(null);
                fetchData();
            } else if (res.data.statusCode === 102) {
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleEdit = (id, address) => {
        setEditingAddressId(id);
        setNewAddress(address);
        setShowTextarea(true);
    };

    const handleRemove = async (itemId) => {
        try {
            const response = await DeleteApiwithHeader(`/api/abn/Delete-Address/${itemId}`, headers);
            setAbn(abn.filter(item => item.id !== itemId));

            if (response.data.statusCode === 100) {
                toast("Deleted Successfully", { autoClose: 1000 })
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleCancel = () => {
        setShowTextarea(false);
        setNewAddress("");
    };

    return (
        <div className="box mb-2 abn-wrapper">
            <CCard className="mb-3">
                <CCardBody className='w-100'>
                    <div className="mb-2">
                        <h2 className="text-center fw-bold mb-2">{codeInfo?.abn}</h2>
                        <p className="subHeadingTexgt">{abnInfo && abnInfo[0]?.entityName}</p>
                    </div>
                    <table className="w-100">
                        <tbody>
                            <tr>
                                <td>ABN Status:</td>
                                <td>{abnInfo && abnInfo[0]?.status} {abnInfo && new Date(abnInfo[0]?.abnStatusFromDate).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })}</td>
                            </tr>
                            <tr>
                                <td>Entity Type:</td>
                                <td>{abnInfo && abnInfo[0]?.entityTypeText}</td>
                            </tr>
                            <tr>
                                <td>GST:</td>
                                <td>{abnInfo && abnInfo[0]?.gstStatus} {abnInfo && new Date(abnInfo[0]?.gstStatusFromDate).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })}</td>
                            </tr>
                            <tr>
                                <td>Location:</td>
                                <td>{abnInfo && abnInfo[0]?.state} {abnInfo && abnInfo[0]?.postcode}</td>
                            </tr>
                        </tbody>
                    </table>
                </CCardBody>    
            </CCard>            
          
            <CNav variant="tabs" role="tablist" className="abnTabs">
                <CNavItem>
                    <CNavLink
                        href="#!"
                        active={activeKey === 1}
                        onClick={() => setActiveKey(1)}
                    >
                        Address
                    </CNavLink>
                </CNavItem>
                <CNavItem>
                    <CNavLink
                        href="#!"
                        active={activeKey === 2}
                        onClick={() => setActiveKey(2)}
                    >
                        Notes
                    </CNavLink>
                </CNavItem>
            </CNav>
            <CTabContent>
                <CTabPane role="tabpanel" aria-labelledby="home-tab" visible={activeKey === 1}>
                    <CButton onClick={handleAddAddress} className='mt-3 btn-link'>+Add Address</CButton>
                  
                    {showTextarea && ( <>
                        <CFormTextarea
                            id="floatingTextarea"
                            floatingLabel="Add Address"
                            placeholder="Leave a comment here"
                            className="mt-2 mb-2"
                            value={newAddress}
                            onChange={handleChange}
                        />

                        <div className="d-flex  justify-content-end text-end">
                            <CButton className="btn-light mr-2" onClick={handleCancel} >Cancel</CButton>
                            <CButton className="btn-primary" onClick={handleSaveButtonClick}>Save</CButton>
                        </div>
                    </>
                    )}
                    
                    {abn && abn.length > 0 && abn?.map((item, index) => (
                        <div key={item.id} className={` ${index == 0 && 'mt-3' } mb-3`} >
                            <CRow>
                                <CCol>
                                    <div className='border-top p-3'>
                                        <p>{item?.address}</p>
                                        <div className="text-end">
                                            <CButton className="btn-light mr-2" onClick={() => handleEdit(item.id, item.address)}>Edit</CButton>
                                            <CButton className="btn-primary" onClick={() => handleRemove(item.id)}>Remove</CButton>
                                            
                                        </div>
                                    </div>
                                </CCol>
                            </CRow>
                        </div>
                    ))}
                </CTabPane>
                <CTabPane role="tabpanel" aria-labelledby="profile-tab" visible={activeKey === 2}>
                    {activeKey === 2 && <Notes codeInfo={codeInfo} />}
                </CTabPane>
            </CTabContent>
        </div>
    )
}

export default Abn

