import React, { useMemo, useEffect, useState, useRef } from "react";
import {
  CButton,
  CCol,
  CFormInput,
  CFormSelect,
  CCardBody,
  CCard,
  CRow,
  CModalBody,
  CModalHeader,
  CModalFooter,
  CModal,
  CFormFeedback,
} from "@coreui/react";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css"; // Import the styles
import PropTypes from "prop-types";
import { userAttributes } from "../../share/localData";
import {
  GetApiwithHeader,
  postApiWithHeader,
  UpdateUserwithHeader,
} from "src/Api/Api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OffCanvas from "../../share/offCanvas";
import Preview from "./preview";
import Snackbar from "@mui/material/Snackbar";

const Mapping = (props) => {
  Mapping.propTypes = {
    csvData: PropTypes.array.isRequired,
    setCsvData: PropTypes.func.isRequired,
    fetchData: PropTypes.func.isRequired,
    importFile: PropTypes.string.isRequired,
    rowDatas: PropTypes.array.isRequired,
  };

  const navigate = useNavigate();
  const { setActiveTab } = props;
  const { cmpID, bankID } = userAttributes();
  const [selectedBank, setSelectedBank] = useState();
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [bankData, setBankData] = useState([]);
  const [templateData, setTemplateData] = useState([]);
  const [mapColumn, setMapColumn] = useState([]);
  const accessToken = localStorage.getItem("token");
  const [visible, setVisible] = useState(false);
  const companyId = cmpID;
  const BankID = bankID;
  const [selectedOption, setSelectedOption] = useState(null);
  const [bankValSelected, setBankValSelected] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  // const [previewData, setPreviewData] = useState()
  const [templateTitle, setTemplateTitle] = useState("");
  const [formValues, setFormValues] = useState({
    templateName: selectedTemplate ? selectedTemplate?.title : "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [resetMapping, setResetMapping] = useState(false);
  const [items, setItems] = useState([]);
  const [dateError, setDateError] = useState("");
  const [amountError, setAmountError] = useState("");
  const [balanceError, setBalanceError] = useState("");
  const [descriptionError, setDescriptionError] =useState("");

  useEffect(() => {
    if(props.csvData && props.csvData.length > 0){
      const arr = props.csvData?.map((item, index) => ({
        title: item,
        index: index + 1,
        displayValue: `Column ${index + 1} : ${item}`,
      }));
      setItems(arr);
    }
  }, [props.csvData]);

  const headers = useMemo(
    () => ({ Authorization: `Bearer ${accessToken}` }),
    [accessToken]
  );

  const [csvData, setCsvData] = useState({
    Date: "",
    Amount: "",
    Description: "",
    Balance: "",
  });

  const [apiData, setApiData] = useState({
    Date: "",
    Amount: "",
    Description: "",
    Balance: "",
  });

  const validateForms = () => {
    const newErrors = {};
    if (!selectedBank?.id) {
      newErrors.bank = "Bank is required";
    }
    if (!selectedTemplate?.id) {
      newErrors.template = "Template is required";
    }
    setFormErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const getBankData = async () => {
    try {
      const response = await GetApiwithHeader(
        `/api/company/bankaccounts/${companyId}`,
        headers
      );

      const filteredBankData = response?.data?.data.filter(
        (item) => item?.bankName
      );
      setBankData(filteredBankData || []);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (bankData.length !== 0) {
      const selectedItem = bankData.find(
        (item) => item.id === parseInt(BankID)
      );
      setBankValSelected(selectedItem);
    }
    // navigate('/transaction');
  }, [bankData, BankID]);

  useEffect(() => {
    setSelectedTemplate(null);
    setCsvData({
      Date: "",
      Amount: "",
      Description: "",
      Balance: "",
    });
  }, [cmpID, bankID]);

  useEffect(() => {
    setSelectedTemplate(null);
    setCsvData({
      Date: "",
      Amount: "",
      Description: "",
      Balance: "",
    });
    setDateError('');
    setBalanceError('');
    setAmountError('');
    setDescriptionError('');
  }, [props?.rowDatas])

  const getTemplateData = async (e) => {
    try {
      const response = await GetApiwithHeader(
        `/api/csv-mapping-template?companyId=${companyId}&bankAccountId=${
          e ? e : BankID
        }`,
        headers
      );
      const filteredTemplateData = response?.data?.data?.filter(
        (item) => item?.title
      );
      setTemplateData(filteredTemplateData);
    } catch (error) {
      console.log(error);
    }
  };

  const getMapColumn = async () => {
    try {
      const response = await GetApiwithHeader(
        `/api/BankTransactions/mappingcolumn`,
        headers
      );
      const filteredmapColumn = response?.data.filter(
        (item) => item?.columnName
      );
      setMapColumn(filteredmapColumn);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBankData();
    getTemplateData();
    getMapColumn();
  }, [selectedOption, BankID]);

  const handleBankChange = (e) => {
    const selectedOption = bankData.find(
      (item) => item?.accountName === e.target.value
    );
    getTemplateData(selectedOption.id);
    setSelectedBank(selectedOption.id);
  };

  useEffect(()=> {
    if(csvData.Date){
      setDateError('');
    }
    if(csvData.Amount){
      setAmountError('');
    }
    if(csvData.Description){
      setDescriptionError('');
    }
    if(csvData.Balance){
      setBalanceError('');
    }
  }, [csvData]);
  const handleTemplateChange = (e) => {
    setResetMapping(true);
    const selectedOption = templateData.find(
      (item) => item?.id === parseInt(e.target.value)
    );
    const mapping = selectedOption ? JSON.parse(selectedOption?.mapping) : null;
    console.log("mapping---",mapping);
    let csvDataTemp = {
      Date: mapping?.Date ?? "",
      Amount: mapping?.Amount ?? "",
      Description: mapping?.Narration ?? "",
      Balance: mapping?.Balance ?? "",
    };
    if(mapping?.Date.search("Column") === -1){
      csvDataTemp = {
        Date: "",
        Amount: "",
        Description: "",
        Balance: "",
      };
      setDateError('Not matched, Please select the value.');
      setAmountError('Not matched, Please select the value.');
      setDescriptionError('Not matched, Please select the value.')
      setBalanceError('Not matched, Please select the value.')
      setCsvData(csvDataTemp);
    }else{
      let idxDate = Number(mapping?.Date?.split(':')[0].split(' ')[1]);
      if(items && items.length > 0){
        const option = items.filter((itm) => itm.index === idxDate);
        if(option && option.length === 0){
          setDateError('Not matched, Please select the value.')
        }
        csvDataTemp = { ...csvDataTemp, Date: option && option.length > 0 ? option[0]?.displayValue: "" };
        setCsvData(csvDataTemp)
      }
      let idxAmount = Number(mapping?.Amount?.split(':')[0].split(' ')[1]);
      if(items && items.length > 0){
        const option = items.filter((itm) => itm.index === idxAmount);
        if(option && option.length === 0){
          setAmountError('Not matched, Please select the value.')
        }
        csvDataTemp = { ...csvDataTemp, Amount: option && option.length > 0 ? option[0]?.displayValue: "" };
        setCsvData(csvDataTemp)
      }
      let idxNarration = Number(mapping?.Narration?.split(':')[0].split(' ')[1]);
      if(items && items.length > 0){
        const option = items.filter((itm) => itm.index === idxNarration);
        if(option && option.length === 0){
          setDescriptionError('Not matched, Please select the value.')
        }
        csvDataTemp = { ...csvDataTemp, Description: option && option.length > 0 ? option[0]?.displayValue: "" };
        setCsvData(csvDataTemp)
      }
      let idxBalance = Number(mapping?.Balance?.split(':')[0].split(' ')[1]);
      if(items && items.length > 0){
        const option = items.filter((itm) => itm.index === idxBalance);
        if(option && option.length === 0){
          setBalanceError('Not matched, Please select the value.')
        }
        csvDataTemp = { ...csvDataTemp, Balance: option && option.length > 0 ? option[0]?.displayValue: "" };
        setCsvData(csvDataTemp)
      }
    }
    // setCsvData({
    //   Date: mapping?.Date ?? "",
    //   Amount: mapping?.Amount ?? "",
    //   Description: mapping?.Narration ?? "",
    //   Balance: mapping?.Balance ?? "",
    // });
    setSelectedTemplate(selectedOption);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    // setItems((prevItems) => prevItems.filter((item) => item.title !== name));

    if (name.startsWith("csv")) {
      setCsvData({
        ...csvData,
        [name.slice(3)]: value,
      });
    } else if (name.startsWith("api")) {
      setApiData({
        ...apiData,
        [name.slice(3)]: value,
      });
    }
  };

  let templateDatas;

  if (selectedTemplate && selectedTemplate.mapping) {
    try {
      templateDatas = JSON.parse(selectedTemplate?.mapping);
    } catch (error) {
      console.error("Error parsing JSON:", error);
    }
  } else {
    templateDatas = {};
  }
  const handleTitleChange = (e) => {
    setTemplateTitle(e.target.value);
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formValues.templateName.trim()) {
      newErrors.templateName = "Title is required";
    }

    setFormErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  let previewData = "";
  const payload = {
    [mapColumn && mapColumn[0]?.columnName]: csvData?.Date
      ? csvData?.Date //.split(':')[1]?.trim()
      : templateDatas?.Date,
    [mapColumn && mapColumn[1]?.columnName]: csvData?.Amount
      ? csvData?.Amount
      : templateDatas?.Amount,
    [mapColumn && mapColumn[2]?.columnName]: csvData?.Description
      ? csvData.Description
      : templateDatas?.Narration,
    [mapColumn && mapColumn[3]?.columnName]: csvData.Balance
      ? csvData.Balance
      : templateDatas?.Balance,
  };

  const mappingJson = JSON.stringify(payload);
  previewData = mappingJson;

  const handleUpdateTemplate = async () => {
    const requestData = {
      title: selectedTemplate?.title,
      mapping: previewData,
      bankAccountId: BankID,
      removeHeader: false,
    };
    const response = await UpdateUserwithHeader(
      `/api/csv-mapping-template/update/${selectedTemplate.id}?companyId=${companyId}`,
      requestData,
      headers
    );
    if (response.message === "Success") {
      getTemplateData();
      setIsModalVisible(false);
      setTemplateTitle("");
    } else {
      setSnackbarMessage(response?.message);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleCreateTemplate = async () => {
    const requestData = {
      title: templateTitle ? templateTitle : selectedTemplate?.title,
      mapping: previewData,
      bankAccountId: BankID,
    };
    const response = await postApiWithHeader(
      `/api/csv-mapping-template/create?companyId=${companyId}`,
      requestData,
      headers
    );
    if (response?.data?.statusCode === 100) {
      const newTemplate = response.data.data;
      setSelectedTemplate(newTemplate);
      const templateExists =
        response?.data?.data.length > 0 &&
        response?.data?.data?.find(
          (template) => template.id === newTemplate.id
        ) !== undefined;

      if (!templateExists) {
        setTemplateData((prevTemplates = []) => [
          ...prevTemplates,
          newTemplate,
        ]);
      }
      setIsModalVisible(false);
      setTemplateTitle("");
    } else {
      setSnackbarMessage(response?.data?.message);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const headersFile = useMemo(
    () => ({
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "multipart/form-data",
    }),
    [accessToken]
  );

  const handlePreview = () => {
    if(csvData?.Date && csvData?.Amount && csvData?.Description && csvData?.Balance){
      props?.rowDatas;
      const columnNames = props?.rowDatas[0]?.split(",");
      let check;
      let checkArray = [];
      for (const key in csvData) {
        let condData = csvData[key]?.split(":")[1]?.trim();
        if(csvData[key].search("Column") === -1){
          if (!condData) {
            condData = csvData[key];
          }
          check = columnNames.find((itm) => itm?.trim() === condData);
        }else{
          let idx = Number(csvData[key]?.split(':')[0].split(' ')[1]) - 1;
          checkArray?.push(columnNames[idx] ?? null);
        }
      }
      if(checkArray && checkArray.length > 0){
        check = !checkArray?.some(itm => itm == null || itm === undefined);
      }
      if (check) {
        const definedProperties = Object.fromEntries(
          Object.entries(payload).filter(([key, value]) => value !== undefined)
        );
        const length = Object.keys(definedProperties).length;
        if (length === 4) {
          setVisible(true);
        } else {
          setSnackbarMessage("Please map all the column.");
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
        }
      } else {
        setSnackbarMessage(
          "Invalid template, headers does not match with existing template."
        );
        setSnackbarSeverity("warning");
        setSnackbarOpen(true);
      }
    }else{
      setSnackbarMessage(
        "Please map all the column's."
      );
      setSnackbarSeverity("warning");
      setSnackbarOpen(true);
    }
  };

  const handleClose = () => {
    setVisible(false);
  };

  const handleSave = (i) => {
    const definedProperties = Object.fromEntries(
      Object.entries(payload).filter(([key, value]) => value !== undefined)
    );
    const length = Object.keys(definedProperties).length;
    if (length === 4) {
      setIsModalVisible(true);
    } else {
      setSnackbarMessage("Please map all the column");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleResetMapping = () => {
    setResetMapping(true);
    setSelectedTemplate(null);
    setCsvData({
      Date: "",
      Amount: "",
      Description: "",
      Balance: "",
    });
  };

  const getDropdownOptions = (dropdownId) => {
    return items.map((item) => (
      <option
        key={item.displayValue}
        value={item.displayValue}
        disabled={
          Object.values(csvData).includes(item.displayValue) &&
          csvData[dropdownId] !== item.displayValue
        }
      >
        {item.displayValue}
      </option>
    ));
  };
  return (
    <div className="mb-2">
      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        handleClose={() => setSnackbarOpen(false)}
      />
      <CCard>
        <CCardBody className="m-3">
          Mapping file name : {props?.importFile?.name}
          <CRow className="mt-2 mb-2">
            <CCol md={12}>
              <label style={{ width: "49%" }}>
                Select Template
                <CFormSelect
                  onChange={handleTemplateChange}
                  name="template"
                  value={selectedTemplate?.id || ""}
                >
                  <option value="">Select Template Name</option>
                  {templateData?.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.title}
                    </option>
                  ))}
                </CFormSelect>
              </label>
              {!selectedTemplate && (
                <div className="text-danger">{formErrors.template}</div>
              )}
            </CCol>
          </CRow>
          <hr />
          <div className="mb-2">
            <h3>
              <b>
                Please map your uploaded bank transcation file column to
                respective titles
              </b>
            </h3>
          </div>
          <CRow className="mb-2">
            <CCol md={6}>
              <CFormSelect
                label={mapColumn && mapColumn[0]?.columnName}
                onChange={handleChange}
                name="csvDate"
                // disabled={selectedTemplate?.mapping}
                value={csvData?.Date ? csvData?.Date : ""}
              >
                <option value="">Select Date</option>
                {getDropdownOptions("Date")}
                {/* {items
                  ?.filter(
                    (item, idx) =>
                      item.displayValue !== `Column ${idx + 1} : ${csvData?.Amount}` &&
                      item.displayValue !== `Column ${idx + 1} : ${csvData?.Description}` &&
                      item.displayValue !== `Column ${idx + 1} : ${csvData?.Balance}`
                  )
                  .map((item, index) => (
                    <option key={item.title} value={item.title}>
                      {item.displayValue}
                    </option>
                  ))} */}
              </CFormSelect>
              {dateError && <span style={{color: 'red'}}>{dateError}</span>}
            </CCol>
            <CCol md={6}>
              <CFormSelect
                label={mapColumn && mapColumn[1]?.columnName}
                onChange={handleChange}
                name="csvAmount"
                // disabled={selectedTemplate?.mapping}
                value={csvData?.Amount ? csvData?.Amount : ""}
              >
                <option value="">Select Amount</option>
                {getDropdownOptions("Amount")}
                {/* {items
                  ?.filter(
                    (item, idx) =>
                    item.displayValue !== `Column ${idx + 1} : ${csvData?.Date}` &&
                    item.displayValue !== `Column ${idx + 1} : ${csvData?.Description}` &&
                    item.displayValue !== `Column ${idx + 1} : ${csvData?.Balance}`
                  )
                  .map((item, index) => (
                    <option key={item.title} value={item.title}>
                      {item.displayValue}
                    </option>
                  ))} */}
              </CFormSelect>
              {amountError && <span style={{color: 'red'}}>{amountError}</span>}
            </CCol>
          </CRow>
          <CRow className="mb-2">
            <CCol md={6}>
              <CFormSelect
                label={mapColumn && mapColumn[2]?.columnName}
                onChange={handleChange}
                name="csvDescription"
                // disabled={selectedTemplate?.mapping}
                value={csvData?.Description ? csvData?.Description : ""}
              >
                <option value="">Select Description</option>
                {getDropdownOptions("Description")}
                {/* {items
                  ?.filter(
                    (item, idx) =>
                    item.displayValue !== `Column ${idx + 1} : ${csvData?.Date}` &&
                    item.displayValue !== `Column ${idx + 1} : ${csvData?.Amount}` &&
                    item.displayValue !== `Column ${idx + 1} : ${csvData?.Balance}`
                  )
                  .map((item, index) => (
                    <option key={item.title} value={item.title}>
                      {item.displayValue}
                    </option>
                  ))} */}
              </CFormSelect>
              {descriptionError && <span style={{color: 'red'}}>{descriptionError}</span>}
            </CCol>
            <CCol md={6}>
              <CFormSelect
                label={mapColumn && mapColumn[3]?.columnName}
                onChange={handleChange}
                name="csvBalance"
                // disabled={selectedTemplate?.mapping}
                value={csvData?.Balance ? csvData?.Balance : ""}
              >
                <option value="">Select Balance</option>
                {getDropdownOptions("Balance")}
                {/* {items
                  ?.filter(
                    (item, idx) =>
                      item.displayValue !== `Column ${idx + 1} : ${csvData?.Date}` &&
                    item.displayValue !== `Column ${idx + 1} : ${csvData?.Amount}` &&
                    item.displayValue !== `Column ${idx + 1} : ${csvData?.Description}`
                  )
                  .map((item, index) => (
                    <option key={item.title} value={item.title}>
                      {item.displayValue}
                    </option>
                  ))} */}
              </CFormSelect>
              {balanceError && <span style={{color: 'red'}}>{balanceError}</span>}
            </CCol>
          </CRow>
          <OffCanvas
            Open={visible}
            title={"Preview"}
            handleCloseClick={handleClose}
            canvasWidth={"650px"}
            component={
              <Preview
                rowDatas={props?.rowDatas}
                previewData={previewData}
                importFile={props?.importFile}
                fetchData={props.fetchData}
                selectedBank={selectedBank}
                selectedTemplate={selectedTemplate}
                bankValSelected={bankValSelected}
                handleClose={handleClose}
                setActiveTab={setActiveTab}
                setFileImport={props?.setFileImport}
                csvData={csvData}
                trasactionData={props?.trasactionData}
                openingBalanceData={props?.openingBalanceData}
                finStartDate={props?.finStartDate}
                finEndDate={props?.finEndDate}
                fileDataReverse={props?.fileDataReverse}
              />
            }
          />
          <CRow className="mt-8 justify-content-end">
            <CCol xs="auto">
              <CButton ml={2} color="light" onClick={handleResetMapping}>
                Reset
              </CButton>
            </CCol>
            <CCol xs="auto">
              <CButton ml={2} color="light" onClick={handlePreview}>
                Preview & Process
              </CButton>
            </CCol>
            <CCol xs="auto">
              <CButton onClick={handleSave} color="primary">
                Create Template
              </CButton>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
      <CModal
        className="overflowBox"
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
      >
        <CModalHeader>
          <h3>Confirmation</h3>
        </CModalHeader>
        {selectedTemplate && selectedTemplate ? (
          <>
            <CModalBody>
              Do you want to overright this {selectedTemplate?.title} template
            </CModalBody>
            <CModalFooter>
              <CButton
                className="btn-primary"
                onClick={() => handleUpdateTemplate()}
              >
                Yes
              </CButton>
            </CModalFooter>
            {"---------------Or--------------"}
          </>
        ) : (
          ""
        )}
        <CModalBody>
          <CFormInput
            type="text"
            label="Create a new template"
            name="templateName"
            placeholder="Enter Template name"
            onChange={handleTitleChange}
          />
          {formErrors.templateName && (
            <div className="text-danger">{formErrors.templateName}</div>
          )}
        </CModalBody>
        <CModalFooter>
          <CButton
            className="btn-light mr-2"
            onClick={() => setIsModalVisible(false)}
          >
            Cancel
          </CButton>
          <CButton
            className="btn-primary"
            onClick={() => handleCreateTemplate()}
          >
            Save
          </CButton>
        </CModalFooter>
      </CModal>
    </div>
  );
};

export default Mapping;

export const CustomSnackbar = ({ open, message, severity, handleClose }) => {
  let backgroundColor;

  switch (severity) {
    case "success":
      backgroundColor = "#4CAF50"; // Green color for success
      break;
    case "warning":
      backgroundColor = "#FFC107"; // Yellow color for warning
      break;
    case "error":
      backgroundColor = "#F44336"; // Red color for error
      break;
    default:
      backgroundColor = "#FFFFFF"; // Default color
      break;
  }
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
      message={message}
      ContentProps={{
        style: { backgroundColor }, // Set background color
      }}
    />
  );
};
