import React from 'react'
import Coding from '../coding/blp1'

const SupportTicket = () => {
  return (
    <div><Coding support={'support'}/></div>
  )
}

export default SupportTicket







