import React, { useMemo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CButton,
  CModalBody,
  CModalHeader,
  CModalFooter,
  CModal,
  CTooltip,
  CSpinner,
} from "@coreui/react";
import { GetApiwithHeader, DeleteApiwithHeader } from "src/Api/Api";
import OffCanvas from "../../share/offCanvas";
import { userAttributes } from "../../share/localData";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useData } from "../../components/dataContext";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { LicenseInfo } from '@mui/x-license';
import AddProduct from "./AddProduct";

const Product = () => {
  const { data, updateData, removeBank, setTriggerRenderHeaderOnCompanyAdd } = useData();
  const navigate = useNavigate();
  LicenseInfo.setLicenseKey('248a71a5e79c492f4b13769843fcf6dcTz04OTIzMCxFPTE3NDU3NTY1ODYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [product, setProduct] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const { cmpID } = userAttributes();
  const accessToken = localStorage.getItem("token");
  const [visible, setVisible] = useState(false);
  const [editValue, setEditValue] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [loadingState, setLoadingState] = useState(true)
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
 
  const headers = useMemo(
    () => ({
      Authorization: `Bearer ${accessToken}`,
    }),
    [accessToken]
  );
  const companyId = cmpID;

  const filteredServices = useMemo(() => {
    return product.filter((service) => {
      // const costMatch = service?.productCost?.toLowerCase().includes(searchQuery?.toLowerCase());
      const idMatch = parseFloat(service?.productnumber).toString().includes(searchQuery?.toLowerCase()); // Update field to 'productnumber'
      const nameMatch = service?.productName?.toLowerCase().includes(searchQuery?.toLowerCase());
      const descriptionMatch = service?.productDescription?.toLowerCase().includes(searchQuery?.toLowerCase());
      // Return true if any of the conditions match
      return idMatch || nameMatch || descriptionMatch;
    });
  }, [product, searchQuery]);

  const fetchData = async () => {
    setLoadingState(true)
    try {
      const res = await GetApiwithHeader(
        `api/Product/Get/${companyId}`,
        headers
      );
      if (res?.data) setLoadingState(false)
      setProduct(res?.data?.data || []); 
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [headers, companyId, cmpID]);

  const [valueData, setValueData] = useState();
  const handleDelete = (i) => {
    setValueData(i);
    setIsModalVisible(true);
  };

  const handleDeleteApi = async (id) => {
    setIsDeleting(true);
    const apiUrl = `api/Product/delete/${companyId},${id}`;
    try {
      const response = await DeleteApiwithHeader(apiUrl, headers);
      if (response.message === "Success") {
        setIsModalVisible(false);
        fetchData();
        toast.success("Company deleted successfully", { autoClose: 1000 });
      } else {
        console.error("Unexpected response format:", response);
      }
    } catch (error) {
      console.error("Delete API Request Error:", error);
    } finally {
      setIsDeleting(false); // Reset loading state regardless of success or failure
    }
  };

  const handleAddClick = () => {
    setVisible(true);
  };

  const handleCloseClick = () => {
    setEditValue();
    setVisible(false);
  };
  const handleEditClick = (i) => {
    setVisible(true);
    setEditValue(i);
  };

  console.log('filteredServices', filteredServices)
  const column = [
    // { field: "accountName", headerName: "Item", width: 100, headerClassName: 'dataGridHeader' },
    {
      field: "productFiles",
      headerName: "Product Img", // Header name for the files
      width: 100, // Adjust width as needed
      headerClassName: 'dataGridHeader',
      renderCell: ({ row }) => (
        <div>
          {row.productFiles.map((file, index) => (
            <div key={index}>
              <img src={file.filePaths} style={{ maxWidth: "40px", maxHeight: "40px" }} />
            </div>
          ))}
        </div>
      ),
    },
    { field: "productName", headerName: "Product Name", width: 200, headerClassName: 'dataGridHeader' },
    { field: "productnumber", headerName: "Product Number", width: 150, headerClassName: 'dataGridHeader' },
    { field: "productCost", headerName: " Item Cost", width: 150, headerClassName: 'dataGridHeader' },
    { field: "gst", headerName: "GST", width: 150, headerClassName: 'dataGridHeader' },
    { field: "gstPercentage", headerName: "GST %", width: 150, headerClassName: 'dataGridHeader' },
    { field: "productDescription", headerName: "Description of Goods", width: 180, headerClassName: 'dataGridHeader' },
    {
      field: "action", headerName: "Action", width: 160, headerClassName: 'dataGridHeader',
      type: "actions",
      renderCell: (({ row }) => {
        return (
          <div className="rowIcon"> 
            <CTooltip content="Edit">
              <button className="icon-editIcon" onClick={() => handleEditClick(row)}></button>
            </CTooltip>
            <CTooltip content="Delete">
              <button className="icon-deleteIcon" onClick={() => handleDelete(row)}></button>
            </CTooltip>
          </div>
        )
      })
    },
  ];

  return (
    <div className="cardBody">
      <ToastContainer position="top-right" />
      <div className="cardHeader">
        <h3>Products</h3>
        <div className="mr-1">
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)} // Step 2: Add input field for search query
          />
          <CButton onClick={() => handleAddClick()}>Add Product</CButton>
        </div>
      </div>

      <OffCanvas
        Open={visible}
        title={editValue ? "Edit Product" : "Add Product"}
        handleCloseClick={handleCloseClick}
        component={
          <AddProduct
            editValue={editValue}
            handleClose={handleCloseClick}
            fetchData={fetchData}
            rowdata={product}
            // onImageUpload={handleImageUpload}
            // imagePreview={imagePreview} // Pass image preview state
          />
        }
      />
      <div className="datatableGrid">
        <DataGridPro
          rows={filteredServices}
          columns={column}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 15 },
            },
          }}
          pageSizeOptions={[5, 10]}
          density="compact"
          disableRowSelectionOnClick
          loading={loadingState}
          sx={{
            '& .MuiDataGrid-columnHeaderRow': {
              background: '#EAF0F2'
            }
          }}
        />
      </div>
      <CModal
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        aria-labelledby="ScrollingLongContentExampleLabel"
        className="overley"
      >
        <CModalHeader>
          <h3>Delete</h3>
        </CModalHeader>
        <CModalBody>
          Are you sure want to delete {valueData?.name} ...?
        </CModalBody>
        <CModalFooter>
          <CButton className="btn-light mr-2" onClick={() => setIsModalVisible(false)}>
            Close
          </CButton>
          <CButton
            className="btn-primary"
            onClick={() => handleDeleteApi(valueData?.id)}
          >
            {isDeleting ? (
              <CSpinner color="white" size="sm" />
            ) : (
              "Delete"
            )}
          </CButton>
        </CModalFooter>
      </CModal>
    </div>
  );
};

export default Product;

