import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CCard, CCardGroup, CCol, CContainer, CRow } from "@coreui/react";
import { postApi } from "src/Api/Api";
import { toast } from "react-toastify";
import QRCode from "qrcode.react";
import "react-toastify/dist/ReactToastify.css";

const QrCode = () => {
  const qrCodeRef = useRef(null);
  const [randomId, setRandomId] = useState("");
  const [loading, setLoading] = useState(false);
  const [browserName, setBrowserName] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const generateRandomString = () => {
      const randomString =
        Math.random().toString(20).substring(2, 14) +
        Math.random().toString(20).substring(2, 14);
      setRandomId(randomString);
    };
    generateRandomString();
  }, []);

  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    let name = "Unknown";
    if (userAgent.indexOf("Chrome") !== -1) {
      name = "Google Chrome";
    } else if (userAgent.indexOf("Firefox") !== -1) {
      name = "Mozilla Firefox";
    } else if (userAgent.indexOf("Safari") !== -1) {
      name = "Apple Safari";
    } else if (
      userAgent.indexOf("Opera") !== -1 ||
      userAgent.indexOf("OPR") !== -1
    ) {
      name = "Opera";
    } else if (userAgent.indexOf("Edge") !== -1) {
      name = "Microsoft Edge";
    } else if (
      userAgent.indexOf("MSIE") !== -1 ||
      userAgent.indexOf("Trident/") !== -1
    ) {
      name = "Internet Explorer";
    }
    setBrowserName(name);
  }, []);

  useEffect(() => {
    if (browserName) {
      handleSubmit();
    }
  }, [browserName]);

  const [qrId, setQrId] = useState();
  const [qrIds, setQrIds] = useState();

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const response = await postApi(
        `api/auth/GenerateQRCode?deviceId=${randomId}&deviceBrowser=${browserName}`
      );
      setLoading(false);
      if (!response || !response.data || !response.data.data) {
        throw new Error("Failed to fetch QR code URL");
      }
      setQrIds(randomId)
      const base64String = response.data.data;
      const decodedString = atob(base64String);
      setQrId(qrCodeRef.current, decodedString);
    } catch (error) {
      setLoading(false);
      console.log("Error fetching QR code URL:", error);
    }
  };
  
  const handleLogin = async () => {
    try {
      setLoading(true);
      const response = await postApi(
        `api/auth/QRLogin?deviceId=${qrIds}&deviceBrowser=${browserName}`
      );
      setLoading(false);
      if (response.data.statusCode === 100) {
        const data = response.data.data;
        const userInfo = {
          firstname: data?.firstName,
          lastname: data?.lastName,
          email: data?.email,
          mobile_number: data?.phoneNumber,
          role : data?.roleName,
          address1:data?.address1,
          address2:data?.address2,
          profile:data?.profileImage
        };
        localStorage.setItem("userId", data.id);
        localStorage.setItem("token", data.token);
        localStorage.setItem("userInfo", JSON.stringify(userInfo));
        navigate("/companyDetails");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error during login:", error);
      // toast.error("Error during login");
    }
  };


  useEffect(() => {
    const interval = setInterval(() => {
        handleSubmit();
    }, 30000); 


    return () => clearInterval(interval);
}, [browserName, randomId]);

useEffect(() => {
  const interval = setInterval(() => {
      handleLogin();
  }, 3000);

  return () => clearInterval(interval);
}, [browserName, qrIds]);
;
  
  const QrDetails = {
    Id: qrIds,
    browserName: browserName,
  };
  return (
    <div
      className="min-vh-100 d-flex flex-row align-items-center"
      style={{ backgroundColor: "#F3F2F7", opacity: 1 }}
    >
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCardGroup>
              <CCard className="loginWrap">
                <div className="d-flex flex-column flex-md-row align-items-center justify-content-center">
                  <div className="mb-4 mb-md-0 mr-md-4">
                    <h3 className="mb-4">
                      Use OverZero on your computer
                    </h3>
                    <div className="mb-4">
                      <p className="mt-3 text-muted ">
                        1. Use overZero on your phone
                      </p>
                      <p className="mt-3 text-muted">
                        2. Tap side menu - App settings and select linked device
                      </p>
                      <p className="mt-3 text-muted">3. Tap on Link a device</p>
                      <p className="mt-3 text-muted">
                        4. Point your phone on this screen to capture the QR
                        code
                      </p>
                    </div>
                    <b className="mt-3 text-muted text-center">
                      *By signing in, you agree to our{" "}
                      <a href="/privacy-policy">Privacy Policy</a> and{" "}
                      <a href="/terms-of-service">Terms of Service</a>
                    </b>
                  </div>
                  <div className="d-flex justify-content-center">
                    {/* <QRCode value={ss} size={200} ref={qrId} /> */}
                    <div className="text-right">
                    <QRCode
                      value={JSON.stringify(QrDetails)}
                      size={160}
                      ref={qrId}
                    />
                    </div>
                  </div>
                </div>
                {/* <button type="submit" onClick={handleLogin} disabled={loading}>
                  {loading ? "Logging in..." : "Login"}
                </button> */}
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default QrCode;

// import React, { useState, useRef, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import { CCard, CCardGroup, CCol, CContainer, CRow } from "@coreui/react";
// import { postApi } from "src/Api/Api";
// import { toast } from "react-toastify";
// import QRCode from "qrcode.react";
// import io from "socket.io-client";
// import "react-toastify/dist/ReactToastify.css";

// const QrCode = () => {
//   const qrCodeRef = useRef(null);
//   const [randomId, setRandomId] = useState("");
//   const [loading, setLoading] = useState(false);
//   const [browserName, setBrowserName] = useState("");
//   const [socket, setSocket] = useState(null); // State for WebSocket

//   const navigate = useNavigate();

//   useEffect(() => {
//     const generateRandomString = () => {
//       const randomString =
//         Math.random().toString(20).substring(2, 14) +
//         Math.random().toString(20).substring(2, 14);
//       setRandomId(randomString);
//     };
//     generateRandomString();
//   }, []);

//   useEffect(() => {
//     const userAgent = window.navigator.userAgent;
//     let name = "Unknown";
//     if (userAgent.indexOf("Chrome") !== -1) {
//       name = "Google Chrome";
//     } else if (userAgent.indexOf("Firefox") !== -1) {
//       name = "Mozilla Firefox";
//     } else if (userAgent.indexOf("Safari") !== -1) {
//       name = "Apple Safari";
//     } else if (
//       userAgent.indexOf("Opera") !== -1 ||
//       userAgent.indexOf("OPR") !== -1
//     ) {
//       name = "Opera";
//     } else if (userAgent.indexOf("Edge") !== -1) {
//       name = "Microsoft Edge";
//     } else if (
//       userAgent.indexOf("MSIE") !== -1 ||
//       userAgent.indexOf("Trident/") !== -1
//     ) {
//       name = "Internet Explorer";
//     }
//     setBrowserName(name);
//   }, []);

//   useEffect(() => {
//     if (browserName) {
//       handleSubmit();
//     }
//   }, [browserName]);

//   useEffect(() => {
//     // Create WebSocket connection
//     // const newSocket = io("http://localhost:3000/");
//     const newSocket = io('ws://localhost:3000/');

//     // Event listener for successful login
//     newSocket.on("loginSuccess", (userData) => {
//       console.log('userData', userData)
//       setLoading(false);
//       localStorage.setItem("userId", userData.id);
//       localStorage.setItem("token", userData.token);
//       localStorage.setItem(
//         "userInfo",
//         JSON.stringify({
//           firstname: userData.firstName,
//           lastname: userData.lastName,
//           email: userData.email,
//           mobile_number: userData.phoneNumber,
//         })
//       );
//       navigate("/bank");
//     });

//     // Event listener for login errors
//     newSocket.on("loginError", (error) => {
//       setLoading(false);
//       console.error("Error during login:", error);
//       toast.error("Error during login");
//     });

//     // Set the socket state
//     setSocket(newSocket);

//     // Clean up function
//     return () => {
//       newSocket.disconnect();
//     };
//   }, []);

//   const handleSubmit = async () => {
//     try {
//       setLoading(true);
//       const response = await postApi(
//         `api/auth/GenerateQRCode?deviceId=${randomId}&deviceBrowser=${browserName}`
//       );
//       setLoading(false);
//       if (!response || !response.data || !response.data.data) {
//         throw new Error("Failed to fetch QR code URL");
//       }
//       const base64String = response.data.data;
//       const decodedString = atob(base64String);
//       QRCode.toCanvas(qrCodeRef.current, decodedString);
//     } catch (error) {
//       setLoading(false);
//       console.log("Error fetching QR code URL:", error);
//     }
//   };

//   const handleLogin = () => {
//     console.log('socket', socket)
//     if (socket) {
//       // Emit a login event with device id and browser name
//       socket.emit("login", { deviceId: 123456, deviceBrowser: browserName });
//       setLoading(true);
//     } else {
//       console.error("Socket is not initialized.");
//     }
//   };

//   return (
//     <div className="min-vh-100 d-flex flex-row align-items-center" style={{ backgroundColor: "#F3F2F7", opacity: 1 }}>
//       <CContainer>
//         <CRow className="justify-content-center">
//           <CCol md={8}>
//             <CCardGroup>
//               <CCard className="p-4">
//                 <div className="d-flex flex-column flex-md-row align-items-center justify-content-center">
//                   <div className="text-center mb-4 mb-md-0 mr-md-4">
//                     <h3 className="text-center mb-4">
//                       Use overZero on your computer
//                     </h3>
//                     <div className="text-center mb-4">
//                       <p className="mt-3 text-muted ">1. Use overZero on your phone</p>
//                       <p className="mt-3 text-muted">2. Tap side menu - App settings and select linked device</p>
//                       <p className="mt-3 text-muted">3. Tap on Link a device</p>
//                       <p className="mt-3 text-muted">4. Point your phone on this screen to capture the QR code</p>
//                     </div>
//                     <b className="mt-3 text-muted text-center">
//                       *By signing in, you agree to our{" "}
//                       <a href="/privacy-policy">Privacy Policy</a> and{" "}
//                       <a href="/terms-of-service">Terms of Service</a>
//                     </b>
//                   </div>
//                   <div className="d-flex justify-content-center">
//                     <QRCode value={randomId} size={200} ref={qrCodeRef} />
//                   </div>
//                 </div>
//                 <button type="submit" onClick={handleLogin} disabled={loading}>
//                   {loading ? "Logging in..." : "Login"}
//                 </button>
//               </CCard>
//             </CCardGroup>
//           </CCol>
//         </CRow>
//       </CContainer>
//     </div>
//   );
// };

// export default QrCode;
