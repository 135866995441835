// Import necessary React components and libraries
import React, { useState, useRef, useEffect } from 'react';
import { CButton, CForm, CFormInput, CFormLabel } from '@coreui/react';
import { useNavigate } from 'react-router-dom';
import { GetApi, postApi,generateOtp } from '../../Api/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Functional component for OTP verification
const VerifyCode = () => {
  const [otp, setOtp] = useState(Array(4).fill(''));
  const navigate = useNavigate();
  const otpInputs = useRef([]);
  const [timer, setTimer] = useState(60); // Timer in seconds
  const [showResend, setShowResend] = useState(false);
  // Retrieve userId from local storage
  const userId = localStorage.getItem('userId');

  // Function to format time in MM:SS format
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
    return `${formattedMinutes}:${formattedSeconds}`;
  };

  // Effect to handle timer countdown for resend functionality
  useEffect(() => {
    let interval;

    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setShowResend(true);
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [timer]);

  // Function to make API request for OTP verification
  const getAssetData = async () => {
    try {
      console.log('otp',otp)
     
      // Join the OTP array into a single string
      const otpCode = otp.join('');
      console.log('otp',otpCode)
      // const queryParams = `${encodeURIComponent(userId)}/${encodeURIComponent(otpCode)}`;

      // Make API request to verify OTP and user ID
      const res = await GetApi(`api/auth/VerifyOtp/${userId}/${otpCode}`);
      console.log('ressss', res);

      // Check if the OTP is empty
      if (otpCode.trim() === '') {
        // Show a validation message for empty OTP
        toast.error('Please enter the OTP.');
        return;
      }
      // Check if the OTP is verified
      if (res && res?.statusCode === 100) {
        console.log('Code verified successfully');
        navigate('/thankYou'); // Redirect to the 'thankyou' page
      } else {
        console.error('Incorrect verification code');
        toast.error('Incorrect verification code. Please try again.',);
        // Handle incorrect code (show a message, allow retries, etc.)
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleResend = async (e) => {
    // e.preventDefault();
    // Logic to resend OTP (call API, reset timer, etc.)
    try {
      // Call the API to generate a new OTP
      const otpResponse = await generateOtp(`api/auth/GenerateOtp/${userId}`);
      console.log('OTP generation response:', otpResponse.data);

      // Reset the timer and hide the "Resend" button
      setTimer(60);
      setShowResend(false);

      // Show a toast message
      toast.success('A new OTP has been sent', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000, // You can adjust the duration of the toast
      });
    } catch (error) {
      console.error('Error generating OTP:', error);
      // Handle the error (show a message, retry logic, etc.)
    }
  };

  // Function to handle OTP input changes
const handleOtpChange = (index, value) => {
  const newOtp = [...otp];
  newOtp[index] = value.replace(/\D/g, '');
  setOtp(newOtp);

  if (value !== '' && index < newOtp.length - 1) {
    // Move focus to the next input box if the current box is not the last one
    otpInputs.current[index + 1]?.focus();
  }

  // Move to the previous input box and clear the value if the current box is not the first one
  if (value === '' && index > 0) {
    newOtp[index] = ''; // Clear the value in the current input box
    setOtp(newOtp);
    otpInputs.current[index - 1]?.focus();
  }
};

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <ToastContainer />
      <h2>Verification</h2>
      <CForm>
        <CFormLabel >Enter code received via SMS</CFormLabel>
        <div>
          <div
            className="otp-input-container"
            style={{
              display: 'flex',
              justifyContent: 'center',
              gap: '10px',
              marginBottom: '30px',
            }}
          >
            {otp.map((digit, index) => (
              <CFormInput
                key={index}
                type="text"
                className="otp-input"
                maxLength="1"
                value={digit}
                onChange={(e) => handleOtpChange(index, e.target.value)}
                ref={(input) => (otpInputs.current[index] = input)}
                style={{ width: '40px', textAlign: 'center', fontSize: '16px' }}
              />
            ))}
          </div>
        </div>
       <p>Resend OTP in <span style={{ color:"#E21515"}}>{formatTime(timer)}</span></p>
        {showResend ? (
          <CButton color="primary" onClick={(e) => handleResend(e)} className="mt-4" style={{ width: '115px' }}>
            Resend
          </CButton>
        ) : (
          <CButton
            color="primary"
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              getAssetData();
            }}
            className="mt-4"
            style={{ width: '115px' }}
          >
            Verify
          </CButton>
        )}
      </CForm>
    </div>
  );
};

export default VerifyCode;











