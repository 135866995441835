import React, { useMemo, useEffect, useState } from "react";
import {
  CButton,
  CModal,
  CModalBody,
  CModalHeader,
  CModalFooter,
  CTooltip,
  CSpinner,
} from "@coreui/react";
import { toast, ToastContainer } from "react-toastify";
import OffCanvas from "../../share/offCanvas";
import AddRule from "./AddRule";
import { GetApiwithHeader, DeleteApiwithHeader } from "src/Api/Api";
import { userAttributes } from "../../share/localData";
import ApplyRule from "./ApplyRule";
import { useData } from "../../components/dataContext";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { LicenseInfo } from '@mui/x-license';

const Index = () => {
  const { data } = useData();
  LicenseInfo.setLicenseKey('248a71a5e79c492f4b13769843fcf6dcTz04OTIzMCxFPTE3NDU3NTY1ODYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');
  const accessToken = localStorage.getItem("token");
  const { cmpID, bankID } = userAttributes();
  const [visible, setVisible] = useState(false);
  const [similarData, setSimilarData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [highFreqRuleData, setHighFreqRuleData] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [narration, setNarration] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [loadingState, setLoadingState] = useState(true);

  const SerialNumber = itemsPerPage * currentPage - itemsPerPage; // Number of items per page
  const headers = useMemo(
    () => ({
      Authorization: `Bearer ${accessToken}`,
    }),
    [accessToken]
  );
  const companyId = cmpID;

  const fetchData = async () => {
    setLoadingState(true);
    try {
      const res = await GetApiwithHeader(
        `api/CodingRuleSet/Get/${companyId}?bankAccountId=${bankID}`,
        headers
      );
      if (res.data) setLoadingState(false);
      if (res.data && res.data.data && res.data.data.length > 0) {
        setSimilarData(res.data.data);
      } else {
        setSimilarData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDataNarration = async () => {
    try {
      const res = await GetApiwithHeader(`/api/BankTransactions/Narration/${companyId}?bankAccountId=${bankID}`, headers);
      setNarration(res?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchDataNarration();
  }, [headers, cmpID, bankID]);

  const handleCloseClick = () => {
    setVisible(false);
  };

  const handleAddClick = () => {
    setHighFreqRuleData({});
    setVisible(true);
    fetchDataNarration();
  };

  const handleDelete = async (id) => {
    setIsDeleting(true);
    try {
      const res = await DeleteApiwithHeader(
        `api/CodingRuleSet/delete/${id},${companyId}`,
        headers
      );
      if (res && res.statusCode === 100) {
        toast.success("Deleted Successfully", { autoClose: 1000 });
        fetchData();
        setIsDeleteModalVisible(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsDeleting(false); // Reset loading state regardless of success or failure
    }
  };

  const filteredData = similarData?.filter(item =>
    item?.narration?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
    item?.codeName?.toLowerCase()?.includes(searchQuery?.toLowerCase())
  ).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  const financialData = filteredData?.filter(item => item.isFinancialInstrument)
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  const nonFinancialData = filteredData?.filter(item => !item.isFinancialInstrument)
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  const columns = [
    { field: "narration", headerName: "Transaction Descriptions", width: 500, headerClassName: 'dataGridHeader',
    sortable: false, 
  },
    {
      field: "financialCodeName", headerName: "Code Name", width: 200, headerClassName: 'dataGridHeader',
      sortable: false, 
      renderCell: (params) => params.row?.isFinancialInstrument ? '' : params.row.codeName,
    },
    {
      field: "nonFinancialCodeName", headerName: "Financial Instrument", width: 200, headerClassName: 'dataGridHeader',
      sortable: false, 
      renderCell: (params) => params.row?.isFinancialInstrument ? params.row.codeName : '',
    },
    {
      field: "createdAt", headerName: "Creation Date", width: 150, headerClassName: 'dataGridHeader',
      sortable: false, 
      valueGetter: (params) => new Date(params).toLocaleDateString("en-GB"),
    },
    {
      field: "count", headerName: "Transaction Identify", width: 150, headerClassName: 'dataGridHeader',
      sortable: false, 
    },
    {
      field: "action", headerName: "Action", width: 200, headerClassName: 'dataGridHeader', type: "actions",
      renderCell: (params) => (
        <div className="rowIcon">
          <CTooltip content="Edit">
            <button
              className="icon-editIcon"
              onClick={() => {
                setHighFreqRuleData(params.row);
                setVisible(true);
              }}
            ></button>
          </CTooltip>
          <CTooltip content="Delete">
            <button
              className="icon-deleteIcon"
              onClick={() => {
                setHighFreqRuleData(params.row);
                setIsDeleteModalVisible(true);
              }}
            ></button>
          </CTooltip>
          {
            params.row?.count !== 0
              ? <CTooltip content="Run">
                <CButton onClick={() => {
                  setIsModalVisible(true);
                  setHighFreqRuleData(params.row);
                }}>
                  Run
                </CButton>
              </CTooltip>
              : <CTooltip content="Run">
                <CButton disabled>
                  Run
                </CButton>
              </CTooltip>
          }
        </div>
      ),
    },
  ];

  return (
    <div className="cardBody">
      <ToastContainer position="top-right" />
      <div className="cardHeader">
        <h3>High Frequency Transactions Definition</h3>
        <div className="mr-1">
          <input
            type="text"
            placeholder="Search..."
            className="mr-2"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <CButton onClick={handleAddClick}>Add Rule</CButton>
        </div>
      </div>
      <div className="datatableGrid">
        <DataGridPro
          rows={[...nonFinancialData, ...financialData]}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 15 },
            },
          }}
          pageSizeOptions={[5, 10]}
          density="compact"
          disableRowSelectionOnClick
          loading={loadingState}
        />
      </div>
      <CModal
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        aria-labelledby="ScrollingLongContentExampleLabel"
        className="overflowBox"
      >
        <CModalHeader>
          <h3>Transaction Definition </h3>
        </CModalHeader>
        <div>
          <CModalBody>
            <ApplyRule HighFreqRuleData={highFreqRuleData} modalShow={setIsModalVisible} fetchDataHighfreq={fetchData} />
          </CModalBody>
        </div>
      </CModal>
      <OffCanvas
        Open={visible}
        title={highFreqRuleData && highFreqRuleData.id ? "Edit Rule" : "Add Rule"}
        handleCloseClick={handleCloseClick}
        component={
          <AddRule
            highFreqRuleData={highFreqRuleData}
            fetchData={fetchData}
            handleClose={handleCloseClick}
            narration={narration}
          />
        }
      />
      <CModal
        className="overflowBox"
        visible={isDeleteModalVisible}
        onClose={() => setIsDeleteModalVisible(false)}
      >
        <CModalHeader>
          <h3>Delete</h3>
        </CModalHeader>
        <CModalBody>
          Are you sure want to delete it?
        </CModalBody>
        <CModalFooter>
          <CButton className="btn-light mr-2" onClick={() => setIsDeleteModalVisible(false)}>
            Close
          </CButton>
          <CButton
            className="btn-primary"
            onClick={() => handleDelete(highFreqRuleData?.id)}
          >
            {isDeleting ? (
              <CSpinner color="white" size="sm" />
            ) : (
              "Delete"
            )}
          </CButton>
        </CModalFooter>
      </CModal>
    </div>
  )
}

export default Index;
