import React, {useEffect, useState} from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete  from '@mui/material/Autocomplete'
import { GetApiwithHeader } from "src/Api/Api";

function AutocompleteEditCell({
  params,
  value,
  options,
  selectVal,
  disableClearable,
  freeSolo,
  funCodeData,
  disable,
  headers,
  companyId
}) {
  const [opt, setOpt] = useState(options);

  const handleValueChange = async(_, newValue) => {
    funCodeData(newValue)
  };

  const fetchData = async () => {
    try {
      const res = await GetApiwithHeader(
        `/api/abn/Frequently-Used-ABN?companyId=${companyId}&abn=${selectVal?.row.abn}`,
        headers
      );
      if (res?.data && res?.data?.data && res?.data?.data.length > 0){
        console.log("res?.data?.data--",res?.data?.data);
        console.log("options---",options.length);
        const matchedArray = options.filter(item =>
          res?.data?.data.some(filterItem => filterItem.businessCodeId === item.value)
        );
        const unmatchedArray = options.filter(item =>
          !res?.data?.data.some(filterItem => filterItem.businessCodeId === item.value)
        );
        const newArr = matchedArray.concat(unmatchedArray);
        console.log("newArr---",newArr.length);
        setOpt(newArr);
      }
    } catch (error) {
      
    }
  }

  useEffect(()=>{
    if(selectVal?.row?.abn){
      fetchData();
    }
  },[selectVal?.row?.abn])

  const sortedOpt = opt.sort((a, b) => a.label.localeCompare(b.label));

  return (
    <Autocomplete
      fullWidth
      disableClearable={disableClearable}
      disabled={disable}
      //multiple={multiple}
      options={sortedOpt}
      freeSolo={freeSolo}
      // value={disable ? '' : ''}
      defaultValue={options.find((o) => o.value === value)?.label || selectVal?.row?.bankEntryCodingResponseDtos[0]?.businessCodeName}
      onChange={handleValueChange}
      renderInput={(params) => <TextField autoFocus {...params} />}
    />
  );
}

export default AutocompleteEditCell;
