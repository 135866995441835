import React from "react";
import {
  CButton,
  CCol,
  CForm,
  CRow,
  CFormInput,
  CFormCheck,
  CFormTextarea,
  CFormSelect,
  CTooltip,
  CSpinner,
} from "@coreui/react";
import { useFormik, useFormikContext } from "formik";
import * as Yup from "yup"; // Import Yup for validation
import PhoneInput from "react-phone-input-2";
import AddIcon from "@mui/icons-material/Add";
import { useState, useEffect } from "react";
const Add = (props) => {
  const { handleClose, viewDetails } = props;
  console.log("viewDetails", viewDetails);
  const [grandTotal, setGrandTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [gstError, setGstError] = useState("");
  const [errors, setErrors] = useState([]);
  const formik = useFormik({
    initialValues: {
      recipientName: "",
      selectedDate: null,
      phoneNumber: "",
      recipientEmail: "",
      companyName: "",
      abnNumber: "",
      itemType: "Service",
      serviceDescription: "",
      serviceCharge: "",
      gst: "",
      products: [
        { product: "", quantity: "", unitPrice: "", gst: "10", total: "" },
      ],
      specialNotes: "",
      address: "",
      deliveryAddress: "",
      paymentTerms: "",
      creditDuration: "",
      otherTerms: "",
    },

    validationSchema: Yup.object().shape({
      recipientName: Yup.string().required("Recipient name is required"),
      selectedDate: Yup.date().required("Date is required"),
      recipientEmail: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      companyName: Yup.string().required("Company name is required"),
      abnNumber: Yup.string()
        .required("ABN number is required")
        .matches(/^\d{11}$/, "ABN number must be 11 digits"),
      address: Yup.string().required("Address is required"),
      deliveryAddress: Yup.string().required(" Delivery Address is required"),
      // Add validation for other fields
    }),

    onSubmit: async (values, { resetForm }) => {
      console.log("Form values:", values); // Log the form values to the console
      const payload = {
        recipientName: values.recipientName,
        selectedDate: values.selectedDate,
        phoneNumber: values.phoneNumber,
        recipientEmail: values.recipientEmail,
        companyName: values.companyName,
        abnNumber: values.abnNumber,
        itemType: values.itemType,
        serviceDescription: values.serviceDescription,
        serviceCharge: values.serviceCharge,
        gst: values.gst,
        products: values.products,
        specialNotes: values.specialNotes,
        address: values.address,
        deliveryAddress: values.deliveryAddress,
        paymentTerms: values.paymentTerms,
        creditDuration: values.creditDuration,
        otherTerms: values.otherTerms,
      };

      // Simulation of form submission, replace with actual API call
      setTimeout(() => {
        // setIsSubmitting(false);
        resetForm();
        handleClose();
        console.log("Form submission completed."); // Log completion message to console
      }, 1000);
    },
  });

  const handleOnChange = (value, country) => {
    formik.setFieldValue({ ...formik.values.phoneNumber, phoneNumber: value });
  };

  const handleRadioChange = (e) => {
    formik.setFieldValue("itemType", e.target.value);
  };

  const handlecheckChange = (e) => {
    formik.setFieldValue("paymentTerms", e.target.value);
  };
  //   console.log(formik.errors)
  // console.log(formik.values)
  // const calculateTotal = () => {
  //   let serviceChargeValue = parseFloat(formik.values.serviceCharge) || 0;
  //   let gstValue = parseFloat(formik.values.gst) || 0;

  //   // Ensure GST value does not exceed 10
  //   gstValue = Math.min(gstValue, 10);

  //   const totalValue = serviceChargeValue + gstValue;
  //   setTotal(totalValue.toFixed(2));
  // };

  const calculateTotal = (serviceCharge, gst) => {
    const serviceChargeValue = parseFloat(serviceCharge) || 0;
    const gstValue = parseFloat(gst) || 0;

    if (gstValue > serviceChargeValue) {
      setGstError("GST value cannot exceed the Service Charge");
    } else {
      setGstError("");
      const totalValue = serviceChargeValue + gstValue;
      setTotal(totalValue.toFixed(2));
    }
  };

  const handleServiceChargeChange = (event) => {
    const serviceCharge = event.target.value;
    formik.setFieldValue("serviceCharge", serviceCharge);
    calculateTotal(serviceCharge, formik.values.gst);
  };

  const handleGstChange = (event) => {
    const gst = event.target.value;
    formik.setFieldValue("gst", gst);
    const serviceCharge = formik.values.serviceCharge;
    calculateTotal(serviceCharge, gst);
  };

  // useEffect(() => {
  //   calculateTotal();
  // }, [formik.values.serviceCharge, formik.values.gst]);

  const handleAddRow = () => {
    const newProduct = {
      product: "",
      quantity: "",
      unitPrice: "",
      gst: "10",
      total: "",
    };
    const updatedProducts = [...formik.values.products, newProduct];
    formik.setFieldValue("products", updatedProducts); // Specify the field name as a string
  };

  const handleRemoveRow = (index) => {
    const newProducts = formik.values.products.filter((_, i) => i !== index);
    formik.setFieldValue("products", newProducts); // Specify the field name as a string
  };

  // const handleInputChange = (index, e) => {
  //   const { name, value } = e.target;
  //   const newProducts = [...formik.values.products];
  //   newProducts[index][name] = value;

  //   if (name === "quantity" || name === "unitPrice" || name === "gst") {
  //     const quantity = parseFloat(newProducts[index].quantity) || 0;
  //     const unitPrice = parseFloat(newProducts[index].unitPrice) || 0;
  //     let gst = parseFloat(newProducts[index].gst) || 0;

  //     // Enforce maximum GST value of 10%
  //     if (gst > 10) {
  //       gst = 10;
  //     }

  //     const total = (
  //       quantity * unitPrice +
  //       (quantity * unitPrice * gst) / 100
  //     ).toFixed(2);
  //     newProducts[index].total = total;
  //     newProducts[index].gst = gst; // Update gst value in case it was corrected
  //   }

  //   formik.setFieldValue("products", newProducts);
  // };

  const handleInputChange = (index, e) => {
    const { name, value } = e.target;
    const newProducts = [...formik.values.products];
    newProducts[index][name] = value;

    if (name === "quantity" || name === "unitPrice" || name === "gst") {
      const quantity = parseFloat(newProducts[index].quantity) || 0;
      const unitPrice = parseFloat(newProducts[index].unitPrice) || 0;
      const gst = parseFloat(value) || 0;

      // Check if GST value exceeds unit price
      if (name === "gst" && gst > unitPrice) {
        // Set error message
        const newErrors = [...errors];
        newErrors[index] = "GST value cannot exceed unit price.";
        setErrors(newErrors);
      } else {
        // Clear error message
        const newErrors = [...errors];
        newErrors[index] = "";
        setErrors(newErrors);

        const total = (
          quantity * unitPrice +
          (quantity * unitPrice * gst) / 100
        ).toFixed(2);
        newProducts[index].total = total;
      }
    }

    formik.setFieldValue("products", newProducts);
  };

  useEffect(() => {
    calculateGrandTotal();
  }, [formik.values.products]);

  const calculateGrandTotal = () => {
    if (!formik.values || !formik.values.products) {
      setGrandTotal(0);
      return;
    }

    let total = 0;
    formik.values.products.forEach((product) => {
      total += parseFloat(product.total) || 0;
    });
    setGrandTotal(total.toFixed(2));
  };

  return (
    <div>
      {viewDetails ? null : (
        <CForm onSubmit={formik.handleSubmit}>
          <CCol className="mb-2" md="12">
            <label htmlFor="nameofrecipient">
              Name of Recipient <span className="text-danger">*</span>
            </label>
            <CFormInput
              type="text"
              id="nameofrecipient"
              placeholder="Enter recipient name"
              name="recipientName"
              value={formik.values.recipientName}
              onChange={formik.handleChange}
            />
            {formik.touched.recipientName && formik.errors.recipientName && (
              <div className="text-danger">{formik.errors.recipientName}</div>
            )}
          </CCol>

          <CCol className="mb-2" md="12">
            <label htmlFor="startDate">
              Date <span className="text-danger">*</span>{" "}
            </label>
            <CFormInput
              type="date"
              name="selectedDate"
              value={formik.values.selectedDate}
              onChange={formik.handleChange}
            />
            {formik.touched.selectedDate && formik.errors.selectedDate && (
              <div className="text-danger">{formik.errors.selectedDate}</div>
            )}
          </CCol>
          <CCol className="mb-2" md="12">
            <label htmlFor="RecipientMobile">
              Recipient Mobile (to send SMS)
              <span className="text-danger">*</span>
            </label>
            <PhoneInput
              name="phoneNumber"
              placeholder="Enter mobile number"
              onChange={(value, country) => handleOnChange(value, country)}
              country={"au"}
              value={formik.values.phoneNumber}
              inputStyle={{ width: "100%" }}
              containerStyle={{ width: "100%" }}
            />
          </CCol>
          <CCol className="mb-2" md="12">
            <label htmlFor="RecipientEmail">
              Recipient Email <span className="text-danger">*</span>
            </label>
            <CFormInput
              type="email"
              id="RecipientEmail"
              name="recipientEmail"
              placeholder="Enter Recipient Email"
              value={formik.values.recipientEmail}
              onChange={formik.handleChange}
            />
            {formik.touched.recipientEmail && formik.errors.recipientEmail && (
              <div className="text-danger">{formik.errors.recipientEmail}</div>
            )}
          </CCol>
          <CCol className="mb-2" md="12">
            <label htmlFor="CompanyName">
              Company Name <span className="text-danger">*</span>
            </label>
            <CFormInput
              type="text"
              id="CompanyName"
              name="companyName"
              placeholder="Enter Company Name"
              value={formik.values.companyName}
              onChange={formik.handleChange}
            />
            {formik.touched.companyName && formik.errors.companyName && (
              <div className="text-danger">{formik.errors.companyName}</div>
            )}
          </CCol>
          <CCol className="mb-2" md="12">
            <label htmlFor="abnnumber">
              ABN Number <span className="text-danger">*</span>
            </label>
            <CFormInput
              type="text"
              id="abnnumber"
              name="abnNumber"
              placeholder="Enter ABN Number"
              value={formik.values.abnNumber}
              onChange={formik.handleChange}
            />
            {formik.touched.abnNumber && formik.errors.abnNumber && (
              <div className="text-danger">{formik.errors.abnNumber}</div>
            )}
          </CCol>

          <CRow className="mt-3">
            <CCol xs="auto">
              <label>Item types: </label>
            </CCol>
            <CCol xs="auto">
              <CFormCheck
                inline
                type="radio"
                name="itemType"
                id="inlineCheckbox1"
                value="Service"
                label="Service"
                checked={formik.values.itemType === "Service"}
                onChange={handleRadioChange}
              />
              <CFormCheck
                inline
                type="radio"
                name="itemType"
                id="inlineCheckbox2"
                value="Product"
                label="Product"
                checked={formik.values.itemType === "Product"}
                onChange={handleRadioChange}
              />
            </CCol>
          </CRow>

          <CCol>
            {formik.values.itemType === "Service" && (
              <CRow>
                <CCol>
                  <CCol>
                    <label htmlFor="serviceInput">Service Description</label>
                    <CFormTextarea
                      id="serviceInput"
                      rows={2}
                      placeholder="Enter description..."
                      name="serviceDescription"
                      value={formik.values.serviceDescription}
                      onChange={formik.handleChange}
                    ></CFormTextarea>
                  </CCol>
                  <CRow className="mt-2 mb-3">
                    <CCol md={3}>
                      <label htmlFor="Service Charge">Service Charge</label>
                      <CFormInput
                        type="text"
                        id="serviceCharge"
                        name="serviceCharge"
                        onChange={handleServiceChargeChange}
                      />
                    </CCol>
                    <CCol md={2}>
                      <label htmlFor="Gst">GST (%)</label>
                      <CFormInput
                        type="text"
                        name="gst"
                        value={formik.values.gst}
                        onChange={handleGstChange}
                      />
                      {gstError && (
                        <div style={{ color: "red" }}>{gstError}</div>
                      )}
                    </CCol>

                    <CCol style={{ marginTop: "34px", marginLeft: "116px" }}>
                      <strong>Total: {total}</strong>
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
            )}
            {formik.values.itemType === "Product" && (
              <CRow className="mt-1">
                <>
                  {formik.values.products.map((product, index) => (
                    <CRow key={index} className="mt-1">
                      <CCol md="3">
                        <CFormSelect
                          name="product"
                          value={formik.values.products[index].product} // Set the value attribute here
                          onChange={(e) => handleInputChange(index, e)}
                          aria-label="Default select example"
                          label={index === 0 ? "Product" : ""}
                        >
                          <option value="">Select product name</option>
                          <option value="product1">product1</option>{" "}
                          {/* Ensure values match the product strings */}
                          <option value="product2">product2</option>
                          <option value="product3">product3</option>
                        </CFormSelect>
                      </CCol>
                      <CCol md="2">
                        <CFormInput
                          type="text"
                          name="quantity"
                          value={product.quantity}
                          onChange={(e) => handleInputChange(index, e)}
                          label={index === 0 ? "Quantity" : ""}
                        />
                      </CCol>
                      <CCol md="2">
                        {index === 0 && (
                          <label htmlFor={`UnitPrice${index}`}>
                            Unit Price
                          </label>
                        )}
                        <CFormInput
                          type="text"
                          name="unitPrice"
                          id={`UnitPrice${index}`}
                          value={product.unitPrice}
                          onChange={(e) => handleInputChange(index, e)}
                        />
                      </CCol>
                      <CCol md="2">
                        <CFormInput
                          type="number"
                          name="gst"
                          value={product.gst}
                          // min="0"
                          // max="10" // Maximum GST value allowed
                          // step="0.01" // Allow decimal values
                          onChange={(e) => handleInputChange(index, e)}
                          label={index === 0 ? "GST (%)" : ""}
                        />
                        {errors[index] && (
                          <div className="text-danger">{errors[index]}</div>
                        )}
                      </CCol>
                      <CCol md="2">
                        <CFormInput
                          type="text"
                          name="total"
                          value={product.total}
                          readOnly
                          label={index === 0 ? "Total" : ""}
                        />
                      </CCol>
                      <CCol className="mt-2" md="1">
                        <div className="rowIcon">
                          {index !== 0 && (
                            <CTooltip content="Delete">
                              <button
                                type="button"
                                className="icon-deleteIcon"
                                onClick={(event) => {
                                  event.preventDefault();
                                  handleRemoveRow(index);
                                }}
                              ></button>
                            </CTooltip>
                          )}
                        </div>
                      </CCol>
                    </CRow>
                  ))}
                  <CTooltip content="Add">
                    <CButton
                      color="primary"
                      onClick={handleAddRow}
                      style={{ marginTop: "-34px", marginLeft: "548px" }}
                    >
                      <AddIcon />
                    </CButton>
                  </CTooltip>
                  <div>
                    <strong>Total:</strong> {grandTotal}
                  </div>
                </>
                <CRow className="mt-3 mb-3">
                  <CCol>
                    <label>Special Notes</label>
                    <CFormTextarea
                      rows={2}
                      name="specialNotes"
                      placeholder="Enter notes here..."
                      value={formik.values.specialNotes}
                      onChange={formik.handleChange}
                    ></CFormTextarea>
                  </CCol>
                </CRow>
              </CRow>
            )}
          </CCol>

          <CRow className="mt-1">
            <CCol>
              <label>
                Address<span className="text-danger">*</span>
              </label>
              <CFormTextarea
                id="address"
                rows={3}
                name="address"
                placeholder="Enter address..."
                value={formik.values.address}
                onChange={formik.handleChange}
              ></CFormTextarea>
              {formik.touched.address && formik.errors.address && (
                <div className="text-danger">{formik.errors.address}</div>
              )}
            </CCol>
            <CCol>
              <label>
                Delivery Address<span className="text-danger">*</span>
              </label>
              <CFormTextarea
                id="deliveryAddress"
                rows={3}
                name="deliveryAddress"
                placeholder="Enter delivery address..."
                value={formik.values.deliveryAddress}
                onChange={formik.handleChange}
              ></CFormTextarea>
              {formik.touched.deliveryAddress &&
                formik.errors.deliveryAddress && (
                  <div className="text-danger">
                    {formik.errors.deliveryAddress}
                  </div>
                )}
            </CCol>
          </CRow>

          <CRow className="mt-3">
            <CCol xs="auto">
              <label>Payment Terms: </label>
            </CCol>
            <CCol xs="auto">
              <CFormCheck
                inline
                type="radio"
                name="paymentTerms"
                id="paymentonorder"
                value="paymentonorder"
                label="Payment upon Order"
                checked={formik.values.paymentTerms === "paymentonorder"}
                onChange={handlecheckChange}
                className="text-wrap fst-normal"
              />
            </CCol>
            <CCol xs="auto">
              <CFormCheck
                inline
                type="radio"
                name="paymentTerms"
                id="agreedterms"
                value="Agreedterms"
                label="Agreed Terms"
                checked={formik.values.paymentTerms === "Agreedterms"}
                onChange={handlecheckChange}
                className="text-md-start"
              />
            </CCol>
            <CCol xs="auto">
              <CFormCheck
                inline
                type="radio"
                name="paymentTerms"
                id="otherterms"
                value="otherTerms"
                label="Other Terms"
                checked={formik.values.paymentTerms === "otherTerms"}
                onChange={handlecheckChange}
                className="text-md-start"
              />
            </CCol>
          </CRow>
          <CCol>
            {formik.values.paymentTerms === "paymentonorder" && (
              <CRow className="mt-1">
                <CCol></CCol>
              </CRow>
            )}
            {formik.values.paymentTerms === "Agreedterms" && (
              <CRow className="mt-1">
                <CCol>
                  <label htmlFor="creditDuration">Credit Duration</label>
                  <CFormSelect
                    id="creditDuration"
                    aria-label="Default select example"
                    name="creditDuration"
                    value={formik.values.creditDuration}
                    onChange={formik.handleChange}
                  >
                    <option>Select credit duration</option>
                    <option value="1 Month">1 Month</option>
                    <option value="2 Month">2 Month</option>
                    <option value="3 Month">3 Month</option>
                  </CFormSelect>
                </CCol>
              </CRow>
            )}
            {formik.values.paymentTerms === "otherTerms" && (
              <CRow className="mt-1">
                <CCol>
                  <label htmlFor="otherTerms">Terms & Conditions</label>
                  <CFormTextarea
                    id="otherTerms"
                    rows={2}
                    name="otherTerms"
                    value={formik.values.otherTerms}
                    onChange={formik.handleChange}
                  ></CFormTextarea>{" "}
                </CCol>
              </CRow>
            )}
          </CCol>

          {/* Add more form fields here */}
          <CRow className="mt-3 mb-3 justify-content-end">
            <CCol xs="auto">
              <CButton onClick={handleClose} color="light">
                Cancel
              </CButton>
            </CCol>
            <CCol xs="auto">
              <CButton
                type="submit"
                color="primary"
                disabled={formik.isSubmitting}
                onClick={formik.handleSubmit} // Add this line to bind handleSubmit to onClick event
              >
                Send Proposal
              </CButton>
            </CCol>
          </CRow>
        </CForm>
      )}
    </div>
  );
};

export default Add;

