import React, { createContext, useState, useContext } from 'react';

const DataContext = createContext();

export const useData = () => useContext(DataContext);

export const DataProvider = ({ children }) => {
  const [triggerRenderHeaderOnCompanyAdd, setTriggerRenderHeaderOnCompanyAdd] = useState(false);
  const [triggerRenderHeaderOnBankAdd, setTriggerRenderHeaderOnBankAdd] = useState(false);
  const [data, setData] = useState(localStorage.getItem('companyData') || '');
  const [bankDataLocal, setBankData] = useState(localStorage.getItem('bankData') || '')

  const updateData = (newValue) => {
    localStorage.setItem('companyData', JSON.stringify(newValue));
    setData(newValue);
    return;
  };

  const updateBank = (newValue) => {
    localStorage.setItem('bankData', JSON.stringify(newValue));
    setBankData(newValue);
    return;
  };

  const removeData = () => {
    localStorage.removeItem("companyData");
    setData('');
    return;
  };

  const removeBank = () => {
    localStorage.removeItem("bankData");
    setBankData('');
    return;
  };

  return (
    <DataContext.Provider value={{ 
      data,
      updateData,
      bankDataLocal,
      updateBank,
      triggerRenderHeaderOnCompanyAdd,
      setTriggerRenderHeaderOnCompanyAdd,
      triggerRenderHeaderOnBankAdd,
      setTriggerRenderHeaderOnBankAdd,
      removeData,
      removeBank
    }}>
      {children}
    </DataContext.Provider>
  );
};
