import React, { useState, useEffect, useMemo, useRef } from "react";
import pako from "pako";
import { useLocation } from "react-router-dom";
import {
  CRow,
  CCol,
  CButton,
  CFormInput,
  CForm,
  CFormLabel,
  CModalBody,
  CCarousel,
  CImage,
  CCarouselItem,
  CFormTextarea,
  CCard,
  CCardBody,
  CTable,
  CTableHead,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
  CTableRow,
  CSpinner,
  CTooltip,
  CFormSelect,
} from "@coreui/react";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { userAttributes } from "src/share/localData";
import {
  GetApiwithHeader,
  UpdateUserwithHeader,
  postApiWithHeader,
  DeleteApiwithHeader,
} from "src/Api/Api";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { useData } from "../../components/dataContext";
import "react-datepicker/dist/react-datepicker.css";
import { addDays } from "date-fns";

const Entry = () => {
  const { data } = useData();
  const [datas, setData] = useState("");
  const [map, setMap] = useState(true);
  const [verifyMsg, setverifyMsg] = useState("");
  const [gstMsg, setGstMsg] = useState("");
  const [transactionCode, setTransactionCode] = useState("");
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const [mapData, setMapData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [formDisabled, setFormDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const { cmpID, bankID } = userAttributes();
  const accessToken = localStorage.getItem("token");
  const [searchQuery, setSearchQuery] = useState("");
  const [abnValue, setAbnValue] = useState();
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [abnValid, setAbnValid] = useState(true);
  const [bankData, setBankData] = useState([]);
  const localBankData = JSON.parse(localStorage.getItem("bankData"));
  const [defaultValueBank, setDefaultValueBank] = useState(
    localBankData ? localBankData.Id : ""
  );
  const [discardMapping, setDiscardMapping] = useState(false);
  const [iconStatus, setIconStatus] = useState(false);
  const [receiptDate, setReceiptDate] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const companyId = cmpID;
  const transactionCodeId = location.state?.item;
  const details = transactionCodeId?.bankTransactions[0];
  const prevCmpID = useRef();
  const prevBankID = useRef();
  const bankSelectId = useRef(null);

  const headers = useMemo(
    () => ({
      Authorization: `Bearer ${accessToken}`,
    }),
    [accessToken]
  );

  useEffect(() => {
    if (transactionCodeId?.receiptDate) {
      transactionCodeId.abn === null
        ? setReceiptDate(new Date())
        : setReceiptDate(new Date(transactionCodeId.receiptDate));
    }
    if (transactionCodeId && transactionCodeId.mappingStatus === "Mapped") {
      setDiscardMapping(true);
    }
    if (transactionCodeId && transactionCodeId.abn) {
      setIconStatus(true);
    }
  }, [transactionCodeId?.receiptDate]);

  const onSubmit = async (data) => {
    setFormDisabled(true);
    setLoading(true);
    const bankIDCon = bankSelectId.current?.id
      ? bankSelectId.current?.id
      : bankID;
    data.companyId = cmpID;
    data.bankAccountId = bankIDCon;
    data.gpsLatitude = transactionCodeId?.gpsLatitude;
    data.gpsLongitude = transactionCodeId?.gpsLongitude;
    data.receiptDate = receiptDate;
    data.ValueGST = data?.ValueGST ? data?.ValueGST : 0;
    data.ValueAmount = data.ValueAmount ? data.ValueAmount : 0;
    setData(data);
    const receiptDateConvert = new Date(receiptDate).toLocaleDateString(
      "en-GB"
    );
    const dt = receiptDateConvert.split("/").reverse().join("-");
    try {
      const response = await UpdateUserwithHeader(
        `api/receipt/update?id=${
          transactionCodeId?.id
        }&CompanyId=${cmpID}&BankAccountId=${bankIDCon}&ABN=${
          data.ABN
        }&ValueGST=${data?.ValueGST ? data?.ValueGST : 0}&ValueAmount=${
          data.ValueAmount ? data.ValueAmount : 0
        }&ReceiptDate=${dt}&Comments=${data?.comments}`,
        // data,
        {},
        headers
      );
      if (response?.statusCode === 100) {
        toast.success("Updated successfully", { autoClose: 1000 });
      } else {
        toast.warning(response?.message, { autoClose: 1000 });
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleBackClick = () => {
    navigate("/receipt");
  };
  const handleDateChange = (date) => {
    setReceiptDate(date);
  };

  // Function to decompress Gzip data to a json array
  const decompressGzipToJson = (data) => {
    try {
      // Decode base64 string to Uint8Array
      const binaryString = atob(data);
      const uint8Array = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        uint8Array[i] = binaryString.charCodeAt(i);
      }

      // Unzip the Uint8Array using pako
      const decompressedData = pako.inflate(uint8Array, { to: "string" });

      // Parse the decompressed data as JSON
      const jsonData = JSON.parse(decompressedData);
      return jsonData;
    } catch (e) {
      console.log("Error decompressing Gzip data:", e);
      return [];
    }
  };

  const fetchData = async () => {
    const bankIDCon = bankSelectId.current?.id
      ? bankSelectId.current?.id
      : bankID;
    try {
      const res = await GetApiwithHeader(
        `/api/BankTransactions/GetAll_new/${companyId},12-01-2024?bankAccountId=${bankIDCon}`,
        headers
      );
      const statementList = await decompressGzipToJson(res?.data?.data);
      const filteredData = (statementList || []).filter(
        (item) => item.isMapped === false
      );
      setMapData(filteredData);
    } catch (error) {
      console.log(error);
    } finally {
      setLoad(false);
    }
  };

  useEffect(() => {
    fetchData();
    if (
      (prevCmpID.current !== undefined && prevCmpID.current !== cmpID) ||
      (prevBankID.current !== undefined && prevBankID.current !== bankID)
    ) {
      navigate("/receipt");
    }
    prevCmpID.current = cmpID;
    prevBankID.current = bankID;
  }, [headers, companyId, cmpID, bankID]);

  const handleClickMap = () => {
    setLoad(true);
    setMap(false);
    fetchData();
  };

  const handleRowSelect = (index) => {
    setTransactionCode(String(index.transactionCode));
    setSelectedRows([index]);
  };

  const handleMapEntry = async (id) => {
    if (selectedRows.length === 0) {
      toast.warning("Please Select Transcation.", {
        autoClose: 1000,
      });
      return;
    }
    const payload = {
      transactionCode: transactionCode,
      receiptId: transactionCodeId.id,
    };
    try {
      const response =
        details && discardMapping
          ? await UpdateUserwithHeader(
              `/api/BankTransactionMapping/update/${id},${companyId}`,
              payload,
              headers
            )
          : await postApiWithHeader(
              `/api/BankTransactionMapping/create/${companyId}`,
              payload,
              headers
            );
      if (
        details && discardMapping
          ? response.message == "Success"
          : response.data.message == "Success"
      ) {
        toast.success("Entry mapped successfully", {
          autoClose: 1000,
          onClose: () => navigate("/receipt"),
        });
      } else {
        toast.warning(response?.data?.message, { autoClose: 1000 });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const filteredData =
    mapData &&
    mapData?.filter(
      (item) =>
        item?.transactionCode
          ?.toLowerCase()
          ?.includes(searchQuery?.toLowerCase()) ||
        item?.createdAt?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
        item?.balance?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
        item?.narration?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
        item?.withdrawalAmount
          ?.toLowerCase()
          ?.includes(searchQuery?.toLowerCase())
    );
  const handleVerify = async (data) => {
    try {
      const res = await GetApiwithHeader(
        `/api/abn/ABN-Details?companyId=${companyId}&abn=${
          abnValue ? abnValue : transactionCodeId?.abn
        }`,
        headers
      );
      if (res.data.statusCode === 101) {
        setverifyMsg("Please enter correct ABN number");
        setSaveDisabled(true);
        setIconStatus(false);
      } else {
        setIconStatus(true);
        setverifyMsg("ABN Verified.");
        setSaveDisabled(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleChangeAbn = (e) => {
    setAbnValue(e.target.value);
    if (e.target.value.length < 11) {
      setverifyMsg("");
    }
    setAbnValid(true);
  };

  const handleChangeGST = (e) => {
    const amount = watch("ValueAmount");
    let gstValue = e.target.value;

     // Clear any existing timeout to prevent multiple timers from running simultaneously
     clearTimeout(handleChangeGST.timeout);

    handleChangeGST.timeout = setTimeout(() => {
      gstValue = parseFloat(gstValue) / 11;
    
    if (parseFloat(gstValue) > parseFloat(amount)) {
      setGstMsg("GST value cannot exceed the amount");
      setSaveDisabled(true);
    } else {
      setGstMsg("");
      setSaveDisabled(false);
      setValue("ValueGST", gstValue.toFixed(2));
    }
    
  },3000)
};


  const bankApi = async () => {
    try {
      const response = await GetApiwithHeader(
        `/api/company/bankaccounts/${cmpID}`,
        headers
      );
      if (
        response &&
        response?.data &&
        response?.data?.data &&
        response?.data?.data.length > 0
      ) {
        setBankData(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    bankApi();
  }, []);

  const handleSelectBank = (selectedBankId) => {
    const selectedBank = bankData.find(
      (bank) => bank.id === parseInt(selectedBankId)
    );
    bankSelectId.current = selectedBank;
  };
  const deleteMapping = async () => {
    const apiUrl = `/api/BankTransactionMapping/delete/${
      transactionCodeId && transactionCodeId?.mappingId
    },${companyId}`;
    try {
      const response = await DeleteApiwithHeader(apiUrl, headers);
      if (response.statusCode === 100) {
        setDiscardMapping(false);
        toast.success("Mapping Deleted successfully", { autoClose: 1000 });
      } else {
        toast.warning("Something went wrong !", { autoClose: 1000 });
        console.error("Unexpected response format:", response);
      }
    } catch (error) {
      console.error("Delete API Request Error:", error);
    } finally {
      // Reset loading state regardless of success or failure
    }
  };

  return (
    <div className="reciptEntryWrapper">
      <ToastContainer />
      <div className="d-flex justify-content-between align-items-center">
        <h2>Receipt</h2>
        <div className="b-button-back">
          <CButton onClick={handleBackClick} color="black" variant="ghost">
            Back
          </CButton>
        </div>
      </div>
      <hr />
      <CRow>
        <CCol md={6}>
          <CCard className="overflow-hidden">
            <CModalBody>
              <div className="entry-carousel entryImgWrapper">
                <CCarousel controls interval={null}>
                  {transactionCodeId.mediaPath.map((items, index) => (
                    <CCarouselItem key={index}>
                      <CImage
                        className="entryCardImage"
                        src={items}
                        alt="slide 1"
                      />
                    </CCarouselItem>
                  ))}
                </CCarousel>
              </div>
            </CModalBody>
          </CCard>
        </CCol>
        <CCol md={6}>
          <CCard className="h-100">
            <CCardBody>
              <CForm className="entry" onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-3">
                  <h3>
                    Receipt Entry - {transactionCodeId && transactionCodeId?.id}
                  </h3>
                </div>
                <CRow>
                  <CCol sm={6}>
                    <div className="form-group">
                      <CFormInput
                        type="text"
                        id="input2"
                        name="ValueAmount"
                        label="Amount"
                        placeholder="Amount"
                        {...register("ValueAmount")}
                        defaultValue={
                          transactionCodeId?.valueAmount
                            ? transactionCodeId?.valueAmount
                            : ""
                        }
                      />
                    </div>
                  </CCol>
                  <CCol sm={6}>
                    <div className="form-group">
                      <CFormInput
                        type="text"
                        id="input3"
                        name="ValueGST"
                        placeholder="GST"
                        label="GST"
                        {...register("ValueGST")}
                        defaultValue={
                          transactionCodeId?.valueGST
                            ? transactionCodeId?.valueGST
                            : ""
                        }
                        onChange={(e) => {
                          handleChangeGST(e);
                          // setValue("ValueGST", e.target.value);
                        }}
                      />
                    </div>
                    {gstMsg && <span style={{ color: "red" }}>{gstMsg}</span>}
                  </CCol>
                  <CCol sm={6}>
                    <div className="form-group">
                      <CFormLabel className="w-100"> Date</CFormLabel>
                      <DatePicker
                        className="form-control"
                        selected={receiptDate}
                        onChange={handleDateChange}
                        dateFormat="dd-MM-yyyy"
                        maxDate={addDays(receiptDate, -1)}
                      />
                    </div>
                  </CCol>
                  <CCol sm={6}>
                    <div className="form-group CheckBoxAbn">
                      <CFormInput
                        type="text"
                        id="input1"
                        name="ABN"
                        label="ABN"
                        placeholder="ABN"
                        {...register("ABN", { required: "ABN is required" })}
                        defaultValue={
                          transactionCodeId.abn ? transactionCodeId.abn : ""
                        }
                        onChange={handleChangeAbn}
                      />
                      <div className="CheckIcon">
                        <CTooltip
                          content={
                            iconStatus ? "Verified" : "Send for verification!"
                          }
                        >
                          <CheckCircleOutlineOutlinedIcon
                            style={{ color: iconStatus ? "green" : "red" }}
                            onClick={handleVerify}
                          />
                        </CTooltip>
                      </div>
                    </div>
                    {verifyMsg === "ABN Verified." ? (
                      <span className="mt--10" style={{ color: "green" }}>
                        {verifyMsg}
                      </span>
                    ) : (
                      <span style={{ color: "red" }}>{verifyMsg}</span>
                    )}
                  </CCol>
                  <CCol sm={6}>
                    <div className="form-group">
                      <CFormSelect
                        label="Select Bank Name"
                        aria-label="Select Bank Name"
                        onChange={(e) => {
                          const selectedValue = e.target.value;
                          handleSelectBank(selectedValue);
                          setDefaultValueBank(selectedValue);
                          handleClickMap();
                        }}
                        disabled={discardMapping}
                        options={[
                          ...(localBankData &&
                          !bankData.some((bank) => bank.id === localBankData.Id)
                            ? [
                                {
                                  label: localBankData.Name,
                                  value: localBankData.Id,
                                },
                              ]
                            : []),
                          ...(bankData.map((bank) => ({
                            label: bank.accountName,
                            value: bank.id.toString(),
                          })) || []),
                        ]}
                        value={defaultValueBank}
                      />
                    </div>
                  </CCol>
                  <CCol sm={12}>
                    <div className="form-group">
                      <CFormTextarea
                        id="comment"
                        name="comments"
                        placeholder="Description here"
                        label="Comments"
                        {...register("comments")}
                        defaultValue={
                          transactionCodeId.comments
                            ? transactionCodeId.comments
                            : ""
                        }
                      ></CFormTextarea>
                    </div>
                  </CCol>
                  <CCol>
                    <div className="text-end">
                      <CButton className="mr-2">Request to process</CButton>
                      <CButton
                        type="submit"
                        color="primary"
                        disabled={loading || saveDisabled}
                      >
                        {loading ? (
                          <CSpinner
                            animation="border"
                            size="sm"
                            role="status"
                          />
                        ) : (
                          "Save"
                        )}
                      </CButton>
                    </div>
                  </CCol>
                </CRow>
                <hr />
                {map ? (
                  <>
                    {details && details?.length !== 0 && discardMapping ? (
                      <CCard className="p-0 overflow-hidden">
                        <h3 className="mb-2">
                          <b>Mapped Entry Details</b>
                        </h3>
                        <CCardBody className="p-0">
                          <CTable className="m-0 tableLastBoderNone">
                            <CTableBody>
                              <CTableRow>
                                <CTableDataCell>
                                  Entry : {details?.entryCode}
                                  <br />
                                  Date :{" "}
                                  {new Date(
                                    details?.valueDate
                                  )?.toLocaleDateString("en-GB")}
                                  <br />
                                  Amount ($) :
                                  {details?.depositAmount !== "0"
                                    ? details?.depositAmount
                                    : details?.withdrawalAmount !== "0"
                                    ? details?.withdrawalAmount
                                    : null}
                                  <br />
                                  Description : {details?.narration}
                                  <br />
                                </CTableDataCell>
                                <CTableDataCell></CTableDataCell>
                              </CTableRow>
                            </CTableBody>
                          </CTable>
                        </CCardBody>
                      </CCard>
                    ) : (
                      ""
                    )}
                    <CCol sm={12} className="d-flex mt-2 justify-content-end">
                      <>
                        {details && discardMapping ? (
                          <CButton className="mr-2" onClick={deleteMapping}>
                            Discard Mapping
                          </CButton>
                        ) : null}
                        <CButton onClick={handleClickMap}>
                          {details && discardMapping ? "Re-Map" : "Map"}
                        </CButton>
                      </>
                    </CCol>
                  </>
                ) : (
                  <>
                    <h3>Transaction List</h3>
                    <div className="cardBody">
                      <div className="cardHeader">
                        <div className="mr-1">
                          <input
                            type="text"
                            placeholder="Search..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                          />
                        </div>
                      </div>
                      <CCard className="p-0 overflow-hidden">
                        <CCardBody className="p-0 reMapEntry">
                          <CTable className="m-0 tableLastBoderNone">
                            <CTableHead>
                              <CTableRow>
                                <CTableHeaderCell></CTableHeaderCell>
                                <CTableHeaderCell>Entry </CTableHeaderCell>
                                <CTableHeaderCell>Date</CTableHeaderCell>
                                <CTableHeaderCell>Amount($)</CTableHeaderCell>
                                <CTableHeaderCell>Description</CTableHeaderCell>
                                <CTableHeaderCell>Balance</CTableHeaderCell>
                              </CTableRow>
                            </CTableHead>
                            <CTableBody>
                              {load ? (
                                <tr>
                                  <td colSpan="6" className="text-center">
                                    <CSpinner color="primary" size="lg" />
                                  </td>
                                </tr>
                              ) : (
                                filteredData?.length !== undefined &&
                                filteredData?.map((item, index) => (
                                  <CTableRow
                                    key={index}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleRowSelect(item)}
                                  >
                                    <CTableDataCell>
                                      <input
                                        type="radio"
                                        checked={selectedRows.includes(item)}
                                        onChange={() => handleRowSelect(item)}
                                      />
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      {item?.entryCode}
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      {new Date(
                                        item?.createdAt
                                      )?.toLocaleDateString("en-US")}
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      {item?.depositAmount !== "0"
                                        ? item?.depositAmount
                                        : item?.withdrawalAmount}
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      {item?.narration}
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      {Math.abs(item?.balance)}
                                    </CTableDataCell>
                                  </CTableRow>
                                ))
                              )}
                            </CTableBody>
                          </CTable>
                        </CCardBody>
                      </CCard>
                      <CRow className="mt-4 justify-content-end wrap">
                        <CButton
                          onClick={() =>
                            handleMapEntry(transactionCodeId.mappingId)
                          }
                        >
                          Map Entry
                        </CButton>
                      </CRow>
                    </div>
                  </>
                )}
              </CForm>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </div>
  );
};

export default Entry;
