import React, { useMemo, useState, useEffect } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { CButton, CFormInput, CImage, CTooltip } from '@coreui/react';
import { userAttributes } from "../../share/localData";
import { DeleteApiwithHeader, GetApiwithHeader, postApiWithHeader, UpdateUserwithHeader } from 'src/Api/Api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { deleteIcon } from 'src/components/Images/Images';
import Checkbox from '@mui/material/Checkbox';

const main = {
  py: 0,
  width: '100%',
  borderRadius: 2,
  border: '1px solid',
  borderColor: 'divider',
  backgroundColor: 'background.paper',
  maxHeight: '410px',
  overflowY: 'auto',
};

const Itim = (props) => {
  // const { bankTransactionCode } = props
  const [showInput, setShowInput] = useState(false);
  const [newItemText, setNewItemText] = useState('');
  const [items, setItems] = useState([])
  const [itimId, setItimId] = useState();
  const { cmpID } = userAttributes();
  const [errorMessage, setErrorMessage] = useState('');
  const accessToken = localStorage.getItem("token");
  const [updateItimID, setUpdateItimID] = useState()
  const companyId = cmpID;
  const fetchData = async () => {
    try {
      const res = await GetApiwithHeader(`/api/ITIM/Get/${companyId}`, headers);
      console.log('res', res)
      setItems(res?.data?.data || []);

    } catch (error) {
      console.log(error);
    }
  };
  const headers = useMemo(
    () => ({
      Authorization: `Bearer ${accessToken}`,
    }),
    [accessToken]
  );
  useEffect(() => {
    fetchData();
  }, [headers]);

  // console.log('items', items)

  const handleAddButtonClick = () => {
    setShowInput(true);
  };

  const handleSaveButtonClick = async () => {
    if (newItemText.trim() !== '') {
      try {
        const res = await postApiWithHeader(`/api/ITIM/create/${companyId}`, { itim: newItemText }, headers);
        if (res.data.statusCode === 100) {
          setNewItemText('');
          setShowInput(false);
          fetchData();
          toast.success("Added Successfully", { autoClose: 1000 })
          setErrorMessage('');
        } else if (res.data.statusCode === 102) {
          setErrorMessage(res.data.message)
        }
      }
      catch (error) {
        console.log(error);
      }
    } else {
      setErrorMessage('Please enter a value');
    }
  };


  const handleSaveButton = async (id) => {
    console.log('id', props?.bankTransactionCode?.itimEntryId)
    if (!itimId) {
      toast.warning("Please check any ITIM", { autoClose: 1000 });
      return;
    }
   
    try {
      const res = props?.bankTransactionCode?.isITIM ? await UpdateUserwithHeader(
        `api/ITIM/UpdateCoding/${props?.bankTransactionCode?.itimEntryId},${companyId}?TransactionCode=${props?.bankTransactionCode?.transactionCode}&ITIMId=${id}`,
        {},
        headers
      ) : await postApiWithHeader(`api/ITIM/CreateCoding/${companyId}?TransactionCode=${props?.bankTransactionCode?.transactionCode}&ITIMId=${itimId}`, {}, headers)
      
      if (props?.bankTransactionCode?.isITIM ? res.message == "Success" : res.data.message == "Success") {
        toast.success("Successfully", { autoClose: 1000, onClose: ()=> (props && props?.handleCloseClick()) })
        props?.fetchDataCoding();
        setItimId('');
      } else if (res?.data?.statusCode === 102) {
        toast.warning(res.data.message, { autoClose: 1000, onClose: ()=> (props && props?.handleCloseClick()) });
        setItimId('');
      }
    }
    catch (error) {
      console.log(error);
    }
  }


  const handleDeleteButtonClick = async (itemId) => {
    try {
      const response = await DeleteApiwithHeader(`/api/ITIM/delete/${itemId},${companyId}`, headers);
      setItems(items.filter(item => item.id !== itemId));

      if (response.statusCode === 100) {
        toast.success("Deleted Successfully", { autoClose: 1000 })
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancelButtonClick = () => {
    setNewItemText('');
    setShowInput(false);
    setErrorMessage('');
    props?.handleCloseClick();
  };

  const handleInputChange = (e) => {
    setNewItemText(e.target.value);
    setErrorMessage('');
  };

  const handleCheckboxToggle = (itemId, checked) => {
    setUpdateItimID(itemId)
    if (!checked) setItimId(itemId);
    else setItimId('');
    setItems(items.map(item => {
      if (item.id === itemId) {
        return { ...item, checked: !item.checked }; // Toggle checked state for the clicked item
      } else {
        return { ...item, checked: false }; // Uncheck other items
      }
    }));
  };

  return (
    <div>
      <List >
        <h3 className="itim-heading">ITIM is an Individual Tax Investment Matter</h3>
        {showInput ? (
          <div className='ulliBox'>
            <>
              <ListItem>
                <CFormInput
                  value={newItemText}
                  onChange={handleInputChange}
                  placeholder="Enter ITIM"
                />
              </ListItem>
              {errorMessage && (
                <ListItem>
                  <p style={{ color: 'red' }}>{errorMessage}</p>
                </ListItem>
              )}
              <ListItem className="d-flex justify-content-end">
                <CButton onClick={handleCancelButtonClick} color="light" className='mr-2'>Cancel</CButton>
                <CButton onClick={handleSaveButtonClick} color="primary" type="submit">Save</CButton>
              </ListItem>
            </>
          </div>
        ) : (
          <ListItem style={{ display: 'flex', paddingLeft: '0', paddingRight: '0', justifyContent: 'flex-end' }} className='additim'>
            <Button onClick={handleAddButtonClick}> + Add ITIM</Button>
          </ListItem>
        )}
        <List sx={main} >
          {items.map((item, index) => (
            <React.Fragment key={index}>
              <div className='ulliBox2'>
                <ListItem>
                  <Checkbox
                    onClick={() => handleCheckboxToggle(item.id, item.checked)}
                    type="checkbox"
                    checked={item.id === itimId}
                  ></Checkbox>
                  <ListItemText onClick={() => handleCheckboxToggle(item.id, item.checked)} primary={item.itim} />
                  <CTooltip content="Delete">
                    <span className="icon-deleteIcon" onClick={() => handleDeleteButtonClick(item.id)}></span>
                  </CTooltip>
                </ListItem>
              </div>
              {index !== items.length - 1 && <Divider component="li" />}
            </React.Fragment>
          ))}
        </List>
        <ListItem className="d-flex justify-content-end mt-10">
          <CButton onClick={handleCancelButtonClick} color="light" className='mr-2'>Cancel</CButton>
          <CButton onClick={()=>{handleSaveButton(updateItimID)}} color="primary">Save</CButton>
        </ListItem>
      </List>
    </div>
  );
}

export default Itim;

