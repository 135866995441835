import React from 'react'
import {
  CAvatar,
  CBadge,
  CCol,
  CDropdown,
  CDropdownDivider,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CRow,
} from '@coreui/react'
import {
  cilBell,
  cilCreditCard,
  cilCommentSquare,
  cilEnvelopeOpen,
  cilFile,
  cilLockLocked,
  cilSettings,
  cilTask,
  cilUser,
} from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { useNavigate } from 'react-router-dom';
import { AvatarHeader } from '../Images/Images'
import DefaultImage from "../../assets/images/download.jpeg";

const AppHeaderDropdown = (props) => {
  const navigate = useNavigate();

  const user = localStorage.getItem('userInfo');
  var userDetail = JSON.parse(user);

  const handleLogout = () => {
    localStorage.removeItem("companyData");
    localStorage.removeItem("bankData");
    localStorage.removeItem("userId");
    localStorage.removeItem("userInfo");
    localStorage.removeItem("token");
    navigate('/');
  };
  const handleProfileClick = () => {
    // Navigate to the profile component
    navigate('/profile');
  };
  return (
    <CDropdown variant="nav-item">
      <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
         <CRow className="align-items-center">
        <CCol xs="auto">
          <CAvatar src={userDetail?.profile || DefaultImage} size="md" />
        </CCol>
        <CCol>
          <div className="font-weight-bold">{`${userDetail?.firstname} ${userDetail?.lastname}` }</div>
          <div className="text-muted">{`${props.role ? props.role :  ""}`}</div>
        </CCol>
      </CRow>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownItem onClick={handleProfileClick}>
          <CIcon icon={cilUser} className="me-2" />
          Profile
        </CDropdownItem>
        <CDropdownItem href="#">
          <CIcon icon={cilSettings} className="me-2" />
          Settings
        </CDropdownItem>
        <CDropdownDivider />
        <CDropdownItem  style={{cursor:'pointer'}} onClick={handleLogout}>
          <CIcon icon={cilLockLocked}  className="me-2"  />
          Logout
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  )
}

export default AppHeaderDropdown
