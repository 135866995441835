import React, { useMemo, useEffect, useState } from "react";
import {
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CButton,
  CTableHead,
  CTableRow,
  CCol,
  CCardBody,
  CCard,
  CImage,
  CModalBody,
  CModalHeader,
  CModalFooter,
  CModal,
  CTooltip,
  CSpinner,
} from "@coreui/react";
import { GetApiwithHeader, DeleteApiwithHeader } from "src/Api/Api";
import Add from "./Add";
import { deleteIcon, edit } from "src/components/Images/Images";
import OffCanvas from "../../share/offCanvas";
import { userAttributes } from "../../share/localData";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useData } from "../../components/dataContext";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { LicenseInfo } from '@mui/x-license';
import Details from "./Details";
import { useNavigate } from 'react-router-dom';

const List = () => {
  LicenseInfo.setLicenseKey('248a71a5e79c492f4b13769843fcf6dcTz04OTIzMCxFPTE3NDU3NTY1ODYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');
  const { data } = useData();
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [finaancialData, setFinacialData] = useState([]);
  const [valueData, setValueData] = useState();
  const { cmpID,bankID } = userAttributes();
  const accessToken = localStorage.getItem("token");
  const [visible, setVisible] = useState(false);
  const [editValue, setEditValue] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [loadingState, setLoadingState] = useState(true)
  const [narration, setNarration] = useState([]);
  const [confDelete, setConfDelete] = useState(false)
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
  const headers = useMemo(
    () => ({
      Authorization: `Bearer ${accessToken}`,
    }),
    [accessToken]
  );
  const companyId = cmpID;
  const BankID = bankID

  const fetchData = async () => {
    setLoadingState(true)
    try {
      const res = await GetApiwithHeader(
        `/api/portfolio/Get/${companyId}?bankAccountId=${BankID ? BankID : 0}`,
        headers
      );
      if (res?.data) setLoadingState(false)
      setFinacialData(res?.data?.data || []); 
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDataNarration = async () => {
    try {
      const res = await GetApiwithHeader(`/api/BankTransactions/Narration/${companyId}?bankAccountId=${bankID}`, headers);
      setNarration(res?.data?.data || []);
    } catch (error) {
      console.log(error);
    }
  };


  const filteredServices = useMemo(() => {
    return finaancialData && finaancialData?.filter(
      (service) =>
        service?.documentName?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
        service?.documentType?.toLowerCase()?.includes(searchQuery?.toLowerCase()) || 
        parseFloat(service?.assetValue).toString()?.includes(searchQuery) ||
        parseFloat(service?.termOfLoan).toString()?.includes(searchQuery) ||
        parseFloat(service?.presentValue).toString()?.includes(searchQuery) ||
        parseFloat(service?.installment).toString()?.includes(searchQuery) ||
        parseFloat(service?.roi).toString()?.includes(searchQuery)
    );
  }, [finaancialData, searchQuery]);
  
  useEffect(() => {
    fetchData();
    fetchDataNarration()
  }, [headers, companyId, cmpID, bankID]);

  const handleDelete = (i) => {
    // console.log('eeee', 
    // i?.bankTransactions.some(t => t.bankEntryCodingResponseDtos !== null && t.bankEntryCodingResponseDtos?.length < 0)
    // ,  i?.bankTransactions)
    const hasNonEmptyEntries = i?.bankTransactions?.some(item => {
      if (item.bankEntryCodingResponseDtos.length > 0) {
          console.log('true');
          return true;
      } else {
          console.log('false');
          return false;
      }
  });
  setConfDelete(hasNonEmptyEntries)
  console.log('Has non-empty entries:', hasNonEmptyEntries);

    setValueData(i);
    setIsModalVisible(true);
  };
  
  const handleDeleteApi = async (id) => {
    setIsDeleting(true);
    const apiUrl = `/api/portfolio/delete/${id},${companyId}`;
    try {
      const response = await DeleteApiwithHeader(apiUrl, headers);
      if (response.message === "Success") {
        setIsModalVisible(false);
        fetchData();
        toast.success("Deleted successfully", { autoClose: 1000 });
      } else {
        console.error("Unexpected response format:", response);
      }
    } catch (error) {
      console.error("Delete API Request Error:", error);
    } finally {
      setIsDeleting(false); 
    }
  };
  const handleAddClick = () => {
    if (cmpID) {
      setVisible(true);
    } else {
      toast.warning("Please select a company first", { autoClose: 1000 });
    }
  };
  const handleCloseClick = () => {
    setEditValue();
    setVisible(false);
  };
  const handleEditClick = (i) => {
    setVisible(true);
    setEditValue(i);
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const sortedItems = () => {
    const sortableItems = [...filteredServices];
    if (sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        const aValue = typeof a[sortConfig.key] === 'string' ? a[sortConfig.key].toLowerCase() : a[sortConfig.key];
        const bValue = typeof b[sortConfig.key] === 'string' ? b[sortConfig.key].toLowerCase() : b[sortConfig.key];

        if (aValue < bValue) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  };

  const handleView = (item) => {
    navigate('/financialDetails', { state: { item } })
  };

  const column = [
    { field: "documentName", headerName: "Document Name", width: 180, headerClassName: 'dataGridHeader' },
    { field: "documentType", headerName: "Document Type", width: 200, headerClassName: 'dataGridHeader' },
    { field: "assetValue", headerName: "Asset Value", width: 150, headerClassName: 'dataGridHeader' },
    { field: "numOfPaymentPeriods", headerName: "Payment Periods", width: 150, headerClassName: 'dataGridHeader' },
    { field: "presentValue", headerName: "Finance Value", width: 150, headerClassName: 'dataGridHeader' },
    { field: "installment", headerName: "EMI PMT", width: 100, headerClassName: 'dataGridHeader' },
    { field: "roi", headerName: "Rate % p.a.", width: 100, headerClassName: 'dataGridHeader',
    renderCell : (({row}) => {
     return `${row?.roi?.toFixed(2) ?? "0.00"}%`
    })
  },
    {
      field: "action", headerName: "Action", width: 100, headerClassName: 'dataGridHeader',
      type: "actions",
      renderCell: (({ row }) => {
        return (
          <div className="rowIcon">
            <CTooltip content="Edit">
              <button className="icon-editIcon" onClick={() => handleEditClick(row)}></button>
            </CTooltip>
            <CTooltip content="Delete">
              <button className="icon-deleteIcon" onClick={() => handleDelete(row)}></button>
            </CTooltip>
          </div>
        )
      })
    },
  ];

  return (
    <div className="cardBody">
      <ToastContainer position="top-right" />
      <div className="cardHeader">
        <h3>Financial Instrument</h3>
        <div className="mr-1">
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)} // Step 2: Add input field for search query
          />
          <CButton onClick={() => handleAddClick()}>Add Financial Instrument</CButton>
        </div>
      </div>
      <OffCanvas
        Open={visible}
        title={editValue ? "Edit Financial Instrument" : "Add Financial Instrument"}
        handleCloseClick={handleCloseClick}
        component={
          <Add
            editValue={editValue}
            handleClose={handleCloseClick}
            fetchData={fetchData}
            narration={narration}
            detailsEdit='detailsEdit'
          />
        }
      />
      <div className="datatableGrid">
        <DataGridPro
          rows={filteredServices}
          columns={column}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 15 },
            },
          }}
          pageSizeOptions={[5, 10]}
          density="compact"
          onRowClick={(row) => handleView(row.row)}
          disableRowSelectionOnClick
          loading={loadingState}
        />
      </div>
      {/* <Details /> */}
      <CModal
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        aria-labelledby="ScrollingLongContentExampleLabel"
        className="overley"
      >
        <CModalHeader>
          <h3>{!confDelete ? 'Delete' : 'Alert'}</h3>
        </CModalHeader>
        <CModalBody>
          {!confDelete? `Are you sure want to delete ${valueData?.documentName} ?` : 'This Financial Instrument cannot be deleted as there are transactions associated to it!!'}
        </CModalBody>
        <CModalFooter>
         {
          !confDelete ? 
          <><CButton className="btn-light mr-2" onClick={() => setIsModalVisible(false)}>
                Close
              </CButton><CButton
                className="btn-primary"
                onClick={() => handleDeleteApi(valueData?.id)}
              >
                  {isDeleting ? (
                    <CSpinner color="white" size="sm" />
                  ) : (
                    "Delete"
                  )}
                </CButton></>
          : 
          <CButton
                className="btn-primary"
                onClick={() => setIsModalVisible(false)}
              >
                   Ok
                </CButton>
          }
        </CModalFooter>
      </CModal>
    </div>
  );
};

export default List;
