import React, { useMemo, useEffect, useState } from "react";
import {
  CTooltip,
  CModal,
  CModalHeader,
  CModalBody,
} from "@coreui/react";
import { GetApiwithHeader } from "src/Api/Api";
import Mapping from "./mapping";
import { userAttributes } from "../../share/localData";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useData } from "../../components/dataContext";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { LicenseInfo } from "@mui/x-license";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import VisibilityIcon from "@mui/icons-material/Visibility";

const List = (props) => {
  const {
    importFile,
    setFileImport,
    setCsvData,
    csvData,
    rowDatas,
    bankVal,
    setActiveTab,
    trascData,
    openingBalanceData,
    finStartDate,
    finEndDate,
    fileDataReverse
  } = props;
  LicenseInfo.setLicenseKey(
    "248a71a5e79c492f4b13769843fcf6dcTz04OTIzMCxFPTE3NDU3NTY1ODYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
  );

  const { data } = useData();
  const [loadingState, setLoadingState] = useState(true);

  const { cmpID, bankID } = userAttributes();
  const accessToken = localStorage.getItem("token");
  const [trasactionData, setTrasactionData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [openFileViewer, setOpenFileViewer] = useState(false);
  const [fileForView, setFileForView] = useState(null);

  const [itemsPerPage] = useState(10);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
  const headers = useMemo(
    () => ({
      Authorization: `Bearer ${accessToken}`,
    }),
    [accessToken]
  );

  const companyId = cmpID;
  const BankID = bankID;
  
  const fetchData = async () => {
    setLoadingState(true)
    const url = `/api/BankTransactions/GetUploadCSVFiles/${companyId}?bankAccountId=${
      BankID ? BankID : 0
    }`;
    try {
      const res = await GetApiwithHeader(url, headers);
      setTrasactionData(res?.data?.data || []);
      props?.trascData(res?.data?.data || [])
      setLoadingState(false)
    } catch (error) {
      console.log(error);
      setLoadingState(false)
    }
  };

  useEffect(() => {
    fetchData();
    if (BankID) {
      fetchData();
    }
  }, [headers, companyId, BankID]);

  const handleView = async (item) => {
    try {
      const res = await GetApiwithHeader(
        `/api/BankTransactions/GetCsv/${companyId},${item.fileName}`,
        headers
      );
      const fileUrl = res?.data?.data?.url;
      if (fileUrl) {
        window.open(fileUrl, "_blank");
      } else {
        console.log("File URL not found");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const sortedItems = () => {
    const sortableItems = [...trasactionData];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  };

  const getCurrentPageData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const sortedData = sortedItems(); // Apply sorting
    return sortedData.slice(startIndex, endIndex);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handleFileViewer = (value) => {
    setFileForView(value);
    setOpenFileViewer(true);
  };

  const column = [
    {
      field: "fileName",
      headerName: "Name",
      width: 200,
      headerClassName: "dataGridHeader",
    },
    {
      field: "rowCount",
      headerName: "Count",
      width: 90,
      headerClassName: "dataGridHeader",
    },
    {
      field: "processed",
      headerName: "Processed",
      width: 90,
      headerClassName: "dataGridHeader",
    },
    {
      field: "uploadDate",
      headerName: "Uploaded At",
      width: 100,
      headerClassName: "dataGridHeader",
      renderCell: ({ row }) => {
        return (
          <>
            {row.uploadDate
              ? new Date(row.uploadDate).toLocaleDateString("en-GB")
              : "NA"}
          </>
        );
      },
    },
    {
      field: "openingBalance",
      headerName: "Opening Balance",
      width: 130,
      headerClassName: "dataGridHeader",
    },
    {
      field: "openingTransactionDate",
      headerName: "Opening Date",
      width: 130,
      headerClassName: "dataGridHeader",
      renderCell: ({ row }) => {
        return (
          <>
            {row.openingTransactionDate
              ? new Date(row.openingTransactionDate).toLocaleDateString("en-GB")
              : "NA"}
          </>
        );
      },
    },
    {
      field: "closingBalance",
      headerName: "Closing Balance",
      width: 130,
      headerClassName: "dataGridHeader",
    },
    {
      field: "closingTransactionDate",
      headerName: "Closing Date",
      width: 130,
      headerClassName: "dataGridHeader",
      renderCell: ({ row }) => {
        return (
          <>
            {row.closingTransactionDate
              ? new Date(row.closingTransactionDate).toLocaleDateString("en-GB")
              : "NA"}
          </>
        );
      },
    },
    {
      field: "filePath",
      headerName: "Log",
      width: 90,
      headerClassName: "dataGridHeader",
      renderCell: ({ row }) => {
        return (
          <div className="rowIcon">
            {row.log ? (
              <CTooltip content="View Log">
                <span className="view">
                  <VisibilityIcon onClick={() => handleFileViewer(row.log)} />
                </span>
              </CTooltip>
            ) : (
              "NA"
            )}
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 70,
      headerClassName: "dataGridHeader",
      type: "actions",
      renderCell: ({ row }) => {
        return (
          <div className="rowIcon">
            <CTooltip content="Download">
              <span className="download">
                <FileDownloadIcon onClick={() => handleView(row)} />
              </span>
            </CTooltip>
          </div>
        );
      },
    },
  ];

  const resetFile = () => {
    setFileImport(null);
    setCsvData(null);
  };

  return (
    <div className="cardBody">
      {importFile === undefined ? (
        <div className="datatableGrid">
          <DataGridPro
            rows={trasactionData}
            columns={column}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 15 },
              },
            }}
            pageSizeOptions={[5, 10]}
            density="compact"
            disableRowSelectionOnClick
            loading={loadingState}

          />
        </div>
      ) : (
        <Mapping
          fetchData={fetchData}
          importFile={importFile}
          csvData={csvData}
          setFileImport={setFileImport}
          rowDatas={rowDatas}
          bankVal={bankVal}
          setActiveTab={setActiveTab}
          trasactionData={trasactionData}
          openingBalanceData={openingBalanceData}
          finStartDate={finStartDate}
          finEndDate={finEndDate}
          fileDataReverse={fileDataReverse}
        />
      )}
      {/* Model for preview the doc */}
      <CModal
        className={"image-viewer modal large overflowBox"}
        visible={openFileViewer}
        onClose={() => setOpenFileViewer(false)}
      >
        <CModalHeader>
          <h3>Preview </h3>
        </CModalHeader>
        <CModalBody>
          <div className="iframeBox">
            <iframe className={"image-viewer img"} src={fileForView} />
          </div>
        </CModalBody>
      </CModal>
    </div>
  );
};

export default List;
