export const userAttributes = () => {
    const userData = JSON.parse(localStorage.getItem("companyData"));
    const bankData = JSON.parse(localStorage.getItem("bankData"))
    const companyName = userData?.company?.Name;
    const bankName = bankData?.Name ? bankData?.Name : '';
    const cmpID = userData?.company?.Id ? userData?.company?.Id : '';
    const role = userData?.company?.role ? userData?.company?.role : '';
    const bankID = bankData?.Id ? bankData?.Id : ''
    return { companyName, cmpID, bankName, bankID, role };
  };
  