import React, { useState, useEffect, useCallback, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  CRow,
  CCol,
  CButton,
  CFormInput,
  CForm,
  CSpinner,
  CFormTextarea,
  CFormLabel, CTooltip
} from "@coreui/react";
import { GetApiwithHeader, UpdateUserwithHeader, postApiWithHeader } from "src/Api/Api";
import PropTypes from "prop-types";
import { userAttributes } from "../../share/localData";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useData } from "../../components/dataContext";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";

const AddProduct = (props) => {
  const { setTriggerRenderHeaderOnCompanyAdd } = useData();
  const { editValue, rowdata } = props;
  const { cmpID } = userAttributes();
  const [industry, setIndustry] = useState([]);
  const accessToken = localStorage.getItem("token");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);
  const [openImageViewer, setOpenImageViewer] = useState(false);
  const [imageForView, setImageForView] = useState("false");
  const uploadedDocumentsRef = useRef([]);
  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const [attachmentError, setAttachmentError] = useState("")

  AddProduct.propTypes = {
    fetchData: PropTypes.func.isRequired,
    editValue: PropTypes.object,
    handleClose: PropTypes.func,
    onImageUpload: PropTypes.func,
    rowdata: PropTypes.object,
  };

  console.log('rowdata', rowdata)

  const onDrop = useCallback((acceptedFiles) => {
    const filesArray = Array.from(acceptedFiles);
    const uploadedFiles = filesArray.map((file) => ({
      url: URL.createObjectURL(file),
      fileUpload: file,
    }));
    if (uploadedDocumentsRef.current?.length > 0) {
      uploadedDocumentsRef.current =
        uploadedDocumentsRef.current.concat(uploadedFiles);
      setUploadedDocuments(uploadedDocumentsRef.current);
    } else {
      uploadedDocumentsRef.current = uploadedFiles;
      setUploadedDocuments(uploadedFiles);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: { "image/*": [] },
    onDrop,
  });

  useEffect(() => {
    if (uploadedDocumentsRef.current?.length > 0) {
      setAttachmentError(""); // Clear error when attachments are available
    }
  }, [uploadedDocumentsRef.current]);

  const headers = {
    'Content-Type': 'multipart/form-data',
    Authorization: `Bearer ${accessToken}`,
  };
  const companyId = cmpID;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await GetApiwithHeader(`/api/industry`, headers);
        setIndustry(res?.data?.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const formik = useFormik({
    initialValues: {
      productName: "",
      productnumber: "",
      productCost: "",
      gst: "",
      gstPercentage: "",
      productDescription: "",
      ProductFiles: ""
    },
    validationSchema: Yup.object({
      productName: Yup.string().required("Product name is required"),
      productnumber: Yup.number().required("Product Number is required"),
      productCost: Yup.string().required("Product Cost is required"),
      gst: Yup.number()
        .required("GST is required")
        .test(
          "gst-less-than-cost",
          "GST cannot be greater than Product Cost",
          function (value) {
            return value <= this.parent.productCost;
          }
        ),
      gstPercentage: Yup.number().required("GST % is required"),
      gstPercentage: Yup.number().required("GST % is required"),
      productDescription: Yup.string().required("Product Description is required"),
    }),

    onSubmit: async (values, { resetForm }) => {
      setIsSubmitting(true);
      const apiUrl = editValue
        ? `/api/Product/update/${editValue.id},${companyId}`
        : `api/Product/create/${companyId}`;

      const formData = new FormData();
      formData.append("companyId", companyId);
      formData.append("ProductName", values.productName);
      formData.append("Productnumber", values.productnumber);
      formData.append("ProductCost", values.productCost);
      formData.append("GST", values.gst);
      formData.append("GSTPercentage", values.gstPercentage);
      formData.append("ProductDescription", values.productDescription);
      // if (uploadedDocumentsRef.current.length === 0) {
      //   setAttachmentError("Please Upload Attachment"); // Set error message
      //   return;
      // }

      uploadedDocuments.forEach((documentData, index) => {
        if (documentData.url) {
          formData.append(`ProductFiles`, documentData.fileUpload);
        }
      })


      try {
        const response = editValue
          ? await UpdateUserwithHeader(apiUrl, values, headers)
          // : await axios.post(`${apiUrl}?ProductName=${formData.ProductName}&Productnumber=${formData.ProductName}&ProductCost=${formData.ProductCost}&GST=${formData.GST}&GSTPercentage=${formData.GSTPercentage}&ProductDescription=${formData.ProductDescription}`, formData, { headers });
          : await postApiWithHeader(apiUrl, formData,  headers );
        if (
          editValue
            ? response.message === "Success"
            : response.data.message === "Success"
        ) {
          toast.success(
            `Product ${editValue ? "updated" : "added"} successfully`,
            {
              autoClose: 1000,
              onClose: () => {
                props?.handleClose();
                formik.resetForm();
                setTriggerRenderHeaderOnCompanyAdd(true);
              },
            }
          );
          props?.fetchData();
        } else if (response.data.statusCode === 102) {
          toast.error(response.data.message, { autoClose: 1000 });
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  const resetFormErrors = () => {
    formik.setErrors({
      productName: "",
      productnumber: "",
      productCost: "",
      gst: "",
      gstPercentage: "",
      productDescription: "",
    });
  };

  useEffect(() => {
    if (editValue) {
      formik.setValues({
        productName: editValue.productName,
        productnumber: editValue.productnumber,
        productCost: editValue.productCost,
        gst: editValue.gst,
        gstPercentage: editValue.gstPercentage,
        productDescription: editValue.productDescription,

      });
      const existingFiles = editValue.productFiles.map((file) => ({
        url: file.filePaths,
        fileUpload: null,
      }));
      setUploadedDocuments(existingFiles);
      uploadedDocumentsRef.current = existingFiles;
    } else {
      formik.setValues({
        productName: "",
        productnumber: "",
        productCost: "",
        gst: "",
        gstPercentage: "",
        productDescription: "",
      });
      setUploadedDocuments([])
      formik.resetForm();
      resetFormErrors();
    }
  }, [editValue]);


  const handleImageViewer = (value, currentIndex) => {
    setImageIndex(currentIndex);
    setImageForView(value);
    setOpenImageViewer(true);
  };

  const handleDeleteImage = (index) => {
    const updatedArr = uploadedDocumentsRef?.current
      .filter((_, i) => i !== index)?.map((item) => ({ ...item, url: URL.createObjectURL(item?.fileUpload) }));
    uploadedDocumentsRef.current = updatedArr;
    setUploadedDocuments(updatedArr);
  };

  return (
    <div className="addBox">
      <CForm onSubmit={formik.handleSubmit}>
        <CFormInput
          type="text"
          name="productName"
          placeholder="Enter product name"
          label={
            <>
              Product Name <span className="text-danger">*</span>
            </>
          }
          onChange={formik.handleChange}
          value={formik.values.productName}
        />
        {formik.touched.productName && formik.errors.productName && (
          <div className="text-danger">{formik.errors.productName}</div>
        )}

        <div className="md-12 mt-3">
          <CFormInput
            type="text"
            name="productnumber"
            placeholder="Enter product number"
            label={
              <>
                Product Number <span className="text-danger">*</span>
              </>
            }
            onChange={formik.handleChange}
            value={formik.values.productnumber}
          />
          {formik.touched.productnumber && formik.errors.productnumber && (
            <div className="text-danger">{formik.errors.productnumber}</div>
          )}
        </div>
        <div className="md-12 mt-3">
          <CFormInput
            type="number"
            name="productCost"
            placeholder="Enter product cost"
            label={
              <>
                Item Cost <span className="text-danger">*</span>
              </>
            }
            onChange={formik.handleChange}
            value={formik.values.productCost}
          />
          {formik.touched.productCost && formik.errors.productCost && (
            <div className="text-danger">{formik.errors.productCost}</div>
          )}
        </div>
        <div className="md-12 mt-3">
          <CFormInput
            type="number"
            name="gst"
            placeholder="Enter GST"
            label={
              <>
                GST <span className="text-danger">*</span>
              </>
            }
            onChange={formik.handleChange}
            value={formik.values.gst}
          />
          {formik.touched.gst && formik.errors.gst && (
            <div className="text-danger">{formik.errors.gst}</div>
          )}
        </div>
        <div className="md-12 mt-3">
          <CFormInput
            type="number"
            name="gstPercentage"
            placeholder="Enter GST percentage"
            label={
              <>
                GST % <span className="text-danger">*</span>
              </>
            }
            onChange={formik.handleChange}
            value={formik.values.gstPercentage}
          />
          {formik.touched.gstPercentage && formik.errors.gstPercentage && (
            <div className="text-danger">{formik.errors.gstPercentage}</div>
          )}
        </div>
        <div className="md-12 mt-3">
          <CFormTextarea
            name="productDescription"
            placeholder="Enter product description"
            label={
              <>
                Description of Goods <span className="text-danger">*</span>
              </>
            }
            onChange={formik.handleChange}
            value={formik.values.productDescription}
          />
          {formik.touched.productDescription && formik.errors.productDescription && (
            <div className="text-danger">{formik.errors.productDescription}</div>
          )}
        </div>
        <CRow>
          <CCol md="12">
            <CFormLabel>Attachment</CFormLabel>
            <div className="dropzone-multiple-img">
              <div
                {...getRootProps()}
                className={`thumb thumb2 dropzone-container}`}
              >
                <input {...getInputProps()} />
                <div className="addFileBtn">
                  <span className="addIcon">
                    <AddIcon />
                  </span>
                  <span>Select Image</span>
                </div>
              </div>
              {rowdata &&
                rowdata.receiptMediaPath?.length > 0 &&
                rowdata.receiptMediaPath.map((url, index) => (
                  <>
                    <div className="thumb" key={index}>
                      <div className="thumbInner">
                        <CTooltip content="Img">
                          <img
                            src={url}
                            className="img"
                            onLoad={() => {
                              URL.revokeObjectURL(url);
                            }}
                            // onClick={(e) => handleImageViewer(url, index)}
                          />
                        </CTooltip>
                      </div>
                    </div>
                  </>
                ))}High Frequency Transactions Definition
              {rowdata &&
                rowdata.filePath?.length > 0 &&
                rowdata.filePath.map((url, index) => (
                  <>
                    <div className="thumb" key={index}>
                      <div className="thumbInner">
                        <CTooltip content="Image">
                          <img
                            src={url}
                            className="img"
                            // onLoad={() => {
                            //   URL.revokeObjectURL(url);
                            // }}
                            // onClick={(e) => handleImageViewer(url, index)}
                          />
                        </CTooltip>
                      </div>
                    </div>
                  </>
                ))}
              {uploadedDocuments?.length > 0 &&
                uploadedDocuments.map((documentData, index) => (
                  <div className="thumb" key={index}>
                    <div className="thumbInner">
                      <CTooltip content="Image">
                        <img
                          src={documentData?.url}
                          className="img"
                          // Revoke data uri after image is loaded
                          onLoad={() => {
                            URL.revokeObjectURL(documentData?.url);
                          }}
                          // onClick={(e) =>
                          //   handleImageViewer(
                          //     URL.createObjectURL(documentData.fileUpload), index
                          //   )
                          // }
                        />
                      </CTooltip>
                      <button
                        className="closeButton"
                        onClick={() => handleDeleteImage(index)}
                      >
                        <CloseIcon />
                      </button>
                    </div>
                  </div>
                ))}
            </div>
          </CCol>
        </CRow>
        {attachmentError && <span style={{ color: "red" }}>{attachmentError}</span>}
        <CRow className="mt-4 justify-content-end">
          <CCol xs="auto">
            <CButton
              color="light"
              onClick={() => {
                props.handleClose();
                formik.resetForm()
              }}
            >
              Cancel
            </CButton>
          </CCol>
          <CCol xs="auto">
            <CButton color="primary" type="submit">
              {isSubmitting ? <CSpinner color="white" size="sm" /> : "Save"}
            </CButton>
          </CCol>
        </CRow>
      </CForm>
    </div>
  );
};

export default AddProduct;


