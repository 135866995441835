import moment from "moment";
import React, { useCallback, useState, useEffect, useMemo } from "react";
import { CButton, CCol, CRow } from "@coreui/react";
import { useDropzone } from "react-dropzone";
import CIcon from "@coreui/icons-react";
import PropTypes from "prop-types";
import { toast, ToastContainer } from "react-toastify";
import { userAttributes } from "../../share/localData";

function Import(props) {
  const [fileData, setFileData] = useState(null);
  const { cmpID, bankID } = userAttributes();
  const [rowData, setRowData] = useState();
  const [fileFyYear, setFileFyYear] = useState(null);
  const [lastClosingDate, setLastClosingDate] = useState(null);

  const accessToken = localStorage.getItem("token");

  const companyId = cmpID;

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFileData(file);
  };

  useEffect(() => {
    setFileData(null);
  }, [cmpID, bankID]);

  useEffect(() => {
    if (props?.trascData && props?.trascData.length > 0) {
      setLastClosingDate(
        props?.trascData[props?.trascData.length - 1].closingTransactionDate
      );
      const financialYear = getFinancialYearFromStartdate(
        new Date(
          props?.trascData[props?.trascData.length - 1].closingTransactionDate
        )
      );
      const start = financialYear?.split("-")[0];
      const end = financialYear?.split("-")[1];
      setFileFyYear(`1 July ${start}- 30 June ${end}`);
    }
  }, [props?.trascData]);

  const getFileStartDateEndDate = (rowData) => {
    const firstRowData = rowData[0]?.split(",");
    let endRowData = findLastElementOfArr(rowData);
    let startDate = findDateInArray(firstRowData);
    if (!startDate) {
      startDate = findDateInArray(rowData[1]?.split(","));
    }
    let endDate = findDateInArray(endRowData);

    let reverse = false;
    // main condition start
    let startDateForCheck;
    let endDateForCheck;
    if (new Date(new Date(startDate)?.setHours(0, 0, 0, 0))?.getTime() > new Date(new Date(endDate)?.setHours(0, 0, 0, 0))?.getTime()) {
      startDateForCheck = new Date(new Date(endDate).setHours(0, 0, 0, 0));
      endDateForCheck = new Date(new Date(startDate).setHours(0, 0, 0, 0));
      reverse = true;
    } else {
      endDateForCheck = new Date(new Date(endDate).setHours(0, 0, 0, 0));
      startDateForCheck = new Date(new Date(startDate).setHours(0, 0, 0, 0));
    }
    return {
      startDate: startDateForCheck,
      endDate: endDateForCheck,
      reverse: reverse
    }
  }

  const parseCSVData = useCallback(
    (csvData) => {
      const rowData = [];
      const rows = csvData.split("\n");
      setRowData(rows);
      const { reverse } = getFileStartDateEndDate(rows);
      let headers = rows[0].split(",").map((header) => header);
      if(reverse){
        headers = findLastElementOfArr(rows);
      }
      
      props.callBack(headers, rows);
    },
    [props.callBack]
  );

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file.type !== "text/csv") {
        toast.error("Please upload a .CSV file", { autoClose: 1000 });
        return;
      }
      const reader = new FileReader();

      reader.onload = (event) => {
        const fileData = event.target.result;
        const data = fileData.split("\n").map((row) => row.split(","));
        parseCSVData(fileData);
        setFileData(file);
      };
      reader.readAsText(file);
    },
    [parseCSVData]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "text/csv": [".csv"] },
  });

  // Function to parse date from different formats
  const parseDate = (dateStr) => {
    // Possible date formats
    const formats = [
      "D/MM/YYYY", // 6/05/2023
      "DD/M/YYYY", // 06/5/2023
      "D/M/YYYY", // 6/5/2023
      "DD/MM/YYYY", // 26/05/2023
      "MM/DD/YYYY", // 05/26/2023
      "YYYY-MM-DD", // 2023-05-26
      "DD-MM-YYYY", // 2023-05-26
      // Add other formats if necessary
    ];

    // Try parsing each format
    for (const format of formats) {
      const parsedDate = moment(dateStr, format, true);
      if (parsedDate.isValid()) {
        return parsedDate.format("YYYY-MM-DD"); // Return the date in a standard format
      }
    }
    return null; // Return null if no valid date format is found
  };

  // Find and extract the date from the array
  const findDateInArray = (arr) => {
    for (const item of arr) {
      const date = parseDate(item.trim());
      if (date) {
        return date; // Return the first valid date found
      }
    }
    return null; // Return null if no date is found
  };

  const findLastElementOfArr = (dataArray) => {
    let arr = [];
    for (let i = dataArray.length - 1; i >= 0; i--) {
      if (dataArray[i]) {
        arr = dataArray[i]?.split(",");
        break; // Exit the loop if the element is found
      }
    }
    return arr;
  };

  const getFinancialYearFromStartdate = (date) => {
    // Ensure the input is a valid Date object
    if (!(date instanceof Date) || isNaN(date)) {
      throw new Error("Invalid date");
    }

    const year = date.getFullYear();
    const month = date.getMonth(); // Months are zero-based, so January is 0 and December is 11
    const day = date.getDate(); // get day
    // Financial year starts in July and ends in June
    // If the month is June or before (0 to 5), the financial year is the previous year to current year
    // Otherwise, it is the current year to next year
    if(month === 5){
      if(day === 30){
        return `${year}-${year + 1}`;
      }else{
        return `${year - 1}-${year}`;
      }
    }else{
      if (month < 6) {
        // January to June
        return `${year - 1}-${year}`;
      } else {
        // July to December
        return `${year}-${year + 1}`;
      }
    }
  };
  function getDateDifference(startDate, endDate) {
    if (
      !(startDate instanceof Date) ||
      isNaN(startDate) ||
      !(endDate instanceof Date) ||
      isNaN(endDate)
    ) {
      throw new Error("Invalid date");
    }
    const diffInMs = endDate - startDate;
    const msInSecond = 1000;
    const msInMinute = msInSecond * 60;
    const msInHour = msInMinute * 60;
    const msInDay = msInHour * 24;

    const days = Math.floor(diffInMs / msInDay);
    const hours = Math.floor((diffInMs % msInDay) / msInHour);
    const minutes = Math.floor((diffInMs % msInHour) / msInMinute);
    const seconds = Math.floor((diffInMs % msInMinute) / msInSecond);
    if (days > 10) {
      return {
        days,
        hours,
        minutes,
        seconds,
      };
    }
    return null;
  }
  const handleSave = () => {
    if (fileData && fileData.type === "text/csv") {
      if (rowData && rowData.length) {
        const firstRowData = rowData[0]?.split(",");
        let endRowData = findLastElementOfArr(rowData);
        let startDate = findDateInArray(firstRowData);
        if (!startDate) {
          startDate = findDateInArray(rowData[1]?.split(","));
        }
        let endDate = findDateInArray(endRowData);

        let check = true;
        let warningMsg = "";
        // main condition start
        let startDateForCheck;
        let endDateForCheck;
        if (new Date(new Date(startDate)?.setHours(0, 0, 0, 0))?.getTime() > new Date(new Date(endDate)?.setHours(0, 0, 0, 0))?.getTime()) {
          startDateForCheck = new Date(new Date(endDate).setHours(0, 0, 0, 0));
          endDateForCheck = new Date(new Date(startDate).setHours(0, 0, 0, 0));
          props?.setFileDataReverse(true);
        } else {
          endDateForCheck = new Date(new Date(endDate).setHours(0, 0, 0, 0));
          startDateForCheck = new Date(new Date(startDate).setHours(0, 0, 0, 0));
          props?.setFileDataReverse(false);
        }
        const financialYear = getFinancialYearFromStartdate(startDateForCheck);
        props?.setFinStartDate(financialYear?.split("-")[0]);
        props?.setFinEndDate(financialYear?.split("-")[1]);

        if (props?.trascData && props?.trascData.length > 0) {
          // condition 1 start - don't allow existing transaction's date;
          const financialYearOfLastTrans = getFinancialYearFromStartdate(
            new Date(
              props?.trascData[
                props?.trascData.length - 1
              ].closingTransactionDate
            )
          );
          const lastDate =
            props?.trascData[props?.trascData.length - 1]
              .closingTransactionDate;
          if (startDateForCheck.getTime() < new Date(lastDate).getTime()) {
            // console.log("refuse-- 8");
            check = false;
            warningMsg = `Date range is beyond finacial year, kindly provide the transaction above ${new Date(
              lastDate
            ).toLocaleDateString("en-GB")} of FY${
              financialYearOfLastTrans?.split("-")[1]
            } year only`;
          }
          // console.log("financialYearOfLastTrans---",financialYearOfLastTrans);
          // console.log("financialYearOfLastTrans---",new Date(financialYearOfLastTrans?.split("-")[1], 5, 30));
          const diff = getDateDifference(
            new Date(lastDate),
            startDateForCheck
          );
          if(diff && (financialYearOfLastTrans === financialYear)){
            if(new Date(financialYearOfLastTrans?.split("-")[1], 5, 30).getTime() < startDateForCheck.getTime()){
              check = false;
              warningMsg = `Date range is beyond finacial year, kindly provide the transaction of financial FY${
                financialYearOfLastTrans?.split("-")[1]
              } year only`;}
            if(new Date(financialYearOfLastTrans?.split("-")[1], 5, 30).getTime() < endDateForCheck.getTime()){
              check = false;
              warningMsg = `Date range is beyond finacial year, kindly provide the transaction of financial FY${
                financialYearOfLastTrans?.split("-")[1]
              } year only`;
            }
          }
          // condition 1 end - don't allow existing transaction's date;

          // condition 2 start - allow only one fy year
          let openingStartYear = new Date(financialYear?.split("-")[0], 6, 1);
          let openingEndYear = new Date(financialYear?.split("-")[1], 5, 30);
          
          // allow only one fy year
          if (
            new Date(openingStartYear).getTime() > startDateForCheck?.getTime()
          ) {
            // console.log("refuse-- 9");
            check = false;
            warningMsg = `Date range is beyond finacial year, kindly provide the transaction of financial FY${
              financialYearOfLastTrans?.split("-")[1]
            } year only`;
          }
          if (new Date(openingEndYear).getTime() < endDateForCheck?.getTime()) {
            //refuse
            // console.log("refuse-- 10");
            check = false;
            warningMsg = `Date range is beyond finacial year, kindly provide the transaction of financial FY${
              financialYearOfLastTrans?.split("-")[1]
            } year only`;
          }
          // condition 2 end - allow only one fy year
        } else {
          // condition 3 start - don't allow selected opening fy;
          if (
            props?.openingBalanceData &&
            props?.openingBalanceData.length > 0
          ) {
            let openingStartYear = new Date(
              props?.openingBalanceData[0]?.financialYearValue?.split("-")[0],
              6,
              1
            );
            let openingEndYear = new Date(
              props?.openingBalanceData[0]?.financialYearValue?.split("-")[1],
              5,
              30
            );
            
            // 1-7-21 > 1-7-21
            if (
              new Date(openingStartYear).getTime() >
              startDateForCheck?.getTime()
            ) {
              //refuse1722  1622
              // console.log("refuse-- 11");
              check = false;
              warningMsg = `Date range is beyond finacial year, kindly provide the transaction of financial FY${openingEndYear.getFullYear()} year only`;
            }
            // 30-6-22 < 30-6-22
            if (
              new Date(openingEndYear).getTime() < endDateForCheck?.getTime()
            ) {
              //refuse
              // console.log("refuse-- 12");
              check = false;
              warningMsg = `Date range is beyond finacial year, kindly provide the transaction of financial FY${openingEndYear.getFullYear()} year only`;
            }
          }
          // condition 3 end - don't allow selected opening fy;
        }

        if (check) props?.hanldeExcelResult(fileData, startDateForCheck);
        else {
          toast.warning(warningMsg, { autoClose: 6000 });
        }
      }
    } else {
      toast.warning("Please Upload a file", { autoClose: 1000 });
    }
  };

  const handleCancel = () => {
    setFileData(null);
    props.callBackFile();
    props.handleCloseClick();
  };
  return (
    <div className="">
      {/* <ToastContainer position="top-right" autoClose={2000} /> */}
      <CRow className="mb-2">
        <CCol md={12} className="d-flex flex-column">
          {props?.trascData && props?.trascData.length > 0 ? (
            <span>
              Your Last transaction date in system is{" "}
              {new Date(lastClosingDate).toLocaleDateString("en-GB")}
            </span>
          ) : null}
          {fileFyYear && (
            <span>
              Please confirm you are uploading transactions for Financial Year{" "}
              {fileFyYear}!!
            </span>
          )}
        </CCol>
      </CRow>
      <CRow className="mb-2">
        <CCol
          md={12}
          className="d-flex flex-column align-items-center uploadBox"
        >
          <div
            {...getRootProps()}
            className={`dropzone ${isDragActive ? "active" : ""}`}
          >
            <input {...getInputProps()} />
            <CButton color="primary">Select a CSV file to import</CButton>
            <p className="mt-2 text-center">or drag and drop here</p>
          </div>
        </CCol>
      </CRow>
      {fileData && (
        <CRow className="mb-4">
          <CCol md={12}>
            <p className="mb-0">
              <strong>File Name:</strong> {fileData.name}
            </p>
          </CCol>
        </CRow>
      )}
      <CRow>
        <CCol className="text-center">
          <CButton onClick={handleSave} color="primary">
            Process
          </CButton>
        </CCol>
      </CRow>
    </div>
  );
}

Import.propTypes = {
  callBack: PropTypes.func.isRequired,
  callBackFile: PropTypes.func.isRequired,
  handleCloseClick: PropTypes.func.isRequired,
};

export default Import;
