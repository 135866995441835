import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import {
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CTableHead,
  CTableRow,
  CCardBody,
  CButton,
  CRow,
  CCol,
  CSpinner,
  CModal,
  CModalHeader,
  CModalBody
} from "@coreui/react";
import { postApiWithHeader, UpdateUserwithHeader } from "src/Api/Api";
import { userAttributes } from "../../share/localData";
import Snackbar from "@mui/material/Snackbar";
import { LicenseInfo } from "@mui/x-license";

const Preview = (props) => {
  LicenseInfo.setLicenseKey(
    "248a71a5e79c492f4b13769843fcf6dcTz04OTIzMCxFPTE3NDU3NTY1ODYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
  );
  const {
    setFileImport,
    rowDatas,
    previewData,
    importFile,
    fetchData,
    bankValSelected,
    handleClose,
    selectedTemplate,
    setActiveTab,
    csvData,
    trasactionData,
    openingBalanceData,
    finStartDate,
    finEndDate,
    fileDataReverse
  } = props;
  const column = JSON.parse(previewData);
  const [columnIndexMap, setColumnIndexMap] = useState({});
  const [tableData, setTableData] = useState([]);
  const accessToken = localStorage.getItem("token");
  const { cmpID, bankID } = userAttributes();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [isSpine, setIsSpine] = useState(false);
  const [warningMsg, setWarningMsg] = useState('');
  const [closingBalance, setClosingBalance] = useState(null);
  const [openBalance, setOpenBalance] = useState(null);
  const [openFileViewer, setOpenFileViewer] = useState(false);
  const [fileForView, setFileForView] = useState(null);


  const headersFile = useMemo(
    () => ({
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "multipart/form-data",
    }),
    [accessToken]
  );
  const headers = useMemo(
    () => ({ Authorization: `Bearer ${accessToken}` }),
    [accessToken]
  );
  const companyId = cmpID;

  const getKeyForCsvData = (columnString, updatedColumnIndexMap) => {
    try {
      if(columnString.search("Column") === -1){
        return updatedColumnIndexMap[columnString];
      }
      return Number(columnString?.split(':')[0].split(' ')[1]) - 1;
    } catch (error) {
      console.error("Error parsing column index:", error);
      return -1; // or some other default value or handling
    }
  }

  useEffect(() => {
    if(column.Date && rowDatas){
      let isFirstLine = column.Date?.search("Column") === -1 ? true : false;
      if(JSON.stringify(column.Date)?.search("Date") >= 0 || 
        JSON.stringify(column.Date)?.search("date") >= 0 ||
        JSON.stringify(column.Amount)?.search("Date") >= 0 ||
        JSON.stringify(column.Amount)?.search("date") >= 0 ||
        JSON.stringify(column.Narration)?.search("Date") >= 0 ||
        JSON.stringify(column.Narration)?.search("date") >= 0 ||
        JSON.stringify(column.Balance)?.search("Date") >= 0 ||
        JSON.stringify(column.Balance)?.search("date") >= 0 
        ){
        isFirstLine = true;
      }
      let updatedColumnIndexMap = {};
      const columnNames = rowDatas[0]?.split(",");
      for (let i = 0; i < columnNames.length; i++) {
        updatedColumnIndexMap[columnNames[i].replace(/\r/g, "")] = i;
      }
      console.log("isFirstLine--",isFirstLine);
      const updatedTableData = [];
      rowDatas?.forEach(async(line) => {
        if (isFirstLine && line && line.length > 0) {
          isFirstLine = false;
          const columnNames = line?.split(",");
          for (let i = 0; i < columnNames.length; i++) {
            updatedColumnIndexMap[columnNames[i].replace(/\r/g, "")] = i;
          }
        } else {
          const values = line?.split(",");
          if (values && values.length > 0 && values[0]) {
            const Parameter = {
              date: values[getKeyForCsvData(column.Date, updatedColumnIndexMap)] || "",
              withdrawal: values[getKeyForCsvData(column.Amount, updatedColumnIndexMap)] || "",
              narration: values[getKeyForCsvData(column.Narration, updatedColumnIndexMap)] || "",
              balance: values[getKeyForCsvData(column.Balance, updatedColumnIndexMap)] || "",
            };
            updatedTableData.push(Parameter);
          }
        }
      });
      setColumnIndexMap(updatedColumnIndexMap);
      if(updatedTableData && updatedTableData.length > 0){
        let openingBalance = updatedTableData[0].balance;
        let amount = updatedTableData[0].withdrawal;
        if(fileDataReverse){
          openingBalance = updatedTableData[updatedTableData.length - 1].balance;
          amount = updatedTableData[updatedTableData.length - 1].withdrawal;
        }else{
          openingBalance = updatedTableData[0].balance;
          amount = updatedTableData[0].withdrawal;
        }
        
        if(isNaN(amount)){
          setWarningMsg('Please correct the mapping');
          setClosingBalance(``);
          setOpenBalance(``);
        }else{
          openingBalance = openingBalance - amount;
          openingBalance = openingBalance ? openingBalance.toFixed(2) : 0
          if(trasactionData && trasactionData.length > 0){
            const closingBalance = Number(trasactionData[trasactionData.length - 1]?.closingBalance);
            setClosingBalance(`Closing Balance : ${closingBalance}`);
            setOpenBalance(`Opening Balance : ${openingBalance}`);
            if(openingBalance == closingBalance){
              setWarningMsg('')
            }else{
              setWarningMsg('The current opening balance does not match the previous closing balance.')
            }
          }else{
            if(openingBalanceData && openingBalanceData.length > 0){
              setClosingBalance(`Entered Opening Balance : ${Number(openingBalanceData[0]?.openingBalance ?? 0)}`);
              setOpenBalance(`Opening Balance : ${openingBalance}`);
              if(openingBalance == Number(openingBalanceData[0]?.openingBalance ?? 0)){
                setWarningMsg('')
              }else{
                setWarningMsg('The entered opening balance does not match the current transactions opening balance.')
              }
            }
          }
        }
      }
      if(fileDataReverse){
        setTableData([...updatedTableData?.reverse()]);
      }else{
        setTableData([...updatedTableData]);
      }
    }
    setFileForView(null)
  }, [rowDatas, previewData, csvData]);

  const handleProcessed = async () => {
    if (selectedTemplate !== undefined && selectedTemplate !== null ) {
      const requestData = {
        title: selectedTemplate?.title,
        mapping: previewData,
        bankAccountId: bankID,
        removeHeader: false,
      };
      const response = await UpdateUserwithHeader(
        `/api/csv-mapping-template/update/${selectedTemplate.id}?companyId=${companyId}`,
        requestData,
        headers
      );
      const formData = new FormData();
      if (importFile) {
        formData.append("file", importFile);
      }
      if (bankValSelected) {
        formData.append("BankAccountId", bankValSelected.id);
      }
      if (selectedTemplate) {
        formData.append("TemplateId", selectedTemplate.id);
      }
      if(finStartDate && finEndDate){
        formData.append("Financialyear", `${finStartDate}-${finEndDate}`);
      }
      if(trasactionData && trasactionData.length > 0){
        const closingBalance = Number(trasactionData[trasactionData.length - 1]?.closingBalance);
        formData.append("OpeningBalance", closingBalance);
      }else{
        if(openingBalanceData && openingBalanceData.length > 0){
          formData.append("OpeningBalance", openingBalanceData[0]?.openingBalance);
        }
      }
      try {
        setIsSpine(true);
        const response = await postApiWithHeader(
          `/api/BankTransactions/create/${companyId}`,
          formData,
          headersFile
        );
        
        if (response?.data?.statusCode === 100) {
          setIsSpine(false);
          setSnackbarMessage(response.data.message);
          handleClose();
          setActiveTab(0);
          setFileImport(undefined);
          setTimeout(() => {
            fetchData();
          }, 1000);
        } 
        if (response?.data !== null && response?.data !== 'true') {
          // console.log(response.data)
          setFileForView(response.data.data)
          setOpenFileViewer(true)
          setIsSpine(false);

        }
        else {
          setIsSpine(false);
          setSnackbarMessage(response?.data?.message);
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
        }
        return response;
      } catch (error) {
        setIsSpine(false);
        setSnackbarMessage(error.response?.data?.message);
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        throw error;
      }
    } else {
      setSnackbarSeverity("warning");
      setSnackbarMessage("Please select template or create a new one.");
      setSnackbarOpen(true);
    }
  };

  return (
    <div className="cardBody">
      <CCardBody>
        <CustomSnackbar
          open={snackbarOpen}
          message={snackbarMessage}
          severity={snackbarSeverity}
          handleClose={() => setSnackbarOpen(false)}
        />
        <div className="cardTable tablePreview">
          {warningMsg && <><span style={{ color: 'red' }}>{warningMsg}</span><br /></>}
          {closingBalance && <><span>{closingBalance}</span>,&nbsp;</>}
          {openBalance && <><span>{openBalance}</span><br /></>}
          <CTable className="text-center">
            <CTableHead>
              <CTableRow>
                <CTableHeaderCell scope="col">Date</CTableHeaderCell>
                <CTableHeaderCell scope="col">Amount</CTableHeaderCell>
                <CTableHeaderCell scope="col">Narration</CTableHeaderCell>
                <CTableHeaderCell scope="col">Balance</CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {tableData?.map((item, index) => (
                <CTableRow key={index}>
                  <CTableDataCell>{item?.date}</CTableDataCell>
                  <CTableDataCell>{item?.withdrawal}</CTableDataCell>
                  <CTableDataCell>{item?.narration}</CTableDataCell>
                  <CTableDataCell>{item?.balance}</CTableDataCell>
                </CTableRow>
              ))}
            </CTableBody>
          </CTable>
        </div>
      </CCardBody>
      <CRow className="mt-4 justify-content-end">
        <CCol xs="auto">
          <CButton
            color="light"
            onClick={() => {
              handleClose();
            }}
          >
            Cancel
          </CButton>
        </CCol>
        <CCol xs="auto">
          <CButton
            color="primary"
            onClick={() => {
              if (!isSpine) handleProcessed();
            }}
            disabled={warningMsg ? true : false}
          >
            {isSpine ? <CSpinner color="white" size="sm" /> : "Process"}
          </CButton>
        </CCol>
      </CRow>
      <CModal
        className={"image-viewer modal large overflowBox"}
        visible={openFileViewer}
        onClose={() => setOpenFileViewer(false)}
      >
        <CModalHeader>
          <h3>Error Log </h3>
        </CModalHeader>
        <CModalBody>
          <div className="iframeBox">
            <iframe className={"image-viewer img"} src={fileForView} />
          </div>
        </CModalBody>
      </CModal>
    </div>

  );
};

Preview.propTypes = {
  rowDatas: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      fileName: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      withdrawal: PropTypes.number.isRequired,
      narration: PropTypes.string.isRequired,
      balance: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export default Preview;

export const CustomSnackbar = ({ open, message, severity, handleClose }) => {
  let backgroundColor;

  switch (severity) {
    case "success":
      backgroundColor = "#4CAF50"; // Green color for success
      break;
    case "warning":
      backgroundColor = "#FFC107"; // Yellow color for warning
      break;
    case "error":
      backgroundColor = "#F44336"; // Red color for error
      break;
    default:
      backgroundColor = "#FFFFFF"; // Default color
      break;
  }
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
      message={message}
      ContentProps={{
        style: { backgroundColor }, // Set background color
      }}
    />
  );
};
