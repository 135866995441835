import React from 'react'

const Terms = () => {
  return (
    <div>
    Term and condition 
    </div>
  )
}

export default Terms