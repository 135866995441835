import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { CImage, CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler } from '@coreui/react'
import CIcon from '@coreui/icons-react'

import { AppSidebarNav } from './AppSidebarNav'

import logo  from 'src/assets/brand/logo.png'
import { sygnet } from 'src/assets/brand/sygnet'

import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'
import { useData } from "./dataContext";


import {bookkeeperNav , _nav} from '../_nav'


const AppSidebar = () => {
  const { data } = useData();
  const dispatch = useDispatch()
  const unfoldable = useSelector((state) => state.sidebarUnfoldable)
  const sidebarShow = useSelector((state) => state.sidebarShow)
   const roleFromStorage = JSON.parse(localStorage.getItem('companyData'))
 const role = roleFromStorage?.company?.role
  return (     
    <CSidebar
      position="fixed"
      unfoldable={unfoldable}
      visible={sidebarShow}
      // onVisibleChange={(visible) => {
      //   dispatch({ type: 'set', sidebarShow: visible })
      // }}
    >
      <CSidebarBrand className="d-none d-md-flex" to="/" style={{backgroundColor:'#FFFFFF'}}>
      <CImage src={logo} />
        <CIcon className="sidebar-brand-narrow" icon={sygnet} height={35} />
      </CSidebarBrand>
      <CSidebarNav>
      <SimpleBar>
      {
            role === 'BusinessOwner' ? 
            <AppSidebarNav items={_nav} />
            :
            <AppSidebarNav items={bookkeeperNav} />
          }
        
          {/* <AppSidebarNav items={navigation} /> */}
        </SimpleBar>
      </CSidebarNav>
      {/* <CSidebarToggler
        className="d-none d-lg-flex"
        onClick={() => dispatch({ type: 'set', sidebarUnfoldable: !unfoldable })}
      /> */}
    </CSidebar>
   )
}

export default React.memo(AppSidebar)
