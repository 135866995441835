import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  CRow,
  CCol,
  CButton,
  CFormInput,
  CFormSelect,
  CForm,
  CFormLabel,CSpinner
} from "@coreui/react";
import {
  GetApiwithHeader,
  postApiWithHeader,
  UpdateUserwithHeader,
} from "src/Api/Api";
import PropTypes from "prop-types";
import { userAttributes } from "../../share/localData";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useData } from '../../components/dataContext';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const AddInvitation = (props) => {
  const { data } = useData();
  const { getRolesOpt, editValue, apiCall } = props
  // const editValue = props?.editValue;
  const { cmpID } = userAttributes();
  const [industry, setIndustry] = useState([])
  const accessToken = localStorage.getItem("token");
  const [isLoading, setIsLoading] = useState(false);


  AddInvitation.propTypes = {
    fetchData: PropTypes.func.isRequired,
    editValue: PropTypes.string.isRequired,
    handleClose: PropTypes.func,
  };

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  const companyId = cmpID;

  const handleOnChange = (value, country) => {
    const sanitizedPhoneNumber = value.replace(/^0+/, '');
    const formattedPhoneNumber = `+${sanitizedPhoneNumber}`;
    formik.setFieldValue('receiverMobile', formattedPhoneNumber);
  };

  const formik = useFormik({
    initialValues: {
      companyId: companyId,
      receiverMobile: '',
      receiverName: "",
      role: "",
      deviceType: "web"
    },

    validationSchema: Yup.object({
      receiverName: Yup.string().required("User Name is required"),
      receiverMobile: Yup.string()
        .required('Mobile number is required')
        // .matches(/^[0-9]+$/, 'Mobile number must contain only digits')
        .min(10, 'Mobile number must be at least 10 digits')
        .max(15, 'Mobile number can not exceed 15 digits'),
      role: Yup.string().required("Role is required")
    }),

    onSubmit: async (values, { resetForm }) => {
      console.log('values', values)
      setIsLoading(true);
      const apiUrl = editValue
        ? `/api/invitation/update/${editValue.id}`
        : '/api/invitation/create';

      const formData = {
        companyId: companyId,
        receiverName: values.receiverName,
        receiverMobile: values.receiverMobile,
        role: values.role,
        deviceType: "web"
      };  

      const response = editValue
          ? await UpdateUserwithHeader(apiUrl, formData, headers)
          : await postApiWithHeader(apiUrl, formData, headers);

      try {
        if (editValue ? response.message === "Success" : response.data.message === "Success") {
          toast.success(
            `${editValue ? 'Updated' : 'Added'} successfully`, { autoClose: 1000 }
          );
          props?.handleClose();
          resetForm();
          apiCall();
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }finally {
        props?.handleClose();
        resetForm();
        apiCall();
        setIsLoading(false);
      }
    },
  });
    
  useEffect(() => {
    if (editValue) {
      formik.setValues({
        receiverName: editValue.receiverName,
        receiverMobile: editValue.receiverMobile,
        role: editValue.role
      });
    } else {
      formik.setValues({
        receiverMobile: '',
        receiverName: "",
        role: "",
        deviceType: "web"
      });
      resetFormErrors();
    }

  }, [editValue]);

  const resetFormErrors = () => {
    formik.setErrors({
      receiverMobile: '',
      receiverName: "",
      role: "",
      deviceType: "web"
    });
  };

  return (
    <CForm onSubmit={formik.handleSubmit} >
      <CCol md={12}>
        <CFormInput
          type="text"
          name="receiverName"
          placeholder="Enter Name"
          label={
            <>
              User Name <span className="text-danger">*</span>
            </>
          }
          onChange={formik.handleChange}
          value={formik.values.receiverName}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
        />
        {formik.touched.receiverName && formik.errors.receiverName && (
          <div className="text-danger">{formik.errors.receiverName}</div>
        )}
      </CCol>
      <div className="mt-3">
        <CFormLabel>Role <span className="text-danger">*</span></CFormLabel>
        {!editValue ? (<CFormSelect
          as="select"
          name="role"
          className="form-control"
          onChange={formik.handleChange}
          value={formik.values.role}
        >
          <option value="">Select Role</option>
          {getRolesOpt
           ?.sort((a, b) => a.name.localeCompare(b.name))
          .map((item, index) => (<> 
            <option key={index} value={item.name}>{item.name} </option></>
          ))}

        </CFormSelect>) : (
          <input
            type="text"
            name="role"
            className="form-control"
            value={formik.values.role}
            disabled
          />
        )}
        {formik.touched.role && formik.errors.role && (
          <div className="text-danger">{formik.errors.role}</div>
        )}
      </div>

      <div className="mt-3">
        <CFormLabel>
          Mobile Number <span className="text-danger">*</span>
        </CFormLabel>
        <PhoneInput
          name="receiverMobile"
          placeholder='Enter mobile number'
          onChange={(value, country) => handleOnChange(value, country)}
          country={'au'}
          value={formik.values.receiverMobile}
          inputStyle={{ width: '100%' }}
          containerStyle={{ width: '100%' }}
          inputProps={{
            className: formik.touched.receiverMobile && formik.errors.receiverMobile
              ? 'form-control is-invalid'
              : 'form-control'
          }}
        />
        {formik.touched.receiverMobile && formik.errors.receiverMobile && (
          <div className="invalid-feedback">{formik.errors.receiverMobile}</div>
        )}
      </div>
      <CRow className="mt-4 justify-content-end">
        <CCol xs="auto">
          <CButton color="light" onClick={() => {
            props.handleClose()
          }}>
            Cancel
          </CButton>
        </CCol>
        <CCol xs="auto">
          <CButton color="primary" type="submit">
            {isLoading ? (
              <CSpinner color="white" size="sm" />
            ) : (
              "Save"
            )}
          </CButton>
        </CCol>
      </CRow>
    </CForm>
  );
};

export default AddInvitation;
