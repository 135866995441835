import React, { useMemo, useEffect, useState } from "react";
import {
  CCol,
  CCardBody,
  CButton,
  CFormInput,
  CRow,
} from "@coreui/react";
import { DataGrid, GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid';
import { toast } from "react-toastify";
import { GetApiwithHeader, postApiWithHeader } from "src/Api/Api";
import { userAttributes } from "../../share/localData";

const HighFrequency = (props) => {
  const { titleNarration, modal, fetchDataCoding, selectData, updatedData } = props;
  const { cmpID, bankID } = userAttributes();
  const [similarData, setSimilarData] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = React.useState(selectData.map(item => item?.id));
  const accessToken = localStorage.getItem("token");
  const headers = useMemo(
    () => ({
      Authorization: `Bearer ${accessToken}`,
    }),
    [accessToken]
  );
 console.log('titleNarration', titleNarration)
  const companyId = cmpID;

  const handleRule = async (e) => {
    let rule = true
    let transactionRequest 
    if (e === 'apply'){
      rule = false
    }
    transactionRequest = selectData.filter(item => rowSelectionModel.includes(item.id));
    const requestBody =
    {
      Narration : titleNarration[0]?.narration,
      bankAccountId: bankID,
      ruleId: 0,
      applyRule: rule,
      transactionCode: titleNarration[0]?.transactionCode,
      transactionRequest: transactionRequest || [],
    }
   
    const response = await postApiWithHeader(
      `api/CodingRuleSet/Apply-Rule/${companyId}`,
      requestBody,
      headers
    );
    try {
      if (response?.data?.statusCode === 100) {
        // fetchDataCoding()
        updatedData(response?.data?.data);
        modal(false);
        toast.success(response?.data?.message, { autoClose: 1000 })
      }
    } catch (error) {
      console.error("Error:", error);
      toast.warning(response?.data?.message, { autoClose: 1000 });
    }
  }

  const columns = [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      headerClassName: 'dataGridHeader',
      cellClassName: (params) => {
        if(params.row.bankEntryCodingResponseDtos.length > 0){
          return 'codeColorCode';
        }
        return '';
      },
    },
    {
      field: 'Entry',
      headerName: 'Entry',
      width: 80,
      headerClassName: 'dataGridHeader',
      valueGetter: (params) =>
        `${params.row.entryCode || ''}`,
    },
    {
      field: 'Date',
      headerName: 'Date',
      width: 100,
      headerClassName: 'dataGridHeader',
      sortable: false,
      valueGetter: (params) =>
        `${new Date(params.row?.valueDate)?.toLocaleDateString("en-US")}`,
    },
    {
      field: 'Amount',
      headerName: 'Amount',
      width: 80,
      headerClassName: 'dataGridHeader',
      sortable: false,
      valueGetter: (params) =>
        `${params.row?.depositAmount !== "0" ? params.row?.depositAmount : params.row?.withdrawalAmount}`,
    },
    {
      field: 'Description',
      headerName: 'Description',
      width: 450,
      headerClassName: 'dataGridHeader',
      sortable: false,
      valueGetter: (params) =>
        `${params.row.narration || ''}`,
    },
    {
      field: 'Balance',
      headerName: 'Balance',
      width: 100,
      headerClassName: 'dataGridHeader',
      sortable: false,
      valueGetter: (params) =>
        `${params.row.balance || ''}`,
    },
  ];
  
  const rows = selectData;
  return (
    <>
      <CRow className="mb-2">
        <CCol md={5}>
          <CFormInput
            disabled
            type="text"
            name="narration"
            label="Transaction Description"
            value={titleNarration[0]?.narration}
          />
        </CCol>
        <CCol md={5}>
          <CFormInput
            disabled
            type="text"
            name="codeName"
            label="Code Name(s)"
            value={titleNarration[0]?.bankEntryCodingResponseDtos?.map((item) => {
              return item?.businessCodeName
            })}
          />
        </CCol>
        <CCol md={2}>
          <div className="pt8">
            Split : {titleNarration[0]?.bankEntryCodingResponseDtos?.length >= 2 ? 'Yes' : 'No'}
          </div>
        </CCol>
      </CRow>
      <hr className="mtb15" />
      {/* {
        !selectData ? <h3 className="mtb-10">Uncoded Similar Transaction Found ({similarData?.length ? similarData?.length : 0})</h3> : ''
      } */}
      <CCardBody>
        <div className="cardTable similarTransactionsTable">
          <div className="TransactionDefinition ">
          <DataGrid
            rows={rows}
            columns={columns}
            density="compact"
            checkboxSelection
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            rowSelectionModel={rowSelectionModel}
            hideFooter
            hideFooterPagination
          />
     </div>
     <div className="mt-4 justify-content-end">
          <CRow>
            <CCol md="6">
              <CButton onClick={() => handleRule('rule')}>
                Make rule and apply code
              </CButton>
            </CCol>
            <CCol md="6" className="text-right">
              <CButton onClick={() => handleRule('apply')}>
                Apply Code
              </CButton>
            </CCol>
          </CRow>
        </div>



          {/* ) : ''} */}
        </div>
      </CCardBody>
    </>
  );
};

export default HighFrequency;
