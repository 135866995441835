import React, { useEffect, useState, useMemo, Fragment, useRef } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Select1 from "react-select";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Checkbox from "@mui/material/Checkbox";
import { calculateFVPayout } from "./FinancialFormula";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import {
  CCol,
  CRow,
  CFormInput,
  CForm,
  CCard,
  CCardTitle,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CImage,
  CButton,
} from "@coreui/react";
import {
  GetApiwithHeader,
  postApiWithHeader,
  UpdateUserwithHeader,
} from "src/Api/Api";
import { userAttributes } from "../../share/localData";
import {
  normal,
  revenue1,
  money,
  debitor,
  out,
  disInsurance,
  capital,
  expense,
  splitInsurance,
  creditors,
  moneyout1,
} from "src/components/Images/Images";

const MultipleSelectCheckmarks = (props) => {
  const futureValue = useRef(0);
  const {
    financeDetails,
    transcationData,
    rowCodeID,
    handleCloseClick,
    fetchData,
  } = props;
  const { cmpID, bankID, companyName } = userAttributes();
  const companyId = cmpID;
  const accessToken = localStorage.getItem("token");
  const [codeData, setCodeData] = useState([]);
  const [businessCode, setBusinessCode] = useState([]);
  const [selectedOptionCode, setSelectedOptionCode] = useState([]);
  const [gstAmounts, setGstAmounts] = useState([]);
  const isManualAltrGstAmt = useRef([0, 0]);
  const [gstErrors, setGstErrors] = useState([]);
  const [capitalAmt, setCapitalAmt] = useState([]);
  const [valuesArray, setValuesArray] = useState(["1"]);

  const headers = useMemo(
    () => ({
      Authorization: `Bearer ${accessToken}`,
    }),
    [accessToken]
  );

  const codeFetchApi = async () => {
    try {
      const response = await GetApiwithHeader(
        `/api/BusinessCodes?companyId=${companyId}`,
        headers
      );
      const businessCode = response?.data?.data;
      setCodeData(businessCode);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    codeFetchApi();
    if (rowCodeID?.period === "") {
      console.log('add', financeDetails?.businessCodeResponses  )
      let arr = "";
      transcationData.map(
        (item) => `${(arr = `${arr}${arr ? "," : ""}` + item.period)}`
      );
      arr = arr.split(",");
      arr =
        arr.length > 0 && arr[0]
          ? arr
              .filter((f) => f)
              .map((i) => parseInt(i))
              .sort((a, b) => b - a)
          : [0];

      const count =
        Math.abs(rowCodeID?.amount) / Math.abs(financeDetails.installment);
      let period = arr[0];
      const valueArray = Array.from(
        { length: count },
        () => `${(period += 1)}`
      );
      setValuesArray(valueArray);
      let capitalAmt = 0;
      let interestAmt = 0;
      valueArray.forEach((element) => {
        let resultObj = calculateFVPayout(
          financeDetails.roi,
          financeDetails.numOfPaymentPeriods,
          financeDetails.presentValue,
          financeDetails.futureValue,
          financeDetails.paymentType,
          JSON.parse(element),
          financeDetails.durationUnit
        );
        capitalAmt += resultObj.capital;
        interestAmt = interestAmt + resultObj.interest;
        futureValue.current = resultObj.futureValue;
      });
      setCapitalAmt([capitalAmt, interestAmt]);
      setGstAmounts([0, 0]);
      let businessCd = [];
      const selectedBussinesCode = financeDetails?.businessCodeResponses?.map(
        (item) => {
          businessCd.push(
            financeDetails?.businessCodeResponses.find(
              (i) => i?.id === item.businessCodeId
            )
          );
          return { label: item.codeName, value: item.id };
        }
      );
      setSelectedOptionCode(selectedBussinesCode);
      const code = [
        financeDetails?.businessCodeResponses[0],
        financeDetails?.businessCodeResponses[1],
      ];
      setBusinessCode(code);
      const gstAmt = code.map((itm, index) => {
        return GstValueCalulation(
          index === 0 ? capitalAmt : interestAmt,
          itm?.gst
        );
      });
      setGstAmounts(gstAmt);
    } else {
      if (rowCodeID?.bankEntryCodingResponseDtos?.length > 0) {
        console.log('edit', rowCodeID?.bankEntryCodingResponseDtos)
        setValuesArray(rowCodeID?.period.split(","));
        const code = [
          financeDetails?.businessCodeResponses[0],
          financeDetails?.businessCodeResponses[1],
        ];
        setBusinessCode(code);
        // setBusinessCode([
        //   rowCodeID?.bankEntryCodingResponseDtos[0],
        //   rowCodeID?.bankEntryCodingResponseDtos[1],
        // ]);
        const selectedBussinesCode =
          rowCodeID?.bankEntryCodingResponseDtos?.map((item) => {
            return { label: item.businessCodeName, value: item.businessCodeId };
          });
        setSelectedOptionCode(selectedBussinesCode);
        setCapitalAmt([
          rowCodeID?.bankEntryCodingResponseDtos[0].splitAmount,
          rowCodeID?.bankEntryCodingResponseDtos[1].splitAmount,
        ]);
        setGstAmounts([
          rowCodeID?.bankEntryCodingResponseDtos[0].gstAmount,
          rowCodeID?.bankEntryCodingResponseDtos[1].gstAmount,
        ]);
      }
    }
  }, [companyId, rowCodeID]);

  const handleChange = (event, index) => {
    const {
      target: { value },
    } = event;
    let valueArray = [...valuesArray];
    valueArray = valueArray.map((itm, idx) => (index === idx ? value : itm));
    let capitalAmt = 0;
    let interestAmt = 0;
    setValuesArray(valueArray);
    valueArray.forEach((element) => {
      let resultObj = calculateFVPayout(
        financeDetails.roi,
        financeDetails.numOfPaymentPeriods,
        financeDetails.presentValue,
        financeDetails.futureValue,
        financeDetails.paymentType,
        JSON.parse(element),
        financeDetails.durationUnit
      );
      capitalAmt += resultObj.capital;
      interestAmt = interestAmt + resultObj.interest;
      futureValue.current = resultObj.futureValue;
    });

    setCapitalAmt([capitalAmt, interestAmt]);
    const gstAmt = businessCode.map((itm, index) => {
      return GstValueCalulation(
        index === 0 ? capitalAmt : interestAmt,
        itm?.gst
      );
    });
    setGstAmounts(gstAmt);
  };

  const options =
    codeData &&
    codeData?.map((item) => ({
      value: item.codeName,
      label: item.codeName,
      gst: item.gst,
      isFinancialInstrument: item?.isFinancialInstrument,
      categoryA: item?.categoryA,
      categoryB: item?.categoryB,
    }));
  const { capitalArray, nonCapitalArray } = options?.reduce(
    (acc, item) => {
      if (item?.isFinancialInstrument) {
        if (item.categoryA === "Capital") {
          acc?.capitalArray.push(item);
        } else if (item?.categoryA === "NonCapital") {
          acc?.nonCapitalArray.push(item);
        }
      }
      return acc;
    },
    { capitalArray: [], nonCapitalArray: [] }
  );

  const renderEntryDetails = (index) => {
    if (
      !businessCode ||
      !businessCode[index] ||
      !selectedOptionCode ||
      !selectedOptionCode[index]
    )
      return null;

    const item = businessCode[index];
    return (
      <Fragment key={index}>
        <CRow className="codeTypeImages canvasCardBox ">
          <CCol md="3">
            <CCard className="text-center">
              {item?.entryType === "In" ? (
                <CImage src={money} alt="Money Icon" />
              ) : (
                <CImage src={moneyout1} alt="Out Icon" />
              )}
              <CCardTitle component="p" className="m-0">
                Money
              </CCardTitle>
            </CCard>
          </CCol>
          <CCol md="3">
            <CCard className="text-center">
              {item?.isInsurance ? (
                <CImage src={splitInsurance} alt="Split Insurance Icon" />
              ) : (
                <CImage src={disInsurance} alt="Dis Insurance Icon" />
              )}
              <CCardTitle component="p" className="m-0">
                Asset
              </CCardTitle>
            </CCard>
          </CCol>
          {item?.categoryB !== "None" && (
            <CCol md="3">
              <CCard className="text-center">
                {item?.categoryB === "Revenue" ? (
                  <CImage src={revenue1} alt="Revenue Icon" />
                ) : (
                  <CImage src={expense} alt="Expense Icon" />
                )}
                <CCardTitle component="p" className="m-0">
                  {item?.categoryB === "Revenue" ? "Revenue" : "Expense"}
                </CCardTitle>
              </CCard>
            </CCol>
          )}

          {item?.value !== "None" && (
            <CCol md="3">
              <CCard
                className="text-center"
                onClick={() => handleCardClick(item?.value)}
              >
                {item?.value === "Debitor" ? (
                  <CImage src={debitor} alt="Money Icon" />
                ) : (
                  <CImage src={creditors} alt="Out Icon" />
                )}
                <CCardTitle component="p" className="m-0">
                  {item?.value === "Debitor" ? "Debitor" : "Creditor"}
                </CCardTitle>
              </CCard>
            </CCol>
          )}

          {item?.gst === "Normal" && (
            <CCol md="3">
              <CCard className="text-center">
                <CImage src={normal} alt="Normal Icon" />
                <CCardTitle component="p" className="m-0">
                  Normal
                </CCardTitle>
              </CCard>
            </CCol>
          )}
          {item?.categoryA === "Capital" && (
            <CCol md="3">
              <CCard className="text-center">
                <CImage src={capital} alt="Capital Icon" />
                <CCardTitle component="p" className="m-0">
                  Capital
                </CCardTitle>
              </CCard>
            </CCol>
          )}
        </CRow>
      </Fragment>
    );
  };

  function GstValueCalulation(splitAmounts, businessCode) {
    let GstCalValue;
    const dividedValue =
      Math.round(
        (splitAmounts?.splitAmount ? splitAmounts?.splitAmount : splitAmounts) *
          100
      ) / 100;
    if (businessCode === "Normal") {
      const minVal = dividedValue / 11;
      GstCalValue = minVal.toFixed(2);
    } else {
      GstCalValue = 0;
    }
    return GstCalValue;
  }

  function NetValueCalculation(captAmt, businessCode, gstValue) {
    const dividedValue = Math.abs(captAmt);
    let calculatedValue;
    if (businessCode === "Normal" && gstValue) {
      calculatedValue = dividedValue - parseFloat(Math.abs(gstValue)); // Parse gstValue
    } else {
      calculatedValue = dividedValue;
    }
    return parseFloat(calculatedValue).toFixed(2);
  }

  const handleChangeCode = (e, index) => {
    const selectedOption = codeData.find((item) => item?.codeName === e.value);
    const updatedCodeVal = [...businessCode];
    updatedCodeVal[index] = selectedOption;
    setBusinessCode(updatedCodeVal);
    const updatedselectedOptionCode = [...selectedOptionCode];
    updatedselectedOptionCode[index] = e;
    setSelectedOptionCode(updatedselectedOptionCode);
    const gstAmt = GstValueCalulation(
      capitalAmt[index],
      updatedCodeVal[index]?.gst
    );
    if (gstAmounts?.length === capitalAmt?.length) {
      setGstAmounts([
        ...gstAmounts.map((i, idx) => (index === idx ? gstAmt : i)),
      ]);
    } else {
      const newGstAmtArr = Array.from({ length: capitalAmt?.length }, () => 0);
      setGstAmounts([
        ...newGstAmtArr.map((i, idx) => (index === idx ? gstAmt : i)),
      ]);
    }
  };

  const codeDropDown = (index) => {
    return (
      <>
        <div className="d-flex financialDetailsCanvasBox">
          <div className="code--select">
            <Select1
              label="Code"
              className=""
              onChange={(e) => handleChangeCode(e, index)}
              name="code"
              // defaultValue=""
              isDisabled={true}
              value={
                selectedOptionCode?.length !== 0
                  ? // && selectedOptionCode[index] !== undefined
                    selectedOptionCode[index]
                  : selectedOptionCode
              }
              // value={selectedOptionCode[index]}
              // options={index === 0 ? capitalArray : nonCapitalArray}
              // isSearchable
              placeholder="Select code name"
            />
            {renderEntryDetails(index)}
          </div>
        </div>
      </>
    );
  };

  const handleGstChange = (e, index) => {
    const { value } = e.target;
    const regex = /^[0-9]*\.?[0-9]*$/;
    if (!regex.test(value)) {
      return;
    }
    let newGstAmounts = [...gstAmounts];
    newGstAmounts = newGstAmounts.map((i, idx) => (idx === index ? value : i));
    setGstAmounts(newGstAmounts);
    isManualAltrGstAmt.current[index] = 1;
    const netValue = parseFloat(
      NetValueCalculation(capitalAmt[index], businessCode[index]?.gst)
    );
    const gstValue = parseFloat(value);

    if (isNaN(gstValue) || gstValue < 0) {
      setGstErrors((prevErrors) => {
        const newErrors = [...prevErrors];
        newErrors[index] = "Invalid GST amount";
        return newErrors;
      });
    } else if (gstValue > netValue) {
      setGstErrors((prevErrors) => {
        const newErrors = [...prevErrors];
        newErrors[index] = "GST amount cannot exceed net value";
        return newErrors;
      });
    } else {
      setGstErrors((prevErrors) => {
        const newErrors = [...prevErrors];
        newErrors[index] = "";
        return newErrors;
      });
    }
  };

  const handleSplitSave = async (e) => {
    e.preventDefault();
    // return
    let arr;
    let payload = [];
    capitalAmt.forEach((item, index) => {
      const splitDefinition = {
        amount: item.toFixed(2),
        splitType: "Dollar",
        splitCount: capitalAmt?.length,
        split1_amount: capitalAmt[0] ? capitalAmt[0].toFixed(2) : 0,
        split2_amount: capitalAmt[1] ? capitalAmt[1].toFixed(2) : 0,
      };
      const abc = {
        transactionCode: rowCodeID?.transactionCode,
        businessCodeId: businessCode[index]?.id,
        splitDefinition: JSON.stringify(splitDefinition), //selectedPerSplit,
        splitAmount: item.toFixed(2),
        gstAmount: gstAmounts[index],
        IsGSTAltered: isManualAltrGstAmt.current[index] ? true : false,
        netAmount: NetValueCalculation(
          capitalAmt[index],
          businessCode[index]?.gst,
          gstAmounts[index]
        ),
        receiptId: 0,
        narration: "",
      };
      payload.push(abc);
    });
    const payloadForMapping = {
      userId: financeDetails?.userId,
      transactionCode: rowCodeID?.transactionCode,
      portFolioId: financeDetails?.id,
      period: valuesArray.toString(),
      capital: capitalAmt[0].toFixed(2),
      intrest: capitalAmt[1].toFixed(2),
      futureValue: futureValue.current.toFixed(2),
    };
    // console.log("payload---",payload);
    // console.log("payloadForMapping---",payloadForMapping);
    // return;
    try {
      const response = await postApiWithHeader(
        `/api/bankentrycodings/create/${companyId}`,
        payload,
        headers
      );
      if (response?.data?.statusCode === 100) {
        const res = await UpdateUserwithHeader(
          `/api/portfolio/UpdateMapping/${companyId}?id=${rowCodeID?.id}`,
          payloadForMapping,
          headers
        );
        if (res.statusCode === 100) {
          toast.success("Save", { autoClose: 1000 });
          fetchData();
          handleCloseClick();
        }
      } else if (response?.data?.statusCode === 102) {
        toast.error("Please provide code for all splits.", { autoClose: 1000 });
      }
    } catch (error) {
      toast.warning(response?.data?.message, { autoClose: 1000 });
    }
  };

  return (
    <div>
      <ToastContainer position="top-right" />
      <div className="entriesWrapper">
        <div className="financialInstrumentSplit">
          <CRow className="w-100">
            <CCol sm={9}>
              <p className="text-start">
                Entry#: <b>{rowCodeID && rowCodeID?.entryCode}</b>{" "}
              </p>
            </CCol>
            <CCol sm={3}>
              <div className="d-flex gap-1 justify-content-end">
                <p>${rowCodeID && rowCodeID?.amount}</p>
              </div>
            </CCol>

            <CCol sm={12}>
              <p className="text-start nmt1">
                {rowCodeID && rowCodeID?.narration}
              </p>
            </CCol>
          </CRow>
        </div>
      </div>
      <CRow className="mb-2">
        <CCol md="6" className="selectMonthSplit">
          {valuesArray && valuesArray.length > 0 ? (
            <>Select Month Period</>
          ) : (
            <div className="text-danger">
              Note - Transaction amount does not match with the installment
              amount
            </div>
          )}
        </CCol>

        {valuesArray?.map((item, index) => {
          return (
            <CCol md="3">
              <CFormInput
                type="number"
                value={item}
                defaultChecked
                onChange={(e) => handleChange(e, index)}
              />
            </CCol>
          );
        })}
      </CRow>
      {valuesArray && valuesArray?.length > 0 && (
        // <></>
        <CForm onSubmit={handleSplitSave}>
          {["1", "2"].map((item, index) => {
            return (
              <div className="splitCardAc mb-10">
                <Accordion key={0} defaultExpanded>
                  <AccordionSummary>
                    {index === 0 ? "Capital $" : "Intreset $"}
                  </AccordionSummary>
                  <AccordionDetails>
                    <CRow>
                      <CCol md="12" sm="12">
                        <div className="mb-3">{codeDropDown(index)}</div>
                      </CCol>
                      <CCol md="6" sm="12">
                        <div className="mb-3">
                          <CFormInput
                            type="text"
                            name="Capital"
                            label={index === 0 ? "Capital $" : "Intreset $"}
                            disabled
                            value={NetValueCalculation(
                              capitalAmt[index],
                              businessCode[index]?.gst,
                              gstAmounts[index]
                            )}
                          />
                        </div>
                      </CCol>
                      <CCol md="6" sm="12">
                        <div className="mb-3">
                          <CFormInput
                            type="text"
                            name="gst"
                            placeholder="Enter GST Amount"
                            label="Gst Amount $"
                            value={gstAmounts[index] || ""}
                            // disabled={businessCode[index]?.gst !== "Normal"}
                            disabled
                            onChange={(e) => handleGstChange(e, index)}
                            onKeyDown={(e) =>
                              e.key === "Enter" && e.preventDefault()
                            }
                          />
                          {gstErrors[0] && (
                            <div className="text-danger">
                              {gstErrors[index]}
                            </div>
                          )}
                        </div>
                      </CCol>
                    </CRow>
                  </AccordionDetails>
                  <AccordionActions />
                </Accordion>
              </div>
            );
          })}
          <CButton className="mt-2" type="submit">
            Save
          </CButton>
        </CForm>
      )}
    </div>
  );
};

export default MultipleSelectCheckmarks;
