import React, { useMemo, useEffect, useState, useCallback } from "react";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarExport,
  GridPrintExportOptions,
} from "@mui/x-data-grid-pro";
import { CButton } from "@coreui/react";
import { Box, MenuItem, Select, Button, InputLabel, FormControl } from "@mui/material";
import { GetApiwithHeader } from "src/Api/Api";
import { userAttributes } from "../../share/localData";
import { LicenseInfo } from "@mui/x-license";
import { makeStyles } from "@mui/styles";
import { useData } from "../../components/dataContext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addDays } from "date-fns";
import { Stack } from "react-bootstrap";


const GenralLedgerReport = () => {
  const { data, bankDataLocal } = useData();
  const accessToken = localStorage.getItem("token");
  LicenseInfo.setLicenseKey(
    "248a71a5e79c492f4b13769843fcf6dcTz04OTIzMCxFPTE3NDU3NTY1ODYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
  );
  const [ledgerData, setLedgerData] = useState([]);
  const { cmpID } = userAttributes();
  const companyId = cmpID;
  const [selectedYear, setSelectedYear] = useState("2022-2023");
  const [loadingState, setLoadingState] = useState(true);
  
  const preparedRows = [];
  const uniqueCodeNames = {};

  const headers = useMemo(
    () => ({
      Authorization: `Bearer ${accessToken}`,
    }),
    [accessToken]
  );

  const fetchData = async (e) => {
    setLoadingState(true);

    try {
      const res = await GetApiwithHeader(
        `/api/Report/General-Ledger?companyId=${companyId}&financialyear=${e ? e :'2022-2023'}`,
        headers
      );
      setLoadingState(false);
      setLedgerData(res?.data?.data || []);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [companyId]);

  const useStyles = makeStyles({
    row: {
      "@media print": {
        innerHeight: "100px",
        pageBreakInside: "avoid",
      },
    },
  });

  

  const columns = [
    {
      field: "TransactionDate",
      headerName: "Date",
      width: 120,
      // type: "date",
      sortable: false,
    },
    {
      field: "accountName",
      headerName: "Bank",
      width: 100,
      sortable: false,
    },
    {
      field: "EntryCode",
      headerName: "Entry #",
      width: 100,
      sortable: false,
    },
    {
      field: "Narration",
      headerName: "Transaction Details",
      width: 450,
      sortable: false,
      renderCell: (params) => <div className="wrap-cell">{params.value}</div>,
    },
    {
      field: "NetAmount",
      headerName: "Net Amount",
      width: 100,
      sortable: false,
    },
    {
      field: "GstAmount",
      headerName: "GST",
      width: 100,
      sortable: false,
    },
  ];

  let count = 0;
  let grandTotalNetAmount = 0;
  let grandTotalGstAmount = 0;

  ledgerData &&
    ledgerData?.forEach((row, index) => {
      if (!uniqueCodeNames[row.codeName]) {
        uniqueCodeNames[row.codeName] = true;

        preparedRows.push({
          id: `header-${row.id}`,
          CodeName: row.codeName,
          isHeader: true,
        });
      }
      console.log('row', row)
      preparedRows.push({
        id: row.id,
        TransactionDate: new Date(row?.transactionDate).toLocaleDateString('en-GB'),
        EntryCode: row.entryCode,
        Narration: row.narration,
        NetAmount: Number(row.netAmount),
        GstAmount: Number(row.gstAmount),
        CodeName: "", // row.codeName
        accountName: row?.accountName,
        isHeader: false,
      });

      count = index + 1;
      if (ledgerData[count]?.codeName !== row?.codeName) {
        preparedRows.push({
          id: `subtotal-${row.id}`,
          Narration: "Total",
          NetAmount: row?.totalNetAmount,
          GstAmount: row?.totalGstAmount,
          isSubtotal: true,
        });

        grandTotalNetAmount = row.grandTotalNetAmount;
        grandTotalGstAmount = row.grandTotalGstAmount;
      }
    });
  preparedRows.push({
    id: "grandtotal",
    Narration: "GrandTotal",
    NetAmount: grandTotalNetAmount,
    GstAmount: grandTotalGstAmount,
    isGrandTotal: true,
  });

  const columnsWithCodeName = [
    {
      field: "CodeName",
      headerName: "Code Name",
      width: 150,
      sortable: false,
      renderCell: (params) => (params.row.isHeader ? params.value : null),
    },
    ...columns,
  ];

  const getRowClassName = (params) => {
    if (params.row.isHeader) {
      return "header-row";
    }
    if (params.row.isSubtotal) {
      return "subtotal-row";
    }
    if (params.row.isGrandTotal) {
      return "grandtotal-row";
    }

    return "pageBreakRow";
  };

  const CustomToolbar = () => (
    <div>
      <div style={{ fontSize: "24px", padding: "5px", float: "left" }}>
        General Ledger
      </div>
      <GridToolbarContainer>
        <div className="exportReport" style={{ justifyContent: "flex-start" }}>
          <Box ml={0}>
            <GridToolbarExport
              csvOptions={{
                fileName: "General Ledger",
              }}
              printOptions={{
                hideToolbar: true,
              }}
            />
          </Box>
        </div>
      </GridToolbarContainer>
    </div>
  );


  const handleYearChange = useCallback((event) => {
    setSelectedYear(event.target.value);
    fetchData(event.target.value);
  }, []);

  // var currentdate = new date()
  // var year = currentdate.year();
  // var compareDate = new date("1 July "+year)
  // var currentFY 
  // if(currentdate > compareDate){
  //   currentFY = year+1
  // }else{
  //   currentFY = year
  // }

  

  return (
    <div className="cardBody reportTable">
       <Stack className="financial-stack">
      <Box className="financial-year-select" mt={2}>
        <Select
          labelId="financial-year-label"
          value={selectedYear || ''}
          onChange={handleYearChange}
          // label="Select Financial Year"
        >
          <MenuItem value="2023-2024">FY2024</MenuItem>
          <MenuItem value="2022-2023">FY2023</MenuItem>
          <MenuItem value="2021-2022">FY2022</MenuItem>
        </Select>
      </Box>
      {/* <Button onClick={applyYearChange} variant="contained" color="primary" style={{ marginTop: '10px' }}>
        Apply
      </Button> */}
    </Stack>
      <div className="datatableGrid autoHeightTable">
        <div className="cardHeader ">
        <Box sx={{height: 'calc(100vh - 150px)',width: '100%'}}>
          <DataGridPro
            rows={preparedRows}
            columns={columnsWithCodeName}
            getRowClassName={getRowClassName}
            getRowHeight={() => "auto"}
            rowSelection={false}
            autoHeight
            pagination={false}
            disableSelectionOnClick
            loading={loadingState}
            cellSelection
            slots={{
              toolbar: CustomToolbar,
              style: { backgroundColor: "green" },
            }}
            hideFooterRowCount
            classes={{
              row: useStyles,
            }}
          />
        </Box>
        </div>
      </div>
    </div>
  );
};

export default GenralLedgerReport;
