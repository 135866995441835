// import React, { useMemo, useEffect, useState } from 'react';
// import { DataGridPro, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-pro';
// import { Typography } from '@mui/material';
// import { Container } from '@mui/system';
// import { GetApiwithHeader } from "src/Api/Api";
// import { userAttributes } from "../../share/localData";
// import { LicenseInfo } from '@mui/x-license';

// const TrialBalanceReport = () => {
//   const accessToken = localStorage.getItem("token");
//   LicenseInfo.setLicenseKey(
//     "248a71a5e79c492f4b13769843fcf6dcTz04OTIzMCxFPTE3NDU3NTY1ODYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
//   );
//   const [data, setData] = useState([]);
//   const { cmpID } = userAttributes();
//   const companyId = cmpID;

//   const headers = useMemo(() => ({
//     Authorization: `Bearer ${accessToken}`,
//   }), [accessToken]);

//   const fetchData = async () => {
//     try {
//       const res = await GetApiwithHeader(
//         `/api/BusinessCodes/TrialReport/${companyId}`,
//         headers
//       );
//       setData(res?.data?.data || {});
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   useEffect(() => {
//     fetchData();
//   }, [companyId]);

//   const columns = [
//     { field: ' ', headerName: '', width: 10, sortable: false,
//     headerClassName: 'dataGridHeader' },
//     { field: 'codeName', headerName: 'Code Name', width: 200, sortable: false,
//     headerClassName: 'dataGridHeader' },
//     { 
//       field: 'inAmount', 
//       headerName: 'In (Cr.)', 
//       width: 130, 
//       sortable: false, 
//       type: 'number',
//       headerClassName: 'dataGridHeader',
//       renderCell: ({ row }) => (
//         row.inAmount != null ? row.inAmount : 0
//       ),
//     },
//     { 
//       field: 'outAmount', 
//       headerName: 'Out (Dr.)', 
//       width: 150, 
//       sortable: false, 
//       type: 'number', 
//       headerClassName: 'dataGridHeader',
//       renderCell: ({ row }) => (
//         row.outAmount != null ? row.outAmount : 0
//       ),
//     },
//      ];

//   const sortedTradingGroup = data?.tradingDto?.sort((a, b) => b.codeName - a.codeName) || [];
//   const sortedProfitLossGroup = data?.profitLoassDto?.sort((a, b) => b.codeName - a.codeName) || [];

//   const separateAndSort = (items, entryType) => {
//     return items
//       .filter(item => item.entryType === entryType)
//       .sort((a, b) => a.codeName - b.codeName); 
//   };
  
//   const sortedTradingGroupIn = separateAndSort(sortedTradingGroup, 'In');
//   const sortedTradingGroupOut = separateAndSort(sortedTradingGroup, 'Out');
//   const sortedProfitLossGroupIn = separateAndSort(sortedProfitLossGroup, 'In');
//   const sortedProfitLossGroupOut = separateAndSort(sortedProfitLossGroup, 'Out');

//   const rows = [
//     ...sortedTradingGroupIn.map((item, index) => ({
//       id: index + sortedTradingGroupOut.length + 1,
//       codeName: item.codeName,
//       outAmount: null,
//       inAmount: item.totalAmount,
//     })),
//     ...sortedTradingGroupOut.map((item, index) => ({
//       id: index + 1,
//       codeName: item.codeName,
//       outAmount: item.totalAmount,
//       inAmount: null,
//     })),
//     { id: 'spacer', codeName: '', outAmount: '', inAmount: '', group: 'Spacer' },
//     ...sortedProfitLossGroupIn.map((item, index) => ({
//       id: index + sortedTradingGroup.length + sortedProfitLossGroupOut.length + 2,
//       codeName: item.codeName,
//       outAmount: null,
//       inAmount: item.totalAmount,
//     })),
//     ...sortedProfitLossGroupOut.map((item, index) => ({
//       id: index + sortedTradingGroup.length + 2, 
//       codeName: item.codeName,
//       outAmount: item.totalAmount,
//       inAmount: null,
//     }))
//   ];

//   const CustomToolbar = () => (
//     <GridToolbarContainer>
//          <div className='exportReport'>
//       <Typography variant="h3" gutterBottom>
//         Trial Balance Report
//       </Typography>
//       <GridToolbarExport />
//       </div>
//     </GridToolbarContainer>
    
//   );

//   return (
//     // <Container className="cardBody">
//     <div className="cardBody reportTable">
//       <div className="datatableGrid">
//       <div className="cardHeader">
//         <DataGridPro
//           rows={rows}
//           columns={columns}
//           autoHeight
//           pagination={false}
//           disableSelectionOnClick
//           slots={{ toolbar: CustomToolbar }}
//           density="compact"
//           getRowClassName={(params) => (params.row.group === 'Spacer' ? 'spacer-row' : '')}
//           hideFooterRowCount
//         />
//       </div>




//       </div>
//       </div>
//     // </Container>
//   );
// };

// export default TrialBalanceReport;

import React, { useMemo, useEffect, useState, useCallback } from "react";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";
import { Box, MenuItem, Select  } from "@mui/material";
import { GetApiwithHeader } from "src/Api/Api";
import { userAttributes } from "../../share/localData";
import { LicenseInfo } from "@mui/x-license";
import { makeStyles } from '@mui/styles';
import { useData } from "../../components/dataContext";
import {  CNav, CNavItem, CNavLink, CRow, CTabContent, CTabPane } from '@coreui/react';
import { Stack } from "react-bootstrap";

const TrialBalanceReport = () => {
  const { data, bankDataLocal } = useData();
  const accessToken = localStorage.getItem("token");
  LicenseInfo.setLicenseKey(
    "248a71a5e79c492f4b13769843fcf6dcTz04OTIzMCxFPTE3NDU3NTY1ODYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
  );
  const [trialData, setTrialData] = useState([]);
  const { cmpID } = userAttributes();
  const companyId = cmpID;
  const [activeKey, setActiveKey] = useState(1)
  const [ledgerData, setLedgerData] = useState([]);
  const [selectedYear, setSelectedYear] = useState("2022-2023");

  const headers = useMemo(
    () => ({
      Authorization: `Bearer ${accessToken}`,
    }),
    [accessToken]
  );

  const fetchData = async (e) => {
    try {
      const res = await GetApiwithHeader(
        `/api/BusinessCodes/TrialReport/${companyId}?financialyear=${e ? e :'2022-2023'}`,
        headers
      );
      console.log('res?.data?.data', res?.data?.data)
      setTrialData(res?.data?.data || []);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDataledger = async (e) => {
    try {
      const res = await GetApiwithHeader(
        `/api/Report/General-Ledger?companyId=${companyId}&type=Unstructured&financialyear=${e ? e :'2022-2023'}`,
        headers
      );
      console.log('ledger data unstructured', res?.data?.data)
      setLedgerData(res?.data?.data || []);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchDataledger()
  }, [companyId]);

  const useStyles = makeStyles({
    row: {
      '@media print': {
        innerHeight: '100px',
        pageBreakInside: 'avoid',
      },
    },
  });

  const columns = [
    {
      field: "codeName",
      headerName: "Code Name",
      width: 250,
      sortable: false,
    },
    {
      field: "NetAmount",
      headerName: "Net Amount",
      width: 150,
      sortable: false,
    },
    {
      field: "GstAmount",
      headerName: "GST",
      width: 150,
      sortable: false,
    },
  ];

  const preparedRows = [];
  const uniqueCatogoryNames = {};

  let grandTotalNetAmount = 0;
  let grandTotalGstAmount = 0;

  trialData && trialData?.forEach((row, index) => {
    if (!uniqueCatogoryNames[row.categoryName]) {
      uniqueCatogoryNames[row.categoryName] = true;

      preparedRows.push({
        id: `header-${row.id}`,
        categoryName: row.categoryName,
        isHeader: true,
      });
    }

    preparedRows.push({
      id: row.id,
      codeName: row.codeName,
      NetAmount: Number(row.netAmount),
      GstAmount: Number(row.gstAmount),
      isHeader: false,
    });

    grandTotalNetAmount = row.grandTotalNetAmount;
    grandTotalGstAmount = row.grandTotalGSTAmount;

    if (trialData[index + 1]?.categoryName !== row?.categoryName) {
      preparedRows.push({
        id: `subtotal-${row.id}`,
        codeName: 'Total',
        NetAmount: row?.totalAmount,
        GstAmount: row?.totalGST,
        isSubtotal: true,
      });
    }
  });

  preparedRows.push({
    id: 'grandtotal',
    codeName: 'GrandTotal',
    NetAmount: grandTotalNetAmount,
    GstAmount: grandTotalGstAmount,
    isGrandTotal: true,
  });


const preparedRowsforUnst = [];
const uniqueCodeNames = {};

let grandTotalNetAmounts = 0;
let grandTotalGstAmounts = 0;

ledgerData && ledgerData?.forEach((row, index) => {

  preparedRowsforUnst.push({
    id: row.id, 
    CodeName: row.codeName, // 
    NetAmount: Number(row.totalNetAmount),
    GstAmount: Number(row.totalGstAmount),
    // isHeader: false,
  });

  grandTotalNetAmounts = row.grandTotalNetAmount;
  grandTotalGstAmounts = row.grandTotalGstAmount;
});
preparedRowsforUnst.push({
  id: 'grandtotal',
  CodeName: 'GrandTotal',
  NetAmount: grandTotalNetAmounts,
  GstAmount: grandTotalGstAmounts,
  isGrandTotal: true,
});

  const columnsWithCodeName = [
    {
      field: "categoryName",
      headerName: "Category Name",
      width: 250,
      sortable: false,
    },
    ...columns,
  ];

  const columnsWithoutCategory = [
    {
      field: " ",
      headerName: "",
      width: 5,
      resizable: false,
      sortable: false,
      headerClassName: 'dataGridHeader'
    },
    {
      field: "CodeName",
      headerName: "Code Name",
      width: 300,
      sortable: false,
    },
    {
      field: "NetAmount",
      headerName: "Net Amount",
      width: 200,
      sortable: false,
    },
    {
      field: "GstAmount",
      headerName: "GST",
      width: 200,
      sortable: false,
    },
  ]

  const getRowClassName = (params) => {
    if (params.row.isHeader) {
      return "header-row";
    }
    if (params.row.isSubtotal) {
      return "subtotal-row";
    }
    if (params.row.isGrandTotal) {
      return "grandtotal-row";
    }
    return "pageBreakRow";
  };

  const CustomToolbar = ({title}) => (
    <div>
      <div style={{ fontSize: '24px', padding: '5px', float: 'left' }}>
      {title}
      </div>
      <GridToolbarContainer>
        <div className="exportReport" style={{ justifyContent: 'flex-start' }}>
          <Box ml={2}></Box>
          <Box ml={0}>
            <GridToolbarExport
              printOptions={{
                hideToolbar: true,
              }}
              csvOptions={{
                fileName: `${title} Report`,
                // delimiter: ';',
                // utf8WithBom: true,
              }}
            />
          </Box>
        </div>
      </GridToolbarContainer>
    </div>
  );

  const handleYearChange = useCallback((event) => {
    setSelectedYear(event.target.value);
    fetchDataledger(event.target.value);
    fetchData(event.target.value)
  }, []);

  return (
    <div className="cardBody reportTable">
       <Stack className="financial-stack">
      <Box className="financial-year-select" mt={2}>
        <Select
          labelId="financial-year-label"
          value={selectedYear || ''}
          onChange={handleYearChange}
          // label="Select Financial Year"
        >
          <MenuItem value="2023-2024">FY2024</MenuItem>
          <MenuItem value="2022-2023">FY2023</MenuItem>
          <MenuItem value="2021-2022">FY2022</MenuItem>
        </Select>
      </Box>
     
    </Stack>
      <div className="datatableGrid autoHeightTable cstm-tab-wrapper">
      <CNav variant="tabs" role="tablist" className="abnTabs">
                <CNavItem>
                    <CNavLink
                        href="#!"
                        active={activeKey === 1}
                        onClick={() => setActiveKey(1)}
                    >
                        Trial Balance - structured
                    </CNavLink>
                </CNavItem>
                <CNavItem>
                    <CNavLink
                        href="#!"
                        active={activeKey === 2}
                        onClick={() => setActiveKey(2)}
                    >
                       Trial Balance - unstructured
                    </CNavLink>
                </CNavItem>
            </CNav>
        <div className="cardHeader">
          <Box width={'100%'}>
              <CTabContent>
                  <CTabPane role="tabpanel" aria-labelledby="home-tab" visible={activeKey === 1}>
            <DataGridPro
              rows={preparedRows}
              columns={columnsWithCodeName}
              getRowClassName={getRowClassName}
              getRowHeight={() => 'auto'}
              rowSelection={false}
              autoHeight
              pagination={false}
              disableSelectionOnClick
              cellSelection
              slots={{
                toolbar: () => <CustomToolbar title="Trial Balance - structured" />,
                style: { backgroundColor: "green" },
              }}
              hideFooterRowCount
              classes={{
                row: useStyles,
              }}
            />
            </CTabPane>
            <CTabPane role="tabpanel" aria-labelledby="profile-tab" visible={activeKey === 2}>
            <DataGridPro
              rows={preparedRowsforUnst}
              columns={columnsWithoutCategory}
              getRowClassName={getRowClassName}
              getRowHeight={() => 'auto'}
              rowSelection={false}
              autoHeight
              pagination={false}
              disableSelectionOnClick
              cellSelection
              slots={{
                toolbar: () => <CustomToolbar title="Trial Balance - unstructured" />,
                style: { backgroundColor: "green" },
              }}
              hideFooterRowCount
              classes={{
                row: useStyles,
              }}
            />
            </CTabPane>
            </CTabContent>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default TrialBalanceReport;
