import React, { useMemo, useState, useRef } from "react";
import {
  CCol,
  CCardBody,
  CCard,
  CImage,
  CButton,
  CRow,
  CForm,
  CFormInput,
} from "@coreui/react";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DefaultImage from "../../assets/images/download.jpeg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useData } from "src/components/dataContext";
import { ToastContainer, toast } from "react-toastify";
import { userAttributes } from "../../share/localData";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from 'yup';
import { Row } from "react-bootstrap";
import { postApiWithHeader } from "src/Api/Api";
import { UpdateUserwithHeader } from "src/Api/Api";

const validationSchema = Yup.object().shape({
  FirstName: Yup.string().required('First Name is required'),
  LastName: Yup.string().required('Last Name is required'),
  AddressLine1: Yup.string().required('Address Line 1 is required'),
});

const Profile = () => {
  const { data } = useData();
  const [imageFile, setImageFile] = useState(null);
  const [imageChanged, setImageChanged] = useState(false);
  const accessToken = localStorage.getItem("token");
  const [validationErrors, setValidationErrors] = useState({});
  const userInfoString = localStorage.getItem('userInfo');
  const userInfo = JSON.parse(userInfoString);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    FirstName: userInfo ? userInfo.firstname || "" : "",
    LastName: userInfo ? userInfo.lastname || "" : "",
    AddressLine1: userInfo ? userInfo.address1 || '' : "",
    AddressLine2: userInfo ? userInfo.address2 || '' : "",
    Image: userInfo ? userInfo.profile || "" : ''
  });
  const { role } = userAttributes();
  const userRole = localStorage.getItem('userInfo');
  const fileInputRef = useRef(null);
  const Role = JSON.parse(userRole);
  console.log('userRole', Role.role[0])


  const headers = useMemo(
    () => ({
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${accessToken}`,
    }),
    [accessToken]
  );

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImageFile(URL.createObjectURL(file));
      setFormData({
        ...formData,
        Image: file,
      });
      setImageChanged(true);
    }
  };

  const handleRemoveImage = () => {
    setImageFile(null);
    setFormData({
      ...formData,
      Image: null,
    });
    setImageChanged(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    setValidationErrors({
      ...validationErrors,
      [name]: '',
    });
  };

  const handleBackClick = () => {
    navigate("/companyDetails");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(formData, { abortEarly: false });

      const payload = new FormData();
      payload.append('FirstName', formData.FirstName);
      payload.append('LastName', formData.LastName);
      payload.append('AddressLine1', formData.AddressLine1);
      payload.append('AddressLine2', formData.AddressLine2);

      if (imageChanged) {
        payload.append('Image', formData.Image);
      }

      const response = await postApiWithHeader("/api/auth/update-profile", payload, headers );
      console.log('response', response);

      if (response.data.statusCode === 100) {
        const { firstName, lastName, addressLine1, addressLine2, image } = response?.data?.data;
        const userInfoString = localStorage.getItem('userInfo');
        const userInfo = JSON.parse(userInfoString);
        userInfo.firstname = firstName;
        userInfo.lastname = lastName;
        userInfo.address1 = addressLine1;
        userInfo.address2 = addressLine2;
        if (imageChanged) {
          userInfo.profile = image;
        }
        localStorage.setItem('userInfo', JSON.stringify(userInfo));
        toast.success(response.data.message, {
          autoClose: 1000,
          onClose: () => {
            navigate("/companyDetails");
          }
        });
      }
    } catch (error) {
      const validationErrors = {};
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach((e) => {
          validationErrors[e.path] = e.message;
        });
        setValidationErrors(validationErrors);
      }
    }
  };


  return (
    <div>
      <ToastContainer position="top-right" />
      <div className="cardHeader mt-3">
        <h3>Personal Details</h3>
      </div>
      <div className="mt-2 profileSection">
        <CCard>
          <CCardBody className="mb-5 p-3">
            <CForm onSubmit={handleSubmit}>
              <CRow className="mt-3">
                  {/* <CCol>
                  <h1>Garry Jack</h1>
                  <h6>{Role.role[0]}</h6>
                <CCol className="mb-3">
                    <div className="profileThumb">
                      <CImage
                        rounded
                        thumbnail
                        src={imageFile || formData.Image || DefaultImage}

                      />
                    </div>
                  </CCol>
                  <CButton className="mr-2" onClick={handleRemoveImage} color="light">
                    Remove
                  </CButton>
                  <ModeEditIcon />
                  <CButton>
                    <label htmlFor="imageUpload" style={{ cursor: "pointer" }}>
                      Upload
                      <input
                        id="imageUpload"
                        type="file"
                        accept="image/*"
                        onChange={handleImageUpload}
                        style={{ display: "none" }}
                      />
                    </label>
                  </CButton>
                </CCol>  */}
                <CCol md={6} className="m-atuo">
                  <div className="ml-50">
                    <Row>
                      <CCol>
                        <div className="profileThumb">
                          <div className="photoEdit">
                          <CImage
                            src={imageFile || formData.Image || DefaultImage}
                          />
                      <div className="icon2">
                        <ModeEditIcon onClick={handleIconClick} />
                        <input
                          id="imageUpload"
                          type="file"
                          accept="image/*"
                          ref={fileInputRef}
                          onChange={handleImageUpload}
                          style={{ display: "none" }}
                        />
                      </div>
                      </div>
                        <div className="nameDetails"> 
                        <h3>{formData.FirstName} {formData.LastName}</h3>
                       <h6>{role}</h6>
                       </div>
                        </div>
                      </CCol>
                    </Row>




                    <h5>Profile Details</h5>
                    <hr />
                    <CRow className="mt-3">
                      <CCol md={6}>


                        <CFormInput
                          type="text"
                          id="FirstName"
                          name="FirstName"
                          label={
                            <>
                              First Name <span className="text-danger">*</span>
                            </>
                          }
                          placeholder="Enter First Name"
                          value={formData.FirstName}
                          onChange={handleInputChange}
                          invalid={!!validationErrors.FirstName}
                        />
                        {validationErrors.FirstName && (
                          <span className="text-danger">{validationErrors.FirstName}</span>
                        )}
                      </CCol>
                      <CCol md={6}>
                        <CFormInput
                          type="text"
                          id="LastName"
                          name="LastName"
                          label={
                            <>
                              Last Name <span className="text-danger">*</span>
                            </>
                          }
                          placeholder="Enter Last Name"
                          value={formData.LastName}
                          onChange={handleInputChange}
                        />
                        {validationErrors.LastName && (
                          <span className="text-danger">{validationErrors.LastName}</span>
                        )}
                      </CCol>
                    </CRow>
                    <CRow className="mt-4">
                      <CCol md={6} className="mt-2">
                        <CFormInput
                          type="text"
                          id="AddressLine1"
                          name="AddressLine1"
                          label={
                            <>
                              Address Line1 <span className="text-danger">*</span>
                            </>
                          }
                          placeholder="Enter Address Line 1"
                          value={formData.AddressLine1}
                          onChange={handleInputChange}
                        />
                        {validationErrors.AddressLine1 && (
                          <span className="text-danger">{validationErrors.AddressLine1}</span>
                        )}
                      </CCol>
                      <CCol md={6} className="mt-2">
                        <CFormInput
                          type="text"
                          id="AddressLine2"
                          name="AddressLine2"
                          label="Address Line2"
                          placeholder="Enter Address Line 2"
                          value={formData.AddressLine2}
                          onChange={handleInputChange}
                        />
                      </CCol>
                    </CRow>
                    <CRow className="mt-4 justify-content-end">
                      <CCol
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          marginLeft: "280px",
                        }}
                      >
                        <CButton color="light" onClick={handleBackClick} className="mr-2">
                          Cancel
                        </CButton>
                        <CButton type="submit">Update</CButton>
                      </CCol>
                    </CRow>
                  </div>
                </CCol>
              </CRow>
            </CForm>
          </CCardBody>
        </CCard>
      </div>
    </div>
  );
};

export default Profile;
