import React from 'react'
import Coding from '../coding/blp1'


const VerifyCoding = () => {
  return (
    <div><Coding verify={'verify'}/></div>
  )
}

export default VerifyCoding